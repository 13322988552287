<template>
   <v-snackbar v-model="clipboardSnackbar" :timeout="2000" bottom>
      Copied "{{clipboardText}}" to clipboard
      
      <template v-slot:action="{ attrs }">
         <v-btn
             color="blue"
             text
             v-bind="attrs"
             @click="clipboardSnackbar = false">
            Close
         </v-btn>
      </template>
   </v-snackbar>

</template>

<script>
   export default {
      name: "ClipboardSnackbar",
      data:()=>({
         clipboardSnackbar: false,
         clipboardText:""
      }),
      mounted(){
         this.$root.$on('updateClipboardSnackbar', data => {
            this.clipboardSnackbar=data;
         });
         this.$root.$on('updateClipboardText', data => {
            this.clipboardText=data;
         });
      }
   };
</script>

<style scoped>

</style>