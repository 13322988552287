<template>
   <v-container>
      <p>Related entities</p>
   </v-container>
</template>

<script>
   export default {
      name: "RelatedEntityList",
      props: {
         patientId: Number,
         customerId: Number
      }
   };
</script>

<style scoped>

</style>