<template>
  <v-row>
    <v-col md="7">
      <v-tabs vertical class="mt-4">
        <v-tabs-slider color="#0072cf"></v-tabs-slider>
        <v-tab class="mr-2" key="sincePreviousCall">Since Previous Call</v-tab>
        <v-tab-item key="sincePreviousCall">
          <v-card class="pr-10 min-height" elevation="2" outlined tile>
            <sincePreviousCall :patientId="patientId" :customerId="customerId" :key="componentKey"></sincePreviousCall>
          </v-card>
        </v-tab-item>
        <v-tab class="mr-2" key="subjective">Subjective</v-tab>
        <v-tab-item key="subjective">
          <v-card class="pr-10 min-height" elevation="2" outlined tile>
            <subjective :patientId="patientId" :customerId="customerId" :key="componentKey"></subjective>
          </v-card>
        </v-tab-item>
        <v-tab key="medical">Medical</v-tab>
        <v-tab-item key="medical">
          <v-card class="pr-10 min-height" elevation="2" outlined tile>
            <medical :patientId="patientId" :customerId="customerId" :key="componentKey"></medical>
          </v-card>
        </v-tab-item>
        <v-tab key="surgical">Surgical</v-tab>
        <v-tab-item key="surgical">
          <v-card class="pr-10 min-height" elevation="2" outlined tile>
            <surgical :patientId="patientId" :customerId="customerId" :key="componentKey"></surgical>
          </v-card>
        </v-tab-item>
        <v-tab key="social">Social</v-tab>
        <v-tab-item key="social">
          <v-card class="pr-10 min-height" elevation="2" outlined tile>
            <social :patientId="patientId" :customerId="customerId" :key="componentKey"></social>
          </v-card>
        </v-tab-item>
        <v-tab key="clinical">Clinical</v-tab>
        <v-tab-item key="clinical">
          <v-card class="pr-10 min-height" elevation="2" outlined tile>
            <clinical-tab :patientId="patientId" :customerId="customerId" :key="componentKey"></clinical-tab>
          </v-card>
        </v-tab-item>
        <v-tab class="mr-2" key="otherQuestion">Additional Questions</v-tab>
        <v-tab-item key="otherQuestion">
          <v-card class="pr-10 min-height" elevation="2" outlined tile>
            <otherQuestion :patientId="patientId" :customerId="customerId" :key="componentKey"></otherQuestion>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import Subjective from "@/components/Patient/History/Subjective";
import OtherQuestion from "@/components/Patient/History/OtherQuestion";
import SincePreviousCall from "@/components/Patient/History/SincePreviousCall";
import Medical from "@/components/Patient/History/Medical";
import Surgical from "@/components/Patient/History/Surgical";
import Social from "@/components/Patient/History/Social";
import ClinicalTab from "@/components/Patient/History/Clinical/ClinicalTab";

export default {
  name: "PatientHistory",
  props: {
    patientId: Number,
    customerId: Number
  },
  components: {
    Subjective,
    Medical,
    Surgical,
    Social,
    ClinicalTab,
    OtherQuestion,
    SincePreviousCall
  },
  computed: {
    componentKey() {
      return this.customerId + ":" + this.patientId;
    }
  }
};
</script>

<style scoped lang="scss">
.v-tabs {
  .v-tab {
    text-transform: capitalize;

    &.v-tab--active {
      color: #0072cf;
    }
  }

  .min-height {
    min-height: 300px;
  }
}
</style>