import * as BaseService from './BaseService';
import * as customer from '../types/customer';
import * as textConverter from '../utils/textConverter';

export default class WorklistItemService extends BaseService.BaseService {
   private url = '/api/WorklistItem';
      
   constructor() {
     super();     
   }
  
   async getWorklistItem(customerId: number, id: number): Promise<customer.WorklistItem>{
    let url = this.url + `/${customerId}/${id}`;
    let response;               
    response = await this.api.get(url).catch(err => {
        throw err;          
      });
    response.data["note"] = textConverter.htmlToPlainText(response.data["note"]);
    return response.data;
  }

  async putWorklistItem(customerId: number,id: number, worklistItem: customer.WorklistItem){
    worklistItem.note = textConverter.plainTextToHtml(worklistItem.note);
    let url = this.url + `/${customerId}/${id}`;     
    let response = await this.api.put(url,worklistItem).catch(err => {         
        throw err;          
      });
    return response;
  }

  async postWorklistItem(customerId: number, worklistItem: customer.WorklistItem){
    worklistItem.note = textConverter.plainTextToHtml(worklistItem.note);
    let url = `/api/WorklistItem/${customerId}`;            
    let response = await this.api.post(url,worklistItem).catch(err => {
      throw err;          
    });                          
    return response;
  }
   
  }