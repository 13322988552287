import ReferenceDataService from "../services/ReferenceDataService";

export const contactDetailsUtils = {
   data: () => ({
      phoneLabelRules: [
         v => !!v || "Phone Label is required"
      ],
      addressLine1Rules: [
         v => !!v || "Address is required"
      ],
      cityRules: [
         v => !!v || "City is required"
      ],
      stateRules: [
         v => !!v || "State is required"
      ],
      phoneNumberRules: [
         v => !!v || "Phone Number is required",
         (v) => v.replace(/\D/g, "").length == 10 || "Phone Number must be valid"
      ],
      phoneNumberValidRules: [
         (v) => !v || v.replace(/\D/g, "").length == 10 || "Phone Number must be valid"
      ],
      emailRules: [
         v => !!v || "E-mail is required",
         (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(v) || "E-mail must be valid"
      ],
      emailValidRules: [
         (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(v) || "E-mail must be valid"
      ],
      zipCodeRules: [
         v => !!v || "Zip Code is required",
         (v) => v.length == 5 || "E-Zip Code must be valid"
      ],
      states: [],
      serialNumberRules: [
         (v) => (v.length == 15 ||  v.replace("-", "").length == 9) || "Device Serial Number must be valid ex:'999-999999-9999' or '999999-999'"
      ],
      autocomplete: {},
   }),
   created: function () {
      this.getStates();
      this.initMaps();
   },
   methods: {
      formatPhone(phoneNumber) {
         if (!phoneNumber.includes("-")) {
            return phoneNumber.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "$1-$2-$3");
         } else {
            return phoneNumber;
         }
      },
      stateName: function (id) {
         
         let foundState = this.states.find((s) => s.stateId == id);
         if (foundState != undefined) {
            return foundState.name;
         } else {
            return "Unknown";
         }
      },
      getStates: function () {
         
         if (this.$store.state.states.length == 0) {
            
            new ReferenceDataService()
               .getStates()
               .then((resp) => {
                  
                  this.states = resp;
                  
                  this.$store.commit("setStates", this.states);
               })
               .catch((err) => {
                  console.log(err);
               });
         } else {
            
            this.states = this.$store.state.states;
         }
      },
     initMaps() {
         // Add google map script if not exist; if exist, return true       
         if (window.google != null && window.google != undefined) {
            this.initAutocomplete();
            return true;
         }
      },
      isGoogleMapsInitialized() {
         return (document.querySelectorAll(`script[src="${this.googleMapUrl}"]`).length > 0)
      },
      initAutocomplete() {
         // Create the autocomplete object, restricting the search predictions to
         // geographical location types.
         if (this.$refs.autocomplete === undefined || this.$refs.autocomplete[0] === undefined) {
            setTimeout(() => {
               this.initAutocomplete();
            }, 2000);
         } else {
            this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.autocomplete[0].$refs.input);
            this.autocomplete.setFields(["address_component"]);
            this.autocomplete.setComponentRestrictions({"country": ["us"]});
            this.autocomplete.addListener("place_changed", () => {
               let place = this.autocomplete.getPlace();
               this.fillInAddress(place);
            });
         }
      },
      stateIdFromShortName: function (name) {
         let foundState = this.states.find((s) => s.name == name);
         if (foundState != undefined) {
            return foundState.stateId;
         } else {
            return null;
         }
      },
      stateFromShortName: function (name) {
         let foundState = this.states.find((s) => s.name === name);
         if (foundState !== undefined) {
            return foundState;
         } else {
            return null;
         }
      },
      //Device SerialNumberMask
      maskSerialNumber(value) {
         let serialNumber = value.replace(/[^0-9]/g, '');
         if (serialNumber.length > 10) {
             return [this.formatFutureStateNumber(serialNumber)];
         }else{
            return [this.formatCurrentStateNumber(serialNumber)];
         }
     },
     formatCurrentStateNumber(serialNumber) {
         return serialNumber.replace(/^(\d{6})(\d{3})$/, '$1-$2');
      },
     formatFutureStateNumber(serialNumber) {
         return serialNumber.replace(/^(\d{3})(\d{6})(\d{4})$/, '$1-$2-$3');
      }
   }
};
