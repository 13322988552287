import {User} from "@/types/userTypes/User";
import gql from 'graphql-tag';
import {SystemRoleEnum, PodRoleEnum} from "@/types/enum";

export class PodAccessService {
    readonly userId!: number;
    readonly podId: number | null;
    readonly apollo;
    firstLoad = true;
    user: User | undefined;
    isSuperAdmin = false;

    readonly assignmentRole = {
        PodAdministrator: [
            PodRoleEnum.PodTeamLeader,
            PodRoleEnum.PodSupervisor,
            PodRoleEnum.PodNurse,
            PodRoleEnum.PodRep
        ],
        PodSupervisor: [
            PodRoleEnum.PodTeamLeader,
            PodRoleEnum.PodNurse,
            PodRoleEnum.PodRep
        ],
        PodTeamLeader: [
            PodRoleEnum.PodNurse,
            PodRoleEnum.PodRep
        ],
    };

    readonly displayDashboardRoles = [
        SystemRoleEnum.Administrator,
        SystemRoleEnum.CareManager,
        SystemRoleEnum.DeviceOperations,
        SystemRoleEnum.ClinicalOperations,
        SystemRoleEnum.VASales,
        SystemRoleEnum.CommercialSales,
        SystemRoleEnum.SofwareDeveloper,
        SystemRoleEnum.CareManagerSuperviser,
        SystemRoleEnum.VASalesSuperviser,
        SystemRoleEnum.CommercialSalesSuperviser,
        SystemRoleEnum.EscalationsWorklistUser,
        SystemRoleEnum.CohortAdministrator
    ];
    readonly canAddPods = [PodRoleEnum.PodAdministrator];
    readonly canEditPods = [PodRoleEnum.PodAdministrator];
    readonly canViewPods = [
        SystemRoleEnum.CareManager,
        SystemRoleEnum.VASales,
        SystemRoleEnum.CommercialSales,
        SystemRoleEnum.CareManagerSuperviser,
        SystemRoleEnum.VASalesSuperviser,
        SystemRoleEnum.CommercialSalesSuperviser,
        SystemRoleEnum.CohortAdministrator
    ];


    constructor(userId: number, podId: number | null, apollo) {
        this.userId = userId;
        this.podId = podId;
        this.apollo = apollo;
    }

    async getUser() {
        if (this.podId != null) {
            await this.userQueryWithSpecificPod()
                .then(response => {
                    this.user = response.data.user
                });

            return;
        }

        await this.userQueryWithAnyPod()
            .then(response => {
                this.user = response.data.user
            });
    }
   
   userQueryWithSpecificPod() {
      const UserPodRole = gql`query {
         user(userId: ${this.userId}){
            userRoles(input: {take: 100, skip: 0}){
               count,
               data{
                  roleId,
                  role{
                     name
                  }
               }
            },
            podRole(podId:${this.podId}){
               podRoleId,
               name
            }
         }
      }`;

       return this.apollo.query({query: UserPodRole});
   }
   
   userQueryWithAnyPod() {
      const UserPodRole = gql`query {
         user(userId: ${this.userId}){
            userRoles(input: {take: 100, skip: 0}){
               count,
               data{
                  roleId,
                  role{
                     name
                  }
               }
            },
            podRoles(input: {take: 100, skip: 0}){
               count,
               data{
                  podRoleId,
                  name
               }
            }
         }
      }`;

       return this.apollo.query({query: UserPodRole});
   }

    setIsSuperAdmin() {
        if (!this.user || typeof this.user.userRoles === 'undefined') {
            this.isSuperAdmin = false;
            return;
        }

        if (this.user.userRoles.data.length === 0) {
            this.isSuperAdmin = false;
            return;
        }

        let isCohortStart = false;
        this.user.userRoles.data.forEach((item) => {
            let role = SystemRoleEnum.CohortAdministrator;
            if (item.roleId === role) {
                isCohortStart = true;
            }
        });
        this.isSuperAdmin = isCohortStart;
    }

    displayByUserPodRoleAssignment(podRoleId) {
        if (this.isSuperAdmin) {
            return true;
        }

        if (!this.user || typeof this.user.podRole === 'undefined' || !this.user.podRole) return false;

        let podRole = this.assignmentRole[this.user.podRole.name];
        if (!podRole || typeof podRole === 'undefined') return false;

        return podRole.includes(podRoleId);
    }

    displayAddPodAssignment() {
        if (this.isSuperAdmin) {
            return true;
        }

        if (!this.user || typeof this.user.podRole === 'undefined' || !this.user.podRole) return false;

        let allowedToAddPodRole = this.assignmentRole[this.user.podRole.name];
        let result = !allowedToAddPodRole || typeof allowedToAddPodRole === 'undefined' ? false : true;

        return result;
    }

    displayMembershipActionByPodAssignment() {
        if (this.isSuperAdmin) {
            return true;
        }

        if (!this.user || typeof this.user.podRole === 'undefined' || !this.user.podRole) return false;

        let allowedMembershipActionPodRole = this.assignmentRole[this.user.podRole.name];
        let result = !allowedMembershipActionPodRole || typeof allowedMembershipActionPodRole === 'undefined' ? false : true;

        return result;
    }

    displayDashboard() {
        if (this.isSuperAdmin || this.hasDisplayDashboardSystemRole()
        ) {
            return true;
        }
    
        return false;
    }

    hasDisplayDashboardSystemRole() {
        if (!this.user || typeof this.user.userRoles === 'undefined' || !this.user.userRoles) return false;

        let hasDisplayDashboardRoles = false;
        for (let item of this.user.userRoles.data) {
            let hasRole = this.displayDashboardRoles.includes(item.roleId);
            if (hasRole && typeof hasRole !== 'undefined') {
                hasDisplayDashboardRoles = true;
                break;
            }
        }

        if (hasDisplayDashboardRoles) return true;
    }

    hasPodAssignment() {
        if (!this.user || typeof this.user.podRoles === 'undefined'
            || !this.user.podRoles || this.user.podRoles.count == 0) return false;

        return true;
    }

    canAddPod() {
        if (this.isSuperAdmin || this.hasAddPodPodRole()) {
            return true;
        }

        return false;
    }

    hasAddPodPodRole() {
        if (!this.user || typeof this.user.podRoles === 'undefined' || !this.user.podRoles) return false;

        let hasEditPodsRoles = false;
        for (let item of this.user.podRoles.data) {
            let hasRole = this.canAddPods.includes(item.podRoleId);
            if (hasRole && typeof hasRole !== 'undefined') {
                hasEditPodsRoles = true;
                break;
            }
        }

        if (hasEditPodsRoles) return true;
    }

    canEditPod(podId, assignments) {
        if (this.isSuperAdmin || this.isAssignedToPod(podId, assignments)) {
            return true;
        }

        return false;
    }

    isAssignedToPod(podId, assignments) {
        let hasEditPodRole = false;

        if (assignments.count == 0) return hasEditPodRole;

        for (let item of assignments.data) {
            let hasRole = this.canEditPods.includes(item.podRole.podRoleId);
            if (hasRole && typeof hasRole !== 'undefined' && podId === item.podId) {
                hasEditPodRole = true;
                break;
            }
        }

        return hasEditPodRole
    }

    canViewPodList() {
        if (!this.user || typeof this.user.userRoles === 'undefined' || !this.user.userRoles) return false;

        let hasViewPodListRole = false;
        for (let item of this.user.userRoles.data) {
            let hasRole = this.canViewPods.includes(item.roleId);
            if (hasRole && typeof hasRole !== 'undefined') {
                hasViewPodListRole = true;
                break;
            }
        }

        if (hasViewPodListRole) return true;
    }
    
    canDeletePod(){
        if (this.isSuperAdmin) return true;
        
        return false;
    }
}