import * as BaseService from './BaseService';
import * as customer from '../types/customer';

export default class MemberDocumentService extends BaseService.BaseService {
   private baseUrl = '/api/MemberDocument';
      
   constructor() {
     super();
   }

   async putDocument(customerId: number, documentId: number, document: customer.MemberDocument){
    let url = `${this.baseUrl}/${customerId}/${documentId}`;
    return await this.api.put(url, document).catch(err => {
        throw err;
    });
  }

  async postDocument(customerId: number, document: customer.MemberDocument){
    let url = `${this.baseUrl}/${customerId}`;
    let response = await this.api.post(url, document).catch(err => {
      throw err;          
    });                          
    return response;
  }

  async getDocuments(customerId: number, memberId: number): Promise<Array<customer.MemberDocument>>{
    let url = `${this.baseUrl}/${customerId}/${memberId}`;
    let response = (await this.api.get(url)).data as Array<customer.MemberDocument>;
    return response;
  }
}
