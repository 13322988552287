export default {
    computed:{
        //
    },
    watch:{
        //
    },
    methods: {
        hasRole(roleName) {
            return this.$store.state.achillesUser.userRoles &&
                this.$store.state.achillesUser.userRoles.some((r) =>
                    r.role.name.toLowerCase() === roleName.toLowerCase()
                );
        },
        hasAccess(accessName) {
            return this.$store.state.achillesUser.userRoles &&
                this.$store.state.achillesUser.userRoles.some((r) =>
                    r.role.roleAccesses &&
                    r.role.roleAccesses.some(ra => ra.access.name.toLowerCase() === accessName.toLowerCase())
                );
        }
    }
}