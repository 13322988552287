<template>
    <div>
        <v-col>
            <v-card max-width="375" class="mx-auto" :loading="isLoading">
                <v-alert :value="alertRef.alert"
                         :type="alertRef.alertType"
                         dense
                         transition="fade-transition">
                    {{alertRef.alertMessage}}
                </v-alert>
                <v-row>
                    <v-col offset-md="10">
                        <v-menu bottom right v-if="editMode==''">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon class="mt-1">mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(action, i) in actions"
                                             :key="i"
                                             link
                                             @click="actionSelected(action)">
                                    <v-list-item-title>{{action}}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-icon color="secondary" large class="mr-2" v-if="editMode==='Delete'" @click="clearDeleteMode">
                            mdi-close-circle
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row class="mt-n7">
                    <v-col>
                        <v-list>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="indigo"> mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold">
                                        {{
                                 entityType == "Patient"
                                 ? patient.firstName + " " + patient.lastName
                                 : ""
                              }}
                           </v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>
                  </v-list>
               </v-col>
            </v-row>
            <v-row class="mt-n7">
               <v-col>
                  <v-alert
                      dense
                      outlined
                      type="error"
                      v-if="patient.hasRequestedDoNotCall">
                     Patient requested <strong>DO NOT CALL</strong>
                  </v-alert>
                  <v-list two-line>
                   <v-list-item v-for="item in patientPhones" :key="item.entityPhoneId + 'a'">
                        <v-list-item-icon>
                           <v-icon color="indigo"> mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                           <v-list-item-title v-on:click="copyToClipboard(formatPhone(item.phoneNumber))">
                              {{formatPhone(item.phoneNumber)}}
                           </v-list-item-title>
                           <v-list-item-subtitle>{{item.phoneType}}</v-list-item-subtitle>
                           <v-list-item-subtitle>{{item.note}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon v-if="editMode==='Delete'">
                           <v-icon color="red" @click="removePhone(item)">mdi-minus-circle</v-icon>
                        </v-list-item-icon>
                     </v-list-item>

                     <v-list-item v-if="showOptOutTextFeature" style="margin-left: 56px">
                          <v-list-item-content>
                              <v-list-item-title>
                                 Opt out from Texts
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                  {{this.patient.optOutText === true ? "Yes" : "No"}}
                              </v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>

                     <v-list-item v-if="showOptOutEmailFeature" style="margin-left: 56px">
                          <v-list-item-content>
                              <v-list-item-title>
                                 Opt out from Emails
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                  {{this.patient.optOutEmail === true ? "Yes" : "No"}}
                              </v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item>
                     
                     <v-divider inset></v-divider>

                     <v-list-item v-for="item in patientEmails" :key="item.entityEmailId + 'b'">
                        <v-list-item-icon>
                           <v-icon color="indigo"> mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                           <v-list-item-title>{{item.emailAddress}}</v-list-item-title>
                           <v-list-item-subtitle>{{item.note}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon v-if="editMode==='Delete'">
                           <v-icon color="red" @click="removeEmail(item)">mdi-minus-circle</v-icon>
                        </v-list-item-icon>
                     </v-list-item>
                     <v-list-item v-if="showPreferredModeOfContactFeature" style="margin-left: 56px">
                        <v-list-item-content>
                           <v-list-item-title>
                              Preferred Form of Contact
                           </v-list-item-title>
                           <v-list-item-subtitle>
                              {{this.preferredModeOfContactDescription}}
                           </v-list-item-subtitle>
                        </v-list-item-content>
                     </v-list-item>
                     <v-divider inset></v-divider>
                     <v-list-item v-for="item in patientAddresses" :key="item.entityAddressId + 'c'">
                        <v-list-item-icon>
                           <v-icon color="indigo"> mdi-map-marker</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                           <v-list-item-title>{{item.addressLine1}} {{item.addressLine2 ? " " + item.addressLine2 : item.addressLine2}}
                           </v-list-item-title>
                           <v-list-item-subtitle>{{item.city}}, {{stateName(item.stateId)}} {{item.zipCode}}
                           </v-list-item-subtitle>
                        </v-list-item-content>
                     </v-list-item>
                  </v-list>
               </v-col>
            </v-row>
         </v-card>
      </v-col>
      <v-snackbar v-model="clipboardSnackbar" :timeout="1100" bottom>
         Copied "{{clipboardText}}" to clipboard
         
         <template v-slot:action="{ attrs }">
            <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="clipboardSnackbar = false">
               Close
            </v-btn>
         </template>
      </v-snackbar>
      <v-dialog v-model="isEditMode" width="800" persistent>
         <v-card>
            <v-card-title class="headline">{{editMode}} Contact</v-card-title>
            <v-card-text>
               <v-container>
                  <div v-if="editMode == 'Edit'">
                     <v-form v-model="editFormValid" lazy-validation>
                        <v-row
                            v-for="phone in patientPhones"
                            :key="phone.entityPhoneId + 'a'">
                           <v-col md="6" v-if="patientPhones.length > 0">
                              <v-text-field
                                  v-model="phone.phoneNumber"
                                  label="Phone Number"
                                  required
                                  prepend-icon="mdi-phone"
                                  v-mask="'###-###-####'"
                                  :rules="$data.phoneNumberRules"></v-text-field>
                           </v-col>
                           <v-col md="6">
                              <v-text-field
                                  v-model="phone.note"
                                  label="Phone Label"
                                  required
                                  :rules="$data.phoneLabelRules"></v-text-field>
                           </v-col>
                        </v-row>
                        <v-row v-if="showOptOutTextFeature">
                           <v-col>
                              <v-checkbox v-model="patient.optOutText" label="Opt out from Texts"></v-checkbox>
                           </v-col>
                        </v-row>
                        <v-row v-if="showOptOutEmailFeature">
                           <v-col>
                              <v-checkbox v-model="patient.optOutEmail" label="Opt out from Emails"></v-checkbox>
                           </v-col>
                        </v-row>
                        <v-row
                            v-for="email in patientEmails"
                            :key="email.entityEmailId + 'b'">
                           <v-col md="6" v-if="patientEmails.length > 0">
                              <v-text-field
                                  v-model="email.emailAddress"
                                  label="Email"
                                  required
                                  prepend-icon="mdi-email"
                                  :rules="$data.emailRules"></v-text-field>
                           </v-col>
                        </v-row>
                        <v-row v-if="showPreferredModeOfContactFeature">
                           <v-col md="6">
                              <v-select style="margin-left: 33px"
                                 :items="preferredModeOfContacts"
                                 item-text="description"
                                 item-value="preferredModeOfContactId"
                                 v-model="preferredModeOfContact"
                                 label="Preferred Form of Contact"
                                 return-object>
                              </v-select>
                           </v-col>
                        </v-row>
                        <v-row
                            v-for="address in patientAddresses"
                            :key="address.entityAddressId + 'c'">
                           <v-col md="12" v-if="patientAddresses.length > 0">
                              <v-row>
                                 <v-col md="12">
                                    <v-text-field
                                        prepend-icon="mdi-map-marker"
                                        ref="autocomplete"
                                        label="Search New Address"
                                        placeholder=""
                                        type="search"></v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col md="12">
                                    Current Address: <a :href="googleMapsDirectionUrl">
                                    {{address.addressLine1}}{{address.addressLine2}}, {{address.city}},
                                    {{stateName(address.stateId)}} {{address.zipCode}} </a>
                                 </v-col>
                              </v-row>
                              <v-row>
                                 <v-col md="6">
                                    <v-text-field
                                        v-model="address.addressLine1"
                                        label="Address Line 1"
                                        required
                                        :rules="$data.addressLine1Rules"
                                        prepend-icon="mdi-map-marker"></v-text-field>
                                 </v-col>
                                 <v-col md="6">
                                    <v-text-field
                                        v-model="address.addressLine2"
                                        label="Address Line 2"
                                        required
                                        prepend-icon="mdi-map-marker"></v-text-field>
                                 </v-col>
                              
                              </v-row>
                              <v-row>
                                 <v-col md="6">
                                    <v-text-field
                                        v-model="address.city"
                                        label="City"
                                        :rules="$data.cityRules"
                                        required
                                        prepend-icon="mdi-map-marker"></v-text-field>
                                 </v-col>
                                 <v-col md="6">
                                    <v-select
                                        :items="$data.states"
                                        :rules="$data.stateRules"
                                        item-text="description"
                                        item-value="stateId"
                                        v-model="address.stateId"
                                        label="State"
                                        required
                                        prepend-icon="mdi-map-marker">
                                    </v-select>
                                 </v-col>
                                 <v-col md="6">
                                    <v-text-field
                                        v-model="address.zipCode"
                                        label="Zip Code"
                                        required
                                        prepend-icon="mdi-map-marker"
                                        v-mask="'#####'"
                                        :rules="$data.zipCodeRules"></v-text-field>
                                 </v-col>
                              </v-row>
                           </v-col>
                        </v-row>
                     </v-form>
                  </div>
                  <div v-if="editMode == 'Add'">
                     <v-form v-model="addFormValid" lazy-validation>
                        <v-row v-for="(phone, index) in newPatientPhones" :key="index">
                           <v-col md="5">
                              <v-text-field
                                  v-model="phone.phoneNumber"
                                  label="Phone Number"
                                  required
                                  prepend-icon="mdi-phone"
                                  v-mask="'###-###-####'"
                                  :rules="$data.phoneNumberValidRules"></v-text-field>
                           </v-col>
                           <v-col md="5">
                              <v-text-field v-model="phone.note" label="Phone Label"
                                            required :rules="!!phone.phoneNumber?phoneLabelRules:[]"></v-text-field>
                           </v-col>
                           <v-col md="2">
                              <v-btn
                                  v-if="index > 0"
                                  fab
                                  dark
                                  x-small
                                  color="red"
                                  class="mt-4"
                                  @click="removeNewPhone(index)">
                                 <v-icon dark> mdi-minus</v-icon>
                              </v-btn>
                              <v-btn
                                  v-if="index == 0"
                                  fab
                                  dark
                                  x-small
                                  color="green"
                                  class="mt-4"
                                  @click="addNewPhone()">
                                 <v-icon dark> mdi-plus</v-icon>
                              </v-btn>
                           </v-col>
                        </v-row>
                        <v-row>
                           <v-col>
                              <v-checkbox v-model="patient.optOutText" label="Opt out from Texts"></v-checkbox>
                           </v-col>
                        </v-row>
                        <v-row>
                           <v-col>
                              <v-checkbox v-model="patient.optOutEmail" label="Opt out from Emails"></v-checkbox>
                           </v-col>
                        </v-row>
                        <v-row
                           v-for="email in newPatientEmails"
                            :key="email.entityEmailId">
                           <v-col md="5" v-if="patientEmails.length==0">
                              <v-text-field
                                  v-model="email.emailAddress"
                                  label="Email"
                                  prepend-icon="mdi-email"
                                  :rules="$data.emailValidRules"></v-text-field>
                           </v-col>
                        </v-row>
                        <v-row v-if="showPreferredModeOfContactFeature">
                           <v-col md="5">
                              <v-select style="margin-left: 33px"
                                 :items="preferredModeOfContacts"
                                 item-text="description"
                                 item-value="preferredModeOfContactId"
                                 v-model="preferredModeOfContact"
                                 label="Preferred Form of Contact"
                                 return-object>
                              </v-select>
                           </v-col>
                        </v-row>
                        <v-row
                            v-for="address in newPatientAddresses"
                            :key="address.entityAddressId">
                           <v-col md="12" v-if="patientAddresses.length==0">
                              <v-row>
                                 <v-col md="12">
                                    <v-text-field
                                        prepend-icon="mdi-map-marker"
                                        ref="autocomplete"
                                        label="Search New Address"
                                        placeholder=""
                                        type="search">
                                    </v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row v-if="patientAddresses.length==0">
                                 <v-col md="6">
                                    <v-text-field
                                        v-model="address.addressLine1"
                                        label="Address Line 1"
                                        required
                                        :rules="$data.addressLine1Rules"
                                        prepend-icon="mdi-map-marker"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col md="6">
                                    <v-text-field
                                        v-model="address.addressLine2"
                                        label="Address Line 2"
                                        required
                                        prepend-icon="mdi-map-marker"
                                    ></v-text-field>
                                 </v-col>
                              </v-row>
                              <v-row v-if="patientAddresses.length==0">
                                 <v-col md="6">
                                    <v-text-field
                                        v-model="address.city"
                                        label="City"
                                        :rules="$data.cityRules"
                                        required
                                        prepend-icon="mdi-map-marker"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col md="6">
                                    <v-select
                                        :items="$data.states"
                                        :rules="$data.stateRules"
                                        item-text="description"
                                        item-value="stateId"
                                        v-model="address.stateId"
                                        label="State"
                                        required
                                        prepend-icon="mdi-map-marker">
                                    </v-select>
                                 </v-col>
                                 <v-col md="6">
                                    <v-text-field
                                        v-model="address.zipCode"
                                        label="Zip Code"
                                        required
                                        prepend-icon="mdi-map-marker"
                                        v-mask="'#####'"
                                        :rules="$data.zipCodeRules"></v-text-field>
                                 </v-col>
                              </v-row>
                           </v-col>
                        </v-row>
                     </v-form>
                  </div>
               </v-container>
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn text @click="cancel"> Cancel</v-btn>
               <v-btn text @click="save"> Save</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
      <v-dialog v-model="deleteConfirmDialog" v-if="deleteConfirmDialog"
          persistent
          max-width="500"
          content-class="confirm-dialog">
         <v-card>
            <v-card-title class="headline primary">
               Confirm Delete
            </v-card-title>
            <v-card-text>Are you sure you want to delete Patient {{deletedItemInfo.type}} {{
                  deletedItemInfo.type == "Email" ? deletedItemInfo.item.emailAddress :
                  deletedItemInfo.item.phoneNumber
                    }}?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
                    <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
   import PatientService from "../services/PatientService";
   import {EntityTypeEnum} from "../types/enum";
   import EntityContactService from "../services/EntityContactService";
   import * as Shared from "../shared";
   import EntityLockService from "../services/EntityLockService";
   import ReferenceDataService from "../services/ReferenceDataService";
   import {contactDetailsUtils} from "./../mixins/contactDetailsUtils";
   import {UpdatePatientPreferredModeOfContact} from "./Patient/mutations/update-patient-preferred-mode-of-contact.graphql";

   const genericErrorMessage = "An exception has occurred. Please try again or contact the HelpDesk.";
          
   export default {
      props: ["entityType", "patient", "customerId"],
      mixins:[contactDetailsUtils],
      data: () => ({
         actions: ["Add", "Edit", "Delete"],
         initialPatient: {},
         preferredModeOfContacts: [],
         preferredModeOfContact: {
            description: '',
            preferredModeOfContactId: -1
         }, 
         showPreferredModeOfContactFeature: process.env.VUE_APP_PATIENT_PREFERRED_MODE_OF_CONTACT === "enabled",
         showOptOutEmailFeature: process.env.VUE_APP_PATIENT_OPTOUT_TO_EMAIL === "enabled",
         showOptOutTextFeature: process.env.VUE_APP_PATIENT_OPTOUT_TO_TEXT === "enabled",
         patientPhones: [],
         autocomplete: {},
         patientEmails: [],
         patientAddresses: [],
         oldPatientPhones: [],
         oldPatientEmails: [],
         oldPatientAddresses: [],
         editedEntityPhoneIds: [],
         editedEntityEmailIds: [],
         editedEntityAddressIds: [],
         deletedEntityPhoneIds: [],
         deletedEntityEmailIds: [],
         deletedEntityAddressIds: [],
         newPatientPhones: [
            {
               entityPhoneId: null,
               phoneNumber: "",
               note: "",
               entityId: "",
               entityTypeId: "",
               updatedByUserId: "",
               created: ""
            }
         ],
         newPatientEmails: [
            {
               entityEmailId: null,
               emailAddress: "",
               entityId: "",
               entityTypeId: "",
               updatedByUserId: "",
               created: ""
            }
         ],
         newPatientAddresses: [
            {
               entityAddressId: null,
               addressLine1: "",
               addressLine2: "",
               city: "",
               stateId: "",
               zipCode: "",
               entityTypeId: "",
               entityId: "",
               updatedByUserId: "",
               created: ""
            }
         ],
         caregivers: [],
         isLoading: false,
         isEditMode: false,
         editMode: "",
         editFormValid: false,
         addFormValid: false,
         alertRef: {
            alert: false,
            alertType: "success",
            alertMessage: ""
         },
         deleteConfirmDialog: false,
         deletedItemInfo: {
            type: "",
            item: {}
         },
         googleMapsDirectionUrl: "",
         clipboardSnackbar: false,
         clipboardText: "",
         updatePatientPreferredModeOfContactSuccessful: false,
         updatePatientSuccessful: false
      }),
      computed: {
         contactInfo: function () {
            let contactInfo = {
               customerId: this.customerId,
               patient: this.patient,
               patientPhones: this.patientPhones,
               patientEmails: this.patientEmails,
               patientAddresses: this.patientAddresses,
               states: this.$data.states
            };
            return contactInfo;
         },
         preferredModeOfContactDescription: function() {
            let preferredModeOfContact = this.preferredModeOfContacts.find(x => x.preferredModeOfContactId == this.preferredModeOfContact.preferredModeOfContactId);

            if (preferredModeOfContact === undefined){
               return 'Unknown';
            }

            return preferredModeOfContact.description;
         }
      },
      async mounted() {
         this.initAutocomplete();
         this.lookupData();
         Object.assign(this.initialPatient, this.patient);
      },
      watch: {
         patient(val) {
            // Items have already been requested
            if (this.isLoading) {
               return;
            }
            if (val === "" || val === null || val === undefined) {
               return;
            }
            // cancel pending call
            clearTimeout(this._timerId);
            // delay new call 500ms
            this._timerId = setTimeout(() => {
               this.lookupData();
            }, 500);
         },
         patientPhones:
             {
                handler: function (after) {
                    if (this.editMode == "Edit") {
                        let foundEntityPhones = after.filter((p, idx) => {
                            return Object.keys(p).some((prop) => {
                                return p[prop] !== this.oldPatientPhones[idx][prop];
                            });
                        });
                        foundEntityPhones.forEach((foundEntityPhone) => {
                            if (this.editedEntityPhoneIds.indexOf(foundEntityPhone.entityPhoneId) === -1) {
                                this.editedEntityPhoneIds.push(foundEntityPhone.entityPhoneId);
                            }
                        });
                    }
                },
                deep: true
            },
            patientAddresses:
            {
                handler: function (after) {
                    let foundEntityAddress = this.patientAddresses.find(
                        (a) => a.entityAddressId == after[0].entityAddressId);
                    if (foundEntityAddress != undefined && this.editMode == "Edit") {
                        if (this.editedEntityAddressIds.indexOf(foundEntityAddress.entityAddressId) === -1) {
                            this.editedEntityAddressIds.push(foundEntityAddress.entityAddressId);
                        }
                    }
                },
                deep: true

            },
            patientEmails:
            {
                handler: function (after) {
                    let foundEntityEmail = this.patientEmails.find((e) => e.entityEmailId == after[0].entityEmailId);
                    if (foundEntityEmail != undefined && this.editMode == "Edit") {
                        if (this.editedEntityEmailIds.indexOf(foundEntityEmail.entityEmailId) === -1) {
                            this.editedEntityEmailIds.push(foundEntityEmail.entityEmailId);
                        }
                    }
                },
                deep: true
             }
      },
      methods: {
        initAutocomplete() {
          // Create the autocomplete object, restricting the search predictions to
          // geographical location types.
          if (this.$refs.autocomplete === undefined || this.$refs.autocomplete[0] === undefined) {
            setTimeout(() => {
              this.initAutocomplete();
            }, 2000);
          } else {
            this.autocomplete = new window.google.maps.places.Autocomplete(this.$refs.autocomplete[0].$refs.input);
            this.autocomplete.setFields(["address_component"]);
            this.autocomplete.setComponentRestrictions({"country": ["us"]});
            this.autocomplete.addListener("place_changed", () => {
              let place = this.autocomplete.getPlace();
              this.fillInAddress(place);
            });
          }
        },
         async failure(error) {
            this.isInProgress = false
      
            let options = {
               text: error,
               title: "Unable to save",
               actions: ["Okay"]
            };
            
            await this.$dialog.error(options);
         },
         async save() {
            let savedCnt = 0;
            if (this.editMode == "Edit" && this.editFormValid) {
               try {

                  await new PatientService().put(this.customerId, this.patient)
                     .then((value) => {
                           this.updatePatientSuccessful = true;
                           Object.assign(this.initialPatient, this.patient);
                        })
                        .catch((err) => {
                           this.updatePatientSuccessful = false;
                           Object.assign(this.patient, this.initialPatient);
                           console.log(err);
                        });
                              
                  this.updatePatientPreferredModeOfContactSuccessful = false;

                  await this.updatePatientPreferredModeOfContact();

                  if (this.updatePatientPreferredModeOfContactSuccessful === true && this.updatePatientSuccessful === true)
                  {
                     let updatedPhoneNumbers = false;
                     for (const item of this.editedEntityPhoneIds) {
                        let editedEntityPhone = this.patientPhones.find((p) => p.entityPhoneId == item);
                        let beforeChange = this.oldPatientPhones.find((p) => p.entityPhoneId == item);
                        if (beforeChange.phoneNumber != editedEntityPhone.phoneNumber
                           || beforeChange.note != editedEntityPhone.note) {
                           await this.savePatientPhone(editedEntityPhone);
                           savedCnt++;
                           updatedPhoneNumbers = true;
                        }
                     }
                     
                     if (updatedPhoneNumbers) {
                        this.lookupPatientPhones();
                     }
                     
                     for (const item of this.editedEntityEmailIds) {
                        let editedEntityEmail = this.patientEmails.find((p) => p.entityEmailId == item);
                        let beforeChange = this.oldPatientEmails.find((p) => p.entityEmailId == item);
                        if (beforeChange.emailAddress != editedEntityEmail.emailAddress) {
                           await this.savePatientEmail(editedEntityEmail);
                           savedCnt++;
                        }
                     }
                     
                     for (const item of this.editedEntityAddressIds) {
                        let editedEntityAddress = this.patientAddresses.find((p) => p.entityAddressId == item);
                        let beforeChange = this.oldPatientAddresses.find((p) => p.entityAddressId == item);
                        if (beforeChange.addressLine1 != editedEntityAddress.addressLine1
                           || beforeChange.addressLine2 != editedEntityAddress.addressLine2
                           || beforeChange.city != editedEntityAddress.city
                           || beforeChange.stateId != editedEntityAddress.stateId
                           || beforeChange.zipCode != editedEntityAddress.zipCode) {
                           await this.savePatientAddress(editedEntityAddress);
                           savedCnt++;
                        }
                     }
                     if (savedCnt > 0) {
                        Shared.showSaveSuccessAlert(this.alertRef);
                     }
                  }
               }
               catch (ex) {
                  Shared.showSaveFailedAlert(this.alertRef);
                  console.log(ex);
               }
               finally {
                  await new EntityLockService().remove(this.customerId, this.$store.state.achillesUser.userId,
                                                       this.patient.patientId, 1, "ContactDetail");
                  this.isEditMode = false;
                  this.editMode = "";
                  this.$emit("changed");
                  this.updatePatientPreferredModeOfContactSuccessful = false;
               }
            }
            
            if (this.editMode == "Add" && this.addFormValid) {
               try {

                  await new PatientService().put(this.customerId, this.patient)
                     .then((value) => {
                           this.updatePatientSuccessful = true;
                           Object.assign(this.initialPatient, this.patient);
                        })
                        .catch((err) => {
                           this.updatePatientSuccessful = false;
                           Object.assign(this.patient, this.initialPatient);
                           console.log(err);
                        });

                  this.updatePatientPreferredModeOfContactSuccessful = false;

                  await this.updatePatientPreferredModeOfContact();

                  if (this.updatePatientPreferredModeOfContactSuccessful === true && this.updatePatientSuccessful === true)
                  {
                     let updatedPhoneNumbers = false;
                  for (const addedPhone of this.newPatientPhones) {
                     if (addedPhone.phoneNumber) {
                        await this.savePatientPhone(addedPhone);
                        savedCnt++;
                        updatedPhoneNumbers = true;
                     }
                  }
                  
                  if (updatedPhoneNumbers) {
                     this.lookupPatientPhones();
                  }
                  
                  for (const addedEmail of this.newPatientEmails) {
                     if (addedEmail.emailAddress) {
                        await this.savePatientEmail(addedEmail);
                        savedCnt++;
                     }
                  }
                  
                  for (const addedAddress of this.newPatientAddresses) {
                     if (addedAddress.addressLine1) {
                        await this.savePatientAddress(addedAddress);
                        savedCnt++;
                     }
                  }
                  if (savedCnt > 0) {
                     Shared.showSaveSuccessAlert(this.alertRef);
                  }
                  
                  Shared.emptyObjectInArray(this.newPatientPhones);
                  }
               }
               catch (ex) {
                  Shared.showSaveFailedAlert(this.alertRef);
                  console.log(ex);
               }
               finally {
                  await new EntityLockService().remove(this.customerId, this.$store.state.achillesUser.userId,
                                                       this.patient.patientId, 1, "ContactDetail");
                  this.isEditMode = false;
                  this.editMode = "";
                  this.$emit("changed");
                  this.updatePatientPreferredModeOfContactSuccessful = false;
               }
            }
         },
         async cancel() {
            if (this.patient.preferredModeOfContactId === null)
            {
               this.preferredModeOfContact = this.preferredModeOfContacts.find(x => x.description === "Unknown");
            } else
            {
               this.preferredModeOfContact = this.preferredModeOfContacts.find(x => x.preferredModeOfContactId === this.patient.preferredModeOfContactId);
            }
            if (this.editMode == "Edit") {
               this.cloneOldPhones(true);
               this.cloneOldEmails(true);
               this.cloneOldAddresses(true);
               this.editedEntityPhoneIds = [];
               this.editedEntityEmailIds = [];
               this.editedEntityAddresses = [];
               this.isEditMode = false;
               this.editMode = "";
            } else if (this.editMode == "Add") {
               Shared.emptyObjectInArray(this.newPatientPhones);
               Shared.emptyObjectInArray(this.newPatientEmails);
               Shared.emptyObjectInArray(this.newPatientAddresses);
               this.isEditMode = false;
               this.editMode = "";
            }

            Object.assign(this.patient, this.initialPatient);
            
            await new EntityLockService().remove(this.customerId, this.$store.state.achillesUser.userId,
                                                 this.patient.patientId, 1, "ContactDetail");
         },
         async updatePatientPreferredModeOfContact() {
            let input = {
               customerId: this.customerId,
               patientId: this.patient.patientId,
               preferredModeOfContactId: this.preferredModeOfContact.preferredModeOfContactId,
               updatedByUserId: this.$store.state.achillesUser.userId
            };
            await this.$apollo.mutate(
            {
               mutation: UpdatePatientPreferredModeOfContact,
               variables: {
                  input: input
               }
            })
            .then(async (data) => {
               if (data.data.payload.error) {
                  return await this.failure(data.data.payload.errorCode);
               } else {
                  this.updatePatientPreferredModeOfContactSuccessful = true;
               }
            })
            .catch(async (error) => {
               await this.failure(genericErrorMessage);
            });
         },
         resetData: function () {
            this.patientPhones = [];
            this.patientEmails = [];
            this.patientAddresses = [];
            this.oldPatientPhones = [];
            this.oldPatientEmails = [];
            this.oldPatientAddresses = [];
            this.editedEntityPhoneIds = [];
            this.editedEntityEmailIds = [];
            this.editedEntityAddresses = [];
            this.deletedEntityPhoneIds = [];
            this.deletedEntityEmailIds = [];
            this.deletedEntityAddresses = [];
            Shared.emptyObjectInArray(this.newPatientPhones);
            Shared.emptyObjectInArray(this.newPatientEmails);
            Shared.emptyObjectInArray(this.newPatientAddresses);
            this.isEditMode = false;
            this.editMode = "";
         },
         cloneOldPhones: function (reverse = false) {
            if (!reverse) {
               this.oldPatientPhones = JSON.parse(JSON.stringify(this.patientPhones));
            } else {
               this.patientPhones = JSON.parse(JSON.stringify(this.oldPatientPhones));
            }
         },
         cloneOldAddresses: function (reverse = false) {
            if (!reverse) {
               this.oldPatientAddresses = JSON.parse(JSON.stringify(this.patientAddresses));
            } else {
               this.patientAddresses = JSON.parse(JSON.stringify(this.oldPatientAddresses));
            }
         },
         cloneOldEmails: function (reverse = false) {
            if (!reverse) {
               this.oldPatientEmails = JSON.parse(JSON.stringify(this.patientEmails));
            } else {
               this.patientEmails = JSON.parse(JSON.stringify(this.oldPatientEmails));
            }
         },


         lookupData: function () {
            this.resetData();
            this.lookupPatientPhones();
            this.lookupPatientEmails();
            this.lookupPatientAddresses();
            this.lookupPreferredModeOfContacts();
         },

         lookupPreferredModeOfContacts: function() {
            this.isLoading = true;
            new ReferenceDataService()
            .getPreferredModeOfContact()
            .then((resp) => {     
               this.preferredModeOfContacts = resp;
               if (this.patient.preferredModeOfContactId === null)
               {
                  this.preferredModeOfContact = this.preferredModeOfContacts.find(x => x.description === "Unknown");
               } else
               {
                  this.preferredModeOfContact = this.preferredModeOfContacts.find(x => x.preferredModeOfContactId === this.patient.preferredModeOfContactId);
               }
            })
            .catch((err) => {
               console.log(err);
            })
            .finally(() => {
               this.isLoading = false;
            });
         },
         lookupPatientPhones: function () {
            this.isLoading = true;
            if (this.entityType == EntityTypeEnum[EntityTypeEnum.Patient]) {
               new EntityContactService()
                   .getPatientPhones(this.customerId, this.patient.patientId)
                   .then((resp) => {
                      this.patientPhones = resp;
                      this.cloneOldPhones();
                   })
                   .catch((err) => {
                      console.log(err);
                   })
                   .finally(() => (this.isLoading = false));
            }
         },
         savePatientPhone: async function (entityPhone) {
            if ((this.editMode == "Edit" || this.editMode == "Delete") && this.entityType ==
                EntityTypeEnum[EntityTypeEnum.Patient]) {
               entityPhone.updatedByUserId = this.$store.state.achillesUser.userId;
               await new EntityContactService().putPatientPhone(this.customerId, entityPhone.entityPhoneId, entityPhone)
                                               .catch(err => {
                                                  Shared.showSaveFailedAlert(this.alertRef);
                                                  console.log(err);
                                               });
            } else if (this.editMode == "Add" && this.entityType == EntityTypeEnum[EntityTypeEnum.Patient]) {
               entityPhone.entityPhoneId = 0;
               entityPhone.updatedByUserId = this.$store.state.achillesUser.userId;
               entityPhone.created = new Date().toISOString();
               entityPhone.entityId = this.patient.patientId;
               entityPhone.entityTypeId = EntityTypeEnum.Patient;
               entityPhone.phoneType = "Phone";
               await new EntityContactService().postPatientPhone(this.customerId, entityPhone).then(() => {
                  this.lookupPatientPhones();
               }).catch(err => {
                  Shared.showSaveFailedAlert(this.alertRef);
                  console.log(err);
               });
            }
         },
         savePatientEmail: async function (entityEmail) {
            if ((this.editMode == "Edit" || this.editMode == "Delete") && this.entityType ==
                EntityTypeEnum[EntityTypeEnum.Patient]) {
               entityEmail.updatedByUserId = this.$store.state.achillesUser.userId;
               await new EntityContactService().putPatientEmail(this.customerId, entityEmail.entityEmailId, entityEmail)
                                               .then(() => {
                                                  this.lookupPatientEmails();
                                               }).catch(err => {
                      Shared.showSaveFailedAlert(this.alertRef);
                      console.log(err);
                   });
            } else if (this.editMode == "Add" && this.entityType == EntityTypeEnum[EntityTypeEnum.Patient]) {
               entityEmail.entityEmailId = 0;
               entityEmail.updatedByUserId = this.$store.state.achillesUser.userId;
               entityEmail.created = new Date().toISOString();
               entityEmail.entityId = this.patient.patientId;
               entityEmail.entityTypeId = EntityTypeEnum.Patient;
               await new EntityContactService().postPatientEmail(this.customerId, entityEmail).then(() => {
                  this.lookupPatientEmails();
               }).catch(err => {
                  Shared.showSaveFailedAlert(this.alertRef);
                  console.log(err);
               });
            }
         },
         savePatientAddress: async function (entityAddress) {
            if ((this.editMode == "Edit" || this.editMode == "Delete") && this.entityType ==
                EntityTypeEnum[EntityTypeEnum.Patient]) {
               entityAddress.updatedByUserId = this.$store.state.achillesUser.userId;
               await new EntityContactService().putPatientAddress(this.customerId, entityAddress.entityAddressId,
                                                                  entityAddress).then(() => {
                  this.lookupPatientAddresses();
               }).catch(err => {
                  Shared.showSaveFailedAlert(this.alertRef);
                  console.log(err);
               });
            } else if (this.editMode == "Add" && this.entityType == EntityTypeEnum[EntityTypeEnum.Patient]) {
               entityAddress.entityAddressId = 0;
               entityAddress.updatedByUserId = this.$store.state.achillesUser.userId;
               entityAddress.created = new Date().toISOString();
               entityAddress.entityId = this.patient.patientId;
               entityAddress.entityTypeId = EntityTypeEnum.Patient;
               await new EntityContactService().postPatientAddress(this.customerId, entityAddress).then(() => {
                  this.lookupPatientAddresses();
               }).catch(err => {
                  Shared.showSaveFailedAlert(this.alertRef);
                  console.log(err);
               });
            }
         },
         
         lookupPatientEmails: function () {
            this.isLoading = true;
            if (this.entityType == EntityTypeEnum[EntityTypeEnum.Patient]) {
               new EntityContactService()
                   .getPatientEmails(this.customerId, this.patient.patientId)
                   .then((resp) => {
                      this.patientEmails = resp;
                      this.cloneOldEmails();
                   })
                   .catch((err) => {
                      console.log(err);
                   })
                   .finally(() => (this.isLoading = false));
            }
         },
         lookupPatientAddresses: function () {
            this.isLoading = true;
            if (this.entityType == EntityTypeEnum[EntityTypeEnum.Patient]) {
               new EntityContactService()
                   .getPatientAddresses(this.customerId, this.patient.patientId)
                   .then((resp) => {
                      this.patientAddresses = resp;
                      this.cloneOldAddresses();
                   })
                   .catch((err) => {
                      console.log(err);
                   })
                   .finally(() => (this.isLoading = false));
            }
         },
   

         async actionSelected(action) {
            const res = await new EntityLockService().post(this.customerId, this.$store.state.achillesUser.userId,
                                                           this.patient.patientId, 1, "ContactDetail");

            if (res.lockedByAnotherUser) {
               await this.$dialog.info({
                                             text: "This Patient has being updated by another user. Please wait or contact " +
                                                   res.lockedByUserFullName + " to access the record.",
                                             title: "Locked for edit by another user",
                                             actions: ['Okay']
                                          });
               
               return;
            }
            
            this.isEditMode = action !== "Delete";
            this.editMode = action;
            if (this.editMode == "Edit" && this.patientAddresses.length > 0) {
               this.googleMapsDirectionUrl = "https://www.google.com/maps?saddr=My+Location&daddr="
                                             + this.patientAddresses[0].addressLine1 + ","
                                             + this.patientAddresses[0].city + ","
                                             + this.stateName(this.patientAddresses[0].stateId) + " "
                                             + this.patientAddresses[0].zipCode;
               this.initMaps();
            } else {
               (this.editMode == "Add");
            }
            {
               this.initMaps();
            }
         },
         addNewPhone: function () {
            let newPhoneObj = {
               entityPhoneId: null,
               phoneNumber: "",
               note: "",
               entityId: "",
               entityTypeId: "",
               updatedByUserId: "",
               created: ""
            };
            newPhoneObj.entityPhoneId = ++this.phoneCount;
            this.newPatientPhones.push(newPhoneObj);
         },
         removeNewPhone: function (idx) {
            this.newPatientPhones.splice(idx, 1);
         },
         removePhone: function (phone) {
            this.deleteConfirmDialog = true;
            this.deletedItemInfo.type = "Phone";
            this.deletedItemInfo.item = phone;
         },
         copyToClipboard(value) {
            let self = this;
            this.$copyText(value).then(
                function (e) {
                   self.clipboardText = value;
                   self.clipboardSnackbar = true;
                   console.log(e);
                },
                function (e) {
                   console.log(e);
                }
            );
         },
         removeEmail: function (email) {
            this.deleteConfirmDialog = true;
            this.deletedItemInfo.type = "Email";
            this.deletedItemInfo.item = email;
         },
         confirmDialogCallback: function (agreed) {
            if (this.deletedItemInfo.type == "Phone" && agreed) {
               let phone = this.deletedItemInfo.item;
               phone.isDeleted = true;
               this.savePatientPhone(phone).then(() => {
                  let index = this.patientPhones.findIndex(p => p.entityPhoneId == phone.entityPhoneId);
                  this.patientPhones.splice(index, 1);
               });
            }
            if (this.deletedItemInfo.type == "Email" && agreed) {
               let email = this.deletedItemInfo.item;
               email.isDeleted = true;
               this.savePatientEmail(email).then(() => {
                  let index = this.patientEmails.findIndex(p => p.entityEmailId == email.entityEmailId);
                  this.patientEmails.splice(index, 1);
               });
            }
            this.deleteConfirmDialog = false;
            Shared.emptyObject(this.deletedItemInfo);
         },
         async clearDeleteMode() {
            this.isEditMode = false;
            this.editMode = "";
            this.deletedEntityPhoneIds = [];
            this.deletedEntityEmailIds = [];
            this.deletedEntityAddressIds = [];
            Shared.emptyObject(this.deletedItemInfo);
            await new EntityLockService().remove(this.customerId, this.$store.state.achillesUser.userId,
                                                 this.patient.patientId, 1, "ContactDetail");
         },
        
         fillInAddress(place) {
            // Get the place details from the autocomplete object.      
            if (place != undefined) {
               let components = {
                  street_number: {
                     result: "",
                     type: "short_name"
                  },
                  route: {
                     result: "",
                     type: "long_name"
                  },
                  locality: {
                     result: "",
                     type: "long_name"
                  },
                  administrative_area_level_1: {
                     result: "",
                     type: "short_name"
                  },
                  postal_code: {
                     result: "",
                     type: "short_name"
                  }
               };
               
               for (let i = 0; i < place.address_components.length; i++) {
                  let addressType = place.address_components[i].types[0];
                  if (typeof components[addressType] !== "undefined") {
                     components[addressType].result = place.address_components[i][components[addressType].type];
                  }
               }
               
               if (this.editMode === "Edit") {
                  this.patientAddresses[0].addressLine1 =
                      (components["street_number"].result + " " + components["route"].result).trim();
                  this.patientAddresses[0].city = components["locality"].result;
                  this.patientAddresses[0].stateId =
                      this.stateIdFromShortName(components["administrative_area_level_1"].result);
                  this.patientAddresses[0].zipCode = components["postal_code"].result;
                  this.patientAddresses[0].addressLine2 = "";
               } else if (this.editMode === "Add") {
                  this.newPatientAddresses[0].addressLine1 =
                      (components["street_number"].result + " " + components["route"].result).trim();
                  this.newPatientAddresses[0].city = components["locality"].result;
                  this.newPatientAddresses[0].stateId =
                      this.stateIdFromShortName(components["administrative_area_level_1"].result);
                  this.newPatientAddresses[0].zipCode = components["postal_code"].result;
                  this.newPatientAddresses[0].addressLine2 = "";
               }
               
            }
         }
      },
   };
</script>