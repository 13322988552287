<template>
   <v-dialog v-model="isOpen" v-if="isOpen"
             fullscreen
             hide-overlay
             transition="dialog-bottom-transition"
             persistent :retain-focus="false">
      <v-card>
         <MemberDetails v-if="member"
                        :customerId="customerId"
                        :customerName="customer.name"
                        :memberId="memberId"
                        :member="member"
                        :crisisCallContent="customer.crisisCallContent"
                        @close-dialog="closeDialog"/>
      </v-card>
   </v-dialog>
</template>

<script>
   import MemberDetails from "./MemberDetails";
   import {GetMember} from "./queries/member-details.graphql";
   
   export default {
      name: "MemberDialog",
      props: {
         customerId: Number,
         memberId: Number,
         dialogMember: Boolean
      },
      components: {
         MemberDetails
      },
      data: () => ({
         customerDb: {}
      }),
      computed: {
         isOpen() {
            return this.dialogMember;
         },
         customer(){
            return this.customerDb;
         },
         member(){
            return this.customerDb.member
         }
      },
      methods: {
         closeDialog() {
            this.$parent.closeMemberDialog(false);
         }
      },
      apollo: {
         customerDb: {
            query: GetMember,
            variables() {
               return {
                  customerId: this.customerId,
                  memberId: this.memberId
               };
            },
            update: data => data.customer
         }
      }
   };

</script>

<style scoped lang="scss">

</style>