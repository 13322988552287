<template>
   <v-container>
      <!-- escalation details -->
      <v-dialog v-model="dialogEscalation" v-if="dialogEscalation"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                :retain-focus="false">
         <v-card>
            <v-toolbar dark color="primary">
               <v-btn icon dark @click="dialogEscalation = false">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
               <v-toolbar-title>View Escalation Details</v-toolbar-title>
               <v-spacer></v-spacer>
               <v-toolbar-items>
                  <v-btn dark text @click="dialogEscalation = false"> Close</v-btn>
               </v-toolbar-items>
            </v-toolbar>
            <escalation-details v-if="dialogEscalation"
                                :customerId="customerId"
                                :escalationId="escalationId"
                                :worklistItemId="worklistItemId"
                                :key="componentKey"
                                :readonly="true"/>
         </v-card>
      </v-dialog>
      <v-row>
         <v-col md="6">
            <v-btn color="secondary" @click="isEditMode=true">Add</v-btn>
            <v-card class="mt-4" :loading="isLoading && isEditMode" v-if="isEditMode">
               <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                     <v-col md="6">
                        <v-select
                            :items="worklistItemTypes"
                            label="Type"
                            item-text="description"
                            item-value="worklistItemTypeId"
                            :rules="[alreadyExists,rules.required]"
                            required
                            v-model="newWorklistItem.worklistItemTypeId"
                            class="ml-4"></v-select>
                     </v-col>
                  </v-row>
                  <v-row>
                     <v-col md="6">
                        <v-autocomplete
                            v-model="newWorklistItem.assignedToUserId"
                            :items="isCareManagerUser?achillesCMUsers:achillesPodiUsers"
                            dense
                            filled
                            label="Assigned to"
                            item-text="email"
                            item-value="userId"
                            class="ml-4">
                           <template v-slot:selection="data">
                              <v-chip v-bind="data.attrs" :input-value="data.selected">
                                 <v-avatar left>
                                    <v-img :src="data.item.avatarUrl" lazy-src="../assets/account.png"></v-img>
                                 </v-avatar>
                                 {{data.item.email}}
                              </v-chip>
                           </template>
                           <template v-slot:item="data">
                              <v-list-item-avatar v-if="data.item.avatarUrl != null">
                                 <v-img :src="data.item.avatarUrl" lazy-src="../assets/account.png"/>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                 <v-list-item-title v-html="data.item.email"></v-list-item-title>
                                 <v-list-item-subtitle v-html="data.item.lastName +' '+ data.item.firstName"></v-list-item-subtitle>
                              </v-list-item-content>
                           </template>
                        </v-autocomplete>
                     </v-col>
                  </v-row>
                  <v-row>
                     <v-col class="md-3">
                        <v-textarea v-model="newWorklistItem.note" label="Note"
                                    required prepend-icon="mdi-note" class="ml-4" auto-grow scrollable></v-textarea>
                     </v-col>
                  </v-row>
                  <v-row v-if="newWorklistItem.worklistItemTypeId === 4">
                     <v-col class="md-3">
                        <v-textarea v-model="newWorklistItem.thermometryAssessment"
                                    label="Thermometry Assessment" required prepend-icon="mdi-note"
                                    class="ml-4" auto-grow scrollable></v-textarea>
                     </v-col>
                  </v-row>
                  <v-row>
                     <v-col md="3">
                        <v-checkbox v-model="isScheduled" label="Schedule" @change="setDisplayDatePicker()" class="ml-4"></v-checkbox>
                     </v-col>
                     <v-col md="4" class="d-flex justify-center align-center">
                        <v-label v-if="isScheduled && !displayDatePicker">
                             Schedule For: {{dateFormatted}}
                             <v-btn icon @click="displayDatePicker = true">
                                <v-icon>mdi-pencil</v-icon>
                             </v-btn>
                          </v-label>
                        <v-menu v-model="isScheduled"
                                :close-on-content-click="false"
                                :close-on-click="false"
                                :nudge-top="100"
                                transition="scale-transition"
                                offset-overflow
                                min-width="auto"
                                v-if="displayDatePicker">
                           <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="dateFormatted"
                                            label="Schedule For"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"></v-text-field>
                           </template>
                           
                           <v-date-picker
                               v-model="scheduledForValue"
                               :min="scheduleAvailabilityMinValue"
                               :max="scheduleAvailabilityMaxValue"
                               :allowed-dates="allowedDates"
                               no-title
                               scrollable>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text @click="cancealScheduleDate()">
                                 Cancel
                              </v-btn>
                              <v-btn color="primary" text @click="setScheduledDate()">
                                 OK
                              </v-btn>
                           </v-date-picker>
                        </v-menu>
                     </v-col>
                  </v-row>
                  <v-row>
                     <v-col class="md-4">
                        <v-btn color="secondary" class="ma-3" @click="cancel">Cancel</v-btn>
                        <v-btn color="secondary" class="ma-3" :disabled="!allowInteractionSave" @click="save">Save</v-btn>
                     </v-col>
                  </v-row>
               </v-form>
            </v-card>
         </v-col>
      </v-row>
      <!-- end: "Add" button and Add Worklist Item Card-->
      
      <v-row>
         <v-col md="12">
            <!-- current worklist item -->
            <v-card class="mt-4">
               <v-card-title>Current
                  <span class="pl-3">
                  <v-icon class="" @click="getPatientWorklistItems">mdi-refresh</v-icon>
               </span>
               </v-card-title>
               <v-alert :value="alertRef.alert" :type="alertRef.alertType" dense transition="fade-transition">
                  {{alertRef.alertMessage}}
               </v-alert>
               <v-data-table
                   :loading="(isLoading && !initialLoad) || isLoadingCombined"
                   :headers="headers"
                   :items="combinedWorklistItems"
                   class="elevation-1"
                   :sort-by.sync="sortBy"
                   :sort-desc.sync="sortDesc"
                   :footer-props="{'items-per-page-options': rowsPerPage}"
                   >
                  <template v-slot:top>
                     <v-dialog v-model="dialog" max-width="800px" persistent v-if="dialog">
                        <v-toolbar dark color="primary">
                           <v-btn icon dark @click="closeWorklistItemDialog">
                              <v-icon>mdi-close</v-icon>
                           </v-btn>
                           <v-toolbar-title>Edit Worklist Item</v-toolbar-title>
                           <v-spacer></v-spacer>
                           <v-toolbar-items>
                              <v-btn dark text @click="closeWorklistItemDialog"> Close</v-btn>
                           </v-toolbar-items>
                        </v-toolbar>
                        <WorklistItemEdit :customerId="customerId" :worklistItemId="worklistItemId" :patientId="patient.patientId" :key="componentKey"/>
                     </v-dialog>
                  </template>
                 <template #[`item.note`]="{ item }">
                   <div v-html="item.note" style="white-space: pre-line; max-height: 300px; overflow: auto"></div>
                 </template>
                  <template #[`item.isEscalation`]="{ item }">
                     {{item.isEscalation == true ? "Yes" : "No"}}
                  </template>
                  <template #[`item.worklistItemTypeId`]="{ item }">
                     {{getItemTypeDescriptionForId(item.worklistItemTypeId)}}
                  </template>
                  <template #[`item.assignedToUserId`]="{ item }">
                     {{getUserNameForId(item.assignedToUserId)}}
                  </template>
                  <template #[`item.createdByUserId`]="{ item }">
                     {{item.createdByUserId == null ? "Achilles" : getUserNameForId(item.createdByUserId)}}
                  </template>
                  <template #[`item.closedByUserId`]="{ item }">
                     {{item.closedByUserId == null ? "Achilles" : getUserNameForId(item.closedByUserId)}}
                  </template>
                  
                  <template #[`item.actions`]="{ item }">
                     <v-icon medium class="mr-2" @click="editItem(item)" v-if="isAuthorized(item)">mdi-pencil</v-icon>
                  </template>
               </v-data-table>
            </v-card>
            <!-- end: current worklist item -->
            
            <!-- scheduled worklist items -->
            <v-card class="mt-4">
               <v-card-title>Scheduled <span class="pl-3">
                  <v-icon @click="getScheduledWorklistItems">mdi-refresh</v-icon>
               </span>
               </v-card-title>
               <v-alert
                   :value="scheduledAlertRef.alert"
                   :type="scheduledAlertRef.alertType"
                   dense
                   transition="fade-transition">{{scheduledAlertRef.alertMessage}}
               </v-alert>
               <v-data-table
                   :loading="(isLoading && !initialLoad) || isLoadingScheduled"
                   :headers="scheduledHeaders"
                   :items="scheduledWorklistItems"
                   class="elevation-1"
                   :sort-by.sync="sortByScheduled"
                   :sort-desc.sync="sortDescScheduled"
                   :footer-props="{'items-per-page-options': rowsPerPage}">
                  <template #[`item.created`]="{ item }">{{formattedDate(item.created)}}</template>
                  <template #[`item.scheduledFor`]="{ item }">{{formattedDate(item.scheduledFor)}}</template>
                  <template #[`item.worklistItemTypeId`]="{ item }">{{getItemTypeDescriptionForId(
                      item.worklistItemTypeId)}}
                  </template>
                  <template #[`item.assignedToUserId`]="{ item }">{{getUserNameForId(item.assignedToUserId)}}</template>
                  <template #[`item.createdByUserId`]="{ item }">
                     {{item.createdByUserId == null ? "Achilles" : getUserNameForId(item.createdByUserId)}}
                  </template>
                  
                  <template #[`item.actions`]="{ item }">
                     <v-icon medium class="mr-2" @click="deleteSchedule(item)" v-if="isAuthorizedScheduled(item)">
                        mdi-delete
                     </v-icon>
                  </template>
               </v-data-table>
            </v-card>
            <!-- end: scheduled worklist items -->
            
            <!-- history worklist items -->
            <v-btn class="mt-3" @click="showHistory" v-if="!loadHistory">Load History</v-btn>
            <v-card class="mt-4" v-if="loadHistory">
               <v-card-title>History <span class="pl-3"><v-icon class="" @click="showHistory">mdi-refresh</v-icon></span>
               </v-card-title>
               
               <v-data-table
                   :loading="(isLoading && !initialLoad) || isLoadingHistory"
                   :headers="historyHeaders"
                   :items="historyWorklistItems"
                   class="elevation-1"
                   :sort-by.sync="sortByHistory"
                   :sort-desc.sync="sortByDescHistory"
                   :footer-props="{'items-per-page-options': rowsPerPage}">
                  <template v-slot:top>
                     <v-dialog v-model="dialog" v-if="dialog" max-width="800px" persistent>
                        <v-toolbar dark color="primary">
                           <v-btn icon dark @click="closeWorklistItemDialog">
                              <v-icon>mdi-close</v-icon>
                           </v-btn>
                           <v-toolbar-title>Edit Worklist Item
                           </v-toolbar-title>
                           <v-spacer></v-spacer>
                           <v-toolbar-items>
                              <v-btn dark text @click="closeWorklistItemDialog"> Close</v-btn>
                           </v-toolbar-items>
                        </v-toolbar>
                        <WorklistItemEdit :customerId="customerId" :worklistItemId="worklistItemId" :patientId="patient.patientId" :key="componentKey"/>
                     </v-dialog>
                  </template>
                  <template #[`item.isEscalation`]="{ item }">
                     {{item.isEscalation == true ? "Yes" : "No"}}
                     <v-icon medium class="mr-2" @click="viewEscalation(item)" v-if="item.isEscalation">
                        mdi-open-in-new
                     </v-icon>
                  </template>
                  <template #[`item.created`]="{ item }">
                     {{formattedDate(item.created)}}
                  </template>
                  <template #[`item.escalatedDate`]="{ item }">
                     {{item.isEscalation ? formattedDate(item.escalatedDate) : ""}}
                  </template>
                  <template #[`item.worklistItemTypeId`]="{ item }">
                     {{getItemTypeDescriptionForId(item.worklistItemTypeId)}}
                  </template>
                  <template #[`item.assignedToUserId`]="{ item }">
                     {{getUserNameForId(item.assignedToUserId)}}
                  </template>
                  <template #[`item.createdByUserId`]="{ item }">
                     {{item.createdByUserId == null ? "Achilles" : getUserNameForId(item.createdByUserId)}}
                  </template>
                  <template #[`item.closedByUserId`]="{ item }">
                     {{item.closedByUserId == null ? "Achilles" : getUserNameForId(item.closedByUserId)}}
                  </template>
                  <template #[`item.callDisposition`]="{ item }">
                     {{item.callDisposition ? item.callDisposition :
                       (!item.closedByUserId && !item.assignedToUserId ? "" : "See Interactions tab")}}
                  </template>
               </v-data-table>
            </v-card>
            <!-- end: history worklist items -->
         
         </v-col>
      </v-row>
      <v-dialog v-model="deleteConfirmDialog" v-if="deleteConfirmDialog"
          persistent
          max-width="500"
          content-class="confirm-dialog">
         <v-card>
            <v-card-title class="headline primary">
               Confirm Delete
            </v-card-title>
            <v-card-text>
               Are you sure you want to delete the Schedule for this Worklist Item?
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-container>
</template>
<script>
   import PatientService from "../services/PatientService";
   import NextWorklistItemService from "@/services/NextWorklistItemService";
   import ScheduledWorklistItemService from "@/services/ScheduledWorklistItemService";
   import * as Shared from "../shared";
   import WorklistItemService from "../services/WorklistItemService";
   import WorklistItemHistoryService from "../services/WorklistItemHistoryService";
   import WorklistItemEdit from "./WorklistItemEdit";
   import Utils from "../mixins/utils";
   import EscalationDetails from "@/components/EscalationDetails";
   import moment from "moment";
   import {WorklistItemTypeEnum} from "@/types/enum";
   
   export default {
      props: {
         patient: Object,
         customerId: Number
      },
      components: {
         EscalationDetails,
         WorklistItemEdit
      },
      mixins: [Utils],
      data: () => ({
         allowInteractionSave: true,
         deletedItem: {},
         deleteConfirmDialog: false,
         isEditMode: false,
         isLoading: false,
         valid: false,
         dialogEscalation: false,
         sortBy: "priority",
         isLoadingHistory: false,
         isLoadingCombined: false,
         isLoadingScheduled: false,
         initialLoad: false,
         sortByHistory: "created",
         sortByDescHistory: true,
         sortByScheduled: "created",
         sortDescScheduled: true,
         escalationId: 0,
         sortDesc: false,
         dialog: false,
         loadHistory: false,
         combinedWorklistItems: [],
         nextWorklistItems: [],
         scheduledWorklistItems: [],
         componentKey: 0,
         historyWorklistItems: [],
         historyHeaders: [
            {
               text: "Created",
               value: "created"
            },
            {
               text: "Type",
               value: "worklistItemTypeId"
            },
            {
               text: "Assigned User",
               value: "assignedToUserId"
            },
            {
               text: "Status",
               value: "status"
            },
            {
               text: "Created By",
               value: "createdByUserId"
            },
            {
               text: "Closed By",
               value: "closedByUserId"
            },
            {
               text: "Call Disposition",
               value: "callDisposition"
            },
            {
               text: "Is Escalated",
               value: "isEscalation"
            },
            {
               text: "Escalated Date",
               value: "escalatedDate"
            }
         ],
         headers: [
            {
               text: "Priority",
               value: "priority"
            },
            {
               text: "Type",
               value: "worklistItemTypeId"
            },
            {
               text: "Assigned User",
               value: "assignedToUserId"
            },
            {
               text: "Is Escalated",
               value: "isEscalation"
            },
            {
               text: "Note",
               value: "note"
            },
            {
               text: "Thermometry Assessment",
               value: "thermometryAssessment"
            },
            {
               text: "Created by",
               value: "createdByUserId"
            },
            {
               text: "Escalation Details",
               value: "actions"
            }
         ],
         scheduledHeaders: [
            {
               text: "Created",
               value: "created"
            },
            {
               text: "Type",
               value: "worklistItemTypeId"
            },
            {
               text: "ScheduledFor",
               value: "scheduledFor"
            },
            {
               text: "Assigned User",
               value: "assignedToUserId"
            },
            {
               text: "Note",
               value: "note"
            },
            {
               text: "Thermometry Assessment",
               value: "thermometryAssessment"
            },
            {
               text: "Created by",
               value: "createdByUserId"
            },
            {
               text: "Delete",
               value: "actions"
            }
         ],
         historyItems: [],
         worklistItems: [],
         worklistItemId: 0,
         rules: {
            required: v => !!v || "WorklistItem Type is required"
         },
         worklistItemTypes: [],
         achillesUsers: [],
         newWorklistItem: {
            worklistId: null,
            worklistItemTypeId: null,
            assignedToUserId: null,
            isEscalation: null,
            note: null,
            thermometryAssessment: null,
            patientId: null,
            WorklistItemStatusTypeId: null,
            created: null
         },
         scheduledWorklistItem: {
            isScheduled: false,
            scheduledFor: false,
            worklistItemTypeId: null,
            assignedToUserId: null,
            note: null,
            thermometryAssessment: null,
            patientId: null,
            created: null
         },
         alertRef: {
            alert: false,
            alertType: "success",
            alertMessage: ""
         },
         scheduledAlertRef: {
            alert: false,
            alertType: "success",
            alertMessage: ""
         },
         isScheduled: false,
         displayDatePicker: false
      }),
      computed: {
         rowsPerPage() {
            return this.$rowsPerPage;
         },
         scheduleAvailabilityMinValue() {
            return moment().startOf("day").add(1, "days").toISOString();
         },
         scheduleAvailabilityMaxValue() {
            return moment().startOf("day").add(40, "days").toISOString();
         },
         achillesPodiUsers() {
            return this.achillesUsers.filter(
                u => u.email.includes("@podimetrics.com") && u.isServiceAccount == false && u.isActive === true);
         },
         isCareManagerUser() {
            let role = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                         r.role.name == "CareManager"
            );
            if (role) {
               return true;
            } else {
               return false;
            }
         },
         isSuperviser() {
            let role = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                         r.role.name.includes("Superviser")
            );
            if (role) {
               return true;
            } else {
               return false;
            }
         },
         achillesCMUsers() {
            return this.achillesUsers.filter(
                u => u.userRoles.some(r => r.role.name == "CareManager" || r.role.name == "CareManagerSuperviser"));
         },
         scheduledForValue: {
            get: function () {
               //required format for vuetify datepicker
               if (this.scheduledWorklistItem.scheduledFor === null) {
                  return moment(new Date()).format("YYYY-MM-DD");
               }
               
               return moment(this.scheduledWorklistItem.scheduledFor).format("YYYY-MM-DD");
            },
            set: function (val) {
               this.scheduledWorklistItem.scheduledFor = val;
            }
         },
         dateFormatted() {
            if (this.scheduledWorklistItem.scheduledFor !== null) {
               return moment(this.scheduledWorklistItem.scheduledFor).format("MM/DD/YYYY");
            } else {
               return null;
            }
         }
      },
      methods: {
         setDisplayDatePicker() {
            if (this.isScheduled && this.scheduledWorklistItem.scheduledFor == null) {
               this.displayDatePicker = true;
            }
            else{
               this.cancealScheduleDate();
            }
         },
         async setScheduledDate() {
            
            if (this.scheduledWorklistItem.scheduledFor == null) {
               let options = {
                  text: "Please select date",
                  title: "Unable to proceed",
                  actions: ["Okay"]
               };
               await this.$dialog.error(options);
               return;
            }
            this.scheduledWorklistItem.isScheduled  = true;
            this.displayDatePicker = false;
         },
         cancealScheduleDate(){
            this.isScheduled = false;
            this.displayDatePicker = false;
            this.scheduledWorklistItem.scheduledFor = null;
            this.scheduledWorklistItem.isScheduled = false;
         },
         allowedDates(val) {
            let isBefore = moment(val).isBefore(moment().startOf("day").add(1, "days"));
            let isAfter = moment(val).isAfter(moment().startOf("day").add(40, "days"));
            
            return !isBefore && !isAfter;
         },
         deleteSchedule: function (item) {
            this.deletedItem = item;
            this.deleteConfirmDialog = true;
         },
         confirmDialogCallback: function (agreed) {
            if (agreed) {
               this.deletedItem.isDeleted = true;
               this.deleteScheduledWorklistItem(this.customerId, this.deletedItem);
            }
            this.deleteConfirmDialog = false;
            this.deletedItem = {};
         },
         forceRerender() {
            this.componentKey += 1;
         },
         async viewEscalation(item) {
            this.escalationId = item.escalationId;
            this.worklistItemId = item.worklistItemId;
            this.dialogEscalation = true;
         },
         formatDateSimple(val) {
            if (val) {
               return new Date(val).toISOString().substr(0, 10);
            } else {
               return null;
            }
         },
         formattedDate(val) {
            if (typeof this.date === undefined || this.date === null) {
               return "";
            }
            
            return this.formatDate(val);
         },
         closeWorklistItemDialog() {
            this.dialog = false;
            this.getPatientWorklistItems();
         },
         async editItem(item) {
            this.patientName = this.patient.firstName + " " + this.patient.lastName;
            this.worklistItemId = item.worklistItemId;
            this.forceRerender();
            this.dialog = true;
         },
         isAuthorized(item) {
            return item.assignedToUserId === this.$store.state.achillesUser.userId &&
                   (this.isCareManagerUser || this.isSuperviser);
         },
         isAuthorizedScheduled(item) {
            return item.assignedToUserId === this.$store.state.achillesUser.userId &&
                   moment(item.scheduledFor).isAfter(moment().startOf("day"));
         },
         async save() {
            try {
               this.allowInteractionSave = false;
               if (this.$refs.form.validate()) {
                  if (!this.scheduledWorklistItem.isScheduled) {
                     this.isLoading = true;
                     await this.saveWorklistItem(this.customerId);
                     Shared.showSaveSuccessAlert(this.alertRef);
                  } else {
                     this.isLoadingScheduled = true;
                     await this.saveScheduledWorklistItem(this.customerId);
                     Shared.showSaveSuccessAlert(this.scheduledAlertRef);
                  }
                  this.initNewWorklistItem();
                  this.resetForm()
                  this.isEditMode = false;
               }
            }
            catch (err) {
               if (!this.scheduledWorklistItem.isScheduled) {
                  Shared.showSaveFailedAlert(this.alertRef);
               } else {
                  Shared.showSaveFailedAlert(this.scheduledAlertRef);
               }
               this.cancel();
               console.log(err);
            }
            finally {
               this.isLoading = false;
               this.isLoadingScheduled = false;
               this.allowInteractionSave = true;
            }
         },
         cancel() {
            this.initNewWorklistItem();
            this.resetForm();
            this.isEditMode = false;
         },
         resetForm(){
            this.isScheduled = false;
            this.displayDatePicker = false;
            this.scheduledWorklistItem.scheduledFor = null;
            this.scheduledWorklistItem.isScheduled = false;
         },
         async saveWorklistItem(customerId) {
            this.newWorklistItem.worklistId = 1;
            this.newWorklistItem.patientId = this.patient.patientId;
            this.newWorklistItem.created = new Date().toISOString();
            this.newWorklistItem.createdByUserId = this.$store.state.achillesUser.userId;
            if (this.newWorklistItem.worklistItemTypeId == WorklistItemTypeEnum.Escalation) //escalation
            {
               this.newWorklistItem.isEscalation = true;
               this.newWorklistItem.worklistId = 2;
            }
            if (this.newWorklistItem.worklistItemTypeId !== WorklistItemTypeEnum.Offloading) //'not' offloading
            {
               this.newWorklistItem.thermometryAssessment = null;
            }
            await new WorklistItemService()
                .postWorklistItem(customerId, this.newWorklistItem)
                .then(() => {
                   this.getPatientWorklistItems();
                });
         },
         async saveScheduledWorklistItem(customerId) {
            if (this.newWorklistItem.worklistItemTypeId !== WorklistItemTypeEnum.Offloading) //'not' offloading
            {
               this.newWorklistItem.thermometryAssessment = null;
            }
            this.scheduledWorklistItem.worklistItemTypeId = this.newWorklistItem.worklistItemTypeId;
            this.scheduledWorklistItem.patientId = this.patient.patientId;
            this.scheduledWorklistItem.created = new Date().toISOString();
            this.scheduledWorklistItem.createdByUserId = this.$store.state.achillesUser.userId;
            this.scheduledWorklistItem.assignedToUserId = this.newWorklistItem.assignedToUserId;
            this.scheduledWorklistItem.thermometryAssessment = this.newWorklistItem.thermometryAssessment;
            this.scheduledWorklistItem.note = this.newWorklistItem.note;
            await new ScheduledWorklistItemService()
                .postScheduledWorklistItem(customerId, this.scheduledWorklistItem)
                .then(() => {
                   this.getScheduledWorklistItems();
                });
         },
         async deleteScheduledWorklistItem(customerId, item) {
            item.isDeleted = true;
            await new ScheduledWorklistItemService()
                .putScheduledWorklistItem(customerId, item.scheduledWorklistItemId, item)
                .then(() => {
                   this.getScheduledWorklistItems();
                });
         },
         getScheduledWorklistItems() {
            this.isLoading = true;
            this.isLoadingScheduled = true;
            
            new ScheduledWorklistItemService()
                .getScheduledWorklistItems(this.customerId, this.patient.patientId)
                .then((resp) => {
                   this.scheduledWorklistItems = resp;
                })
                .catch((err) => {
                   console.log(err);
                })
                .finally(() => {
                   this.isLoading = false;
                   this.isLoadingScheduled = false;
                });
         },
         getPatientWorklistItems() {
            let promises = [];
            this.isLoading = true;
            this.combinedWorklistItems = [];
            promises.push(
                new PatientService()
                    .getPatientWorklistItems(this.customerId, this.patient.patientId)
                    .then((resp) => {
                       this.worklistItems = resp;
                       this.worklistItems.forEach(w => {
                          if (w.worklistItemTypeId === WorklistItemTypeEnum.Escalation) {
                             w.priority = "";
                          } else {
                             w.priority = "High";
                          }
                          this.combinedWorklistItems.push(w);
                       });
                    })
                    .catch((err) => {
                       console.log(err);
                    })
            );
            
            promises.push(
                new NextWorklistItemService()
                    .getNextWorklistItems(this.customerId, this.patient.patientId)
                    .then((resp) => {
                       this.nextWorklistItems = resp;
                       this.nextWorklistItems.forEach(w => {
                          w.priority = "Low";
                          this.combinedWorklistItems.push(w);
                       });
                    })
                    .catch((err) => {
                       console.log(err);
                    })
            );
            
            Promise.allSettled(promises).then(() => {
               this.isLoading = false;
               this.initialLoad = true;
            });
         },
         alreadyExists() {
            if (!this.scheduledWorklistItem.isScheduled) {
               let res = this.worklistItems.some(
                   w => w.worklistItemTypeId == this.newWorklistItem.worklistItemTypeId && w.isEscalation == false);
               if (res) {
                  return `This Patient already has this Worklist Item Type of "${this.getItemTypeDescriptionForId(
                      this.newWorklistItem.worklistItemTypeId)}" Open`;
               } else {
                  return true;
               }
            } else {
               let res2 = this.scheduledWorklistItems.some(
                   w => this.formatDateSimple(w.scheduledFor) == this.scheduledWorklistItem.scheduledFor);
               if (res2) {
                  return "This Patient already has a Scheduled Worklist Item on that date";
               } else {
                  return true;
               }
            }
         },
         getItemTypeDescriptionForId(id) {
            return this.worklistItemTypes.find((u) => u.worklistItemTypeId === id).description;
         },
         getUserNameForId(id) {
            if (!id) {
               return "Unassigned";
            } else {
               let user = this.achillesUsers.find((u) => u.userId === id);
               if (user) {
                  return user.lastName + " " + user.firstName;
               } else {
                  return "Unknown";
               }
            }
         },
         initNewWorklistItem() {
            if (this.newWorklistItem.worklistId) {
               Shared.emptyObject(this.newWorklistItem);
               Shared.emptyObject(this.scheduledWorklistItem);
            }
            this.newWorklistItem.worklistId = 1;
            this.newWorklistItem.worklistItemTypeId = 0;
            this.newWorklistItem.isEscalation = false;
            this.newWorklistItem.patientId = 0;
            this.newWorklistItem.WorklistItemStatusTypeId = 1;
            this.scheduledWorklistItem.isScheduled = false;
            this.scheduledWorklistItem.scheduledFor = null;
         },
         async showHistory() {
            this.isLoading = true;
            this.isLoadingHistory = true;
            await (new WorklistItemHistoryService()
                .getWorklistItems(this.customerId, this.patient.patientId)
                .then((resp) => {
                   this.historyWorklistItems = resp;
                })
                .catch((err) => {
                   console.log(err);
                }));
            this.loadHistory = true;
            this.isLoadingHistory = false;
            this.isLoading = false;
         }
      },
      mounted() {
         this.worklistItemTypes = this.$store.state.worklistItemTypes.sort(
             (a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
         this.achillesUsers =
             this.$store.state.users.sort((a, b) => (a.email > b.email) ? 1 : ((b.email > a.email) ? -1 : 0));
         this.initNewWorklistItem();
         this.getPatientWorklistItems();
         this.getScheduledWorklistItems();
      }
   };
</script>