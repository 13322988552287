<template>
    <v-container fluid ma-0 pa-0>
        <v-row>
            <v-col >
                <v-data-table :headers="assignmentHistoryHeaders"
                                :items="patientAssignmentHistory"
                                :loading="isLoading"
                                disable-pagination
                                :hide-default-footer="true"
                                class="elevation-1">
                    <template #[`item.deviceStatusChanged_formatted`]="{ item }">
                        {{ item.deviceStatusChanged | formatDate }}
                    </template>
                    <template #[`item.trackingNumberForShipped`]="{ item }">
                        {{ item.deviceStatusId == 3 ? item.trackingNumber : '' }}
                    </template>
                    <template #[`item.withdrawalReason`]="{ item }">
                        {{ item.deviceStatusId == 9 ? item.reason : '' }}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Vue from "vue";
import moment from 'moment';
import CustomerAssignmentsService from "@/services/CustomerAssignmentService";

Vue.filter('formatDate', function (value) {
        if (value) {
            return moment(String(value)).format('MM/DD/YYYY')
        }
    });

export default {
    name: "AssignmentHistory",
    props: { patientId: Number, customerId: Number },
    data:()=>({
        isLoading:false,
        assignmentHistoryHeaders: [
            { text: "Serial Number", value: "deviceSerialNumber" },
            { text: "Status", value: "deviceStatusDescription" },
            { text: "Reason", value: "withdrawalReason" },
            { text: "Tracking Number", value: "trackingNumberForShipped" },
            { text: "Date", value: "deviceStatusChanged_formatted", sortable: false, },
            { text: "User", value: "updatedByUserName", sortable: false, },
        ],
        patientAssignmentHistory: []
    }),
    methods:  {
        lookupPatientAssignmentHistory: async function () {
            this.patientAssignmentHistory = [];
            if(!this.customerId || !this.patientId) return;

            this.isLoading = true;
            await new CustomerAssignmentsService()
                .getPatientAssignmentHistory(this.customerId, this.patientId)
                .then((value) => {
                    this.patientAssignmentHistory = value.assignments;
                })
                .finally(() => (this.isLoading = false));
        }
    },
    watch: {
        refreshProperties: function () {
            this.lookupPatientAssignmentHistory();
        }
    },
    computed: {
        refreshProperties() {
          return `${this.patientId}|${this.customerId}`;
        },
        hasCloseListener(){
            return this.$listeners && this.$listeners.close;
        }
    },
    created() {
        this.lookupPatientAssignmentHistory();
    }
}
</script>