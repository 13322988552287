import * as master from '../types/master';
import * as BaseService from './BaseService';
import * as customer from "@/types/customer";

export default class CustomerMasterService extends BaseService.BaseService {
    private url = '/api/CustomerMaster';

    constructor() {
        super();
    }

    async get(customerId: number): Promise<master.Customer> {
        let url = this.url + `/${customerId}`;
        let response = (await this.api.get(url)).data as master.Customer;
        return response;
    }

    async getVACustomer(): Promise<Array<master.Customer>> {
        let response = (await this.api.get(this.url)).data as Array<master.Customer>;
        return response.filter(element => element.customerTypeName == "VA");
    }

    async getCommericalCustomer(): Promise<Array<master.Customer>> {
        let response = (await this.api.get(this.url)).data as Array<master.Customer>;
        return response.filter(element => element.customerTypeName == "Commercial");
    }

    async put(customerId: number, customer: master.Customer) {
        let url = this.url + `/${customerId}`;
        let response = await this.api.put(url, customer).catch(err => {
            throw err;
        });
        return response;
    }

    async getCustomer(): Promise<Array<master.Customer>> {
        let response = (await this.api.get(this.url)).data as Array<master.Customer>;
        return response;
    }

}