<template>
  <v-dialog v-model="show" v-if="show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      :retain-focus="false"
  >
    <v-card>
      <patient-details
          :patientId="patientId"
          :customerId="customerId"
          :customer-name="customerName"
          v-on:closed="show = false"
      />
    </v-card>
  </v-dialog>

</template>

<script>
import PatientDetails from "@/components/PatientDetails";

export default {
  name: "PatientDialog",
  props: {
    customerId: Number,
    patientId: Number,
    customerName: String,
    visible: Boolean
  },
  data: () => ({
    //
  }),
  components: {
    PatientDetails
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },
  methods: {
    closeDialog() {
      this.show = false;
    },
    selectText(value) {
      this.search = value;
    },
    copyToClipboard(value) {
      let self = this;
      this.$copyText(value).then(
          function () {
            self.clipboardText = value;
            self.clipboardSnackbar = true;
          });
    }
  }
}
</script>

<style scoped>

</style>