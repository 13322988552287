<template>
  <v-card v-if="isLoaded">
    <v-alert v-if="!alertRef && alertRef.alert"  :value="alertRef.alert" :type="alertRef.alertType" dense transition="fade-transition">{{ alertRef.alertMessage }}</v-alert>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <DatePicker v-model="escalationToClinicDateInternal" label="Escalation To Clinic Date"/>
          <v-text-field v-model="providerDisplayName" label="Provider" readonly required @click="onLookup"
                        @focus="onLookup"></v-text-field>
          <v-text-field v-model="locationIEN" label="LocationIEN" readonly required></v-text-field>
          <v-text-field v-model="localEscalationId" label="Escalation ID" readonly required></v-text-field>
          <v-text-field v-model="patientIcn" label="Patient ICN" readonly required></v-text-field>
          <v-text-field v-model="patientDFN" label="Patient DFN" readonly required></v-text-field>
          <v-text-field v-model="patientFirstName" label="Patient First Name" readonly required></v-text-field>
          <v-text-field v-model="patientLastName" label="Patient Last Name" readonly required></v-text-field>
          <DatePicker v-model="nextAppointmentDateInternal" label="Next Appointment Date"/>
          <v-textarea v-model="nextAppointmentNote" :rows="2" label="Next Appointment Note"></v-textarea>
        </v-col>
        <v-col cols="6">
          <v-textarea v-model="escalationSummary" :rows="10" label="Escalation Summary" prepend-icon= "mdi-note"></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" @click="confirmSendToProvider" :disabled="isSendToProviderDisabled || hasSentToClinicOn">Send to Provider</v-btn>
    </v-card-actions>


    <v-dialog v-if="showLookupDialog" v-model="showLookupDialog" max-width="1200px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn dark icon @click="showLookupDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Lookup Provider</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="showLookupDialog = false"> Close</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <provider-lookup :customer-id="customerId" :patientId="patientId" :patientSiteId="patientSiteId"
                           @selected="onSearchSelected"></provider-lookup>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-card>

</template>

<script>
import GetPatient from "@/components/Escalation/queries/patient.graphql";
import DatePicker from "@/components/Shared/DatePicker.vue";
import ProviderLookup from "@/components/Provider/ProviderLookup.vue";
import EscalationService from "@/services/EscalationService";
import ProviderService from "@/services/ProviderService";
import SiteService from "@/services/SiteService";
import VuetifyDialog from "vuetify-dialog";
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import { IdentifierTypeEnum } from "@/types/enum";

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});

export default {
  name: "VaEscalation",
  components: {
    ProviderLookup,
    DatePicker,
  },
  props: {
    hasSentToClinicOn: Boolean,
    customerId: {
      type: Number,
      required: true,
    },
    patientId: {
      type: Number,
      required: true,
    },
    patientSiteId: {
        type: Number,
        required: true,
    },
    providerIdProp: {
        type: Number,
        required: false,
    },
    providerSiteId: {
        type: Number,
        required: false,
    },
    escalationId: {
      type: Number,
      required: true,
    },
    currentAdherence: {
        type: String,
        required: false,
    },
    lastUsed: {
        type: String,
        required: false,
    },
    inflammationDuration: {
      type: Number,
      required: true,
    },
    inflammationLocation: {
      type: Number,
      required: true,
    }
  },
  data() {

    return {
      showLookupDialog: false,
      escalationToClinicDateMenu: false,
      escalationToClinicDateInternal: null,
      patientIcn: "",
      patientDFN: "",
      nextAppointmentDateMenu: false,
      nextAppointmentDateInternal: null,
      nextAppointmentNote: "",
      escalationSummary: "",
      skipQuery: false,
      customer: null,
      subjectiveQuestionsFindingsAssessment: null,
      localEscalationId: null,
      patientFirstName: "",
      patientLastName: "",
      npi: null,
      providerId: null,
      providerDUZId: "",
      providerStation: "",
      locationName: "",
      locationIEN: null,
      providerName: "",
      providerDisplayName: "",
      patientSiteNumber: "",
      userId: this.$store.state.achillesUser.userId,
      alertRef: {
        alert: false,
        alertType: "",
        alertMessage: "",
      },
      isLoaded:false,
      isSendToProviderDisabled: false
    };
  },
  methods: {
    confirmSendToProvider() {
        this.$dialog.info({
            text: "By selecting to send this health information to the patient's provider, you acknowledge and agree that all of the information is accurate and may be incorporated into the patient's permanent medical record. Would you like to proceed?",
            title: "Escalation Confirmation",
            actions: ['Okay', 'Cancel'],
            type: "info"
        }).then((response) => {
            if (response === 'Okay') {
                this.siteReceivesEscalationsCheck();
            } 
        });
    },
    triggerQuery() {
      this.$apollo.queries.customer.skip = false;
      this.$apollo.queries.customer.refetch();
    },
    onLookup() {
      this.showLookupDialog = true;
    },
    async onSearchSelected(val) {
      this.showLookupDialog = false;
      this.providerId = val.providerId;
      this.providerName = val.name;
      this.npi = val.number;
      this.providerDUZId = val.providerDUZ;
      this.locationIEN = val.locationIEN;
      this.providerStation = val.siteNumber;
      this.providerDisplayName = val.name + (val.number != null ? ` (${val.number})` : "");
      this.getSiteLocationName();
    },
    formatDate(dateStr) {
      if (!dateStr) return "";
      const [year, month, day] = dateStr.split("-");
      return `${month}/${day}/${year}`;
    },
    onCancel() {
      this.$emit("cancel");
    },
    async siteReceivesEscalationsCheck() {
        if (this.patientSiteId) {
            await new SiteService().getSitesReceivingEscalations(this.customerId)
                .then((value) => {
                    const patientSiteReceiving = value.some(site => site.siteId === this.patientSiteId);
                    if (!patientSiteReceiving) {
                        this.$dialog.info({
                            text: "Patient's Site is not receiving escalations at this time.",
                            title: "Site Not Receiving Escalations",
                            actions: ['Okay'],
                            type: "warning"
                        });
                    } else {
                        this.onSendToProvider();
                    }
                })
                .catch((error) => {
                    console.error("Error fetching sites: ", error);
                });
        } else {
            this.$dialog.info({
                text: "Patient has no SiteID",
                title: "Missing SiteID",
                actions: ['Okay'],
                type: "warning"
            })
        }
    },
    async onSendToProvider() {
        if (!this.providerStation) {
            this.$dialog.info({
                text: "Provider is missing station value!",
                title: "Missing Provider Station",
                actions: ['Okay'],
                type: "warning"
            });
        } else if (!this.providerDUZId) {
            this.$dialog.info({
                text: "Provider does not have a DUZ!",
                title: "Missing DUZ",
                actions: ['Okay'],
                type: "warning"
            });
        } else if (!this.locationIEN) {
            this.$dialog.info({
                text: "Provider LocationIEN is missing!",
                title: "Missing Provider LocationIEN",
                actions: ['Okay'],
                type: "warning"
            });
        } else if (!this.locationName) {
            this.$dialog.info({
                text: "Location Name is missing!",
                title: "Missing Provider Location Name",
                actions: ['Okay'],
                type: "warning"
            });
        } else if (this.providerId == null) {
            this.$dialog.info({
                text: "Provider is mandatory field!",
                title: "Missing Provider",
                actions: ['Okay'],
                type: "warning"
            });
        } else if (this.patientIcn === null || this.patientIcn.length !== 17) {
            this.$dialog.info({
                text: "A 17 digit PatientICN is required!",
                title: "PatientICN",
                actions: ['Okay'],
                type: "warning"
            });
        } else {
            const escalationData = {
                escalationToClinicDate: this.escalationToClinicDateInternal,
                escalationId: this.escalationId,
                patientIcn: this.patientIcn,
                patientDFN: this.patientDFN,
                patientFirstName: this.patientFirstName,
                patientLastName: this.patientLastName,
                nextAppointmentDate: this.nextAppointmentDateInternal,
                nextAppointmentNote: this.nextAppointmentNote,
                subjectiveAssessment: this.escalationSummary,
                providerId: this.providerId,
                providerDUZId: this.providerDUZId,
                providerStation: this.providerStation,
                locationIEN: this.locationIEN,
                locationName: this.locationName,
                providerName: this.providerName,
                userId: this.userId
            };

            await new EscalationService().sendVaPatientEscalation(this.customerId, escalationData)
                .then(response => {
                    this.$dialog.info({
                        text: "Sent to provider successfully.",
                        title: "Sent",
                        actions: ['Okay'],
                        type: "success"
                    });
                    this.$emit('sent');
                })
                .catch(error => {
                    if (error.response && error.response.status === 409) {
                        this.$dialog.info({
                            text: "Escalation already exists in the staging table.",
                            title: "Duplicate",
                            actions: ['Okay'],
                            type: "warning"
                        });
                    } else {
                        console.error(error); 
                        this.$dialog.info({
                            text: "An error occurred. Please try again.",
                            title: "Error",
                            actions: ['Okay'],
                            type: "error"
                        });
                    }
                });
        }
    },
    doLowerCase(arr) {
      if (!arr) return arr;
      return arr.toLowerCase();
    },
    isNull(arr) {
      return !arr || typeof arr === 'undefined' || this.doLowerCase(arr) === 'null';
    },
    async getPatientEscalationDetails() {
       await new EscalationService().getPatientEscalationDetails(this.customerId, this.escalationId)
        .then((value) => {
            const numberOfPriorClinicalEscalations = Math.max(0, value.numberOfClinicalEscalations - 1);
            const priorEscalationsString = `Total Number of Prior Clinical Escalations: ${numberOfPriorClinicalEscalations.toString()}\n`;

          const currentAdherence = `Adherence: ${this.isNull(this.currentAdherence) ? 'N/A' : this.currentAdherence}\n`;

          let dateLastUsed = '';
          if (!this.isNull(this.lastUsed)) {
            const dateLastUsedValue = new Date(this.lastUsed);
            const mm = String(dateLastUsedValue.getMonth() + 1).padStart(2, '0');
            const dd = String(dateLastUsedValue.getDate()).padStart(2, '0');
            const yyyy = dateLastUsedValue.getFullYear();
            dateLastUsed = `Date Last Used: ${mm}/${dd}/${yyyy}\n`;
          } else {
            dateLastUsed = `Date Last Used: N/A\n`;
          }
          const inflammationDuration = `Inflammation Duration: ${this.inflammationDuration}\n`;
          const location = `Location: ${this.isNull(this.inflammationLocation) ? 'N/A' : this.inflammationLocation}\n\n`;

            this.escalationSummary = priorEscalationsString;
            this.escalationSummary += currentAdherence; 
            this.escalationSummary += dateLastUsed;  
            this.escalationSummary += inflammationDuration;  
            this.escalationSummary += location + value.escalationSummary;  

            this.localEscalationId = value.escalationId;
            this.nextAppointmentDateInternal = value.nextAppointmentDate;
            this.nextAppointmentNote = value.nextAppointmentNote;
            this.subjectiveQuestionsFindingsAssessment = value.subjectiveQuestionsFindingsAssessment;
        });
    },
    async getProviderEscalationDetails() {
        console.log("PSID: " + this.providerSiteId);
        await new ProviderService().get(this.customerId, this.providerId)
            .then((value) => {
              if (value.providerSites !== null) {
                let matchedSite = value.providerSites.find(ps => ps.providerSiteId === this.providerSiteId);

                if (matchedSite) {
                  this.providerDUZId = matchedSite.providerDUZ;
                  this.providerStation = matchedSite.siteNumber;
                  this.locationIEN = matchedSite.locationIEN == 0 ? null : matchedSite.locationIEN;
                } else {
                  console.log("No matching sites");
                }
              }
            });
    },
    async getSiteLocationName() {
        if (this.locationIEN) {
            await new SiteService().getSiteReference(this.customerId, this.locationIEN)
                .then((value) => {
                    this.locationName = value.locationClinicName;
                });
        }
    },
  },
  apollo: {
    customer: {
      query: GetPatient,
      variables() {
        return {
          patientId: this.patientId,
          customerId: this.customerId
        }
      },
      update(data) {
        const patientData = data.customer.patient;
        if (patientData != null) {
          this.patientFirstName = patientData.firstName;
          this.patientLastName = patientData.lastName;
          const patientIdentifierData = patientData.patientIdentifiers.data;
          const patientEnterpriseId = patientIdentifierData.find(identifier => identifier.identifierTypeId && identifier.identifierTypeId === IdentifierTypeEnum.EnterpriseId);
          const patientEnterpriseIdTwo = patientIdentifierData.find(identifier => identifier.identifierTypeId && identifier.identifierTypeId === IdentifierTypeEnum.DFN);
          this.patientIcn = patientEnterpriseId ? patientEnterpriseId.identifierValue : "";
          this.patientDFN = patientEnterpriseIdTwo ? patientEnterpriseIdTwo.identifierValue : "";

          if (patientData.patientHistory &&
              patientData.patientHistory.patientInteractionForms) {
                this.nextAppointmentDateInternal = this.nextAppointmentDateInternal ? this.nextAppointmentDateInternal : patientData.patientHistory.patientInteractionForms[0].nextClinicVisit;
                this.nextAppointmentNote = this.nextAppointmentNote ? this.nextAppointmentNote : "";
          }
          
          if (patientData.patientProviders && patientData.patientProviders.data[0]) {
            let patientProviderData = patientData.patientProviders.data[0];
            this.providerId = this.providerId || patientProviderData.providerId;
            this.providerDisplayName = patientProviderData.provider.name + (patientProviderData.provider.nPI != null ? ` (${patientProviderData.provider.nPI})` : "");
            this.providerName = patientProviderData.provider.name;
          }
          this.isLoaded=true;
          if(this.providerDisplayName != ""){
             this.isSendToProviderDisabled = false;  
          }
          else{
              this.isSendToProviderDisabled =  true;
            }
          return data.customer;
        }
      },

      // Disable query on component load.
      // We need to execute query when component is created and props are passed 
      skip() {
        return this.skipQuery;
      }
    }
  },
  computed: {
    //
  },
  created() {
    this.escalationToClinicDateInternal = new Date()
        .toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).split("/").reverse().join("-");
  },
  async mounted() {
    this.providerId = this.providerIdProp;
    this.triggerQuery();
    this.getPatientEscalationDetails();   
    if (this.providerId) {
        await this.getProviderEscalationDetails();
        this.getSiteLocationName();
    }
  }
};
</script>

<style lang="scss">
  .v-dialog__content .v-dialog--fullscreen {
    background-color: white !important;
  }
</style>
