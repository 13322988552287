import * as BaseService from './BaseService';
import {NpiRequest, NpiResult} from "@/types/npi";

export default class NpiService extends BaseService.BaseService {
   private url = '/api/Npi';
      
   constructor() {
     super();     
   }
    async search(request: NpiRequest): Promise<NpiResult>{
        let url = this.url;
        let response = await this.api.post(url, request).catch(err => {
            throw err;
        });
        return response.data as NpiResult;
    }
  }