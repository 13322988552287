import * as BaseService from './BaseService';
import * as customer from '../types/customer';
import * as escalation from '../types/escalation';
import * as textConverter from '../utils/textConverter';
export default class EscalationService extends BaseService.BaseService {
    private url = '/api/Escalation';

    constructor() {
        super();
    }

    async getEscalation(customerId: number, id: number): Promise<customer.Escalation> {
        let url = this.url + `/${customerId}/${id}`;
        let response;
        response = await this.api.get(url).catch(err => {
            throw err;
        });
        response.data["escalationContext"] =  textConverter.htmlToPlainText(response.data["escalationContext"]);
        return response.data;
    }

    async putEscalation(customerId: number, id: number, escalation: customer.Escalation) {
        let url = this.url + `/${customerId}/${id}`;
        let response = await this.api.put(url, escalation).catch(err => {
            throw err;
        });
        return response;
    }

    async postEscalation(customerId: number, escalation: customer.Escalation) {
        let url = `/api/Escalation/${customerId}`;
        let response = await this.api.post(url, escalation).catch(err => {
            throw err;
        });
        return response;
    }

    async sendVaPatientEscalation(customerId: number, escalation: escalation.VaPatientEscalation) {
        let url = this.url + `/StageVaPatientEscalation/${customerId}`;
        const response = await this.api.post(url, escalation).catch(err => {
            throw err;
        });
        return response;
    }

    async getPatientEscalationDetails(customerId: number, escalationId: number): Promise<customer.EscalationSummaryDetails> {
        let url = this.url + `/GetPatientEscalationDetails/${customerId}/${escalationId}`;
        let response;
        response = await this.api.get(url).catch(err => {
            throw err;
        });
        response.data["escalationSummary"] = textConverter.htmlToPlainText(response.data["escalationSummary"]);
        return response.data;
    }
}