<template>
<div v-show="savedForms.length > 0">
  You have unsaved Patient Interactions for the following Patients:
  <ul style="list-style-type:circle">
    <li v-for="form in savedForms" :key="'' + form.patientId + form.customerId">{{form.firstName}} {{form.lastName}} - {{form.designation}}</li>
  </ul>
</div> 
</template>
<script>

import ls from 'localstorage-slim';
import encUTF8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';
import PatientService from "../services/PatientService";



export default {
  data: () => ({
      savedForms: [],
      cachedEncryptedInteractionForms: "",
      firstRun: true,
    }),   
  created(){ 
     //
  },
  watch: {
    '$store.getters.getLastUnsavedInteraction': function () {
      this.checkForSavedPatientInteractions();
    }
  },
  mounted() {
      const self = this;
      setTimeout(function() {
        ls.config.encrypt = true;
        ls.config.secret = self.$store.state.achillesUser.uniqueKey;
        ls.config.encrypter = (data, secret) => AES.encrypt(JSON.stringify(data), secret).toString();
        ls.config.decrypter = (data, secret) => JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
        self.firstRun = false;
        self.checkForSavedPatientInteractions();
      }, 1000);
    }, 
  methods: {
      checkForSavedPatientInteractions() {
        if (this.firstRun) return;
        if (this.cachedEncryptedInteractionForms  !== ls.get('savedPatientInteractionForms', {decrypt: false})) {
          this.savedForms.splice(0);
          const savedPatientInteractionForms = ls.get('savedPatientInteractionForms', {decrypt: true}) ? ls.get('savedPatientInteractionForms', {decrypt: true}) : [];
          if (savedPatientInteractionForms.length > 0) {
            let parsedSavedPatientInteractionForms = savedPatientInteractionForms;
            if (!Array.isArray(savedPatientInteractionForms)) {
              parsedSavedPatientInteractionForms = JSON.parse(savedPatientInteractionForms);
            }
            for (const form of parsedSavedPatientInteractionForms) {
              const savedForm = {
                firstName: form.patientInfo.firstName,
                lastName: form.patientInfo.lastName,
                designation: form.patientInfo.designation,
                customerId: form.patientInfo.customerId,
                patientId: form.patientInfo.patientId
              };

              this.savedForms.push(savedForm);
            }
          }
        }
      }
    }
}
</script>
