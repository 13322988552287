import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import * as master from "../types/master";
import * as customer from "../types/customer";
import { GUserProfile } from "../types/gUserProfile";
import UserService from "../services/UserService";
import ReferenceDataService from "../services/ReferenceDataService";
import router from "../router";
import { PodAccessService } from "@/services/PodAccessService";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    signedIn: false as boolean,
    restrictedAccess: false as boolean,
    timeoutAlert: false as boolean,
    lastUnsavedInteraction: String,
    gUserProfile: {} as GUserProfile,
    achillesUser: {} as master.User,
    states: [] as Array<customer.State>,
    footLocations: [] as Array<customer.FootLocation>,
    foots: [] as Array<customer.Foot>,
    footConditions: [] as Array<customer.FootCondition>,
    users: [] as Array<master.User>,
    interactionTypes: [] as Array<customer.InteractionType>,
    worklistTypes: [] as Array<customer.WorklistType>,
    worklistItemTypes: [] as Array<customer.WorklistItemType>,
    worklistItemStatusTypes: [] as Array<customer.WorklistItemStatusType>,
    timeZones: [] as Array<customer.TimeZone>,
    patientInteractionForms: [] as Array<customer.PatientInteractionForm>,
    forms: [] as Array<customer.Form>,
    interactionTypeForms: [] as Array<customer.InteractionTypeForm>,
    savedPatientInteractionForms: [] as Array<customer.PatientInteractionForm>,
    genders: [] as Array<customer.Gender>,
    providerTypes: [] as Array<customer.ProviderType>,
    podAccessServices: {} as PodAccessService,
  },
  mutations: {
    setPodAccessServices(state, podAccessServices) {
      state.podAccessServices = podAccessServices;
    },
    setSavedPatientInteractionForms(
      state,
      savedPatientInteractionForms: Array<customer.PatientInteractionForm>
    ) {
      state.savedPatientInteractionForms = savedPatientInteractionForms;
    },
    setLastUnsavedInteraction(state, lastUnsavedInteraction) {
      state.lastUnsavedInteraction = lastUnsavedInteraction;
    },
    setTimeoutAlert(state, timeoutAlert) {
      state.timeoutAlert = timeoutAlert;
    },
    setSignedIn(state, isSignedIn) {
      state.signedIn = isSignedIn;
    },
    setRestrictedAccess(state, isRestricted) {
      state.restrictedAccess = isRestricted;
    },
    setGUserProfile(state, profile: GUserProfile) {
      state.gUserProfile = profile;
    },
    setachillesUser(state, user: master.User) {
      state.achillesUser = user;
    },
    setStates(state, states: Array<customer.State>) {
      state.states = states;
    },
    setFootLocations(state, footLocations: Array<customer.FootLocation>) {
      state.footLocations = footLocations;
    },
    setFootConditions(state, footConditions: Array<customer.FootCondition>) {
      state.footConditions = footConditions;
    },
    setFoots(state, foots: Array<customer.Foot>) {
      state.foots = foots;
    },
    setForms(state, forms: Array<customer.Form>) {
      state.forms = forms;
    },
    setPatientInteractionForms(
      state,
      patientInteractionForms: Array<customer.PatientInteractionForm>
    ) {
      state.patientInteractionForms = patientInteractionForms;
    },
    setInteractionTypeForms(
      state,
      interactionTypeForms: Array<customer.InteractionTypeForm>
    ) {
      state.interactionTypeForms = interactionTypeForms;
    },
    setUsers(state, users: Array<master.User>) {
      state.users = users;
    },
    setInteractionTypes(
      state,
      interactionTypes: Array<customer.InteractionType>
    ) {
      state.interactionTypes = interactionTypes;
    },
    setWorklistTypes(state, worklistTypes: Array<customer.WorklistType>) {
      state.worklistTypes = worklistTypes;
    },
    setWorklistItemTypes(
      state,
      worklistItemTypes: Array<customer.WorklistItemType>
    ) {
      state.worklistItemTypes = worklistItemTypes;
    },
    setWorklistItemStatusTypes(
      state,
      worklistItemStatusTypes: Array<customer.WorklistItemStatusType>
    ) {
      state.worklistItemStatusTypes = worklistItemStatusTypes;
    },
    setTimeZones(state, timeZones: Array<customer.TimeZone>) {
      state.timeZones = timeZones;
    },
    setGenders(state, genders: Array<customer.Gender>) {
      state.genders = genders;
    },
    setProviderTypes(state, providerTypes: Array<customer.ProviderType>) {
      state.providerTypes = providerTypes;
    },
  },
  getters: {
    getLastUnsavedInteraction: (state) => {
      return state.lastUnsavedInteraction;
    },
    getGUserProfile(state) {
      const nowSeconds = new Date().getTime() / 1000; // new Date(Date.now());
      if (nowSeconds >= state.gUserProfile.expires) {
        console.log("token expired");
        state.signedIn = false;
        return;
      }

      return state.gUserProfile;
    },
    getAchillesUser(state) {
      return state.achillesUser;
    },
  },
  actions: {
    logout() {
      router.push("Logout");
    },
    goHome() {
      if (router.currentRoute.path !== "/") router.push("/");
    },
    getUsers({ commit }) {
      new UserService()
        .getUsers()
        .then(async (resp) => {
          commit("setUsers", resp);
          return resp;
        })
        .catch((error) => {
          throw new Error(`API ${error}`);
        });
    },
    getUser({ commit }, email) {
      new UserService()
        .getUser(email)
        .then(async (resp) => {
          commit("setachillesUser", resp);
          return resp;
        })
        .catch(() => {
          setTimeout(() => {
            new UserService()
              .getUser(email)
              .then(async (resp) => {
                commit("setachillesUser", resp);
              })
              .catch((error) => {
                throw new Error(`API ${error}`);
              });
          }, 1000);
        })
        .catch((error) => {
          throw new Error(`API ${error}`);
        });
    },
    getTimeZones({ commit }) {
      new ReferenceDataService()
        .getTimeZones()
        .then((resp) => {
          commit("setTimeZones", resp);
          return resp;
        })
        .catch((error) => {
          throw new Error(`API ${error}`);
        });
    },
    getWorklistItemTypes({ commit }) {
      new ReferenceDataService()
        .getWorklistItemTypes()
        .then((resp) => {
          commit("setWorklistItemTypes", resp);
          return resp;
        })
        .catch((error) => {
          throw new Error(`API ${error}`);
        });
    },
    getGenders({ commit }) {
      new ReferenceDataService()
        .getGenders()
        .then((resp) => {
          commit("setGenders", resp);
          return resp;
        })
        .catch((error) => {
          throw new Error(`API ${error}`);
        });
    },
    getProviderTypes({ commit }) {
      new ReferenceDataService()
        .getProviderTypes()
        .then((resp) => {
          commit("setProviderTypes", resp);
          return resp;
        })
        .catch((error) => {
          throw new Error(`API ${error}`);
        });
    },
  },
  modules: {},
});
