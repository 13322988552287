<template>
  <div>
    <v-dialog v-model="dialogPurchaseOrder" v-if="dialogPurchaseOrder"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="dialogPurchaseOrder = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    Purchase Order - {{ selectedPurchaseOrderValues.legacyId }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="dialogPurchaseOrder = false">
                        Close
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <PurchaseOrderDetails :poId="selectedPurchaseOrderValues.poId"
                                  :legacyId="selectedPurchaseOrderValues.legacyId"
                                  :patientFirstName="selectedPurchaseOrderValues.patientFirstName"
                                  :patientLastName="selectedPurchaseOrderValues.patientLastName"
                                  :siteId="selectedPurchaseOrderValues.siteId"
                                  :created="formDateFormat(selectedPurchaseOrderValues.created)"
                                  :updatedByUserId="selectedPurchaseOrderValues.updatedByUserId"
                                  :updatedByUserName="selectedPurchaseOrderValues.updatedByUserName"
                                  :received="formDateFormat(selectedPurchaseOrderValues.received)"
                                  :poDate="formDateFormat(selectedPurchaseOrderValues.poDate)"
                                  :delivered="formDateFormat(selectedPurchaseOrderValues.delivered)"
                                  :assigned="formDateFormat(selectedPurchaseOrderValues.assigned)"
                                  :legacyNumber="selectedPurchaseOrderValues.legacyNumber"
                                  :fileStoreObjectName="selectedPurchaseOrderValues.fileStoreObjectName"
                                  :processed="formDateFormat(selectedPurchaseOrderValues.processed)"
                                  :voided="formDateFormat(selectedPurchaseOrderValues.voided)"
                                  :trained="formDateFormat(selectedPurchaseOrderValues.trained)"
                                  :isDeleted="selectedPurchaseOrderValues.isDeleted"
                                  :note="selectedPurchaseOrderValues.note"
                                  :assignedNote="selectedPurchaseOrderValues.assignedNote"
                                  :trainedNote="selectedPurchaseOrderValues.trainedNote"
                                  :isShipmentRequested="selectedPurchaseOrderValues.isShipmentRequested"
                                  :isRenewalPatient="selectedPurchaseOrderValues.isRenewalPatient"
                                  :onFulfillmentHold="selectedPurchaseOrderValues.onFulfillmentHold"
                                  :providerId="selectedPurchaseOrderValues.providerId"
                                  :isDLC="selectedPurchaseOrderValues.isDLC"
                                  :initialDeliveryMethod="selectedPurchaseOrderValues.initialDeliveryMethod"
                                  :trainingTypeDescription="selectedPurchaseOrderValues.trainingTypeDescription" 
                                  :showViewOption="false"/>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPrescription"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition">
      <v-card>
          <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogPrescription = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                  Prescription - {{ selectedPrescriptionValues.legacyId }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                  <v-btn dark text @click="dialogPrescription = false">
                      Close
                  </v-btn>
              </v-toolbar-items>
          </v-toolbar>
          <PrescriptionDetails  :patientFirstName="selectedPrescriptionValues.patientFirstName"
                                :patientLastName="selectedPrescriptionValues.patientLastName"
                                :prescribingPhysicianName="selectedPrescriptionValues.prescribingPhysicianName"
                                :prescriptionId="selectedPrescriptionValues.prescriptionId"
                                :prescriptionDate="selectedPrescriptionValues.prescriptionDate | formatDate"
                                :legacyId="selectedPrescriptionValues.legacyId"
                                :created="selectedPrescriptionValues.created | formatDate"
                                :updatedByUserId="selectedPrescriptionValues.updatedByUserId"
                                :updatedByUserName="selectedPrescriptionValues.updatedByUserName"
                                :received="selectedPrescriptionValues.received | formatDate"
                                :voided="selectedPrescriptionValues.voided | formatDate"
                                :trained="selectedPrescriptionValues.trained | formatDate"
                                :delivered="selectedPrescriptionValues.delivered | formatDate"
                                :legacyNumber="selectedPrescriptionValues.legacyNumber"
                                :patientId="selectedPrescriptionValues.patientId"
                                :fileStoreObjectName="selectedPrescriptionValues.fileStoreObjectName"
                                :isRenewalPatient="selectedPrescriptionValues.isRenewalPatient"
                                :healthPlanMemberId="selectedPrescriptionValues.healthPlanMemberId"
                                :providerId="selectedPrescriptionValues.providerId"
                                :processed="selectedPrescriptionValues.processed | formatDate"
                                :assigned="selectedPrescriptionValues.assigned | formatDate"
                                :note="selectedPrescriptionValues.note"
                                :assignedNote="selectedPrescriptionValues.assignedNote"
                                :trainedNote="selectedPrescriptionValues.trainedNote"
                                :isShipmentRequested="selectedPrescriptionValues.isShipmentRequested"
                                :onFulfillmentHold="selectedPrescriptionValues.onFulfillmentHold"
                                :initialDeliveryMethod="selectedPrescriptionValues.initialDeliveryMethod"
                                :isDeleted="selectedPrescriptionValues.isDeleted"
                                :trainingTypeDescription="selectedPrescriptionValues.trainingTypeDescription"
                                :lineOfBusinessName = "selectedPrescriptionValues.lineOfBusinessName" 
                                :showViewOption="false"/>

      </v-card>
    </v-dialog>
    <v-dialog 
        v-model="deleteConfirmDialog"
        persistent
        max-width="500"
        content-class="confirm-dialog">
      <v-card>
       <v-card-title class="headline primary">
            Confirm Delete
       </v-card-title>
        <v-card-text>Are you sure you want to delete Image? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDialogCallback(false)"> No </v-btn>
          <v-btn text @click="confirmDialogCallback(true)"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-btn color="secondary" @click="isUploadMode=true">Add</v-btn>
        <uploader :entityId="patient.patientId" :entityType="entityType()" :customerId="customerId" @uploadcomplete="uploadComplete" v-if="isUploadMode"></uploader>
      <v-row>
        <v-col md="3" v-for="(purchaseOrder,index) in patientPurchaseOrders" :key="purchaseOrder.purchaseOrderId">
          <PatientDocumentThumbnail :isLoading="isLoading && index==activeIndex" :index="index"
            :documentType="'Purchase Order'"
            :documentIdentifier="purchaseOrder.purchaseOrderId.toString()"
            :subTitle="purchaseOrder.legacyId"
            :createdDate="new Date(purchaseOrder.poDate).toLocaleDateString('en-US')"
            @view-document="viewDocument($event)">
          </PatientDocumentThumbnail>
        </v-col>
        <v-col md="3" v-for="(prescription,index) in patientPrescriptions" :key="prescription.prescriptionId">
          <PatientDocumentThumbnail :isLoading="isLoading && index==activeIndex" :index="index"
            :documentType="'Prescription'"
            :documentIdentifier="prescription.prescriptionId.toString()"
            :subTitle="prescription.legacyId"
            :createdDate="new Date(prescription.prescriptionDate).toLocaleDateString('en-US')"
            @view-document="viewDocument($event)">
          </PatientDocumentThumbnail>
        </v-col>
        <v-col md="3" v-for="(doc,index) in patientDocuments" :key="doc.patientDocumentId">
          <PatientDocumentThumbnail :isLoading="isLoading && index==activeIndex" :index="index"
            :documentType="doc.documentType.includes('Report') ? 'Report' : doc.documentType"
            :documentIdentifier="doc.fileStoreObjectName"
            :subTitle="doc.note"
            :createdDate="new Date(doc.created).toLocaleDateString('en-US')"
            @view-document="viewDocument($event)"
            @delete-document="deleteDocument($event)">
          </PatientDocumentThumbnail>
        </v-col>          
      </v-row>  
    <div v-viewer="{movable: false}">
        <img max-height="150" max-width="250" :src="imageSrc" class="image"/>
    </div>
  </div>
</template>
<script>
import PatientDocumentThumbnail from './PatientDocumentThumbnail.vue';
import PrescriptionDetails from '@/components/OrderIntake/PrescriptionDetails.vue';
import PurchaseOrderDetails from '@/components/OrderIntake/PurchaseOrderDetails.vue';
import Uploader from '@/components/Shared/Uploader';
import PatientService from "../services/PatientService";
import PatientPrescriptionService from '../services/PatientPrescriptionService';
import PatientPurchaseOrderService from '../services/PatientPurchaseOrderService';
import ImageService from "../services/ImageService";
import UserService from "../services/UserService";
import ReferenceDataService from "../services/ReferenceDataService";
import {EntityTypeEnum} from '../types/enum';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer'
import Vue from 'vue'
import moment from 'moment';
Vue.use(Viewer);

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});

export default { 
  props: { patient: Object, customerId: Number, provider: String },
  components: {
    Uploader,
    PatientDocumentThumbnail,
    PrescriptionDetails,
    PurchaseOrderDetails
  },
  data: () => ({
    patientDocuments: [],
    patientPrescriptions: [],
    patientPurchaseOrders: [],
    activeIndex:-1,
    isLoading: false,
    imageSrc:'',
    deletedItem:{},
    isUploadMode:false,
    deleteConfirmDialog: false,
    selectedPrescriptionValues: {},
    selectedPurchaseOrderValues: {},
    dialogPrescription: false,
    dialogPurchaseOrder: false,
    achillesUsers: [],
    showPrescriptionsFeature: process.env.VUE_APP_VIEW_PATIENT_DOCUMENT_RX === "enabled",
    lineOfBusinessName: "",
  }),
  watch: {
    patient(val) {
      // Items have already been requested
      if (this.isLoading) return;
      if (val === "" || val === null || val === undefined) return;
      // cancel pending call
      clearTimeout(this._timerId);
      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.getPatientDocuments();
      }, 500);
    },
  }, 
  methods: {
    confirmDialogCallback:function(agreed){      
      if(agreed)
      {
          let document = this.deletedItem;          
          let index = this.patientDocuments.findIndex(d=>d.patientDocumentId == document.patientDocumentId);
          this.patientDocuments.splice(index,1);
          this.deletePatientDocument(document);
          this.imageSrc ='';
      }      
      this.deletedItem={};
      this.deleteConfirmDialog = false;
    },
    deletePatientDocument(document){
        this.isLoading = true;
        document.updatedByUserId = this.$store.state.achillesUser.userId;
        document.isDeleted = true;
        new PatientService()
        .putPatientDocument(this.customerId, this.patient.patientId,document)
        .then(() => {          
            this.getPatientDocuments();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },   
    viewDocument: async function($event)
    {
      
      if ($event.documentType == "Report") {
        let url = process.env.VUE_APP_REPORT_BASE_URL + $event.documentIdentifier;
        window.open(url, "_blank");
      }
      else if ($event.documentType == "Image")
      {
        this.getImage($event.documentIdentifier);
      }
      else if ($event.documentType == "Prescription") {
        this.selectedPrescriptionValues = this.patientPrescriptions[$event.index];
        await this.getLineOfBusinessByCustomerId(this.customerId,this.selectedPrescriptionValues.lineOfBusinessId);
        this.selectedPrescriptionValues.patientFirstName = this.patient.firstName;
        this.selectedPrescriptionValues.patientLastName = this.patient.lastName;
        this.selectedPrescriptionValues.updatedByUserName = this.getUserNameForId(this.selectedPrescriptionValues.updatedByUserId);
        this.selectedPrescriptionValues.lineOfBusinessName = this.lineOfBusinessName;
        this.selectedPrescriptionValues.prescribingPhysicianName = this.provider;
        this.dialogPrescription = true;
      }
      else if ($event.documentType == "Purchase Order") {
        this.selectedPurchaseOrderValues = this.patientPurchaseOrders[$event.index];

        this.selectedPurchaseOrderValues.patientFirstName =  this.patient.firstName;
        this.selectedPurchaseOrderValues.patientLastName =  this.patient.lastName;

        this.selectedPurchaseOrderValues.poDate = this.formDateFormat(this.selectedPurchaseOrderValues.poDate);
        this.selectedPurchaseOrderValues.processed = this.formDateFormat(this.selectedPurchaseOrderValues.processed);
        this.selectedPurchaseOrderValues.received = this.formDateFormat(this.selectedPurchaseOrderValues.received);
        this.selectedPurchaseOrderValues.assigned = this.formDateFormat(this.selectedPurchaseOrderValues.assigned);
        this.selectedPurchaseOrderValues.delivered = this.formDateFormat(this.selectedPurchaseOrderValues.delivered);
        this.selectedPurchaseOrderValues.trained = this.formDateFormat(this.selectedPurchaseOrderValues.trained);

        this.dialogPurchaseOrder = true;
      }
    },
    deleteDocument: async function($event)
    {
      this.activeIndex = $event.index;
      let doc = this.patientDocuments[$event.index];

      this.deletedItem = doc;

      this.deleteConfirmDialog = true;
    },
    async getImage(objectName){
      this.isLoading = true;
      new ImageService()
        .getImage(objectName)
        .then((resp) => {               
         this.imageSrc = resp;         
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getPatientDocuments: function () {
      this.isLoading = true;     
      new PatientService()
        .getPatientDocuments(this.customerId, this.patient.patientId)
        .then((resp) => {
          this.patientDocuments = resp;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getPatientPrescriptions: async function(){
         this.isLoading = true;
      await new PatientPrescriptionService()
        .get(this.customerId, this.patient.patientId)
        .then((resp) => {
          this.patientPrescriptions = resp;                  
        })
        .catch((err) => {          
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getPatientPurchaseOrders: async function(){
         this.isLoading = true;
      await new PatientPurchaseOrderService()
        .get(this.customerId, this.patient.patientId)
        .then((resp) => {
          this.patientPurchaseOrders = resp;                  
        })
        .catch((err) => {          
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    uploadComplete() {
      this.isUploadMode = false;
      this.getPatientDocuments();
    },
    entityType() {
      return EntityTypeEnum.Patient;
    },
    formDateFormat(date) {
                if (date != null) {
                    return moment(new Date(date)).format('MM/DD/YYYY');
                }
            },
    getUsers: async function () {
          if (this.$store.state.users.length === 0) {
              this.loading = true;
              new UserService()
                  .getUsers()
                  .then((resp) => {
                    this.achillesUsers = resp;
                    this.$store.commit("setUsers", this.achillesUsers);
                  })
                  .catch((err) => {
                    console.log(err);
                  })
                  .finally(() => (this.loading = false));
                 } else {
              this.achillesUsers = this.$store.state.users;
                 }
        },
    getUserNameForId(id) {
        if (!id) {
          return "Unassigned";
        } else {
            let user = this.achillesUsers.find((u) => u.userId === id);
            if (user) {
                return user.firstName + " " + user.lastName;
            } else {
                  return "Unknown";
            }
          }
      },
      getLineOfBusinessByCustomerId: async function (customerId, lineOfBusinessId) {
                this.loading = true;
                await new ReferenceDataService()
                    .getLineOfBusiness(customerId)
                    .then((values) => {

                      for (const value of values) {
                        if (value.lineOfBusinessId === lineOfBusinessId) {
                          this.lineOfBusinessName = value.name;
                        }
                      }
                    })
                    .finally(() => (this.loading = false));
      },
  },
  mounted() {
    this.patientDocuments = [];
    this.getPatientDocuments();
    if (this.showPrescriptionsFeature) {
      this.getPatientPrescriptions();
    }
    this.getPatientPurchaseOrders();
    this.getUsers();
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  .image {
    width: calc(20% - 10px);
    cursor: pointer;
    margin: 5px;
    display: inline-block;
  }
</style>