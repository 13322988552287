<template>
    <div>
        <v-row>
            <v-col md="6">
                <v-form ref="form" v-model="valid" lazy-validation> 
                    <v-card elevation="2" outline class="pa-md-6" :loading="isLoading">
                        <v-row>
                            <v-col md="6">
                                <v-file-input v-model="file" accept="image/*" label="Click to select Image file..." prepend-icon="mdi-image" :rules="[validateFile]"></v-file-input>             
                            </v-col>
                            <v-col md="6">
                                <v-text-field v-model="fileDescription" prepend-icon="mdi-note" label="Description"></v-text-field>
                            </v-col>
                        </v-row>  
                        <v-btn text rounded @click="onUpload" small>Upload</v-btn> 
                        <v-btn text rounded @click="onCancel" small>Cancel</v-btn> 
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>
<script>

import ImageService from '@/services/ImageService';
import PatientService from '@/services/PatientService';
import MemberDocumentService from '@/services/MemberDocumentService';
import {EntityTypeEnum} from '@/types/enum';

export default {
    name: 'Uploader',
    props: { entityType: Number, entityId: Number, customerId: Number, modelValue: Boolean },
    data: () => ({
        valid: false,
        isLoading: false,
        file:null,
        fileDescription:'',
        fileTypes:["image/apng","image/bmp","image/gif","image/jpeg","image/pjpeg","image/png","image/svg+xml","image/tiff","image/webp","image/x-icon"],
    }),
    watch: {

    },
    methods: {
        onUpload(){
            if(this.$refs.form.validate() && this.file)
            this.uploadImage();
        },
        onCancel(){
            this.file= null;
            this.fileDescription='';
            this.uploadComplete();
        },
        validateFile()
        {
            if(!this.file)
                return 'file is required to upload';
            else if (this.file.size > 10485760) 
                return 'file size should be less than 10MB';
            else if (!this.validFileType(this.file))
                return 'only Images are allowed';
            else 
                return true;
        },
        validFileType(file) {
            return this.fileTypes.includes(file.type);
        },
        async uploadImage(){
            this.isLoading = true;
            new ImageService()
                .postImage(this.file)
                .then((resp) => {
                    if (this.entityType === EntityTypeEnum.Patient)
                        this.savePatientDocument(resp);
                    else if (this.entityType === EntityTypeEnum.Member)
                        this.saveMemberDocument(resp);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        savePatientDocument(name){      
            let patientDocument={};
            patientDocument.patientId = this.entityId;
            patientDocument.fileStoreObjectName = name;
            patientDocument.note = this.fileDescription;
            patientDocument.created= new Date().toISOString();
            patientDocument.documentType='Image';
            this.isLoading = true;
            new PatientService()
                .postPatientDocument(this.customerId, patientDocument)
                .then((resp) => {
                    if(resp.status == 201)
                    {
                        this.file= null;
                        this.fileDescription='';   
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.uploadComplete();
                });
        },
        saveMemberDocument(name) {
            let document={};
            document.memberId = this.entityId;
            document.fileStoreObjectName = name;
            document.note = this.fileDescription;
            document.created= new Date().toISOString();
            document.documentType='Image';
            this.isLoading = true;
            new MemberDocumentService()
                .postDocument(this.customerId, document)
                .then((resp) => {
                    if(resp.status == 201)
                    {
                        this.file= null;
                        this.fileDescription='';   
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.uploadComplete();
                });        
        },
        uploadComplete()
        {
            this.$emit('uploadcomplete');
        }
    }
};
</script>
