<template>
    <v-container>
       <h4 v-if="loaded && !hasData" class="ml-5">No new concerns reported.</h4>
       <v-timeline v-if="loaded && hasData" dense>
          <div v-for="(item, index) in patient.patientHistory.sincePreviousCall" :key="index">
             <v-timeline-item>
                <v-card>
                   <v-col>
                      <interaction-info :description="item.interactionType.description"
                                        :date="item.interactionDateTime" :name="item.updatedByUser.displayName"/>
                      
                      <v-text-field disabled v-if="displayItem(item, 'sincePreviousCallTrigger')"  v-model="item.sincePreviousCallTrigger" 
                                    label="Have you had any changes to your health or procedures since we last spoke?"/>
                      
                      <div class="py-2 title" v-if="item.sincePreviousCallAdditionalInformation.length > 0">Additional information here</div>
                      <div v-for="(finding, findingIndex) in item.sincePreviousCallAdditionalInformation" :key="findingIndex">
                         <v-card tile class="pa-2 mb-4">

                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallHaveYouBeenToER')" v-model="finding.sincePreviousCallHaveYouBeenToER" 
                                          label="Since we last spoke, have you been to the ER?"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallApproximateDateOfVisit')" v-model="finding.sincePreviousCallApproximateDateOfVisit" 
                                          label="If yes, approximate date of visit"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallApproximateDateOfVisitFreeText')" v-model="finding.sincePreviousCallApproximateDateOfVisitFreeText" 
                                          label="If date unknown, enter notes here:"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallReasonForVisits')" v-model="finding.sincePreviousCallReasonForVisits" 
                                          label="If yes, reason for the visit"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallDischargeDisposition')" v-model="finding.sincePreviousCallDischargeDisposition" 
                                          label="If yes, discharge disposition"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallDischargeDispositionOther')" v-model="finding.sincePreviousCallDischargeDispositionOther" 
                                          label="If other selected, enter the discharge disposition here:"/>

                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallHaveYouBeenAdmittedHospital')" v-model="finding.sincePreviousCallHaveYouBeenAdmittedHospital" 
                                          label="Since we last spoke, have you been admitted to the hospital?"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallAdmittedDateOfVisit')" v-model="finding.sincePreviousCallAdmittedDateOfVisit" 
                                          label="If yes, approximate date of visit"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallAdmittedDateOfVisitFreeText')" v-model="finding.sincePreviousCallAdmittedDateOfVisitFreeText" 
                                          label="If date unknown, enter notes here:"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallAdmittedReasonForVisits')" v-model="finding.sincePreviousCallAdmittedReasonForVisits" 
                                          label="If yes, reason for the visit"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallAdmittedDischargeDisposition')" v-model="finding.sincePreviousCallDischargeDisposition" 
                                          label="If yes, discharge disposition"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallAdmittedDischargeDispositionOther')" v-model="finding.sincePreviousCallDischargeDispositionOther" 
                                          label="If other selected, enter the discharge disposition here:"/>



                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallLowerLimbAmputations')" v-model="finding.sincePreviousCallLowerLimbAmputations" 
                                          label="Since we last spoke, have you had any lower limb amputations?"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallLocationOfAmputation')" v-model="finding.sincePreviousCallLocationOfAmputation" 
                                          label="If yes, location of amputation"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallDateOfSurgery')" v-model="finding.sincePreviousCallDateOfSurgery" 
                                          label="If yes, date of surgery"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallDateOfSurgeryFreeText')" v-model="finding.sincePreviousCallDateOfSurgeryFreeText" 
                                          label="If date unknown, enter notes here:"/>


                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallHaveYouHadAnyFalls')" v-model="finding.sincePreviousCallHaveYouHadAnyFalls" 
                                          label="Have you had any falls?"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallDateOfFall')" v-model="finding.sincePreviousCallDateOfFall" 
                                          label="If yes, date of fall"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallDateOfFallFreeText')" v-model="finding.sincePreviousCallDateOfFallFreeText" 
                                          label="If date unknown, enter notes here:"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallAnyInjury')" v-model="finding.sincePreviousCallAnyInjury" 
                                          label="Any injury?"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallDescribeInjury')" v-model="finding.sincePreviousCallDescribeInjury" 
                                          label="If yes, describe the injury"/>


                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallHaveYouHadSurgicalProceduresFoot')" v-model="finding.sincePreviousCallHaveYouHadSurgicalProceduresFoot" 
                                          label="Since we last spoke, have you had surgical procedures to your foot/feet?"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallLocationOfSurgery')" v-model="finding.sincePreviousCallLocationOfSurgery" 
                                          label="If yes, location of surgery"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallTypeOfProcedure')" v-model="finding.sincePreviousCallTypeOfProcedure" 
                                          label="If yes, type of procedure"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallFootDateOfSurgery')" v-model="finding.sincePreviousCallFootDateOfSurgery" 
                                          label="If yes, date of surgery"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallFootDateOfSurgeryFreeText')" v-model="finding.sincePreviousCallFootDateOfSurgeryFreeText" 
                                          label="If date unknown, enter notes here:"/>



                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallHaveYouHadSurgicalProcedures')" v-model="finding.sincePreviousCallHaveYouHadSurgicalProcedures" 
                                          label="Since we last spoke, have you had surgical procedures?"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallSurgicalLocationOfSurgery')" v-model="finding.sincePreviousCallSurgicalLocationOfSurgery" 
                                          label="If yes, location of surgery"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallSurgicalTypeOfProcedure')" v-model="finding.sincePreviousCallSurgicalTypeOfProcedure" 
                                          label="If yes, type of procedure"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallSurgicalDateOfSurgery')" v-model="finding.sincePreviousCallSurgicalDateOfSurgery" 
                                          label="If yes, date of surgery"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallSurgicalDateOfSurgeryFreeText')" v-model="finding.sincePreviousCallSurgicalDateOfSurgeryFreeText" 
                                          label="If date unknown, enter notes here:"/>

 
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallNewMedicalDiagnosis')" v-model="finding.sincePreviousCallNewMedicalDiagnosis" 
                                          label="Any new medical diagnosis?"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallNewMedicalDiagnosisFreetext')" v-model="finding.sincePreviousCallNewMedicalDiagnosisFreetext" 
                                          label="If yes, diagnosis"/>
                           

                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallNewBehavorialHealthConcerns')" v-model="finding.sincePreviousCallNewBehavorialHealthConcerns" 
                                          label="New behavorial health concerns?"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallNewBehavorialDescribeConcernsFreeText')" v-model="finding.sincePreviousCallNewBehavorialDescribeConcernsFreeText" 
                                          label="If yes, describe concern"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallNewBehavorialReceivingTreatment')" v-model="finding.sincePreviousCallNewBehavorialReceivingTreatment" 
                                          label="Receiving treatment?"/>

                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallNewSDOHConcerns')" v-model="finding.sincePreviousCallNewSDOHConcerns" 
                                          label="New SDOH concerns?"/>
                            <v-text-field disabled v-if="displayItem(finding, 'sincePreviousCallNewDescribeConcernsFreeText')" v-model="finding.sincePreviousCallNewDescribeConcernsFreeText" 
                                          label="If yes, describe concern"/>              

                         </v-card>
                        </div>
                   </v-col>
                </v-card>
                <template v-slot:icon>
                   <timeline-avatar :userId="item.updatedByUser.updatedByUserId" :alt="item.updatedByUser.displayName" :src="item.updatedByUser.avatarURL"/>
                </template>
             </v-timeline-item>
          </div>
       </v-timeline>
    </v-container>
 </template>
 
 <script>
    import {GetSincePreviousCall} from "./queries/patient-history.graphql";
    import {HistoryTabNames} from "@/types/enum";
    import InteractionInfo from "./InteractionInfo";
    import TimelineAvatar from "./TimelineAvatar";
    
    export default {
       name: "SincePreviousCall",
       props: {
          patientId: Number,
          customerId: Number
       },
       components: {
          InteractionInfo,
          TimelineAvatar
       },
       data: () => ({
          patient: null,
          loaded: false,
          hasData:false
       }),
       methods: {
          displayItem(item, propertyName) {
             return typeof item[propertyName] !== "undefined" && item[propertyName] !== null && item[propertyName].length > 0;
          },
          displayItemObject(item, propertyName){
             return typeof item[propertyName] !== "undefined" && item[propertyName] !== null;
          }
       },
       apollo: {
          patient: {
             query: GetSincePreviousCall,
             variables() {
                return {
                   customerId: this.customerId,
                   patientId: this.patientId,
                   patientHistoryTab: HistoryTabNames.SincePreviousCall
                };
             },
             update(data) {
                this.loaded = true;
                this.hasData = data.customer.patient.patientHistory!==null
                               && data.customer.patient.patientHistory.sincePreviousCall.length > 0;
                return data.customer.patient;
             }
          }
       }
    };
 </script>
 
 <style scoped>
 
 </style>