<template>
   <v-container>
      <!-- "Add" button and Add Worklist Item Card-->
      <v-row>
         <v-col md="6">
            <v-btn color="secondary" @click="isEditMode=true">Add</v-btn>
         </v-col>
      </v-row>
      <!-- end: "Add" button and Add Worklist Item Card-->
   
      <v-row>
         <v-col md="12">
            <!-- current worklist items -->
            <v-card class="mt-4">
               <v-card-title>Current 
                  <span class="pl-3">
                     <v-icon  @click="getWorklistItems">mdi-refresh</v-icon>
                  </span>
               </v-card-title>
               <v-alert :value="alertRef.alert" :type="alertRef.alertType" dense transition="fade-transition">
                  {{alertRef.alertMessage}}
               </v-alert>
               <v-data-table :loading="(isLoading && !initialLoad) || isLoadingCombined"
                   :footer-props="{ 'items-per-page-options': rowsPerPage }"
                   class="elevation-1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                  <template v-slot:top>
                     <v-dialog v-model="dialog" max-width="800px" persistent v-if="dialog">
                        <v-toolbar dark color="primary">
                           <v-btn icon dark @click="closeWorklistItemDialog">
                              <v-icon>mdi-close</v-icon>
                           </v-btn>
                           <v-toolbar-title>Edit Worklist Item</v-toolbar-title>
                           <v-spacer></v-spacer>
                           <v-toolbar-items>
                              <v-btn dark text @click="closeWorklistItemDialog"> Close</v-btn>
                           </v-toolbar-items>
                        </v-toolbar>
                     </v-dialog>
                  </template>
                  
               </v-data-table>
            </v-card>
            <!--end: current worklist items -->
         
            <v-btn class="mt-3" @click="showHistory" v-if="!loadHistory">Load History</v-btn>
         
            <!-- history worklist items -->
            <v-card class="mt-4" v-if="loadHistory">
               <v-card-title>History
                  <span class="pl-3">
                     <v-icon @click="showHistory">mdi-refresh</v-icon>
                  </span>
               </v-card-title>
            
               <v-data-table
                   :loading="(isLoading && !initialLoad) || isLoadingHistory"
                   :headers="historyHeaders"
                   :items="historyWorklistItems"
                   class="elevation-1"
                   :sort-by.sync="sortByHistory"
                   :sort-desc.sync="sortByDescHistory"
                   :footer-props="{'items-per-page-options': rowsPerPage }">
                  <template v-slot:top>
                     <v-dialog v-model="dialog" max-width="800px" persistent v-if="dialog">
                        <v-toolbar dark color="primary">
                           <v-btn icon dark @click="closeWorklistItemDialog">
                              <v-icon>mdi-close</v-icon>
                           </v-btn>
                           <v-toolbar-title>Edit Worklist Item</v-toolbar-title>
                           <v-spacer></v-spacer>
                           <v-toolbar-items>
                              <v-btn dark text @click="closeWorklistItemDialog"> Close</v-btn>
                           </v-toolbar-items>
                        </v-toolbar>

                     </v-dialog>
                  </template>
                
               </v-data-table>
            </v-card>
            <!-- end: history worklist items -->
      
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   export default {
      name: "MemberWorklistItems",
      props: {
         customerId: Number,
         memberId: Number
      },
      data: () => ({
         isEditMode: false,
         isLoading: false,
         initialLoad: false,
         isLoadingCombined: false,
         combinedWorklistItems: [],
         sortBy: "priority",
         sortByHistory: "created",
         sortDesc: false,
         dialog: false,
         loadHistory: false,
         isLoadingHistory: false,
         
         alertRef: {
            alert: false,
            alertType: "success",
            alertMessage: ""
         },
         historyHeaders: [
            {
               text: "Created",
               value: "created"
            },
            {
               text: "Type",
               value: "worklistItemTypeId"
            },
            {
               text: "Assigned User",
               value: "assignedToUserId"
            },
            {
               text: "Status",
               value: "status"
            },
            {
               text: "Created By",
               value: "createdByUserId"
            },
            {
               text: "Closed By",
               value: "closedByUserId"
            },
            {
               text: "Call Disposition",
               value: "callDisposition"
            },
            {
               text: "Is Escalated",
               value: "isEscalation"
            },
            {
               text: "Escalated Date",
               value: "escalatedDate"
            }
         ],
         headers: [
            {
               text: "Priority",
               value: "priority"
            },
            {
               text: "Type",
               value: "worklistItemTypeId"
            },
            {
               text: "Assigned User",
               value: "assignedToUserId"
            },
            {
               text: "Is Escalated",
               value: "isEscalation"
            },
            {
               text: "Note",
               value: "note"
            },
            {
               text: "Thermometry Assessment",
               value: "thermometryAssessment"
            },
            {
               text: "Created by",
               value: "createdByUserId"
            },
            {
               text: "Escalation Details",
               value: "actions"
            }
         ],
      }),
      methods:{
         getWorklistItems(){
            console.log("TODO: getWorklistItems");
         },
         closeWorklistItemDialog(){
            console.log("TODO: closeWorklistItemDialog");
         },
         async showHistory() {
            this.isLoading = true;
            this.isLoadingHistory = true;
   
            console.log("TODO: showHistory: get history items");
            
            this.loadHistory = true;
            this.isLoadingHistory = false;
            this.isLoading = false;
         }
      },
      computed: {
         rowsPerPage() {
            return this.$rowsPerPage;
         }
      }
   };
</script>

<style scoped lang="scss">

</style>