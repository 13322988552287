<template>
  <v-menu
      ref="dateMenu"
      v-model="dateMenu"
      :close-on-content-click="false"
      max-width="290px"
      min-width="auto"
      offset-y
      transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="formattedDate"
          :label="label"
          persistent-hint
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="internalDate"
        no-title
        @input="dateMenu = false"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "DatePicker",
  props: {
    value: String,
    label: String,
  },
  data() {
    return {
      dateMenu: false,
      internalDate: this.value,
    };
  },
  computed: {
    formattedDate: {
      get() {
        if (!this.internalDate) return;
        const [year, month, day] = this.internalDate.split("-");
        return `${month}/${day}/${year}`;
      },
      set(value) {
        const [month, day, year] = value.split("/");
        this.internalDate = `${year}-${month}-${day}`;
      },
    },
  },
  watch: {
    internalDate(newVal) {
      this.$emit("input", newVal);
    },
  },
};
</script>
<style scoped>
/* You can add any DatePicker-specific styles here */
</style>