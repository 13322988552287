<template>
   <v-app>
      <FocusManager @focus-change="handleAppFocus">
         <LoginComponent v-show="loginInit && !signedIn" :signedInState="signedIn" @init="loginInit = true"
            @loggedIn="init" @achilles-user-set="handleSetAchillesUser" />
         <router-view v-if="displayPages" />
         <RedirectComponent v-if="restrictedAccess"></RedirectComponent>
         <transition name="fade">
            <empty-banner v-if="displayEmptyBanner" />
            <Navbar v-if="displayPages" :userId="achillesUserId" />
         </transition>
         <v-row class="loading ml-4" v-if="displayEmptyBanner">
            <p>Loading....</p>
         </v-row>
      </FocusManager>
   </v-app>
</template>

<script>
import FocusManager from "./components/FocusManager.vue";
import LoginComponent from "./components/LoginComponent";
import Navbar from "./components/Navbar";
import EmptyBanner from "./components/EmptyBanner";
import store from "./store";
import { mapState } from "vuex";
import RedirectComponent from "./components/RedirectComponent.vue";

export default {
   name: "app",
   components: {
      FocusManager,
      LoginComponent,
      Navbar,
      EmptyBanner,
      RedirectComponent
   },
   data() {
      return {
         achillesUserId: null,
         achillesUserSet: false,
         appInFocus: null,
         loginInit: false,
         podAccessInit: false,
         showTimeoutDialog: false,
         timeExpired: null,
      };
   },
   computed: {
      ...mapState(['signedIn', 'restrictedAccess']),
      displayPages() {
         return this.loginInit && this.signedIn && this.$store.state.achillesUser.userId && !this.restrictedAccess;
      },
      displayEmptyBanner() {
         return !this.displayPages && this.signedIn;
      }
   },
   methods: {
      init() {
         this.$store.commit("setSignedIn", true);
         if (!this.restrictedAccess) {
             this.$store.dispatch("getWorklistItemTypes");
             this.$store.dispatch("getUsers");
             this.$store.dispatch("getGenders");
             this.$store.dispatch("getProviderTypes");
         }
      },
      handleSetAchillesUser(status) {
         this.achillesUserSet = status
         this.achillesUserId = this.$store.state.achillesUser.userId
      },
      handleTimeoutDialog(expireTime) {
         this.showTimeoutDialog = true
         this.timeExpired = expireTime * 1000
      },
      handleAppFocus(flag) {
         this.appInFocus = flag

         if (this.appInFocus && this.signedIn && (localStorage.getItem('getSession') === 'false')) {
            console.log('Not authorized. Please sign-in again.')
            this.$store.commit('setSignedIn', false)
            this.loginInit = true
         }

         if (this.appInFocus && this.signedIn) {
            let tokenExpiration = this.$store.state.gUserProfile.expires
            let nowSeconds = new Date().getTime() / 1000;
            return nowSeconds >= tokenExpiration ? this.$store.commit('setSignedIn', false) : null
         }
      },

   },

   async created() {
      if (typeof this.$store.state.achillesUser.userId === "undefined") {
         this.achillesUserSet = false;
      }
   },
};
</script>

<style scoped lang="scss">
.loading {
   margin-top: 64px;
}

.fade-enter-active,
.fade-leave-active {
   transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
   opacity: 0
}
</style>

<style lang="scss">
@import "./scss/variables.scss";

.v-date-picker-table {
   table {
      border-collapse: collapse;
      border: 0;

      th {
         padding: 0;
         margin: 0;
         color: black !important;
         border-bottom: 2px solid $podi-light-blue;
      }

      button {
         width: 100% !important;

         &.accent {
            background-color: $podi-medium-blue !important;
            border-color: $podi-medium-blue !important;

            &-text {
               color: $podi-yellow;
               caret-color: $podi-yellow;
            }
         }

         &:not(.v-btn--disabled) {
            border-radius: 0 !important;
            border-bottom: 2px solid $podi-dark-blue !important;

            &:hover {
               background-color: $podi-medium-blue !important;
               color: white !important;
            }
         }

         &.v-btn--disabled {
            background-color: transparent !important;
            color: rgba(0, 0, 0, 0.30) !important;
            border-radius: 0 !important;
            border-bottom: 2px solid $podi-light-blue !important;
         }

         &.v-btn--outlined {
            border-left: none !important;
            border-right: none !important;
            border-top: none !important;
         }
      }
   }
}

.confirm-dialog {
   .v-card {
      .title {
         color: #ffffff;
         font-size: 1.25rem !important;
         line-height: 1.5 !important;
      }

      .text {
         margin-top: 26px;
         font-size: 1rem !important;
      }
   }
}
</style>
