import * as BaseService from './BaseService';
import * as customer from '../types/customer';
import * as enums from '../types/enum';


export default class EntityContactService extends BaseService.BaseService {
    private url = '/api/EntityContact';

    constructor() {
      super();
    }
  
    async getPatientPhones(customerId: number, patientId: number): Promise<Array<customer.EntityPhone>>{
      let url = this.url + `/Phones/${customerId}/${enums.EntityTypeEnum.Patient}/${patientId}`;            
      let response = (await this.api.get(url)).data as Array<customer.EntityPhone>;          
      return response;
    }

    async getEntityPhones(customerId: number, providerId: number, entityTypeId: number): Promise<Array<customer.EntityPhone>>{
        let url = this.url + `/Phones/${customerId}/${entityTypeId}/${providerId}`;
        let response = (await this.api.get(url)).data as Array<customer.EntityPhone>;
        return response;
    }

    async putPatientPhone(customerId: number, entityPhoneId: number,entityPhone: customer.EntityPhone){
      let url = this.url + `/Phone/${customerId}/${entityPhoneId}`;            
      let response = await this.api.put(url,entityPhone).catch(err => {
        console.log(err); 
        throw err;                 
      });          
      return response;
    }

    async postPatientPhone(customerId: number, entityPhone: customer.EntityPhone){
      let url = this.url + `/Phone/${customerId}`;                 
      let response = await this.api.post(url,entityPhone).catch(err => {
        console.log(err); 
        throw err;                 
      });          
      return response;
    }
    
    async getPatientEmails(customerId: number, patientId: number): Promise<Array<customer.EntityEmail>>{
        let url = this.url + `/Emails/${customerId}/${enums.EntityTypeEnum.Patient}/${patientId}`;            
        let response = (await this.api.get(url)).data as Array<customer.EntityEmail>;          
        return response;
      }

      async putPatientEmail(customerId: number, entityEmailId: number,entityEmail: customer.EntityEmail){
        let url = this.url + `/Email/${customerId}/${entityEmailId}`;            
        let response = await this.api.put(url,entityEmail).catch(err => {
          throw err;          
        });          
        return response;
      }

      async postPatientEmail(customerId: number, entityEmail: customer.EntityEmail){
        let url = this.url + `/Email/${customerId}`;
        console.log(entityEmail);            
        let response = await this.api.post(url,entityEmail).catch(err => {
          console.log(err); 
          throw err;                 
        });          
        return response;
      }

    async getPatientAddresses(customerId: number, patientId: number): Promise<Array<customer.EntityAddress>>{
        let url = this.url + `/Addresses/${customerId}/${enums.EntityTypeEnum.Patient}/${patientId}`;            
        let response = (await this.api.get(url)).data as Array<customer.EntityAddress>;          
        return response;
      }

    async getEntityAddresses(customerId: number, providerId: number, entityTypeId: number): Promise<Array<customer.EntityAddress>>{
        let url = this.url + `/Addresses/${customerId}/${entityTypeId}/${providerId}`;
        let response = (await this.api.get(url)).data as Array<customer.EntityAddress>;
        return response;
    }

    async putPatientAddress(customerId: number, entityAddressId: number, entityAddress: customer.EntityAddress){
        let url = this.url + `/Address/${customerId}/${entityAddressId}`;            
        let response = await this.api.put(url,entityAddress).catch(err => {
          throw err;          
        });
        return response;
      }

      async postPatientAddress(customerId: number, entityAddress: customer.EntityAddress){
        let url = this.url + `/Address/${customerId}`;            
        let response = await this.api.post(url,entityAddress).catch(err => {
          throw err;          
        });
        return response;
      }

  }