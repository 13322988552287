import * as BaseService from './BaseService';
import * as customer from '../types/customer';

export default class SiteService extends BaseService.BaseService {
   private url = '/api/Site';
      
   constructor() {
     super();     
   }
  
    async get(customerId: number, siteId: number): Promise<customer.Site>{
      let url = this.url + `/${customerId}/${siteId}`;               
      let response = (await this.api.get(url)).data as customer.Site;     
      return response;
    }
    
    async getSites(customerId: number): Promise<Array<customer.Site>>{
        let url = this.url + `/${customerId}`;
        let response =  (await this.api.get(url)).data as Array<customer.Site>;
      return response;
    }

    async getSiteReference(customerId: number, locationIEN: number): Promise<customer.SiteReference>{
        let url = this.url + `/${customerId}/ien/${locationIEN}`;
        let response =  (await this.api.get(url)).data as customer.SiteReference;
      return response;
    }

    async put(customerId: number,site: customer.Site)
    {
        let url = this.url + `/${customerId}/${site.siteId}`;
        let response = await this.api.put(url, site).catch(err => {
            throw err;
        });
        return response;
    }

    async getSitesReceivingEscalations(customerId: number): Promise<Array<customer.Site>>{
        let url = this.url + `/ReceiveEscalations/${customerId}`;
        let response =  (await this.api.get(url)).data as Array<customer.Site>;
      return response;
    }
  }