<template>
   <v-container>
      <v-row justify="center" align="center">
         <nav>
            <v-app-bar app color="primary" dark>
               <v-app-bar-nav-icon @click="drawer = !drawer" v-if="podAccess"></v-app-bar-nav-icon>
               <div class="d-flex align-center">
                  <v-img alt="Podimetrics Logo" class="shrink mr-2" contain src="../assets/Podi_logo-color-white.svg"
                     transition="scale-transition" width="250" />
               </div>
               <v-spacer></v-spacer>
               <v-col cols="1" sm="3" md="4" v-if="!isMobile">
                  <PatientSearch />
               </v-col>
               <v-avatar color="primary" size="48">
                  <v-img lazy-src="../assets/account.png" :src="$store.state.achillesUser.avatarUrl"
                     :alt="$store.state.achillesUser.firstName + ' ' + $store.state.achillesUser.lastName" referrerpolicy="no-referrer" />
               </v-avatar>
               <v-btn icon class="d-none d-sm-flex" v-if="$store.state.signedIn" @click="$router.push('Logout')">
                  <v-icon>mdi-logout-variant</v-icon>
               </v-btn>
            </v-app-bar>
            <v-navigation-drawer v-model="drawer" app v-if="podAccess">
               <v-list nav dense>
                  <v-list-item v-for="link in enabledLinks" :key="link.text" router :to="link.route">
                     <v-list-item-action>
                        <v-icon :color="link.color">{{ link.icon }}</v-icon>
                     </v-list-item-action>
                     <v-list-item-content>
                        <v-list-item-title>{{ link.text }}</v-list-item-title>
                     </v-list-item-content>
                  </v-list-item>
               </v-list>
            </v-navigation-drawer>
         </nav>
      </v-row>
      <IdleTimeout idle="true" v-if="$store.state.idleVue.isIdle" />
      <DialogPrompt v-if="$store.state.timeoutAlert" :header-text="dialog.header" :body-text1="dialog.body1"
         :body-text2="dialog.body2" :enable-timer="$store.state.timeoutAlert" :dialog-width="dialog.width" />
      <v-snackbar v-model="clipboardSnackbar" :timeout="online ? 4000 : -1" bottom>
         {{ clipboardText }}
         <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="clipboardSnackbar = false">
               Close
            </v-btn>
         </template>
      </v-snackbar>
      <v-dialog v-model="showHelpDialog" hide-overlay :retain-focus="false" max-width="400px" v-if="showHelpDialog">
         <v-toolbar dark color="primary" dense>
            <v-btn icon dark @click="showHelpDialog = false">
               <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Help Desk Ticket
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
               <v-btn dark text @click="showHelpDialog = false"> Close</v-btn>
            </v-toolbar-items>
         </v-toolbar>
         <v-card ma-3>
            <v-card-text>
               <br />
               Help Desk ticket form opened in a new tab.
            </v-card-text>
         </v-card>
      </v-dialog>
   </v-container>
</template>
<script>
import { PodAccessService } from "@/services/PodAccessService";
import DialogPrompt from "./Shared/DialogPrompt.vue";
import IdleTimeout from "./IdleTimeout";
import PatientSearch from "./PatientSearch";

export default {
   name: "Navbar",
   components: {
      IdleTimeout,
      PatientSearch,
      DialogPrompt
   },
   data: () => ({
      clipboardSnackbar: false,
      clipboardText: "",
      drawer: false,
      online: true,
      showHelpDialog: false,
      helpDeskEnabled: process.env.VUE_APP_HELPDESK_FEATURE == "enabled",
      links: [
         {
            icon: "mdi-home",
            text: "Home",
            route: "/",
            color: "black",
            enabled: true
         },
         {
            icon: "mdi-view-dashboard",
            text: "Dashboards",
            route: "/dashboards",
            color: "black",
            enabled: false
         },
         {
            icon: "mdi-format-list-bulleted",
            text: "Worklist",
            route: "/worklist",
            color: "black",
            enabled: process.env.VUE_APP_WORKLIST_FEATURE == "enabled"
         },
         {
            icon: "mdi-domain",
            text: "Customers",
            route: "/customers",
            color: "black",
            enabled: true
         },
         {
            icon: "mdi-account-group",
            text: "Patients",
            route: "/patients",
            color: "black",
            enabled: false
         },
        {
          icon: "mdi-receipt",
          text: "Purchase Orders",
          route: "/po",
          color: "black",
          enabled: process.env.VUE_APP_PURCHASEORDERS_UPLOAD_FEATURE === "enabled"
        },
         {
            icon: "mdi-prescription",
            text: "Prescriptions",
            route: "/prescriptions",
            color: "black",
            enabled: process.env.VUE_APP_PRESCIPTIONS_FEATURE === "enabled"
         },
         {
            icon: "mdi-clipboard-account",
            text: "Assignments",
            route: "/assignments",
            color: "black",
            enabled: process.env.VUE_APP_ASSIGNMENT_FEATURE == "enabled"
         },
         {
            icon: "mdi-select-group",
            text: "Pods",
            route: "/pods",
            color: "black",
            enabled: process.env.VUE_APP_PODS_FEATURE === "enabled",
            ruleName: "podsAccessRule"
         },
         {
            icon: "mdi-information",
            text: "About",
            route: "/about",
            color: "black",
            enabled: true
         },
         {
            icon: "mdi-help-box",
            text: "Help",
            route: "/help",
            color: "black",
            enabled: process.env.VUE_APP_HELPDESK_FEATURE == "enabled"
         },
         {
            icon: "mdi-logout-variant",
            text: "Logout",
            route: "/logout",
            color: "black",
            enabled: true
         }
      ],
      podAccessServices: null,
      dialog:
      {
         header: "Auto-Logout Alert",
         body1: "Your token is about to expire. You will be logged out automatically after the amount of time below: ",
         body2: "Please save your work, log-out, and then log back in again.",
         width: 560
      },
   }),
   computed: {
      enabledLinks() {
         let links = this.links.filter(this.isEnabledLink);

         return links;
      },
      isMobile() {
         return this.$vuetify.breakpoint.xsOnly;
      },
      podAccess() {
         if (!this.podAccessServices) {
            return null;
         }
         return this.podAccessServices;
      },
   },
   async created() {
      window.addEventListener("keydown", (e) => {
         if (e.key == "F1" && this.helpDeskEnabled) {
            window.open(process.env.VUE_APP_HELPDESK_TICKET_URL, "_blank");
            this.showHelpDialog = !this.showHelpDialog;
            e.preventDefault();
         }
      });
   },
   mounted() {
      this.initPodAccessService();
   },
   methods: {
      status(chg) {
         console.log(chg);
         this.online = chg;
      },
      onlineChange(chg) {
         console.log(chg);
         this.clipboardText = "Internet is " + chg.type;
         this.clipboardSnackbar = true;
      },
      isEnabledLink(element) {
         if (typeof element.ruleName !== "undefined" && element.ruleName === "podsAccessRule") {
            return this.podAccess.canViewPodList();
         }
         return element.enabled === true;
      },

      async initPodAccessService() {
            let podId = null;
            let service = new PodAccessService(this.$store.state.achillesUser.userId, podId, this.$apollo);
            //get user data: pod roles and system roles
            service.getUser()
               .then(() => {
                  this.podAccessServices = service;
                  this.podAccessServices.setIsSuperAdmin();
                  this.podAccessInit = true;
               });
      }
   }
}
</script>