import * as BaseService from './BaseService';
import * as customer from '../types/customer';
import * as enums from '../types/enum';


export default class LockService extends BaseService.BaseService {
    private url = '/api/EntityLock';
         
   constructor() {
     super();     
   }
    async get(customerId: number, userId: number, entityId: number, entityTypeId: number, area: string): Promise<customer.EntityLock>{
      let url = this.url + `/${customerId}/${userId}/${entityId}/${entityTypeId}/${area}`;            
      let response = (await this.api.get(url)).data as customer.EntityLock;          
      return response;
    }

    async post(customerId: number, userId: number, entityId: number, entityTypeId: number, area: string): Promise<customer.EntityLock>{
        let url = this.url + `/${customerId}/${userId}/${entityId}/${entityTypeId}/${area}`;
        let response = (await this.api.post(url)).data as customer.EntityLock;
        return response;
    }


    async remove(customerId: number, userId: number, entityId: number, entityTypeId: number, area: string){
      let url = this.url + `/${customerId}/${userId}/${entityId}/${entityTypeId}/${area}`;            
      let response = await this.api.delete(url).catch(err => {
        console.log(err); 
        throw err;                 
      });          
      return response;
    }
    


  }