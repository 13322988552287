import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Dashboards from "../views/Dashboards.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/help",
    name: "Help",
    beforeEnter() {
      window.open(process.env.VUE_APP_HELPDESK_TICKET_URL, "_blank");
    },
  },
  {
    path: "*/logout",
    name: "Logout",
    component: () => import("../components/Logout.vue"),
    props: true,
  },
  {
    path: "/dashboards",
    name: "Dashboards",
    component: Dashboards,
  },
  {
    path: "/customers",
    name: "Customers",
    component: () => import("../views/Customers.vue"),
  },
  {
    path: "/patients",
    name: "Patients",
    component: () => import("../views/Patients.vue"),
  },
  {
    path: "/viewPatient",
    name: "ViewPatient",
    component: () => import("../views/ViewPatient.vue"),
  },
  {
    path: "/po",
    name: "Purchase Orders",
    component: () => import("../views/PurchaseOrders.vue"),
  },
  {
    path: "/prescriptions",
    name: "Prescriptions",
    component: () => import("../views/Prescriptions.vue"),
  },
  {
    path: "/assignments",
    name: "Assignments",
    component: () => import("../views/Assignments.vue"),
  },
  {
    path: "/worklist",
    name: "Worklist",
    component: () => import("../views/WorklistHome.vue"),
  },
  {
    path: "/pods",
    name: "Pods",
    component: () => import("../views/Pods.vue"),
  },
  {
    path: "/pods/assignments/pending",
    name: "PodsAssignmentsPending",
    component: () => import("../views/PodsAssignmentsPending.vue"),
  },
  {
    path: "/pods/memberships/:podId",
    name: "PodMemberships",
    component: () => import("../views/PodPatients.vue"),
  },
  {
    path: "/pods/assignments/:podId",
    name: "PodAssignments",
    component: () => import("../views/PodAssignments.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
