import * as BaseService from './BaseService';

export default class HangFireJobService extends BaseService.BaseService {
    private url = '/api/HangFireJob';

    constructor() {
        super();
    }

    async enqueueRefreshNotCalledSinceBucketByPrimaryNurseUserId() {
        let url = this.url + "/EnqueueRefreshNotCalledSinceBucketByPrimaryNurseUserId";
        let response = await this.api.post(url).catch(err => {
            throw err;
        });
        return response;
    }
}