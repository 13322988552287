<template>
    <v-card elevation="2" outlined shaped tile class="my-2" max-width="300" :loading="isLoading">
        <v-list-item dense>
        <v-list-item-icon>
            <v-icon color="indigo" large> {{  documentType=='Image' ? 'mdi-image' : documentType=='Report' ? 'mdi-file-chart' : 'mdi-prescription' }} </v-icon>
        </v-list-item-icon>
        <v-list-item-content> 
        <v-list-item-title class="font-weight-bold">
            {{ documentType }}
        </v-list-item-title>                
        <v-list-item-subtitle class="subtitle">
            {{ subTitle }}
        </v-list-item-subtitle>
            <v-list-item-subtitle>
            {{ (documentType == "Prescription" ? 'RxPo: ' : 'Added: ') + createdDate }}
        </v-list-item-subtitle>                      
        </v-list-item-content>
                
        </v-list-item>
        <v-card-actions>
            <!-- $emit should be able to be called directly -->
            <v-btn small text rounded @click="$emit('view-document', { documentType, documentIdentifier, index })">
                View
            </v-btn>
            <v-btn small text rounded @click="$emit('delete-document', { index })" v-if="documentType=='Image'">
                Delete
            </v-btn>     
        </v-card-actions>
    </v-card>                                       
</template>
<script>

export default {
    props: { isLoading: Boolean, index: Number, documentType: String, subTitle: String, createdDate: String, documentIdentifier: String }
}
</script>
<style scoped>
    .subtitle {
        white-space: normal;
    }
</style>