<template>
   <v-container>
      <h4 v-if="loaded && !hasData" class="ml-5 mb-5">No records available.</h4>
      <v-btn color="secondary" @click="displayAddNote=true" class="mb-5">Add</v-btn>
      <clinical-note-add v-if="displayAddNote" 
                         :patientId="patientId" :customerId="customerId"
                         @canceled="onAddClinicalNoteCanceled()" @saved="onAddClinicalNoteSaved()"></clinical-note-add>
      <v-timeline v-if="loaded && hasData" dense>
         <div v-for="(item, index) in patient.patientHistory.clinicalHistory" :key="index">
            <v-timeline-item v-if="item.data">
               <v-card>
                  <v-col>
                     <interaction-info v-if="item.interactionType" :description="item.interactionType.description"
                                       :date="item.interactionDateTime" :name="item.updatedByUser.displayName"/>
                     <div v-html="item.data"></div>
                  </v-col>
               </v-card>
               <template v-slot:icon>
                  <timeline-avatar :userId="item.updatedByUser.updatedByUserId" :alt="item.updatedByUser.displayName" :src="item.updatedByUser.avatarURL"/>
               </template>
            </v-timeline-item>
         </div>
      </v-timeline>
   </v-container>
</template>

<script>
   import {GetClinicalHistory} from "./queries/clinical-tab.graphql";
   import {HistoryTabNames} from "@/types/enum";
   import InteractionInfo from "../InteractionInfo";
   import TimelineAvatar from "../TimelineAvatar";
   import ClinicalNoteAdd from "./ClinicalNoteAdd";
   
   export default {
      name: "ClinicalTab",
      props: {
         patientId: Number,
         customerId: Number
      },
      components: {
         InteractionInfo,
         TimelineAvatar,
         ClinicalNoteAdd
      },
      data: () => ({
         patient: null,
         loaded: false,
         hasData: false,
         displayAddNote: false
      }),
      methods: {
         displayItem(item, propertyName) {
            return typeof item[propertyName] !== "undefined" && item[propertyName] !== null &&
                   item[propertyName].length > 0;
         },
         onAddClinicalNoteCanceled(){
            this.displayAddNote = false;
         },
         onAddClinicalNoteSaved(){
            this.displayAddNote = false;
            this.clearApolloCache();
            this.refetchData();
         },
         clearApolloCache() {
            let clients = Object.values(this.$apollo.provider.clients);
            clients.forEach(client => client.cache.reset());
         },
         refetchData(){
            this.$apollo.queries.patient.refetch();
         }
      },
      apollo: {
         patient: {
            query: GetClinicalHistory,
            variables() {
               return {
                  customerId: this.customerId,
                  patientId: this.patientId,
                  patientHistoryTab: HistoryTabNames.ClinicalHistory
               };
            },
            update(data) {
               this.loaded = true;
               this.hasData = data.customer.patient.patientHistory !== null
                              && data.customer.patient.patientHistory.clinicalHistory.length > 0;
               return data.customer.patient;
            }
         }
      }
   };
</script>

<style scoped>

</style>