<template>
  <div class="chart-wrapper">
    <v-chart @click="onClick" autoresize :option="data" />
  </div>
</template>

<script>

import { PieChart } from "echarts/charts";
import {
LegendComponent,
TitleComponent,
TooltipComponent
} from "echarts/components";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);


export default {
  name: "EscalationsItemsChart",
  props: {
    chartData: Array,
    title: String
  },
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  computed: {
    data() {
      if (!this.chartData) return;
      return {
        title: {
          text: this.title,
          left: "center",
          subtext: '',
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        label: {
          show: true,
          formatter: '{b}: {c}'
        },
        legend: {
          show: false,
          orient: "vertical",
          formatter: '{name} days',
          left: "left",
          data: [
          ]
        },
        series: [
          {
            name: "Escalations Items by Type",
            type: "pie",
            radius: "55%",
            centerdAVE1: ["50%", "60%"],
            data: this.chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      }
    },
  },
  methods: {
    onClick(e) {
      this.$emit("clicked", e);
    }
  },
}
</script>

<style scoped>
.chart-wrapper {
  height: 500px;
}
</style>