<template>
  <v-container>
    <h4 v-if="loaded && !hasData" class="ml-5">No records available.</h4>
    <v-timeline v-if="loaded && hasData" dense>
      <div v-for="(item, index) in patient.patientHistory.otherQuestion" :key="index">
        <v-timeline-item>
          <v-card>
            <v-col>
              <interaction-info :description="item.interactionType.description"
                                :date="item.interactionDateTime" :name="item.updatedByUser.displayName"/>

              <v-text-field v-if="displayItem(item, 'nonFootRelatedClinicalSignificantInfo')"
                            v-model="item.nonFootRelatedClinicalSignificantInfo" disabled
                            label="nonFootRelatedClinicalSignificantInfo"/>
              <v-text-field v-if="displayItem(item, 'nonFootRelatedClinicalSignificantInfoAdditionalInfo')"
                            v-model="item.nonFootRelatedClinicalSignificantInfoAdditionalInfo" disabled
                            label="nonFootRelatedClinicalSignificantInfoAdditionalInfo"/>
              <v-text-field v-if="displayItem(item, 'majorChangesRegardingHealth')"
                            v-model="item.majorChangesRegardingHealth" disabled label="majorChangesRegardingHealth"/>
              <v-text-field v-if="displayItem(item, 'majorChangesRegardingHealthInfo')"
                            v-model="item.majorChangesRegardingHealthInfo" disabled
                            label="majorChangesRegardingHealthInfo"/>
            </v-col>
          </v-card>
          <template v-slot:icon>
            <timeline-avatar :userId="item.updatedByUser.updatedByUserId" :alt="item.updatedByUser.displayName"
                             :src="item.updatedByUser.avatarURL"/>
          </template>
        </v-timeline-item>
      </div>
    </v-timeline>
  </v-container>
</template>

<script>
import {GetOtherQuestion} from "./queries/patient-history.graphql";
import {HistoryTabNames} from "@/types/enum";
import InteractionInfo from "./InteractionInfo";
import TimelineAvatar from "./TimelineAvatar";

export default {
  name: "OtherQuestion",
  props: {
    patientId: Number,
    customerId: Number
  },
  components: {
    InteractionInfo,
    TimelineAvatar
  },
  data: () => ({
    patient: null,
    loaded: false,
    hasData: true
  }),
  methods: {
    displayItem(item, propertyName) {
      return typeof item[propertyName] !== "undefined" && item[propertyName] !== null && item[propertyName].length > 0;
    }
  },
  apollo: {
    patient: {
      query: GetOtherQuestion,
      variables() {
        return {
          customerId: this.customerId,
          patientId: this.patientId,
          patientHistoryTab: HistoryTabNames.OtherQuestion
        };
      },
      update(data) {
        this.loaded = true;
        this.hasData = data.customer.patient.patientHistory !== null
            && data.customer.patient.patientHistory.otherQuestion.length > 0;

        return data.customer.patient;
      }
    }
  }
};
</script>

<style scoped>

</style>