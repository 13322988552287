<template>
  <div id="DetailsBanner">
    <v-container fluid dark style="background: #123559; color: white;">
         <v-row :style="setBreakpointHeights">
            <v-col cols="12" md="auto" class="pa-2 pb-0 pa-sm-3 pb-sm-0 pb-md-3">
               <div class="d-flex">
                  <v-icon size="65px" color="white">mdi-account</v-icon>
               <v-container class="py-0 pr-0">
                  <div v-on:click="copyData(displayData.customer.patient.firstName + ' ' + displayData.customer.patient.lastName)" class="data-content">
                     <h2>{{ displayData.customer.patient.firstName }} {{ displayData.customer.patient.lastName }} 
                     <span v-if="displayData.customer.patient.nickName">{{ `(${displayData.customer.patient.nickName})` }}</span></h2>
                  </div>

                  <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <div v-bind="attrs" v-on="on" class="data-content">
                              <h4 class="grey--text lighten-5 text--lighten-1" v-on:click="copyData(displayData.customer.patient.designation)">
                                 {{ displayData.customer.patient.designation }}
                              </h4>
                           </div>
                        </template>
                        <span>Patient Designation</span>
                     </v-tooltip>

                  <v-tooltip bottom v-if="displayData.patientIdentifier">
                        <template v-slot:activator="{ on, attrs }">
                           <div v-bind="attrs" v-on="on" class="data-content">
                              <h4 class="grey--text lighten-5 text--lighten-1" v-on:click="copyData(displayData.patientIdentifier.identifierValue)">
                                 {{ displayData.patientIdentifier.identifierValue }}
                              </h4>
                           </div>
                        </template>
                        <span>Patient Enterprise ID</span>
                     </v-tooltip>

                     <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <div v-bind="attrs" v-on="on" class="data-content">
                              <h4 class="grey--text lighten-5 text--lighten-1" v-on:click="copyData(displayData.customer.patient.legacyUniqueId)">
                                 {{ displayData.customer.patient.legacyUniqueId }}
                              </h4>
                           </div>
                        </template>
                        <span>Patient Unique Legacy ID</span>
                     </v-tooltip>
                  </v-container>
                  <v-btn class="float-right d-md-none pa-0 ml-n11" dark text @click="close()">Close</v-btn>
               </div>
            </v-col>
            <v-col class="pa-2 pb-0 pa-sm-3">
               <DataField 
               field-title="Customer"
               field-title2="PatientData.customer.patient.nickName"
               :field-data="displayData.customerName"
               @copy-data="copyData"
               />
               <DataField
               field-title="Site"
               :field-data="displayData.vaSiteName"
               @copy-data="copyData"
               />
            </v-col>
            <v-col class="pa-2 pb-0 pa-sm-3">
               <DataField 
               field-title="Prescribing Provider"
               :field-data="displayData.provider"
               @copy-data="copyData"
               />
               <DataField 
               field-title="Provider Type"
               :field-data="displayData.providerTypeName"
               @copy-data="copyData"
               />
            </v-col>
            <v-col class="pa-2 pb-0 pa-sm-3">
               <DataField 
               field-title="Age"
               :field-data="displayData.patientAge"
               @copy-data="copyData"
               />
               <DataField 
               field-title="Gender"
               :field-data="displayData.genderText"
               @copy-data="copyData"
               />
            </v-col>
            <v-col class="pa-2 pb-0 pa-sm-3">
               <DataField 
               field-title="Pod Name"
               :field-data="displayData.podName"
               @copy-data="copyData"
               />
               <DataField 
               field-title="Primary Nurse"
               :field-data="displayData.primaryNurse"
               @copy-data="copyData"
               />
            </v-col>
            <v-col class="pa-2 pb-0 pa-sm-3">
               <DataField 
               field-title="Serial Number"
               :field-data="serialNumberDisplay"
               @copy-data="copyData(displayData.serialNumber)"
               />
               <!-- Copy to clipboard button -->
               <ClipboardButton 
                  :customerId="displayData.customer.customerId"                 
                  :itemId="displayData.customer.patient.patientId"                 
                  :firstName="displayData.customer.patient.firstName"
                  :lastName="displayData.customer.patient.lastName"
                  :isPatient="true" 
                  class="mr-2 d-inline-block"
                  />
               <!-- Export info button -->
               <ExportInfoButton 
                  :customerId="displayData.customer.customerId"
                  :itemId="displayData.customer.patient.patientId"
                  :firstName="displayData.customer.patient.firstName"
                  :lastName="displayData.customer.patient.lastName"
                  :designation="displayData.customer.patient.designation"
                  :isPatient="true"
                  class="mt-2 d-inline-block"
                  />
            </v-col>
            <div class="d-none d-md-flex flex-column justify-space-between pa-2 pb-0 pa-sm-3">
               <v-btn class="d-block ml-auto" dark text @click="close(displayData.customer)"> Close</v-btn>
            </div>
         </v-row>
      </v-container>
  </div>
      
</template>

<script>
import DataField from "./DataField.vue";
import ExportInfoButton from "./ExportInfoButton.vue";
import ClipboardButton from "./ClipboardButton.vue";
import DeviceTypeIdentifier from '@/utils/padsDeviceTypeIdentifier';

  export default {
   name: "DetailsBanner",
   components: {
      DataField,
      ExportInfoButton,
      ClipboardButton
   },
   props: {
      displayData: Object
   },
   computed: {
     serialNumberDisplay() {
       const identifier = new DeviceTypeIdentifier();
       const rawSerialNumber = this.displayData.serialNumber;
       const identifiedType = identifier.identify(rawSerialNumber);
       return identifiedType ? `${rawSerialNumber} (${identifiedType})` : rawSerialNumber;
     },
      setBreakpointHeights() {
         let computedHeight = {}
         switch (this.$vuetify.breakpoint.name) {
            case 'xs': computedHeight.minHeight = '315px'; break;
            case 'sm': computedHeight.minHeight = '260px'; break;
            case 'md': computedHeight.minHeight = '165px'; break;
            case 'lg': computedHeight.minHeight = '135px'; break;
            case 'xl': computedHeight.minHeight = '135px'
         }
         return computedHeight
      }
   },
   methods: {
      copyData(value) {
         this.$emit("copy-data", value)
      },
      close(value) {
         this.$emit("close", value)
      }
   }
}
</script>

<style lang="scss" scoped>
   #DetailsBanner {
      position: sticky;
      top: 0;
      z-index: 100;
      margin: 0px;
      min-height: 132px;
      .data-content {
      font-size: 1rem;
      > * {
         cursor: pointer;
      }
    }
   }

</style>