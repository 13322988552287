import * as BaseService from './BaseService';
import * as customer from '../types/customer';
import * as master from '../types/master';

export default class PurchaseOrderService extends BaseService.BaseService {
   private url = '/api/PurchaseOrder';
      
   constructor() {
     super();     
   }

     async getAllPurchaseOrders(pagedDataOptions: BaseService.DataOptions): Promise<BaseService.PagedData<master.CustomerPurchaseOrder>>{          
        let response = (await this.api.get(this.url, {
            params:
                { Page: pagedDataOptions.Page, ItemsPerPage: pagedDataOptions.ItemsPerPage, Search: pagedDataOptions.Search, SortBy: pagedDataOptions.SortBy, SortDesc: pagedDataOptions.SortDesc }
        })).data as BaseService.PagedData<master.CustomerPurchaseOrder>;   
      return response;
    } 
  
    async get(customerId: number, purchaseOrderId: number): Promise<customer.PurchaseOrder>{
      let url = this.url + `/${customerId}/${purchaseOrderId}`;            
      let response = (await this.api.get(url)).data as customer.PurchaseOrder;          
      return response;
    }

    async put(customerId: number,purchaseOrder: customer.PurchaseOrder)
    {
      let url = this.url + `/${customerId}`;            
      let response = await this.api.put(url,purchaseOrder).catch(err => {
        throw err;          
      });                
      return response;
    }
}