<template>
<v-card>
  <v-card-title v-if="!prescribingProvider" >{{value.willReceiveReports ? 'Escalation' : 'Other'}}</v-card-title>
  <v-card-title v-if="prescribingProvider">Prescribing Provider</v-card-title>
  <v-card-text>
    <v-row>
      <v-col cols="2">
        <v-icon color="indigo" large  :disabled="!value.isActive">mdi-doctor</v-icon>
      </v-col>
      <v-col>
        <div><label>Name: </label> <span class="font-weight-bold">{{value.provider.lastName ? value.provider.firstName + ' ' + value.provider.lastName : value.provider.name}}{{ value.isActive ? '' : ' (inactive)'}}</span></div>
        <div><label>Type: </label> <span class="font-weight-bold">{{value.provider.providerType.name}}</span></div>
        <div><label>NPI: </label> <span class="font-weight-bold">{{value.provider.npi}}</span></div>
      </v-col>
    </v-row>
  </v-card-text>
  <v-card-actions>
    <v-btn text color="primary" v-if="viewButton" @click="onView">View</v-btn>
    <v-btn text @click="onEdit" v-if="editButton">Edit</v-btn>
    <v-btn text @click="onDelete" v-if="deleteButton">Remove</v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
export default {
  props: {
    value: Object,
    editButton: Boolean,
    deleteButton: Boolean,
    viewButton: Boolean,
    prescribingProvider: Boolean
  },
  name: "ProviderCard",
  data: () => ({
    //
  }),
  methods: {
    handleInput (e) {
      //this.$emit('input', this.provider)
    },
    onDelete() {
      this.$emit('delete', this.value);
    },
    onView() {
      this.$emit('view', this.value);
    },
    onEdit() {
      this.$emit('edit', this.value);
    }
  }
}
</script>

<style scoped>

</style>