<template>
  <div>
    <v-app :style="{ background: $vuetify.theme.themes.light.primary }">
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col md="4">
            <v-card class="mx-auto pa-10" max-width="400">
              <v-img alt="Podimetrics Logo" class="shrink mr-2" contain src="../assets/logo.png"
                transition="scale-transition" width="250" />
              <v-card-subtitle class="pb-0">
                Login to Achilles
              </v-card-subtitle>
              <LoadingSpinner v-if="isLoading" />
              <v-card-actions v-else>
                <div id="buttonDiv" class="google-sign-in"></div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import store from '../store'
import LoadingSpinner from './Shared/LoadingSpinner.vue';

export default {
    name: "LoginComponent",
    props: {
        signedInState: Boolean,
  },
    components: { LoadingSpinner },
    data: () => ({
        isInit: false,
        authenticated: false,
        autoRefreshEnabled: false,
        clientId: process.env.VUE_APP_DEV_CLIENT_ID,
        isLoading: false
    }),
    mounted: function () {
      this.initGoogle();
    },
    watch: {
        signedInState: function (newVal) {
            console.log(`signinstate: ${newVal}`);
            this.initGoogle();
        },
    },
    methods: {
        initGoogle() {
            if (this.signedInState !== true) {
                window.google.accounts.id.initialize({
                    client_id: this.clientId,
                    callback: this.loginComplete,
                });
                window.google.accounts.id.renderButton(document.getElementById("buttonDiv"), { theme: "outline", size: "large" }
                );
                window.google.accounts.id.prompt();
                this.$emit("init");
            }
            else {
                this.$emit("init");
                this.$emit("loggedIn");
                this.$emit("achilles-user-set", true);
                this.$store.dispatch("goHome");
            }
        },
        async loginComplete({ credential }) {
            this.handleLoading(true);
            const authToken = {
                token: credential
            };
            const baseUrl = process.env.VUE_APP_API_BASE_URL;
            const options = {
                method: "POST",
                body: JSON.stringify(authToken),
                headers: {
                    "Content-Type": "application/json",
                }
            };
            await fetch(`${baseUrl}/auth`, options)
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            this.$store.commit("setRestrictedAccess", true);
                            this.$emit("loggedIn");
                            this.$store.dispatch("goHome");
                            throw new Error('Unauthorized access');
                        } else {
                            throw new Error('Network response was not ok');
                        }
                    }
                    return response.json();
                })
                .then((data) => {
                this.authenticated = true;
                function decodeJwt({ token }) {
                    let base64Payload = token.split(".")[1];
                    let payloadBuffer = Buffer.from(base64Payload, "base64");
                    return JSON.parse(payloadBuffer.toString());
                }
                let payload = decodeJwt(data);
                const profile = {
                    email: payload.email,
                    firstInitial: payload.given_name.charAt(0),
                    sd: null,
                    token: data.token,
                    expires: payload.exp
                };
                this.$store.commit("setGUserProfile", profile);
                this.handleSetAchillesUser(profile.email);
                this.setLogoutTimer(profile.expires);
                this.handleInitLocalSession();
                this.$emit("loggedIn");
                this.handleLoading(false);
                this.$store.dispatch("goHome");
                console.log(`Token will expire at ${new Date(payload.exp * 1000).toString()}`);
            })
                .catch((error) => {
                console.error("API Auth:", error);
            });
        },
        setLogoutTimer(timeExpired) {
            let alertTime = new Date(timeExpired * 1000);
            let difference = alertTime.getMinutes() - 10;
            let alertTimeDif = alertTime.setMinutes(difference);
            let alertTimeMs = alertTimeDif / 1000;
            // Set alert for 10-min before token expiration
            setTimeout(() => {
                this.$store.commit("setTimeoutAlert", true);
            }, alertTimeMs);
            // Logout after token expires
            setTimeout(() => {
                this.authenticated = false;
                this.$store.commit("setTimeoutAlert", false);
                this.$router.push("Logout");
                console.log("Token expired, please sign-in again.");
            }, timeExpired);
        },
        handleInitLocalSession() {
            if (localStorage.getItem("getSession") === "false") {
                localStorage.setItem("getSession", Date.now());
            }
        },
      handleLoading(state) {
      return this.isLoading = state;
    },
        async handleSetAchillesUser(userEmail) {
            this.$store.dispatch("getTimeZones");
            await this.$store.dispatch("getUser", userEmail);
            this.$emit("achilles-user-set", true);
        },
    },
}
</script>