<template>
  
  <v-container>
    <v-row>
      <v-col><v-btn class="secondary" @click="showAddDialog = true">Add</v-btn></v-col>
    </v-row>
    <v-row>
      <v-col md="4" sm="6" lg="3" v-for="(provider, index) in providers" :key="listKey(provider)">
        <!--  || !provider.npi -->
        <provider-card view-button :prescribing-provider="isPrescribingProvider(provider)" :delete-button="!isPrescribingProvider(provider)" :edit-button="!isPrescribingProvider(provider)" @view="onView(provider)" @edit="onEdit(provider)" @delete="onDelete(provider)" v-model="providers[index]"/>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmDialog" v-if="confirmDialog"
      persistent
      max-width="500"
      content-class="confirm-dialog">
      <v-card>
        <v-card-title class="headline primary">
            Remove Provider
        </v-card-title>
        <v-card-text>
          Are you sure you want to remove this provider?
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
            <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAddDialog" max-width="800px" v-if="showAddDialog">
      <provider-add mode="Add" :key="addKey" v-if="showAddDialog && !isMemberEntity()" @saved="onAddSave" :entity-providers="providers" :entity-id="this.entityId" :customer-id="customerId" @closed="showAddDialog = false"></provider-add>
    </v-dialog>
    <v-dialog v-model="showEditDialog" max-width="800px" v-if="showEditDialog">
      <provider-add mode="Edit" :prescribing-provider="isPrescribingProvider(selectedProvider)" :key="editKey" v-if="showEditDialog && !isMemberEntity()" @saved="onEditSave" v-model="selectedProvider" :entity-id="this.entityId" :entity-type-id="this.entityTypeId" :entity-providers="providers" :customer-id="customerId" @closed="onCloseEdit()"></provider-add>
    </v-dialog>
    <v-dialog v-model="showViewDialog" max-width="800px" v-if="showViewDialog">
      <provider-add mode="View" :prescribing-provider="isPrescribingProvider(selectedProvider)" :key="viewKey" v-if="selectedProvider && !isMemberEntity()" :customer-id="customerId" v-model="selectedProvider" @closed="showViewDialog = false"></provider-add>
      <member-provider-add mode="View" :prescribing-provider="isPrescribingProvider(selectedProvider)" :key="viewKey" v-if="selectedProvider && isMemberEntity()" :customer-id="customerId" :entity-type-id="entityTypeId" v-model="selectedProvider" @closed="showViewDialog = false"></member-provider-add>
    </v-dialog>
  </v-container>
</template>

<script>
import ProviderCard from "@/components/Provider/ProviderCard";
import PatientProviderService from "@/services/PatientProviderService";
import ProviderAdd from "@/components/Provider/ProviderAdd";
import MemberProviderAdd from '@/components/Provider/MemberProviderAdd';
import ProviderService from "@/services/ProviderService";
import ReferenceDataService from "@/services/ReferenceDataService";
import {EntityTypeEnum} from '@/types/enum';
import MemberProviderService from '@/services/MemberProviderService';

export default {
  name: "ProviderList",
  components: {
    ProviderCard,
    ProviderAdd,
    MemberProviderAdd
  },
  props: {
    customerId: Number,
    entityId: Number,
    entityTypeId: Number
  },
  data: () => ({
    providers: [],
    isLoading: false,
    confirmDialog: false,
    showEditDialog: false,
    showViewDialog: false,
    selectedProvider: null,
    showAddDialog: false,
    viewKey: 0,
    editKey: 0,
    addKey: 0,
    prescribingProvider: null,
    entityProviderSvc: null,
    providerSvc: null,
    providerToDelete: null
  }),
  methods: {
    isPrescribingProvider(val) {
      return this.prescribingProvider && this.prescribingProvider.providerId === val.providerId;
    },
    async loadData() {
      const promises = [];

      promises.push(new ReferenceDataService()
          .getStates()
          .then((resp) => {
            this.$store.commit("setStates", resp);
          })
          .catch((err) => {
            console.log(err);
          }));

      if(this.entityTypeId === EntityTypeEnum.Patient)
      {
        promises.push(this.entityProviderSvc
            .get(this.customerId, this.entityId)
            .then((resp) => {
              this.providers = resp;
            }).catch(e => {
              console.log(e);
            }));

        promises.push(this.providerSvc
            .getProviderByPatientId(this.customerId, this.entityId)
            .then((value) => {
              if (value) {
                this.prescribingProvider = value;
              }
            })
            .catch((err) => {
              console.log(err);
            }));
      }
      else if(this.isMemberEntity())
      {
        const memSvc = new MemberProviderService(this.$apollo);
        promises.push(memSvc
          .getMemberProvider(this.customerId, this.entityId)
          .then(r => {
            this.providers = memSvc.parseMemberProviders(r.data.customer.member.memberProviders.data);
          })
          .catch(e => {
            console.log(e);
          }));
      }

      const self = this;
      Promise.allSettled(promises).then(() => {
        this.isLoading = false;
        this.addKey += 1;
        this.editKey += 1;
        this.viewKey += 1;

        this.providers.sort(function (a, b) {
              return (self.isPrescribingProvider(a) ? 0 : 1) - (self.isPrescribingProvider(b) ? 0 : 1)
            }
        );
      });
    },
    onView(provider) {
      this.selectedProvider = provider;
      this.viewKey += 1;
      this.showViewDialog = true;
    },
    onDelete(provider) {
      this.confirmDialog = true;
      this.providerToDelete = provider;
    },
    async confirmDialogCallback (agreed) {
      this.confirmDialog = false;

      if (agreed) {
        await this.entityProviderSvc.delete(this.customerId, this.providerToDelete.patientProviderId);
        this.providerToDelete = null;
        await this.loadData();
      }
    },
    onEdit(provider) {
      this.selectedProvider = provider;
      this.editKey += 1;
      this.showEditDialog = true;
    },
    onAddSave() {
      this.showAddDialog = false;
      this.loadData();
    },
    onEditSave() {
      this.showEditDialog = false;
      this.loadData();
    },
    onCloseEdit() {
      this.showEditDialog = false;
      this.loadData();
    },
    listKey(p) {
      if (this.entityTypeId === EntityTypeEnum.Patient)
      {
        return p.patientProviderId;
      }
      else if (this.isMemberEntity())
      {
        return p.memberProviderId;
      }
    },
    isMemberEntity() {
      return this.entityTypeId === EntityTypeEnum.Member;
    }
  },
  created() {
    if(this.entityTypeId === EntityTypeEnum.Patient)
    {
      this.entityProviderSvc = new PatientProviderService();
      this.providerSvc = new ProviderService();
    }
    this.loadData();
  },
  computed: {
    prescribingProviderModel: {
      get() {
        return {
          provider: this.prescribingProvider
        }
      }
    }
  }
}
</script>

<style scoped>
  .confirm-dialog .v-card__title {
    color: #FFFFFF;
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
  }

  .confirm-dialog .v-card__text {
    margin-top: 26px;
    font-size: 1rem !important;
  }
</style>