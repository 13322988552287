<template>
   <div>
      <v-row>
         <v-col class="pa-0">
            <v-simple-table calculate-widths dark class="podi-color">
               <thead>
                  <tr>
                     <th class="text-left empty-header"></th>
                     <th class="pr-0 text-left empty-header"></th>
                     <th class="text-left empty-header"></th>
                     <th class="text-left empty-header"></th>
                     <th class="text-left empty-header"></th>
                     <th class="text-left empty-header"></th>
                     <th class="text-left empty-header"></th>
                     <th class="text-left no-height"></th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td rowspan="2">
                        <v-btn icon dark @click="closeDialog()">
                           <v-icon>mdi-close</v-icon>
                        </v-btn>
                     </td>
                     <td rowspan="2" class="pr-0 pl-3">
                        <v-icon size="65px" class="float-right">mdi-account</v-icon>
                     </td>
                     <td rowspan="2" nowrap>
                        <div v-on:click="copyToClipboard(member.firstName + ' ' + member.lastName)">
                           <h2>{{member.firstName}} {{member.lastName}}</h2>
                        </div>
                        <div v-on:click="copyToClipboard(member.designation)">
                           <h3 class="grey--text lighten-5 text--lighten-1">{{member.designation}}</h3>
                        </div>
                     </td>
                     <td nowrap class="align-top">
                        <div class="grey--text  text-caption">Customer</div>
                        <h3>{{customerName}}</h3>
                     </td>
                     <td nowrap class="align-top" >
                        <div class="grey--text  text-caption">Prescribing Provider</div>
                        <div v-on:click="copyToClipboard(providerName)" v-if="providerName">
                           <h3>{{providerName}}</h3>
                        </div>
                     </td>
                     <td nowrap class="align-top">
                        <div class="grey--text  text-caption">Provider Type</div>
                        <div v-on:click="copyToClipboard(providerType)">
                           <h3>{{providerType}}</h3>
                        </div>
                     </td>
                     <td class="align-top"></td>
                     <td rowspan="2" class="text-right">
                        <v-btn class="float-right" dark text @click="closeDialog()"> Close</v-btn>
                     </td>
                  </tr>
                  
                  <tr>
                     <td nowrap class="align-top">
                        <div v-on:click="copyToClipboard(genderText)">
                           <div class="grey--text  text-caption">Gender</div>
                           <h3>{{genderText}}</h3>
                        </div>
                     
                     </td>
                     <td nowrap class="align-top">
                        <div v-on:click="copyToClipboard(member.age)">
                           <div class="grey--text  text-caption">Age</div>
                           <h3>{{member.age}}</h3>
                        </div>
                     </td>
                  </tr>
               
               </tbody>
            </v-simple-table>
         </v-col>
      </v-row>
      
      <v-col>
         <ExportSnackbar></ExportSnackbar>
         <ClipboardSnackbar></ClipboardSnackbar>
         
         <!-- Crisis call dialog -->
         <CrisisCallDialog v-if="hasCustomerCrisisCallContent" :isPatient="false"
                           :dialogCrisis="dialogCrisis"
                           :customerCrisisCallContent="crisisCallContent">
         </CrisisCallDialog>
         
         <!-- Cris call button -->
         <CrisisCallButton v-if="member.memberId" :isPatient="false" :info="member.memberId.toString()"></CrisisCallButton>
         
         <!-- Export info button -->
         <ExportInfoButton :customerId="customerId"
                           :itemId="member.memberId"
                           :firstName="member.firstName"
                           :lastName="member.lastName"
                           :designation="member.designation"
                           :isPatient="false">
         
         </ExportInfoButton>
         
         <!-- Copy to clipboard button -->
         <ClipboardButton :customerId="customerId"
                          :itemId="member.memberId"
                          :firstName="member.firstName"
                          :lastName="member.lastName"
                          :isPatient="false">
         </ClipboardButton>
         
         <v-tabs>
            <v-tab key="memberSummary" @change="summaryKey++">Member Summary</v-tab>
            <v-tab-item key="memberSummary">
               <MemberSummary :customerId="customerId" :memberId="member.memberId" :key="summaryKey"></MemberSummary>
            </v-tab-item>
            <v-tab key="demo">Demographics</v-tab>
            <v-tab-item key="demo">
               <MemberDemographics :customerId="customerId" :memberId="member.memberId"></MemberDemographics>
            </v-tab-item>
          
            <v-tab key="clinical">Clinical</v-tab>
            <v-tab-item key="clinical">
            
            </v-tab-item>
            <v-tab key="workitems"> Worklist Items</v-tab>
               <v-tab-item key="workitems">
                  <MemberWorklistItems :customerId="customerId" :memberId="member.memberId"></MemberWorklistItems>
               </v-tab-item>
            <v-tab key="interactions">Interactions</v-tab>
            <v-tab-item key="interactions">
               <member-interaction-list :customerId="customerId" :memberId="member.memberId"></member-interaction-list>
            </v-tab-item>
            <v-tab key="providers"> Providers</v-tab>
            <v-tab-item key="providers">
              <provider-list v-if="renderProviders" :customer-id="customerId" :entityId="member.memberId" :entityTypeId="6"></provider-list>
            </v-tab-item>
            <v-tab key="documents"> Documents</v-tab>
            <v-tab-item key="documents">
               <member-documents :member="member" :customerId="customerId"></member-documents>
            </v-tab-item>
            <v-tab key="related-entities">Related Entities</v-tab>
            <v-tab-item key="related-entities">
               <related-entity-list :member="member" :customerId="customerId"/>
            </v-tab-item>
         </v-tabs>
      
      </v-col>
   </div>
</template>

<script>
   import CrisisCallButton from "../Shared/CrisisCallButton";
   import CrisisCallDialog from "../Shared/CrisisCallDialog";
   import ExportInfoButton from "../Shared/ExportInfoButton";
   import ExportSnackbar from "../Shared/ExportSnackbar";
   import ClipboardSnackbar from "../Shared/ClipboardSnackbar";
   import ClipboardButton from "../Shared/ClipboardButton";
   import MemberSummary from "./MemberSummary";
   import MemberDemographics from "./MemberDemographics";
   import MemberWorklistItems from  "./MemberWorklistItems"
   import MemberDocuments from '@/components/Member/MemberDocuments';
   import ProviderList from "@/components/Provider/ProviderList";
   import MemberInteractionList from "./Interactions/MemberInteractionList";
   import RelatedEntityList from  "@/components/Member/RelatedEntities/RelatedEntityList";
   
   export default {
      name: "MemberDetails",
      props: {
         customerId: Number,
         customerName: String,
         memberId: Number,
         member: {},
         crisisCallContent: String
      },
      components: {
         CrisisCallButton,
         CrisisCallDialog,
         ExportInfoButton,
         ExportSnackbar,
         ClipboardSnackbar,
         ClipboardButton,
         MemberSummary,
         MemberDemographics,
         MemberWorklistItems,
         MemberDocuments,
         ProviderList,
         MemberInteractionList,
         RelatedEntityList
      },
      data: () => ({
         dialogCrisis: false,
         renderProviders: true,
         summaryKey:0,
      }),
      computed: {
         providerName() {
            if (typeof this.member.memberProviders !== "undefined" && this.member.memberProviders.data.length > 0) {
               return this.member.memberProviders.data[0].prequalificationProvider.name;
            }
            return null;
         },
         providerType() {
            if (typeof this.member.memberProviders !== "undefined" && this.member.memberProviders.data.length > 0) {
               return this.member.memberProviders.data[0].prequalificationProvider.providerType.name;
            }
            return null;
         },
         genderText() {
            const gen = this.$store.state.genders.find(g => g.genderId === this.member.genderId);
            
            return gen ? gen.description : "";
         },
         hasCustomerCrisisCallContent() {
            return this.crisisCallContent != null
                   && this.crisisCallContent.length > 0;
         }
      },
      methods: {
         closeDialog() {
            this.$emit("close-dialog");
         },
         setCrisisDialogVal(val) {
            this.dialogCrisis = val;
         },
         copyToClipboard(value) {
            let self = this;
            this.$copyText(value).then(
                function (e) {
                   self.$root.$emit("updateClipboardText", value);
                   
                   self.$root.$emit("updateClipboardSnackbar", true);
                   console.log(e);
                }
            );
         }
      }
   };
</script>

<style scoped lang="scss">
   thead {
      th {
         &.empty-header {
            width: 1% !important;
            height: 0 !important;
         }
         &.no-height{
            height:0!important;
         }
      }
   }
   
   tbody {
      tr:hover {
         background-color: transparent !important;
      }
      
      td {
         &.align-top {
            vertical-align: top !important;
            border-bottom: none !important;
         }
      }
   }
   
   .podi-color {
      background: #123559;
   }
</style>