<template>
   <div>
      <span class="caption grey--text">{{formattedDate}}</span>
      <h3 class="my-1 pb-4">
         {{description}}
         <span class="caption">by {{name}}</span>
      </h3>
   </div>
</template>

<script>
   import Utils from "../../../mixins/utils";
   export default {
      name: "InteractionInfo",
      props: {
         description: String,
         name: String,
         date: String,
      },
      mixins: [Utils],
      computed:{
         formattedDate(){
            if (typeof this.date === undefined || this.date === null) return "";
            
            return this.formatDateTime(this.date);
         }
      }
   };
</script>

<style scoped>

</style>