<template>
   <div :style="isFixed ? fixedStyle : null ">
      <v-tooltip top>
         <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="warning" @click="onCrisisClick">
               <v-icon dark>mdi-phone</v-icon>
               Crisis Call
            </v-btn>
         </template>
         <span>Start Crisis Call Workflow</span>
      </v-tooltip>
   </div>
</template>

<script>
   import EmailService from "@/services/EmailService";
   
   export default {
      name: "CrisisCallButton",
      props: {
         info: String,
         isPatient: Boolean,
         isFixed: Boolean,
      },
      data: () => ({
         crisisSubject: process.env.VUE_APP_CRISIS_EMAIL_SUBJECT,
         crisisEmail: process.env.VUE_APP_CRISIS_EMAIL,
         fixedStyle: {
            position: 'fixed',
            bottom: '1.5rem',
            right: '1.5rem',
            zIndex: '100',
            filter: 'drop-shadow(.5rem .5rem 1rem #D3D3D3)'
         }

      }),
      computed: {
         patientOrMemberText() {
            return this.isPatient
                   ? " is currently viewing the chart of Patient "
                   : " is currently viewing the chart of Member ";
         }
      },
      methods: {
         async onCrisisClick() {
            this.openCrisisDialog(true);
            
            let email = {
               to: this.crisisEmail,
               subject: this.crisisSubject,
               body: "User "
                     + this.$store.state.achillesUser.firstName
                     + " "
                     + this.$store.state.achillesUser.lastName
                     + " has indicated that a crisis call is currently in progress.  "
                     + this.$store.state.achillesUser.firstName
                     + " " + this.$store.state.achillesUser.lastName
                     + this.patientOrMemberText
                     + this.info + "."
            };
            await new EmailService().post(email);
         },
         openCrisisDialog(val) {
            this.$emit("set-crisis-dialog-val", val);
         }
      }
   };
</script>

<style scoped>

</style>