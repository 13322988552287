class DeviceTypeIdentifier {
    constructor() {
        this.deviceTypes = [
            { pattern: /^\d{9}$/, type: 'Cellular SmartMat' },
            { pattern: /^21\d{11}$/, type: 'Cellular SmartMat' },
            { pattern: /^22\d{11}$/, type: 'Cellular SmartMat (Remanufactured)' },
            { pattern: /^23\d{11}$/, type: 'Wi-Fi SmartMat' },
            { pattern: /^30\d{11}$/, type: 'Salus Device' }
        ];
    }

    identify(serialNumber) {
        const standardizedSN = serialNumber.replace(/-/g, '');
        const deviceType = this.deviceTypes.find(dt => dt.pattern.test(standardizedSN));
        return deviceType ? deviceType.type : '';
    }
}

export default DeviceTypeIdentifier;