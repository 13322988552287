import * as BaseService from './BaseService';
import * as master from '../types/master';

export default class UserService extends BaseService.BaseService {
   private url = '/api/User';
      
   constructor() {
     super();     
   }
  
    async getUser(email: string): Promise<master.User>{
      let url = this.url + `/${email}`;               
      let response = (await this.api.get(url)).data as master.User;     
      return response;
    }

    async getUserById(userId: number): Promise<master.User>{
      let url = this.url + `/GetUserById/${userId}`;               
      let response = (await this.api.get(url)).data as master.User;     
      return response;
    }
    
    async getUsers(): Promise<Array<master.User>>{           
      let response =  (await this.api.get(this.url)).data as Array<master.User>;
      let sortedResponse = response.sort((a,b) => (a.email > b.email) ? 1 : ((b.email > a.email) ? -1 : 0));     
      return sortedResponse;
    }
  }