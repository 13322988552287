<template>
   <v-container fluid>
     
      <WorklistItemCadence v-if="configDialog" 
                           :configDialog="configDialog"
                           :customerId="customerId" 
                           :itemId="patient.patientId"
                           :isPatient="true"
                           :saveAction="onPatientConfigSaved"
                           :cancelAction="() => configDialog = false"/>
      
      <v-row>
         <v-col md="7" sm="12" xs="12">
            <v-card :loading="loading" class="pa-5" elevation="2" outlined tile>
               <v-alert :type="alertRef.alertType" :value="alertRef.alert" dense transition="fade-transition">
                  {{alertRef.alertMessage}}
               </v-alert>
               
               <v-row>
                  <v-col md="6">
                     <v-card-title class="headline">
                        <span class="patient-updated-info-patient-caption">Patient</span>
                        <v-icon v-if="isSnoozed" class="ma-1">mdi-sleep</v-icon>
                        <div class ="patient-updated-info">
                           <span class="patient-updated-info-caption">{{ "Last Updated: " + patientInfoLastUpdated  + " "}}</span>
                           <span class="patient-updated-info-caption">{{ "By: " + patientInfoLastUpdatedUser }}</span>
                        </div>
                     </v-card-title>
                  </v-col>
                  <v-col md="1" offset-md="5" class="align-middle orient-right">
                     <v-btn v-if="hasAccess('EditPatientWorklistItemCadence')" icon @click="configDialog = true">
                        <v-icon>mdi-cog-outline</v-icon>
                     </v-btn>
                     <v-btn icon @click="edit">
                        <v-icon>mdi-pencil</v-icon>
                     </v-btn>
                  </v-col>
               </v-row>
               
               <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                     <v-col cols="12" md="6" sm="12">
                        <v-text-field
                            v-model="patient.firstName"
                            :readonly="!isEditMode"
                            :rules="nameRules"
                            label="First Name"
                            prepend-icon="mdi-account"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="patient.lastName"
                            :readonly="!isEditMode"
                            :rules="nameRules"
                            label="Last Name"
                            prepend-icon="mdi-account"
                            required
                        ></v-text-field>
                        <v-select
                            v-model="patient.timeZoneId"
                            :items="$store.state.timeZones"
                            :readonly="!isEditMode"
                            item-text="description"
                            item-value="timeZoneId"
                            label="Time Zone"
                            prepend-icon="mdi-clock"
                        ></v-select>
                        <v-select v-if="showTimeOfDayPreferenceFeature"
                           :items="timeOfDayPreferences"
                           item-text="description"
                           item-value="timeOfDayPreferenceId"
                           v-model="patient.timeOfDayPreferenceId"
                           label="Time Of Day Preference"
                           :readonly="!isEditMode"
                           prepend-icon="mdi-clock">
                        </v-select>
                        <v-text-field
                            v-model="patient.lastFourSsn"
                            v-mask="'####'"
                            :readonly="!isEditMode || initialPatient.lastFourSsn != null"
                            label="SSN Last Four"
                            prepend-icon="mdi-card-bulleted"
                        ></v-text-field>
                        <v-select
                            v-model="patient.genderId"
                            :items="$store.state.genders"
                            item-text="description"
                            item-value="genderId"
                            label="Gender"
                            :readonly="!isEditMode"
                            prepend-icon="mdi-human-non-binary"
                        ></v-select>
                        <!-- https://css-tricks.com/how-to-add-text-in-borders-using-basic-html-elements/ -->

                        <v-select v-if="showLanguagePreferenceFeature"
                           :items="languagePreferences"
                           item-text="description"
                           item-value="languagePreferenceId"
                           v-model="patient.languagePreferenceId"
                           label="Language Preference"
                           :readonly="!isEditMode"
                           @change="onLanguagePreferenceChanged"
                           prepend-icon="mdi-translate">
                        </v-select>
                        <v-text-field  style="margin-left:33px" v-if="languagePreference.description === 'Other'"
                            v-model="patient.otherLanguagePreference"
                            :readonly="!isEditMode"
                            label="Other Language Preference"
                            required
                            counter
                            maxlength="50"
                            :rules="[otherLanguagePreferenceRules.required, otherLanguagePreferenceRules.counter]"
                        ></v-text-field>
                        <v-select v-if="showAudioImpairmentFeature"
                           :items="audioImpairment"
                           item-text="description"
                           item-value="audioImpairmentId"
                           v-model="patient.audioImpairmentId"
                           label="Audio Impaired"
                           :readonly="!isEditMode"
                           prepend-icon="mdi-ear-hearing">
                        </v-select>
                     </v-col>
                     <v-col cols="12" md="6" sm="12">
                        <v-text-field
                            v-model="patient.middleName"
                            :readonly="!isEditMode"
                            label="Middle Name"
                            prepend-icon="mdi-account"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="patient.nickName"
                            :readonly="!isEditMode"
                            label="Nick Name"
                            prepend-icon="mdi-account"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="patient.designation"
                            label="Patient Designation"
                            :readonly="(!isClinicalOperationsUser && !isDeviceOperationsUser && validDesignation) || !isEditMode"
                            prepend-icon="mdi-card-account-details">
                        </v-text-field>
                        <v-textarea
                            v-model="patient.note"
                            :readonly="!isEditMode"
                            cols="5"
                            label="Notes"
                            prepend-icon="mdi-note"
                        ></v-textarea>
                     </v-col>
                     <v-col cols="12" class="text-right">
                        <v-btn
                            :disabled="!isEditMode"
                            class="mr-4"
                            color="secondary"
                            @click="cancel">
                           Cancel
                        </v-btn>
                        <v-btn
                            :disabled="!isEditMode"
                            class="mr-4"
                            color="secondary"
                            @click="save">
                           Save
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-form>
            </v-card>
         </v-col>
         
         <v-col cols="12" md="5" sm="12">
            <ContactDetail @changed="loadData" v-if="patient && patient.patientId" :customerId="customerId" :patient="patient" entityType="Patient"/> 
         </v-col>
      </v-row>
   </v-container>
</template>
<script>
   import moment from 'moment';
   import ContactDetail from "./ContactDetail";
   import * as Shared from "../shared";
   import PatientService from "../services/PatientService";
   import _isEqual from "lodash/isEqual";
   import EntityLockService from "../services/EntityLockService";
   import Security from "../mixins/security";
   import WorklistItemCadence from "./Shared/WorklistItemCadence";
   import { GetPatientLookupValues } from "../common/queries/patient-lookup-values.graphql";
   import { GetPatientUpdatedInfo } from "../components/Patient/queries/updated-info.graphql";
   
   export default {
      mixins: [Security],
      props: {
         patientId: Number,
         customerId: Number
      },
      components: {
         ContactDetail,
         WorklistItemCadence
      },
      name: "PatientDemographics",
      data: () => ({
         audioImpairment: [],
         languagePreferences: [],
         timeOfDayPreferences: [],
         showAudioImpairmentFeature: process.env.VUE_APP_PATIENT_AUDIO_IMPAIRMENT === "enabled",
         showLanguagePreferenceFeature: process.env.VUE_APP_PATIENT_LANGUAGE_PREFERENCE === "enabled",
         showTimeOfDayPreferenceFeature: process.env.VUE_APP_PATIENT_TIME_OF_DAY_PREFERENCE === "enabled",
         updatedInfo: {
         },
         initialPatient: {},
         isEditMode: false,
         loading: false,
         configDialog: false,
         patient: {},
         valid: false,
         rowVersion: "",
         nameRules: [
            v => !!v || "Name is required"
         ],
         otherLanguagePreferenceRules: {
            required: v => !!v || "Other Language is required",
            counter: v => v && v.length <= 50 || "Max 50 characters"
         },
         alertRef: {
            alert: false,
            alertType: "success",
            alertMessage: ""
         }
      }),
      watch: {
         patient() {
            Object.assign(this.initialPatient, this.patient);
         }
      },
      mounted() {
         this.loadData();
      },
      computed: {
         patientInfoLastUpdated() {
            if (this.updatedInfo.updated == null) {
               return '';
            }
            let isoDateString = moment(this.updatedInfo.updated).toISOString();
            return new Intl.DateTimeFormat('en-US', {
               month: '2-digit', day: '2-digit', year: 'numeric'
            }).format(new Date(isoDateString));
         },
         patientInfoLastUpdatedUser() {
            if (this.updatedInfo.user == null) {
               return "Achilles";
            } else {
               return this.updatedInfo.user.firstName + ' ' + this.updatedInfo.user.lastName;
            }
         },
         isClinicalOperationsUser() {
            let role = this.$store.state.achillesUser.userRoles.find(
                (r) => r.role.name === "ClinicalOperations"
            );
            return !!role;
         },
         isDeviceOperationsUser() {
            let role = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                         r.role.name === "DeviceOperations"
            );
            return !!role;
         },
         validDesignation() {
            return !(this.initialPatient.designation == null
                     || this.initialPatient.designation == ""
                     || this.initialPatient.designation.includes("XXXX")
                     || this.initialPatient.designation.includes("VA "));
         },
         isSnoozed() {
            let snoozedDate = new Date(this.patient.snoozedTill);
            return (new Date()) < snoozedDate;
         }
         ,
         languagePreference() {
            let languagePreference = this.languagePreferences.find(x => x.languagePreferenceId === this.patient.languagePreferenceId);
            return languagePreference == undefined ? {
               languagePreferenceId: -1,
               description: ''
            } :
            languagePreference;
         }
      },
      methods: {
         onLanguagePreferenceChanged(languagePreferenceId) {
            let languagePreference =  this.languagePreferences.find(x => x.languagePreferenceId === languagePreferenceId);
            if (!["Other"].includes(languagePreference.name))
            {
               this.patient.otherLanguagePreference = null;
            }

            this.patient.isInterpreterServicesNeeded =
                !["English", "Unknown",].includes(languagePreference.name);
         },
         onPatientConfigSaved() {
            this.configDialog = false;
            this.$emit("changed");
         },
         async loadData() {
            this.loading = true;
            let promises = [];
            promises.push(new PatientService()
                              .get(this.customerId, this.patientId)
                              .then((value) => {
                                 this.patient = value;
                              })
                              .catch((err) => {
                                 console.log(err);
                              }));
            
            Promise.allSettled(promises).then(() => {
               this.rowVersion = this.patient.rowVersion;
               Object.assign(this.initialPatient, this.patient);
               this.loading = false;
            });
         },
         async edit() {
            let startEdit = true;
            const patient = await new PatientService().get(this.customerId, this.patient.patientId);
            if (this.rowVersion !== patient.rowVersion) {
               await this.$dialog.info({
                                             text: "This Patient has already been updated by another user. Please close and re-open this Patient's record.",
                                             title: "Already modified by another user",
                                             actions: ['Okay']
                                          });
               
               startEdit = false;
            }
            
            const res = await new EntityLockService().post(this.customerId, this.$store.state.achillesUser.userId,
                                                           this.patient.patientId, 1, "PatientDemographics");
            if (res.lockedByAnotherUser) {
               await this.$dialog.info({
                                             text: "This Patient is being edited by another user. Please wait or contact " +
                                                   res.lockedByUserFullName + " to access the record.",
                                             title: "Locked for edit by another user",
                                             actions: ['Okay']
                                          });
               
               startEdit = false;
            }
            
            this.isEditMode = startEdit;
         },
         async save() {

            if (this.$refs.form.validate()) {
               try {
                  if (!_isEqual(this.patient, this.initialPatient)) {
                     this.loading = true;
                     this.patient.updatedByUserId = this.$store.state.achillesUser.userId;
                     await new PatientService().put(this.customerId, this.patient);

                     let patient = await new PatientService().get(this.customerId, this.patient.patientId);
                     this.rowVersion = patient.rowVersion;
                     Shared.showSaveSuccessAlert(this.alertRef);
                     this.$emit("changed");
                     Object.assign(this.initialPatient, patient);
                     this.patient = patient;
                  }
               }
               catch (ex) {
                  Shared.showSaveFailedAlert(this.alertRef);
                  await this.cancel();
                  console.log(ex);
               }
               finally {
                  await new EntityLockService().remove(this.customerId, this.$store.state.achillesUser.userId,
                                                       this.patient.patientId, 1, "PatientDemographics");
                  this.isEditMode = false;
                  this.loading = false;
               }
            }
         },
         async cancel() {
            this.$refs.form.resetValidation();
            Object.assign(this.patient, this.initialPatient);
            await new EntityLockService().remove(this.customerId, this.$store.state.achillesUser.userId,
                                                 this.patient.patientId, 1, "PatientDemographics");
            this.isEditMode = false;
         },
         closeConfigDialog(val){
            this.configDialog = val;
         }
      },
      apollo: {
         patientLookupValues: {
            query: GetPatientLookupValues,
            variables() {
               return {
                  customerId: this.customerId
               };
            },
            update(data) {
               this.audioImpairment = data.customer.audioImpairment.data,
               this.languagePreferences = data.customer.languagePreferences.data,
               this.timeOfDayPreferences = data.customer.timeOfDayPreferences.data
            }
         },
         patientUpdatedInfo: {
            query: GetPatientUpdatedInfo,
            variables() {
               return {
                  customerId: this.customerId,
                  patientId: this.patientId
               };
            },
            update(data) {
               this.updatedInfo = data.customer.patient.updatedInfo;
            }
         }
      }
   };
</script>

<style scoped>
   fieldset {
      border: 2px solid transparent;
      /* border-top-color: black;
      border-bottom-color: black;
      border-left-color: black; */
      border-color: rgb(192, 192, 192);
      box-sizing: border-box; 
      grid-area: 1 / 1; /* first row, first column */
      padding: 20px; 
      width: inherit; 
   }

   fieldset i[class*="v-icon"] {
     padding-right:10px;
   }

   legend {
      margin: auto; /* to center */
      padding: 0px 4px;
      margin-left: -10px;
   }

   .patient-updated-info {
      height: 20px;
      line-height: 20px;
      letter-spacing: normal;
      font-size: 16px;
   }

   .patient-updated-info-caption
   {
      font-size: 12px;
   }

   .patient-updated-info-patient-caption {
      margin-right: 10px;
   }

   .orient-right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
   }
</style>