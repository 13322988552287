import axios, { AxiosStatic } from "axios";
import store from '../store';

export class BaseService {
   public api: AxiosStatic;  
   constructor() {
      axios.defaults.headers.common =  {'Authorization': `Bearer ${store.getters.getGUserProfile.token}`,
      'Content-Type': 'application/json' };      
      axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;      
      axios.interceptors.response.use(response => {
         return response;
      }, error => {
        if (error.response.status === 401) {         
         store.commit('setSignedIn',false);
         store.dispatch('goHome');         
        }
        throw error;
      });      
      this.api = axios;            
   }   
  }

  export interface DataOptions
  {
        Page: number;
        ItemsPerPage: number;
        SortBy: string;
        SortDesc: boolean;
        Search: string;
  }

  export interface FlexDataOptions
  {
        Page: number;
        ItemsPerPage: number;
        SortBy: string;
        SortDesc: boolean;
        Search: string;
        ForUserId: number;
        ViewFlex: boolean;
        ViewEscalation: boolean;
        PodIds: number[];
  }
  
  export interface PagedData<T>
  {
      Items: T[];
      TotalCount: number;
  }