<template>
  <div>
    <div v-if="!loading && !this.initialLoad">
      <div class="row">
        <v-col md="4" sm="6" >
          <v-select
              :items="podSelections"
              item-text="name"
              item-value="podId"
              label="Select Pod"
              prepend-icon="mdi-select-group"
              :menu-props="{ top: false, offsetY: true }"
              v-model="podSelection"
              solo
              dense>

          </v-select>
        </v-col>
        <v-col>
          <v-badge
              bordered
              :color="patientsWithoutPodsCount === 0 ? 'success' : 'error'"
              :content="patientsWithoutPodsCount ? patientsWithoutPodsCount.toString() : '-'"
              overlap>
            <v-btn class="float-right" @click="openPendingAssignments" depressed>
              Pod Assignment Pending
            </v-btn>
          </v-badge>
        </v-col>
        <v-col>
          <v-btn class="float-right " @click="refreshData" depressed :disabled="refreshDataJobInitiated">
            <span v-if="!refreshDataJobInitiated">Refresh</span>
            <span v-if="refreshDataJobInitiated">Refresh Queued</span>
          </v-btn>
        </v-col>
      </div>
      <v-row>
        <v-col sm="12" md="6">
          <v-card>
            <v-card-title class="align-center justify-center">Worklist Items By Type</v-card-title>
            <v-card-subtitle>
              
              <v-checkbox class="float-right mr-3" label="My Assignments" v-model="myWorklistItems"
                          v-if="showWorklistMyAssignments"></v-checkbox>
            </v-card-subtitle>
            <v-card-text>
              <worklist-type-chart class="mt-8"
                                   :chart-data="worklistTypeChartData"
                                   @clicked="onWorklistTypeChartClick">
              </worklist-type-chart>
              <span class="float-left mt-5">Last Updated: {{this.GetWorklistTypeChartDataLastUpdated}}</span>
            </v-card-text>
          </v-card>

        </v-col>
        <v-col v-if="escalationsChartEnabled && hasEscalationsWorklistAccess" sm="12" md="6">
            <v-card>
              <v-card-title class="align-center justify-center">Escalation Items By Type</v-card-title>
              <v-card-subtitle>
                <v-checkbox class="float-right mr-3" label="My Assignments" v-model="myEscalationItems"
                            v-if="showEscalationMyAssignments"></v-checkbox>
              </v-card-subtitle>
              <v-card-text>
                <!-- Escalations Items Chart -->
                <escalations-items-chart
                  class="mt-8"
                  :chart-data="escalationItemsChartData"
                  @clicked="onEscalationsItemsChartClick">
                </escalations-items-chart>
                <span class="float-left mt-5">Last Updated: {{this.GetEscalationItemsChartDataLastUpdated}}</span>
              </v-card-text>
            </v-card>

          </v-col>
        <v-col sm="12" md="6">
          <v-card>
            <v-card-title class="align-center justify-center">Patients By Last Called</v-card-title>
            <v-card-subtitle>
              <v-checkbox class="float-right mr-3" label="My Primary Patients" v-model="myPrimaryPatients"
                          v-if="showPatientsMyAssignments"></v-checkbox>
            </v-card-subtitle>
            <v-card-text>
              <patient-by-last-called-chart class="mt-8"
                                            :chart-data="patientByLastCalledChartData"
                                            @clicked="onPatientByLastCalledChartClick">
              </patient-by-last-called-chart>
              <span class="float-left mt-5">Last Updated: {{this.GetPatientByLastCalledChartDataLastUpdated}}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-if="loading || this.initialLoad">
      <v-progress-linear indeterminate rounded height="6"></v-progress-linear>
    </div>
    <patients-by-worklist-dialog v-if="showPatientsByWorklistDialog"
                                 :primary-nurse-user-id="patientFilter.userId"
                                 :visible="showPatientsByWorklistDialog"
                                 @close="showPatientsByWorklistDialog = false"
                                 @clicked="onPatientsByWorklistDialogClick"
                                 :not-called-since-bucket="patientFilter.notCalledSinceBucket"
                                 :pod-id="patientFilter.podId"
                                 :pod-ids="patientFilter.podId"
                                 :assignedToUserPodIds="patientFilter.assignedToUserPodIds">
    </patients-by-worklist-dialog>
    <patient-dialog
        v-if="showPatientDetailsDialog"
        :visible="showPatientDetailsDialog"
        @close="showPatientDetailsDialog = false"
        :customer-id="selectedLastNotCalled.customerId"
        :customer-name="selectedLastNotCalled.customerName"
        :patient-id="selectedLastNotCalled.patientId">
    </patient-dialog>
    <worklist-type-dialog v-if="showWorklistTypeDialog"
                          :assignedToUserPodIds="worklistTypeFilter.assignedToUserPodIds"
                          :podId="worklistTypeFilter.podId"
                          :type="worklistTypeFilter.type"
                          :user-id="worklistTypeFilter.userId"
                          :pod-ids="worklistTypeFilter.podIds"
                          :title="worklistTypeDialogTitle"
                          :visible="showWorklistTypeDialog"
                          @close="showWorklistTypeDialog = false">
    </worklist-type-dialog>
    <escalations-type-dialog v-if="escalationsChartEnabled && showEscalationsTypeDialog"
                               :assignedToUserPodIds="escalationsTypeFilter.assignedToUserPodIds"
                               :podId="escalationsTypeFilter.podId"
                               :type="escalationsTypeFilter.type"
                               :user-id="escalationsTypeFilter.userId"
                               :pod-ids="escalationsTypeFilter.podIds"
                               :title="escalationsTypeDialogTitle"
                               :visible="showEscalationsTypeDialog"
                               @close="showEscalationsTypeDialog = false">
      </escalations-type-dialog>

  </div>
</template>
<script>

import PatientByLastCalledChart from "@/components/Dashboards/NurseCareManager/Charts/PatientByLastCalledChart";
import WorklistTypeChart from "@/components/Dashboards/NurseCareManager/Charts/WorklistTypeChart";
import EscalationsTypeDialog from "@/components/Dashboards/NurseCareManager/Dialogs/EscalationsTypeDialog.vue";
import PatientsByWorklistDialog from "@/components/Dashboards/NurseCareManager/Dialogs/PatientsByWorklistDialog";
import WorklistTypeDialog from "@/components/Dashboards/NurseCareManager/Dialogs/WorklistTypeDialog";
import Pods from "@/components/Dashboards/NurseCareManager/queries/pods.graphql";
import PatientDialog from "@/components/Patient/PatientDialog";
import { PodsSummaryTypeEnum } from "@/types/enum";
import EscalationsItemsChart from "./Charts/EscalationsItemsChart.vue";
import GetEscalationTypeByAssignedToUserId from "./queries/escalationTypeByAssignedToUserId.graphql";
import GetNotCalledSinceBucketByPrimaryNurseUserId from "./queries/notCalledSinceBucketByPrimaryNurseUserId.graphql";
import GetPatientsWithoutPods from "./queries/patientswithoutpods.graphql";
import GetWorklistTypesByAssignedToUserId from "./queries/worklistTypesByAssignedToUserId.graphql";

import moment from "moment/moment";
import HangFireJobService from "@/services/HangFireJobService";

export default {
  name: "NurseCareManagerDashboard",
  components: {
    WorklistTypeDialog,
    PatientsByWorklistDialog,
    PatientByLastCalledChart,
    WorklistTypeChart,
    PatientDialog,
    EscalationsItemsChart,
    EscalationsTypeDialog
},
  data: () => ({
    myWorklistItems: false,
    myPrimaryPatients: false,
    myEscalationItems: false,
    notCalledSinceBucket: null,
    loading: false,
    worklist: null,
    worklistTypesByAssignedToUserId: [],
    notCalledSinceBucketByPrimaryNurseUserId: [],
    escalationTypeByAssignedToUserId:[],
    patientsWithoutPodsCount: 0,
    pods: [],
    podSelection: null,
    selectedLastNotCalled: null,
    showPatientDetailsDialog: false,
    showPatientsByWorklistDialog: false,
    showWorklistTypeDialog: false,
    showEscalationsTypeDialog: false,
    skipWorklistTypesByAssignedToUserIdQuery: true,
    skipNotCalledSinceBucketByPrimaryNurseUserIdQuery: true,
    skipEscalationTypeByAssignedToUserIdQuery: true,
    skipPodsQuery: true,
    skipPatientsWithoutPodsQuery: true,
    initialLoad: true,
    worklistTypeDialogTitle: null,
    escalationsTypeDialogTitle: null,
    escalationsChartFeat: process.env.VUE_APP_NURSE_CARE_ESCALATIONS_CHART,
    worklistTypeFilter: {
      podId: null,
      podIds: [],
      type: null,
      userId: null
    },
    patientFilter: {
      podId: null,
      podIds: [],
      notCalledSinceBucket: null,
      userId: null
    },
    escalationsTypeFilter: {
      podId: null,
      podIds: [],
      escalationType: null,
      userId: null
    },
    allPods: {name: "All Pods", podId: 0},
    refreshDataJobInitiated: false
  }),
  computed: {
    selectedPod() {
      return !this.podSelection || this.podSelection.podId === 0 ? null : this.podSelection;
    },
    worklistAssignedToUserId() {
      return this.$store.state.achillesUser.userId;
    },
    worklistTypesByAssignedToUserIdItems() {
      return this.worklistTypesByAssignedToUserId ? this.worklistTypesByAssignedToUserId.data : [];
    },
    notCalledSinceBucketByPrimaryNurseUserIdItems() {
      return this.notCalledSinceBucketByPrimaryNurseUserId ? this.notCalledSinceBucketByPrimaryNurseUserId.data : [];
    },
    escalationTypeByAssignedToUserIdItems() {
      return this.escalationTypeByAssignedToUserId ? this.escalationTypeByAssignedToUserId.data : [];
    },
    podSelections() {
      const sortedPods = this.pods.data.slice().sort((a, b) => a.name.localeCompare(b.name));
      const allAssignedPods = { name: "All Assigned Pods", podId: -1 }; 
      const allOptions = [allAssignedPods, ...sortedPods];

      return [this.allPods, ...allOptions];
    },
    podItems() {
      return this.pods ? this.pods.data : [];
    },
    worklistTypeChartData() {
      if (!this.worklistTypesByAssignedToUserIdItems) return [];
      const allAssignedPods = -1;
      const isPodSelected = this.selectedPod && this.selectedPod !== allAssignedPods;
      const isNoPodSelected = !this.selectedPod || this.selectedPod === allAssignedPods;
      const hasMyWorklistItems = this.myWorklistItems;
      const noMyWorklistItems = !this.myWorklistItems;

      let summaryType = PodsSummaryTypeEnum.NoSelectedPodAndNoPrimaryPatients;

      if (isPodSelected && hasMyWorklistItems) {
        summaryType = PodsSummaryTypeEnum.SelectedPodAndPrimaryPatients;
      } else if (isNoPodSelected && hasMyWorklistItems) {
        summaryType = PodsSummaryTypeEnum.NoSelectedPodAndPrimaryPatients;
      } else if (isPodSelected && noMyWorklistItems) {
        summaryType = PodsSummaryTypeEnum.SelectedPodAndNoPrimaryPatients;
      } else if (this.selectedPod === allAssignedPods && noMyWorklistItems) {
        summaryType = PodsSummaryTypeEnum.SelectedAllAssignedPods;
      }

      const assignedToUserPods = this.podItemsAssignedToUser(this.worklistAssignedToUserId);

      const filterBySummaryType = w => w.summaryType === summaryType || (summaryType === PodsSummaryTypeEnum.SelectedAllAssignedPods 
                                                         && w.summaryType === PodsSummaryTypeEnum.SelectedPodAndNoPrimaryPatients);
      const filterBySelectedPod = w => this.selectedPod !== allAssignedPods ? w.podId === this.selectedPod : true;
      const filterByAssignedToUserId = w => w.assignedToUserId === this.worklistAssignedToUserId || !this.myWorklistItems;
      const filterByAssignedToUserPods = w => !(summaryType === PodsSummaryTypeEnum.SelectedAllAssignedPods) 
                                              || assignedToUserPods.some(a => a.podId === w.podId);

      const filteredWorklistItems = this.worklistTypesByAssignedToUserIdItems
                                    .filter(filterBySummaryType)
                                    .filter(filterBySelectedPod)
                                    .filter(filterByAssignedToUserId)
                                    .filter(filterByAssignedToUserPods);

      const resultCountByType = filteredWorklistItems.reduce((accumulator, currentItem) => {
        const { type, resultCount } = currentItem;
        accumulator[type] = accumulator[type] || { value: 0, name: type };
        accumulator[type].value += resultCount;
        return accumulator;
      }, {});

      const chartData = Object.values(resultCountByType);
      return chartData;
    },

    hasEscalationsWorklistAccess() {
      let access;
      if (this.$store.state.achillesUser.userRoles) {
        access = this.$store.state.achillesUser.userRoles.find((r) =>
          r.role.roleAccesses ?
            r.role.roleAccesses.some(
              ra => ra.access.name ===
                "EscalationsWorklistAccess") : false
        );
      }
      return !!access;
    },
    
    escalationItemsChartData() {
      if (!this.escalationTypeByAssignedToUserIdItems) return [];
      const allAssignedPods = -1;
      const isPodSelected = this.selectedPod && this.selectedPod !== allAssignedPods;
      const isNoPodSelected = !this.selectedPod || this.selectedPod === allAssignedPods;
      const hasMyEscalationItems = this.myEscalationItems;
      const noMyEscalationItems = !this.myEscalationItems;

      let summaryType = PodsSummaryTypeEnum.NoSelectedPodAndNoPrimaryPatients;

      if (isPodSelected && hasMyEscalationItems) {
        summaryType = PodsSummaryTypeEnum.SelectedPodAndPrimaryPatients;
      } else if (isNoPodSelected && hasMyEscalationItems) {
        summaryType = PodsSummaryTypeEnum.NoSelectedPodAndPrimaryPatients;
      } else if (isPodSelected && noMyEscalationItems) {
        summaryType = PodsSummaryTypeEnum.SelectedPodAndNoPrimaryPatients;
      } else if (this.selectedPod === allAssignedPods && noMyEscalationItems) {
        summaryType = PodsSummaryTypeEnum.SelectedAllAssignedPods;
      }

      const assignedToUserPods = this.podItemsAssignedToUser(this.worklistAssignedToUserId);

      const filterBySummaryType = w => w.summaryType === summaryType || (summaryType === PodsSummaryTypeEnum.SelectedAllAssignedPods 
                                                         && w.summaryType === PodsSummaryTypeEnum.SelectedPodAndNoPrimaryPatients);
      const filterBySelectedPod = w => this.selectedPod !== allAssignedPods ? w.podId === this.selectedPod : true;
      const filterByAssignedToUserId = w => w.assignedToUserId === this.worklistAssignedToUserId || !this.myEscalationItems;
      const filterByAssignedToUserPods = w => !(summaryType === PodsSummaryTypeEnum.SelectedAllAssignedPods) 
                                              || assignedToUserPods.some(a => a.podId === w.podId);

      const filteredEscalationItems = this.escalationTypeByAssignedToUserIdItems
                                    .filter(filterBySummaryType)
                                    .filter(filterBySelectedPod)
                                    .filter(filterByAssignedToUserId)
                                    .filter(filterByAssignedToUserPods);

      const resultCountByType = filteredEscalationItems.reduce((accumulator, currentItem) => {
        const { escalationType, resultCount } = currentItem;
        accumulator[escalationType] = accumulator[escalationType] || { value: 0, name: escalationType };
        accumulator[escalationType].value += resultCount;
        return accumulator;
      }, {});

      const chartData = Object.values(resultCountByType);
      return chartData;
    },
    GetPatientByLastCalledChartDataLastUpdated(){
      return this.notCalledSinceBucketByPrimaryNurseUserIdItems && this.notCalledSinceBucketByPrimaryNurseUserIdItems.length > 0  ? moment(this.notCalledSinceBucketByPrimaryNurseUserIdItems[0].lastUpdated).local().format('MM/DD/YYYY hh:mm A') : null;
    },
    GetEscalationItemsChartDataLastUpdated(){
      return this.escalationTypeByAssignedToUserIdItems && this.escalationTypeByAssignedToUserIdItems.length > 0 ? moment(this.escalationTypeByAssignedToUserIdItems[0].lastUpdated).local().format('MM/DD/YYYY hh:mm A') : null;
    },
    GetWorklistTypeChartDataLastUpdated() {
      return this.worklistTypesByAssignedToUserIdItems && this.worklistTypesByAssignedToUserIdItems.length > 0 ? moment(this.worklistTypesByAssignedToUserIdItems[0].lastUpdated).local().format('MM/DD/YYYY hh:mm A') : null;
    },
    patientByLastCalledChartData() {
      if (!this.notCalledSinceBucketByPrimaryNurseUserIdItems) return [];

      const allAssignedPods = -1;
      const isPodSelected = this.selectedPod && this.selectedPod !== allAssignedPods;
      const isNoPodSelected = !this.selectedPod || this.selectedPod === allAssignedPods;
      const hasMyPrimaryPatients = this.myPrimaryPatients;
      const noMyPrimaryPatients = !this.myPrimaryPatients;

      let summaryType = PodsSummaryTypeEnum.NoSelectedPodAndNoPrimaryPatients;

      if (isPodSelected && hasMyPrimaryPatients) {
        summaryType = PodsSummaryTypeEnum.SelectedPodAndPrimaryPatients;
      } else if (isNoPodSelected && hasMyPrimaryPatients) {
        summaryType = PodsSummaryTypeEnum.NoSelectedPodAndPrimaryPatients;
      } else if (isPodSelected && noMyPrimaryPatients) {
        summaryType = PodsSummaryTypeEnum.SelectedPodAndNoPrimaryPatients;
      } else if (this.selectedPod === -1 && noMyPrimaryPatients) {
        summaryType = PodsSummaryTypeEnum.SelectedAllAssignedPods;
      }
     
      const assignedToUserPods = this.podItemsAssignedToUser(this.worklistAssignedToUserId);

      const filterBySummaryType = p=> p.summaryType === summaryType || (summaryType === PodsSummaryTypeEnum.SelectedAllAssignedPods 
                                                         && p.summaryType === PodsSummaryTypeEnum.SelectedPodAndNoPrimaryPatients);
      const filterBySelectedPod = p => this.selectedPod !== allAssignedPods ? p.podId === this.selectedPod : true;
      const filterByAssignedToUserId = p => p.primaryNurseUserId === this.worklistAssignedToUserId || !this.myPrimaryPatients;
      const filterByAssignedToUserPods = p => !(summaryType === PodsSummaryTypeEnum.SelectedAllAssignedPods) || assignedToUserPods.some(a => a.podId === p.podId);

      const filteredPrimaryPatientItems = this.notCalledSinceBucketByPrimaryNurseUserIdItems
                                        .filter(filterBySummaryType)
                                        .filter(filterBySelectedPod)
                                        .filter(filterByAssignedToUserId)
                                        .filter(filterByAssignedToUserPods);

      const resultCountByType = filteredPrimaryPatientItems.reduce((accumulator, currentItem) => {
      const { notCalledSinceBucket, resultCount } = currentItem;
      accumulator[notCalledSinceBucket] = accumulator[notCalledSinceBucket] || { value: 0, name: notCalledSinceBucket };
      accumulator[notCalledSinceBucket].value += resultCount;
      return accumulator;
      }, {});

      const chartData = Object.values(resultCountByType);
      return chartData;
    },
    escalationsChartEnabled() {
      return this.escalationsChartFeat === 'enabled'
    },
    showWorklistMyAssignments() {
      return true;
    },
    showPatientsMyAssignments() {
      return true;
    },
    showEscalationMyAssignments() {
      return true;
    }
  },
  methods: {
    onWorklistTypeChartClick(e) {
      this.worklistTypeFilter.type = e.data.name;
      const allAssignedPods = -1;

      if (this.selectedPod === allAssignedPods) {
        const assignedToUserPods = this.podItemsAssignedToUser(this.worklistAssignedToUserId);

        if (assignedToUserPods && assignedToUserPods.length > 0) {
          this.worklistTypeFilter.podId = null;
          this.worklistTypeFilter.podIds = [];
          this.worklistTypeFilter.assignedToUserPodIds = assignedToUserPods.map(p => p.podId);
        }
      } else  if (this.selectedPod && this.selectedPod !== allAssignedPods){
        this.worklistTypeFilter.podId = this.selectedPod;
        this.worklistTypeFilter.podIds = [];
        this.worklistTypeFilter.assignedToUserPodIds = null;
      }else {
        this.worklistTypeFilter.podIds = this.podItems.map(p => p.podId);
        this.worklistTypeFilter.podId = null;
        this.worklistTypeFilter.assignedToUserPodIds = null;
      }

      this.worklistTypeFilter.userId = this.myWorklistItems ? this.worklistAssignedToUserId : null;

      this.worklistTypeDialogTitle = e.data.name;
      this.showWorklistTypeDialog = true;
    },
    onEscalationsItemsChartClick(e) {
      this.escalationsTypeFilter.type = e.data.name;
      const allAssignedPods = -1;

      if (this.selectedPod === allAssignedPods) {
        const assignedToUserPods = this.podItemsAssignedToUser(this.worklistAssignedToUserId);

        if (assignedToUserPods && assignedToUserPods.length > 0) {
          this.escalationsTypeFilter.podId = null;
          this.escalationsTypeFilter.podIds = [];
          this.escalationsTypeFilter.assignedToUserPodIds = assignedToUserPods.map(p => p.podId);
        }
      } else  if (this.selectedPod && this.selectedPod !== allAssignedPods){
        this.escalationsTypeFilter.podId = this.selectedPod;
        this.escalationsTypeFilter.podIds = [];
        this.escalationsTypeFilter.assignedToUserPodIds = null;
      }else {
        this.escalationsTypeFilter.podIds = this.podItems.map(p => p.podId);
        this.escalationsTypeFilter.podId = null;
        this.escalationsTypeFilter.assignedToUserPodIds = null;
      }

      this.escalationsTypeFilter.userId = this.myEscalationItems ? this.worklistAssignedToUserId : null;

      this.escalationsTypeDialogTitle = e.data.name;
      this.showEscalationsTypeDialog = true;
    },
    onPatientByLastCalledChartClick(e) {
      this.patientFilter.notCalledSinceBucket = e.data.name;
      const allAssignedPods = -1;

      if (this.selectedPod === allAssignedPods) {
        const assignedToUserPods = this.podItemsAssignedToUser(this.worklistAssignedToUserId);

        if (assignedToUserPods && assignedToUserPods.length > 0) {
          this.patientFilter.podIds = [];
          this.patientFilter.assignedToUserPodIds = assignedToUserPods.map(p => p.podId);
        }
      } else  if (this.selectedPod && this.selectedPod !== allAssignedPods){
        this.patientFilter.podId = this.selectedPod;
        this.patientFilter.podIds = [];
        this.patientFilter.assignedToUserPodIds = null;
      }else {
        this.patientFilter.podIds = this.podItems.map(p => p.podId);
        this.patientFilter.podId = null;
        this.patientFilter.assignedToUserPodIds = null;
      }
      this.patientFilter.userId = this.myPrimaryPatients ? this.worklistAssignedToUserId : null;
      this.showPatientsByWorklistDialog = true;
    },
    async triggerQuery() {
      if (!this.initialLoad) {
        return;
      }

      this.$apollo.queries.worklistTypesByAssignedToUserId.skip = false;
      this.$apollo.queries.notCalledSinceBucketByPrimaryNurseUserId.skip = false;
      this.$apollo.queries.escalationTypeByAssignedToUserId.skip = false;
      this.$apollo.queries.pods.skip = false;
      this.$apollo.queries.patientsWithoutPodsCount.skip = false;
      
      const refreshPromises = [
        this.$apollo.queries.worklistTypesByAssignedToUserId.refetch(),
        this.$apollo.queries.notCalledSinceBucketByPrimaryNurseUserId.refetch(),
        this.$apollo.queries.escalationTypeByAssignedToUserId.refetch(),
        this.$apollo.queries.pods.refetch(),
        this.$apollo.queries.patientsWithoutPodsCount.refetch()
      ];
      
      await Promise.all(refreshPromises);

      this.podSelection = this.allPods;
      this.initialLoad = false;
    },
    onPatientsByWorklistDialogClick(e) {
      this.selectedLastNotCalled = e;
      this.showPatientDetailsDialog = true;
    },
    openPendingAssignments() {
      this.$router.push({name: "PodsAssignmentsPending"});
    },
    async refreshData() {
      this.refreshDataJobInitiated = true;
      
      const refreshPromises = [
        new HangFireJobService().enqueueRefreshNotCalledSinceBucketByPrimaryNurseUserId(),
        this.$apollo.queries.worklistTypesByAssignedToUserId.refetch(),
        this.$apollo.queries.notCalledSinceBucketByPrimaryNurseUserId.refetch(),
        this.$apollo.queries.escalationTypeByAssignedToUserId.refetch(),
        this.$apollo.queries.pods.refetch(),
        this.$apollo.queries.patientsWithoutPodsCount.refetch()
      ];
      
      await Promise.all(refreshPromises);

      this.refreshDataJobInitiated = false;
    },
    podItemsAssignedToUser(userId) {
     if (this.pods && this.pods.data) {
       // Filter pods based on assignments to the specified userId
       return this.pods.data.filter(podItem => {
         if (podItem.assignments && podItem.assignments.data) {

           return podItem.assignments.data.some(assignment => assignment.user.userId === userId);
          }
         return false;
       });
     } else {
       return [];
     }
   },
  },
  created() {
    this.triggerQuery();
  },
  apollo: {
    patientsWithoutPodsCount: {
      query: GetPatientsWithoutPods,
      variables() {
        return {
          input: {
            take: 100,
            skip: 0
          }
        };
      },
      update: data => data.patientsWithoutPod.count,
      skip() {
        return this.skipPatientsWithoutPodsQuery;
      }
    },
    worklistTypesByAssignedToUserId: {
      query: GetWorklistTypesByAssignedToUserId,
      variables() {
        return {
          input: {
            take: 100,
            skip: 0,
            assignedToUserId: this.$store.state.achillesUser.userId
          }
        };
      },
      update: data => data.worklistTypesByAssignedToUserId,
      skip() {
        return this.skipWorklistTypesByAssignedToUserIdQuery;
      }
    },
    notCalledSinceBucketByPrimaryNurseUserId: {
      query: GetNotCalledSinceBucketByPrimaryNurseUserId,
      variables() {
        return {
          input: {
            take: 100,
            skip: 0
          },
          primaryNurseUserId: this.$store.state.achillesUser.userId
        };
      },
      update: data => data.notCalledSinceBucketByPrimaryNurseUserId,
      skip() {
        return this.skipNotCalledSinceBucketByPrimaryNurseUserIdQuery;
      }
    },
    escalationTypeByAssignedToUserId: {
      query: GetEscalationTypeByAssignedToUserId,
      variables() {
        return {
          input: {
            take: 100,
            skip: 0,
            assignedToUserId: this.$store.state.achillesUser.userId
          }
        };
      },
      update: data => data.escalationTypeByAssignedToUserId,
      skip() {
        return this.skipEscalationTypeByAssignedToUserIdQuery;
      }
    },
    pods: {
      query: Pods,
      variables() {
        return {
          input: {
            take: 100,
            skip: 0,
            active: true
          },
          podAssignmentInput: {
            take: 100,
            skip: 0,
            userId: this.$store.state.achillesUser.userId
          }
        };
      },
      update: data => data.pods,
      skip() {
        return this.skipPodsQuery;
      }
    }
  },
}
</script>