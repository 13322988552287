import * as BaseService from './BaseService';
import * as customer from '../types/customer';

export default class PatientPrescriptionService extends BaseService.BaseService {
   private url = '/api/PatientPrescription';
      
   constructor() {
     super();     
   }
  
    async get(customerId: number, patientId: number): Promise<customer.Prescription>{
      let url = this.url + `/${customerId}/${patientId}`;            
      let response = (await this.api.get(url)).data as customer.Prescription;          
      return response;
    }

  }