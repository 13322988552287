<template>
   <v-dialog v-model="isEditMode" width="800" persistent v-if="isEditMode">
      <v-card>
         <v-card-title class="headline">Edit Contact</v-card-title>
         <v-card-text>
            <v-container>
               <v-form v-model="editFormValid" lazy-validation>
                  <v-row v-for="phone in phonesToEdit" :key="phone.entityPhoneId">
                     <v-col md="6" v-if="phones.length > 0">
                        <v-text-field v-model="phone.phoneNumber" label="Phone Number"
                                      required prepend-icon="mdi-phone" v-mask="'###-###-####'"
                                      :rules="$data.phoneNumberRules">
                        </v-text-field>
                     </v-col>
                     <v-col md="6">
                        <v-text-field v-model="phone.note" label="Phone Label"
                                      required :rules="$data.phoneLabelRules">
                        </v-text-field>
                     </v-col>
                  </v-row>
                  <v-row v-for="email in emailsToEdit" :key="email.entityEmailId">
                     <v-col md="6" v-if="emails.length > 0">
                        <v-text-field v-model="email.emailAddress" label="Email" required prepend-icon="mdi-email"
                                      :rules="$data.emailRules">
                        </v-text-field>
                     </v-col>
                  </v-row>
                  <v-row v-for="address in addressesToEdit" :key="address.entityAddressId">
                     <v-col md="12" v-if="addresses.length > 0">
                        <v-row>
                           <v-col md="12">
                              <v-text-field prepend-icon="mdi-map-marker"
                                            ref="autocomplete"
                                            placeholder=""
                                            label="Search New Address" type="search">
                              </v-text-field>
                           </v-col>
                        </v-row>
                        <v-row>
                           <v-col md="12">
                              Current Address: <a :href="googleMapsDirectionUrl">
                              {{address.addressLine1}}{{address.addressLine2}}, {{address.city}},
                              {{stateName(address.stateId)}} {{address.zipCode}} </a>
                           </v-col>
                        </v-row>
                        <v-row>
                           <v-col md="6">
                              <v-text-field v-model="address.addressLine1"
                                            label="Address Line 1" required :rules="$data.addressLine1Rules"
                                            prepend-icon="mdi-map-marker">
                              </v-text-field>
                           </v-col>
                           <v-col md="6">
                              <v-text-field v-model="address.addressLine2"
                                            label="Address Line 2" required
                                            prepend-icon="mdi-map-marker">
                              </v-text-field>
                           </v-col>
                        
                        </v-row>
                        <v-row>
                           <v-col md="6">
                              <v-text-field v-model="address.city"
                                            label="City" :rules="$data.cityRules" required
                                            prepend-icon="mdi-map-marker">
                              </v-text-field>
                           </v-col>
                           <v-col md="6">
                              <v-select :items="states" :rules="$data.stateRules"
                                        item-text="description" item-value="stateId"
                                        v-model="address.stateId" label="State" required
                                        prepend-icon="mdi-map-marker">
                              </v-select>
                           </v-col>
                           <v-col md="6">
                              <v-text-field v-model="address.zipCode"
                                            label="Zip Code" required prepend-icon="mdi-map-marker"
                                            v-mask="'#####'" :rules="$data.zipCodeRules">
                              </v-text-field>
                           </v-col>
                        </v-row>
                     </v-col>
                  </v-row>
               </v-form>
            </v-container>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancel">Cancel</v-btn>
            <v-btn text @click="save">Save</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
   import {contactDetailsUtils} from "@/mixins/contactDetailsUtils";
   import {UpdateEntityPhones, UpdateEntityAddresses, UpdateEntityEmails} from "./mutations/update-contact.graphql";
   
   export default {
      name: "EditContact",
      props: {
         customerId: Number,
         entityId: Number,
         isEditMode: Boolean,
         entityTypeId: Number,
         phones: Array,
         emails: Array,
         addresses: Array
      },
      mixins: [contactDetailsUtils],
      data: () => ({
         editFormValid: false,
         emailsToEdit: [],
         phonesToEdit: [],
         addressesToEdit: [],
      }),
      mounted() {
         this.emailsToEdit = JSON.parse(JSON.stringify(this.emails));
         this.phonesToEdit = JSON.parse(JSON.stringify(this.phones));
         this.addressesToEdit = JSON.parse(JSON.stringify(this.addresses));
      
         if (this.addressesToEdit.length > 0) {
            this.initMaps();
         }
      
         this.initAutocomplete();
      },
      computed: {
         googleMapsDirectionUrl() {
            if (this.addressesToEdit.length === 0) {
               return "";
            }
            
            let address = this.addressesToEdit[0];
            if (typeof address === "undefined" || !address) {
               return "";
            }
            
            let url = `https://www.google.com/maps?saddr=My+Location&daddr=
            ${address.addressLine1},${address.city},${this.stateName(address.stateId)}
            ${address.zipCode}`;
            
            return url;
         }
      },
      methods: {
         async cancel() {
            this.$emit("canceled");
         },
         async save() {
            if (this.phonesToEdit.length > 0) {
               await this.savePhones();
            }
            
            if (this.addressesToEdit.length > 0) {
               await this.saveAddresses();
            }
            
            if(this.emailsToEdit.length>0){
               await this.saveEmails();
            }
            this.$emit("saved");
         },
         async savePhones() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: UpdateEntityPhones,
                             variables: {
                                input: {
                                   phones: this.phonesToEdit,
                                   customerId: this.customerId,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
                         if (this.addressesToEdit.length === 0 && this.emailsToEdit.length === 0) {
                            return await this.success();
                         }
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         async saveAddresses() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: UpdateEntityAddresses,
                             variables: {
                                input: {
                                   addresses: this.addressesToEdit,
                                   customerId: this.customerId,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
                         if (this.emailsToEdit.length === 0) {
                            return await this.success();
                         }
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         async saveEmails(){
            await this.$apollo
                      .mutate(
                          {
                             mutation: UpdateEntityEmails,
                             variables: {
                                input: {
                                   emails: this.emailsToEdit,
                                   customerId: this.customerId,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         async failure(data) {
            let errorTest = "Unable to update contact information";
            
            if (typeof data.errorCode !== undefined) {
               errorTest += `: ${data.errorCode}`;
            }
            //exception from API
            else {
               console.log(data);
            }
            
            let options = {
               text: errorTest,
               title: "Unable to update contact information",
               actions: ["Okay"]
            };
            await this.$dialog.error(options);
         },
         async success() {
            
            let options = {
               text: "Contact information was updated successfully",
               title: "Saved",
               actions: ["Okay"]
            };
            await this.$dialog.info(options);
         },

         fillInAddress(place) {
            // Get the place details from the autocomplete object.      
            if (typeof place === "undefined") {
               return;
            }
            
            let components = {
               street_number: {
                  result: "",
                  type: "short_name"
               },
               route: {
                  result: "",
                  type: "long_name"
               },
               locality: {
                  result: "",
                  type: "long_name"
               },
               administrative_area_level_1: {
                  result: "",
                  type: "short_name"
               },
               postal_code: {
                  result: "",
                  type: "short_name"
               }
            };
            
            for (let i = 0; i < place.address_components.length; i++) {
               let addressType = place.address_components[i].types[0];
               if (typeof components[addressType] !== "undefined") {
                  components[addressType].result = place.address_components[i][components[addressType].type];
               }
            }
            
            let address = this.addressesToEdit[0];
            address.addressLine1 =
                (components["street_number"].result + " " + components["route"].result).trim();
            address.city = components["locality"].result;
            address.stateId =
                this.stateIdFromShortName(components["administrative_area_level_1"].result);
            address.zipCode = components["postal_code"].result;
            address.addressLine2 = "";
         }
      },

   };
</script>

<style scoped>

</style>