<template>
   <div>
      <v-tooltip top>
         <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="info" @click="onExportClick" :loading="loadingExport"
               :disabled="loadingExport">
               <v-icon dark>mdi-file-download</v-icon>
            </v-btn>
         </template>
         <span>Export Patient Information</span>
      </v-tooltip>
   </div>
</template>

<script>
   //TODO: update logic for Member
   import ExportService from "@/services/PatientExportService";
   
   export default {
      name: "ExportInfoButton",
      props: {
         customerId: Number,
         itemId: Number,
         firstName: String,
         lastName: String,
         designation: String,
         isPatient: Boolean
      },
      data: () => ({
         loadingExport: false
      }),
      methods: {
         async onExportClick() {
            this.loadingExport = true;
            
            if (this.isPatient) {
               await this.exportPatientData();
            } else {
               this.exportMember();
            }
            this.loadingExport = false;
            this.updateExportSnackbar(true);
         },
         async exportPatientData() {
            let text = await new ExportService().get(this.customerId, this.itemId,
                                                     new Date().getTimezoneOffset() / 60);
            this.download(this.firstName
                          + " " + this.lastName
                          + " " + (this.designation ? this.designation : "")
                          + " " + this.getFormattedDate(new Date()) + ".doc", text);
         },
         exportMember() {
            console.log("get member data from graphGL")
         },
         download(filename, text) {
            const preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
            const postHtml = "</body></html>";
            const html = preHtml + text + postHtml;
            
            let element = document.createElement("a");
            element.setAttribute("href", "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html));
            element.setAttribute("download", filename);
            
            element.style.display = "none";
            document.body.appendChild(element);
            
            element.click();
            
            document.body.removeChild(element);
         },
         getFormattedDate(date) {
            let year = date.getFullYear();
            let month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;
            let day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;
            
            return month + "-" + day + "-" + year;
         },
         updateExportSnackbar(val) {
            this.$root.$emit("exportSnackbar", val);
         }
      }
   };
</script>

<style scoped>
.v-btn {
   height: 30px !important;
   min-width: 58px !important;
   padding: 0 12px !important;
}
</style>