var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('div',{staticClass:"headline"},[_vm._v("Worklist Item Cadence")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"configForm"},[(_vm.member !== null)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-checkbox',{attrs:{"label":"Has Requested Do Not Call"},model:{value:(_vm.member.hasRequestedDoNotCall),callback:function ($$v) {_vm.$set(_vm.member, "hasRequestedDoNotCall", $$v)},expression:"member.hasRequestedDoNotCall"}})],1)]}}],null,false,2794296115)},[_c('span',[_vm._v("Will stop all Worklist Items for this Member. Also places a \"DO NOT CALL\" message on the contact card.")])])],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-menu',{ref:"snoozedTillMenu",attrs:{"close-on-content-click":false,"return-value":_vm.memberSnoozedTillFormatted,"min-width":"auto","offset-y":"","transition":"scale-transition"},on:{"update:returnValue":function($event){_vm.memberSnoozedTillFormatted=$event},"update:return-value":function($event){_vm.memberSnoozedTillFormatted=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Snoozed Till","prepend-icon":"mdi-calendar","readonly":""},on:{"blur":function($event){_vm.memberSnoozedTill = _vm.parseDate(_vm.memberSnoozedTillFormatted)}},model:{value:(_vm.memberSnoozedTillFormatted),callback:function ($$v) {_vm.memberSnoozedTillFormatted=$$v},expression:"memberSnoozedTillFormatted"}},'v-text-field',attrs,false),Object.assign({}, on, tooltip)))]}}],null,true)},[_c('span',[_vm._v("Will not create Worklist Items until Snoozed Till date Expires.")])])],1)]}}],null,false,811708021),model:{value:(_vm.snoozedTillMenu),callback:function ($$v) {_vm.snoozedTillMenu=$$v},expression:"snoozedTillMenu"}},[_c('v-date-picker',{attrs:{"min":new Date().toISOString().substring(0, 10),"no-title":"","scrollable":""},model:{value:(_vm.memberSnoozedTill),callback:function ($$v) {_vm.memberSnoozedTill=$$v},expression:"memberSnoozedTill"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.snoozedTillMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$refs.snoozedTillMenu.save(_vm.memberSnoozedTillFormatted)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1):_vm._e()])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onSave}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }