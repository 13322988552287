<template v-slot:top>    
    <v-card :loading="loading || saving" :key="componentKey">
      <v-alert
        :value="alertRef.alert"
        :type="alertRef.alertType"
        dense
        transition="fade-transition"
        >{{ alertRef.alertMessage }}</v-alert
      >
      <v-card-title v-if="isAuthorized && !loading">
        <span class="headline">{{ patientName }}</span>
      </v-card-title>
      <v-card-text>
        <v-container v-if="isAuthorized && !loading">
          <v-row>
            <v-col md="6">
              <v-autocomplete
                v-model="editedItem.assignedToUserId"
                :items="isCareManagerUser?achillesCMUsers:achillesPodiUsers"
                dense
                 filled
                label="Assigned to"
                item-text="email"
                item-value="userId"                
              >
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected">
                    <v-avatar left>
                      <v-img :src="data.item.avatarUrl" lazy-src="../assets/account.png"></v-img>
                    </v-avatar>
                    {{ data.item.email }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar v-if="data.item.avatarUrl != null">
                    <v-img :src="data.item.avatarUrl" lazy-src="../assets/account.png" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.email"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.lastName + ' ' + data.item.firstName"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5" v-if="isClinicalOperationsUser">
              <!-- <v-checkbox
                v-model="editedItem['shippingConsent']"
                label="Shipping Consent Received"
                v-if="
                  editedItem.worklistItemTypeId ==
                    worklistItemType.InitialOutreach ||
                  editedItem.worklistItemTypeId ==
                    worklistItemType.Prequalification
                "
                :readonly="editedItem['shippingConsent']"
              >
              </v-checkbox> -->
              <v-checkbox
                v-model="editedItem['trainingCompleted']"
                label="Training Completed"
                v-if="
                  editedItem.worklistItemTypeId == worklistItemType.Training
                "
                :readonly="editedItem['trainingCompleted']"
              >
              </v-checkbox>
            </v-col>
            <v-col md="5" v-if="isClinicalOperationsUser">
              <v-checkbox
                v-model="editedItem['scanConfirmed']"
                label="Verified First Scan Received"
                v-if="
                  editedItem.worklistItemTypeId == worklistItemType.Training
                "
                :readonly="editedItem['scanConfirmed']"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <!-- commented for US:ST2-I157 -->
            <!-- <v-col md="5" v-if="isClinicalOperationsUser">
              <v-checkbox
                v-model="editedPatient.hasRequestedDoNotCall"
                label="Patient Requested No Calls"
              >
              </v-checkbox>
            </v-col> -->  
            <v-col md="5" v-if="isClinicalOperationsUser">
              <v-checkbox
                v-model="editedItem['patientDeclined']"
                label="Patient Declined"
                v-if="
                  editedItem.worklistItemTypeId ==
                    worklistItemType.InitialOutreach && editedItem.isEscalation
                "
                :readonly="editedItem['patientDeclined']"
              >
              </v-checkbox>
              <!-- commented for US:ST2-I157 -->
              <!-- <v-checkbox
                v-model="editedItem['patientWithdrew']"
                label="Patient Withdrew"
                v-if="
                  editedItem.worklistItemTypeId ==
                    worklistItemType.Escalation || editedItem.isEscalation
                "
                :readonly="editedItem['patientWithdrew']"
              >
              </v-checkbox> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5">
              <v-checkbox
                v-model="editedItem.isEscalation"
                label="Escalate Worklist Item*"
                :readonly="worklistItem.isEscalation"
              >
              </v-checkbox>
              <v-checkbox
                v-model="editedItem['closedItem']"
                label="Close Worklist Item"
              >
              </v-checkbox>
            </v-col>
            <v-col md="5">
              <v-checkbox
                v-model="editedItem['snoozedItem']"
                label="Snooze Patient"
                @change="snoozeChanged"                
              >
              </v-checkbox>
              <v-label v-if="!snoozeMenu && editedPatient.snoozedTill != null"
                >Snoozed Till: {{ dateFormatted }}
                <v-btn icon @click="snoozeMenu = true"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></v-label
              >
              <v-menu
                v-model="snoozeMenu"
                :close-on-content-click="false"
                :close-on-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-overflow
                min-width="auto"
                v-if="snoozeMenu == true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Snooze Till"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="snoozedTillValue"
                  @input="snoozeMenu = false"
                  :min="tomorrow"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="10">
              <v-textarea v-model="editedItem.note"
                  label="Escalation Context"
                  prepend-icon="mdi-note"
                  auto-grow
                  scrollable
                  cols="5"
                  rows="7"
                  v-if="editedItem.isEscalation && !isNull(editedItem.note)"
              ></v-textarea>
              
              <escalation-summary v-if="editedItem.isEscalation && (isNull(editedItem.note) || editedItem.note === '') && this.subjectiveQuestionsFindingsAssessment" v-model="editedItem.note"
                                  label="Escalation Context"
                                  :subjectiveQuestionsFindingsAssessment="this.subjectiveQuestionsFindingsAssessment"
                                  :interactionDateTime="this.interactionDateTime"
                                  :nextClinicVisit="this.nextClinicVisit"
                                  :nextClinicVisitNote="this.nextClinicVisitNote"
                                  :lastClinicVisit="this.lastClinicVisit"
                                  :lastClinicVisitNote="this.lastClinicVisitNote"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="10">
              <v-textarea
                v-model="editedItem.note"
                label="Note"
                prepend-icon="mdi-note"
                auto-grow
                scrollable
                cols="5"
                rows="7"
                v-if="!editedItem.isEscalation"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="10">
              <v-textarea
                v-model="editedItem.thermometryAssessment"
                label="Thermometry Assessment"
                prepend-icon="mdi-note"
                auto-grow
                scrollable
                cols="5"
                rows="2"
                v-if="
                  editedItem.worklistItemTypeId ==
                    worklistItemType.Offloading ||
                  editedItem.worklistItemTypeId ==
                    worklistItemType.InflammationCheckin
                "
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="10">
              <div>*Escalate to Clinical Operations</div>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else-if="loading">
            <v-row>
            <v-col md="10">
              Fetching Worklist Item...
            </v-col>
            </v-row>    
        </v-container>
        <v-container v-else>
            <v-row>
            <v-col md="10" v-if="!isAuthorized && !loading">
                <v-alert type="error">You are not assigned or authorized to edit this Worklist Item</v-alert>
            </v-col>
            </v-row>    
        </v-container>
      </v-card-text>
      <v-card-actions v-if="isAuthorized && !loading">
        <v-spacer></v-spacer>        
        <v-btn color="blue darken-1" text @click="cancel"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
      </v-card-actions>
    </v-card>     
</template>
<script>
import EscalationSummary from "@/components/Escalation/EscalationSummary.vue";
import GetPatient from "@/components/Escalation/queries/patient.graphql";
import PatientService from "../services/PatientService";
import UserService from "../services/UserService";
import WorklistItemService from "../services/WorklistItemService";
import * as Shared from "../shared";

export default {
  components: {
    EscalationSummary
  },
  props: { customerId: Number, worklistItemId: Number, patientId: Number },
  name: "WorklistItemEdit", 
  data: () => ({
    snoozeMenu: false,    
    loading: true,
    saving: false,    
    achillesUsers: [],       
    worklistItem: {},
    copyOfWorklistItem: {},      
    editedItem: {},
    editedPatient: {},
    latestPatient:{},
    patientName:"",
    defaultItem: {},    
    patientStatuses: [],  
    componentKey: 0,
    alertRef: {
      alert: false,
      alertType: "success",
      alertMessage: "",
    },
    patientStatusType: {
      Qualified: 1,
      NotQualified: 2,
      ConsentPending: 3,
      PendingTraining: 4,
      Trained: 5,
      Scanning: 6,
      Withdrawn: 7,
      Renewal: 8,
      Decline: 9,
      ConsentReceived: 10,
    },
    patientScanStatusType: {
      Awaiting: 1,
      FirstScan: 2,
      Symmetrical: 3,
      Asymmetrical: 4,
      Disengaged: 5,
      NA: 6,
    },
    worklistItemStatusType: { Open: 1, Closed: 2, Snoozed: 3 },
    worklistItemType: {
      InitialOutreach: 1,
      Training: 2,
      Onboarding: 3,
      Offloading: 4,
      Reengagement: 5,
      Resolution: 6,
      ClinicFeedback: 7,
      Inbound: 8,
      Wellness: 9,
      DeviceAction: 10,
      PatientCallRequested: 11,
      Escalation: 12,
      Replacement: 13,
      Renewal: 14,
      Prequalification: 15,
      InflammationCheckin: 16,
    },
    subjectiveQuestionsFindingsAssessment: null,
    interactionDateTime: null,
    nextClinicVisit: null,
    nextClinicVisitNote: null,
    lastClinicVisit: null,
    lastClinicVisitNote: null
  }),
  apollo: {
    customer: {
      query: GetPatient,
      variables() {
        return {
          patientId: this.patientId,
          customerId: this.customerId
        }
      },
      update(data) {
        const patientData = data.customer.patient;
        if (patientData != null) {
          if (patientData.currentWorklistItem &&
              patientData.currentWorklistItem.subjectiveQuestion &&
              patientData.currentWorklistItem.subjectiveQuestion.subjectiveQuestionsFindingsAssessment) {
            this.subjectiveQuestionsFindingsAssessment = patientData.currentWorklistItem.subjectiveQuestion.subjectiveQuestionsFindingsAssessment;
            this.interactionDateTime = patientData.currentWorklistItem.subjectiveQuestion.interactionDateTime;
            this.nextClinicVisit = patientData.currentWorklistItem.subjectiveQuestion.nextClinicVisit;
            this.nextClinicVisitNote = patientData.currentWorklistItem.subjectiveQuestion.nextClinicVisitNote;
            this.lastClinicVisit = patientData.currentWorklistItem.subjectiveQuestion.lastClinicVisit;
            this.lastClinicVisitNote = patientData.currentWorklistItem.subjectiveQuestion.lastClinicVisitNote;
          }
          if (patientData.currentWorklistItem &&
              patientData.currentWorklistItem.escalation) {
            this.escalationId = patientData.currentWorklistItem.escalation.escalationId;
          }
          this.patientFirstName = patientData.firstName;
          this.patientLastName = patientData.lastName;
          const patientIdentifierData = patientData.patientIdentifiers.data;
          this.patientIcn = patientIdentifierData.length > 0 ? patientIdentifierData[0].identifierValue : "";
          return data.customer;
        }
      },

      // Disable query on component load.
      // We need to execute query when component is created and props are passed 
      skip() {
        return this.skipQuery;
      }
    }
  },
  
  computed: {   
    console: () => console,
    isAuthorized(){
        return this.worklistItem.assignedToUserId === this.$store.state.achillesUser.userId || this.isSuperviser || this.isDeviceOperationsUser || this.isClinicalOperationsUser || this.isCareManagerUser;
    },   
    isSuperviser() {
      let role = this.$store.state.achillesUser.userRoles.find((r) =>
        r.role.name.includes("Superviser")
      );
      if (role) return true;
      else return false;
    },
    isCareManagerUser() {
      let role = this.$store.state.achillesUser.userRoles.find(
        (r) => r.role.name == "CareManager"
      );
      if (role) return true;
      else return false;
    },
    isClinicalOperationsUser() {
      let role = this.$store.state.achillesUser.userRoles.find(
        (r) => r.role.name == "ClinicalOperations"
      );
      if (role) return true;
      else return false;
    },
    isDeviceOperationsUser()
    {
      let role = this.$store.state.achillesUser.userRoles.find((r) =>
        r.role.name == "DeviceOperations"
      );
      if (role) return true;
      else return false;
    }, 
    achillesPodiUsers() {
      return this.achillesUsers.filter(
        (u) =>
          u.email.includes("@podimetrics.com") && u.isServiceAccount == false && u.isActive===true
      );
    },
    achillesCMUsers(){
      return this.achillesUsers.filter(u=> u.userRoles.some(r=> r.role.name=="CareManager" || r.role.name=="CareManagerSuperviser"));
    },
    snoozedTillValue: {
       get:function(){
        return this.formatDate(this.editedPatient.snoozedTill);
       },
       set:function(val){         
         this.editedPatient.snoozedTill = val;
       }
    },
    dateFormatted(){
        if(this.editedPatient.snoozedTill != null)
        {
          let date = new Date(this.editedPatient.snoozedTill).toISOString().substr(0, 10);
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        }
        else
        {
          return null;
        }
    },
    tomorrow() {
      let date = new Date();
      // Add a day
      date.setDate(date.getDate());
      return date.toISOString().substr(0, 10);
    },
  },
 
  mounted() {
    this.loading = true;   
    this.getUsers();
    this.getWorklistItem();
    this.getPatientStatuses();
    this.triggerQuery();
  },
  updated(){
    this.loading = false;
  }, 

  methods: {
    triggerQuery() {
      this.$apollo.queries.customer.skip = false;
      this.$apollo.queries.customer.refetch();
    },
    isSnoozeActive()
    {
      let snoozeDate = new Date(this.editedPatient.snoozedTill);      
      let today = new Date();
      today.setHours(0,0,0,0);
      return snoozeDate > today;
    },
    forceRerender() {
      this.componentKey += 1;
    },    
    snoozeChanged() {
      if (this.editedItem["snoozedItem"]) 
        this.snoozeMenu = true;
      else
      {
        this.snoozeMenu = false;
        this.editedPatient.snoozedTill = null;  
      }
    },  
    formatDate(val) {
      if (val) {
        return new Date(val).toISOString().substr(0, 10);        
      } else return null;
    },   
    async getWorklistItem() {
      await new WorklistItemService()
        .getWorklistItem(this.customerId, this.worklistItemId)
        .then((resp) => {
          this.worklistItem = resp;
          this.editItem();
        });
    },
    async editItem() {   
      this.patientName = this.worklistItem.patient.firstName + ' ' + this.worklistItem.patient.lastName; 
      this.editedItem = Object.assign({}, this.worklistItem);
      this.copyOfWorklistItem = Object.assign({}, this.worklistItem);
      this.editedPatient = Object.assign({}, this.worklistItem.patient);
      this.editedItem["closedItem"] = this.worklistItem.closed != null;
      this.editedItem["snoozedItem"] = this.editedPatient.snoozedTill != null;
      // this.editedItem["shippingConsent"] = this.hasStatus(
      //   this.patientStatusType.ConsentReceived
      // );
      this.editedItem["trainingCompleted"] = this.hasStatus(
        this.patientStatusType.Trained
      );
      this.editedItem["scanConfirmed"] = this.hasScanStatus(
        this.patientScanStatusType.FirstScan
      );
      this.editedItem["patientDeclined"] = this.hasStatus(
        this.patientStatusType.Decline
      );
      // this.editedItem["patientWithdrew"] = this.hasStatus(
      //   this.patientStatusType.Withdrawn
      // );      
    },
    hasStatus(status) {
      return this.patientStatuses.some((s) => s.patientStatusTypeId === status);
    },
    hasScanStatus(scanStatus) {
      return this.patientStatuses.some(
        (s) => s.patientScanStatusTypeId === scanStatus
      );
    },          
    async saveWorklistItem(editedItem) {
      editedItem.updatedByUserId = this.$store.state.achillesUser.userId;
      await new WorklistItemService()
        .putWorklistItem(this.customerId, this.worklistItemId, editedItem)
        .then(() => {
          this.getWorklistItem();
        });       
    },
    async savePatient(customerId, patient) {
      await new PatientService().put(customerId, patient);
    },
    async getPatient(customerId, patientId) {
      await new PatientService().get(customerId, patientId)
      .then((resp) => {
          this.latestPatient = resp;
        })
        .catch((err) => {
          Shared.showSaveFailedAlert(this.alertRef);
          console.log(err);
        });
    },
    async savePatientStatus(customerId, patientStatus) {
      await new PatientService().postPatientStatus(customerId, patientStatus);
    },
    async constructAndSavePatientStatus() {
      let patientStatus = {
        updated: "",
        patientId: 0,
        updatedByUserId: 0,
        patientStatusTypeId: 0,
        patientScanStatusTypeId: 0,
      };
      patientStatus.updated = new Date().toISOString();
      patientStatus.patientId = this.worklistItem.patient.patientId;
      patientStatus.updatedByUserId = this.$store.state.achillesUser.userId;
      if (
        this.editedItem["shippingConsent"] &&
        this.editedItem["shippingConsent"] !=
          this.hasStatus(this.patientStatusType.ConsentReceived)
      ) {
        patientStatus.patientStatusTypeId =
          this.patientStatusType.ConsentReceived;
        patientStatus.patientScanStatusTypeId = this.patientScanStatusType.NA;
        await this.savePatientStatus(this.customerId, patientStatus);
      }
      if (this.editedItem["trainingCompleted"] && this.editedItem["trainingCompleted"] != this.hasStatus(this.patientStatusType.Trained))
      {
        patientStatus.patientStatusTypeId = this.patientStatusType.Trained;
        if (this.editedItem["scanConfirmed"] || this.hasScanStatus(this.patientScanStatusType.FirstScan))
          patientStatus.patientScanStatusTypeId = this.patientScanStatusType.FirstScan;
        else
          patientStatus.patientScanStatusTypeId = this.patientScanStatusType.Awaiting;        
        await this.savePatientStatus(this.customerId, patientStatus);
      }
      if (
        this.editedItem["patientDeclined"] &&
        this.editedItem["patientDeclined"] !=
          this.hasStatus(this.patientStatusType.Decline)
      ) {
        patientStatus.patientStatusTypeId = this.patientStatusType.Decline;
        patientStatus.patientScanStatusTypeId = this.patientScanStatusType.NA;
        await this.savePatientStatus(this.customerId, patientStatus);
      }
      if (
        this.editedItem["patientWithdrew"] &&
        this.editedItem["patientWithdrew"] !=
          this.hasStatus(this.patientStatusType.Withdrawn)
      ) {
        patientStatus.patientStatusTypeId = this.patientStatusType.Withdrawn;
        patientStatus.patientScanStatusTypeId = this.patientScanStatusType.NA;
        await this.savePatientStatus(this.customerId, patientStatus);
      }
    },
    cancel() {          
        if(this.snoozeMenu == true)
            this.snoozeMenu = false;
        this.editItem();
        this.forceRerender();                       
    },
    async save() {
      try {
        if (this.worklistItem.isEscalation === false && this.editedItem.isEscalation === true)
        {
          let escalationWarning = await this.$dialog.info({
                                             text: "You have selected to have this worklist item to be escalated.  Is this correct?",
                                             title: "Patient Escalation Confirmation",
                                             actions: ['Yes', "No"]
                                          });

          if (escalationWarning === "No") return;
        }

        this.saving = true;
        if (this.editedPatient.hasRequestedDoNotCall != this.worklistItem.patient.hasRequestedDoNotCall 
          || this.editedPatient.snoozedTill != this.worklistItem.patient.snoozedTill)
        {
          await this.getPatient(this.customerId,this.editedPatient.patientId);
          this.latestPatient.updatedByUserId = this.$store.state.achillesUser.userId;
          this.latestPatient.hasRequestedDoNotCall = this.editedPatient.hasRequestedDoNotCall;
          this.latestPatient.snoozedTill = this.editedPatient.snoozedTill;         
          await this.savePatient(this.customerId, this.latestPatient);
        }
        if ( this.editedItem["closedItem"] != (this.worklistItem.closed != null)
        || (this.editedItem["closedItem"] === true && this.worklistItem.worklistItemStatusTypeId == this.worklistItemStatusType.Open)
        || this.editedPatient.snoozedTill != this.worklistItem.patient.snoozedTill 
        || this.editedItem.note != this.worklistItem.note 
        || this.editedItem.thermometryAssessment != this.worklistItem.thermometryAssessment 
        || this.editedItem.assignedToUserId != this.worklistItem.assignedToUserId 
        || this.editedItem.isEscalation != this.worklistItem.isEscalation)
        {
          this.editedItem.lastUpdated = new Date().toISOString();
          if (this.editedItem["closedItem"] === true && (this.worklistItem.closed == null || this.worklistItem.worklistItemStatusTypeId == this.worklistItemStatusType.Open))
          {
            this.editedItem.closed = new Date().toISOString();
            this.editedItem.worklistItemStatusTypeId = this.worklistItemStatusType.Closed;
          }
          else if(this.editedItem["closedItem"] === false && this.worklistItem.closed != null)
          {
            this.editedItem.closed = null;
            this.editedItem.worklistItemStatusTypeId = this.worklistItemStatusType.Open;
          }
          if (this.editedItem["snoozedItem"] && this.isSnoozeActive())
              this.editedItem.worklistItemStatusTypeId = this.worklistItemStatusType.Snoozed;
          else if (this.editedItem["snoozedItem"] === false && this.worklistItem.worklistItemStatusTypeId == this.worklistItemStatusType.Snoozed)
          {
              this.editedItem.worklistItemStatusTypeId = this.worklistItemStatusType.Open;
          }  
          await this.saveWorklistItem(this.editedItem);
        }
        if (
          this.editedItem["shippingConsent"] ||
          this.editedItem["trainingCompleted"] ||
          this.editedItem["scanConfirmed"] ||
          this.editedItem["patientDeclined"] ||
          this.editedItem["patientWithdrew"]
        ) {
          await this.constructAndSavePatientStatus();
        }
        Shared.showSaveSuccessAlert(this.alertRef);       
        this.$emit('saved',this.dateFormatted);
      } catch (err) {
        Shared.showSaveFailedAlert(this.alertRef);        
        console.log(err);
      } finally {       
        this.saving = false;
      }
    },
    getUsers: async function () {
      if (this.$store.state.users.length == 0) {
        new UserService()
          .getUsers()
          .then((resp) => {
            this.achillesUsers = resp;
            this.$store.commit("setUsers", this.achillesUsers);
          })
          .catch((err) => {
            console.log(err);
          });         
      } else {
        this.achillesUsers = this.$store.state.users;
      }
    }, 
    getPatientStatuses: async function () {
      await new PatientService()
        .getPatientStatuses(this.customerId, this.patientId)
        .then((resp) => {
          this.patientStatuses = resp;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    doLowerCase(arr) {
      if (!arr) return arr;
      return arr.toLowerCase();
    },
    isNull(arr) {
      if (!arr || typeof arr === 'undefined' || this.doLowerCase(arr) === 'null')
        return true;

      return false;
    },
  },
};
</script>