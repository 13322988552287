import { TaskTimer } from 'tasktimer';

export function showSaveSuccessAlert(alertRef)
{
    let timer= new TaskTimer(3000);
    alertRef.alertType = "success";
    alertRef.alert = true;
    alertRef.alertMessage = "Saved successfully";        
    timer.on('tick', () => {alertRef.alert = false;});
    timer.start();       
}


export function showSaveFailedAlert(alertRef)
{
    let timer= new TaskTimer(3000);
    alertRef.alertType = "error";
    alertRef.alert = true;
    alertRef.alertMessage = "Unable to save, unexpected error encountered";        
    timer.on('tick', () => {alertRef.alert = false;});
    timer.start();       
}

export function emptyObject(obj)
{
    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            //Now, object[key] is the current value
            if (obj[key] != null)
                obj[key]='';
        }
    } 
}

export function emptyObjectInArray(arr)
{
    for (let i = 0; i < arr.length; i++) {
        emptyObject(arr[i]);        
    }    
}
