<template>
   <v-snackbar v-model="exportSnackbar" :timeout="2000" bottom>
      Exported to your downloads folder
      
      <template v-slot:action="{ attrs }">
         <v-btn
             color="blue"
             text
             v-bind="attrs"
             @click="exportSnackbar = false">
            Close
         </v-btn>
      </template>
   </v-snackbar>
</template>

<script>
   export default {
      name: "ExportSnackbar",
      data:()=>({
         exportSnackbar: false
      }),
       mounted(){
          this.$root.$on('exportSnackbar', data => {
             this.exportSnackbar=data;
          });
       }
   };
</script>

<style scoped>

</style>