<template>
  <div>
    <v-dialog v-model="dialog" max-width="290" v-if="dialog">
      <v-card>
        <v-card-title>
             <v-icon medium>
                mdi-account-clock
            </v-icon>
            <span> Idle Timeout</span>            
        </v-card-title>
        <v-card-text>
          If no activity is detected, you will be logged out in {{timeLeft/1000}} seconds.
        </v-card-text>       
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { TaskTimer } from 'tasktimer';
export default {
  data: () => ({   
    timeLeft: 60000,
    dialog: true,
    timer:{}    
  }),
  created() {
    this.timer = new TaskTimer(10000);
    this.timer.on('tick', () => this.elapsed());
    this.timer.start();
  },
  unmounted() {
    this.timer.stop();
  },
  methods: {
      logout(){
          this.$router.push('Logout');
      },
      elapsed(){
        if (!this.$store.state.idleVue.isIdle) return;
        else {
          this.timeLeft -= 10000;
          if(this.timeLeft < 1)
          {
              this.timer.stop();
              this.logout();            
          }
        }
      }
  },
};
</script>
