import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    theme:{
        options: { customProperties: true },
        themes: {
            light: {
                    primary:'#002859', //Podi Colors
                    secondary:'#0072cf',
                    accent:'#ffc82e',
                    error:'#f44336',
                    info:'#6e949e',
                    success:'#4caf50',
                    warning:'#ff9800',
                    positive:'#8bc34a',
                    negative:'#ff5722'
                },
            },
        },
});
