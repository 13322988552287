<template>
   <Summary
       @primary-care-nurse-changed="primaryCareNurseInfoChanged($event)"
       @save="save"
       @cancel="cancel"
       :items="items"
       :headers="headers"
       :allowPrimaryCareNurseEdit="allowPrimaryCareNurseEdit()"
       :isModified="isModified"
       :defaultPrimaryCareNurseInfo="primaryCareNurseInfo"></Summary>
</template>

<script>
   
   import PatientService from "@/services/PatientService";
   import NextWorklistItemService from "@/services/NextWorklistItemService";
   import {PodAccessService} from "@/services/PodAccessService";
   import {Input} from "@/types/queryInput";
   import Summary from "./Shared/Summary";
   import Utils from "../mixins/utils";
   import {UpdatePatientPrimaryNurseUser} from "./Patient/mutations/update-patient-primary-nurse-user.graphql";
import { xhrCallback } from "@sentry/tracing/dist/browser/request";

   const genericErrorMessage = "An exception has occurred. Please try again or contact the HelpDesk.";
   
   export default {
      name: "PatientSummary",
      props: {
         patient: Object,
         customerId: Number
      },
      mixins: [Utils],
      components: {
         Summary
      },
      data: () => ({
         nextWorklistItems: [],
         nextWorklistItemsList: "",
         worklistItems: [],
         newWorklistItemsList: "",
         interactionsByType: {
            totalCalls: 0,
            IO: 0,
            training: 0
         },
         podAccessServices: null,
         podId: null,
         nurses: [
            {
               firstName: "",
               lastName: "",
               displayName: "Unassigned",
               userId: -1
            }
         ],
         primaryCareNurseInfo: {
            firstName: "",
            lastName: "",
            displayName: "Unassigned",
            userId: -1
         },
         originalPrimaryCareNureInfo: {},
         patientStatus: {
            patientStatusTypeDescription: '------'
         },
         isModified: false,
         headers: [
            {
               text: "",
               align: "start",
               value: "text"
            },
            {
               text: "",
               value: "value"
            }
         ]
      }),
      methods: {
         allowPrimaryCareNurseEdit() {
            return this.podAccess && this.podAccess.displayAddPodAssignment();
         },
         getItemTypeDescriptionForId(id) {
            return this.worklistItemTypes.find((u) => u.worklistItemTypeId === id).description;
         },
         getCallsByType() {
            this.interactionsByType.totalCalls = this.patientInteractionForms.length;
            this.interactionsByType.IO =
                this.patientInteractionForms.filter(i => i.patientInteraction.interactionTypeId === 1).length;
            this.interactionsByType.training =
                this.patientInteractionForms.filter(i => i.patientInteraction.interactionTypeId === 2).length;
         },
         primaryCareNurseInfoChanged(item) {
            this.primaryCareNurseInfo = this.nurses.find(nurse => nurse.userId == item);
            this.isModified = true;
         },
         async cancel() {
            this.primaryCareNurseInfo = this.originalPrimaryCareNureInfo;
            this.isModified = false;
            
            await this.$dialog.info({
                                       text: "Primary care nurse update is cancelled",
                                       title: "Cancelled",
                                       actions: ["Okay"]
                                    });
         },
         async save() {
            
            let primaryNurseUserinput = {
               customerId: this.customerId,
               patientId: this.patient.patientId,
               primaryNurseUserId: this.primaryCareNurseInfo.userId === -1 ? null : this.primaryCareNurseInfo.userId,
               updatedByUserId: this.$store.state.achillesUser.userId
            };
            await this.$apollo.mutate(
                          {
                             mutation: UpdatePatientPrimaryNurseUser,
                             variables: {
                                input: primaryNurseUserinput
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.data.payload.errorCode);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(genericErrorMessage);
                      });
            
         },
         async success() {
            this.clearApolloCache();
            this.originalPrimaryCareNureInfo = this.primaryCareNurseInfo;
            this.isModified = false;
            
            let options = {
               text: "Primary care nurse successfully",
               title: "Saved",
               actions: ["Okay"]
            };
            await this.$dialog.info(options);
            
            this.$emit("patient-primary-nurse-user-saved", this.primaryCareNurseInfo);
         },
         async failure(error) {
            
            let options = {
               text: error,
               title: "Unable to save",
               actions: ["Okay"]
            };
            
            this.selectedUsers = [];
            await this.$dialog.error(options);
         },
         clearApolloCache() {
            let clients = Object.values(this.$apollo.provider.clients);
            clients.forEach(client => client.cache.reset());
         },
         async getUserPodRoleAccess() {
            if (!this.podId) {
               return;
            }
            
            let service = new PodAccessService(this.$store.state.achillesUser.userId, this.podId, this.$apollo);
            //get user pod role for action buttons
            await service.getUser()
                         .then(() => {
                            this.podAccessServices = service;
                            this.podAccessServices.setIsSuperAdmin();
                         });
         }
      },
      
      mounted() {
         this.podId = this.patient.podId;
         if (this.patient.pod) {
            this.nurses = this.nurses.concat(this.patient.pod.nurses.data);
         }
         if(this.patient.primaryNurseUser){
            this.primaryCareNurseInfo = this.patient.primaryNurseUser;
         }
         this.originalPrimaryCareNureInfo =  this.primaryCareNurseInfo;
         this.getUserPodRoleAccess();
         
         let self = this;
         let promises = [];
         
         promises.push(
             new NextWorklistItemService()
                 .getNextWorklistItems(this.customerId, this.patient.patientId)
                 .then((resp) => {
                    this.nextWorklistItems = resp;
                    this.nextWorklistItemsList = this.nextWorklistItems.map(function (e) {
                       return e.worklistItemType.description;
                    }).join(", ");
                 })
                 .catch((err) => {
                    console.log(err);
                 }));
         
         promises.push(
             new PatientService()
                 .getPatientWorklistItems(this.customerId, this.patient.patientId)
                 .then((resp) => {
                    this.worklistItems = resp;
                 })
                 .catch((err) => {
                    console.log(err);
                 }));
         
         promises.push(new PatientService()
                           .getPatientInteractionForms(this.customerId, this.patient.patientId)
                           .then((resp) => {
                              let forms = [];
                              resp.forEach(function (form, i) {
                                 if (form.formId === 1) {
                                    // Legacy form conversion
                                    let parsedNotes = JSON.parse(form.formData).Fields[0].Value;
                                    form.parsedForm = {notes: parsedNotes};
                                 } else {
                                    form.parsedForm = JSON.parse(form.formData);
                                 }
                   
                                 forms.push(form);
                              });
                
                              this.patientInteractionForms = forms;
                           })
                           .catch((err) => {
                              console.log(err);
                           }));

                            promises.push(new PatientService()
                           .getPatientStatuses(this.customerId, this.patient.patientId)
                           .then((resp) => {
                              if (resp.length == 0) {
                                 this.patientStatus = {
                                    patientStatusTypeDescription: '------'
                                 }
                              }

                              let withdrawnAfterFilteringForNotReverted = resp.filter(x => x.patientStatusTypeName == 'Withdrawn' && !x.isReverted);

                              if (withdrawnAfterFilteringForNotReverted.length > 0) {
                                 this.patientStatus = {
                                    patientStatusTypeDescription: 'Withdrawn'
                                 };
                              } else {
                                 let sorted = resp.filter(status => !status.isReverted).sort((a,b) => new Date(b.updated) - new Date(a.updated));


                                 if (sorted.length > 0) {
                                    this.patientStatus = {
                                       patientStatusTypeDescription: 'Active'
                                    };
                                 } else {
                                    this.patientStatus = {
                                       patientStatusTypeDescription: '------'
                                    };
                                 }
                              }
                           })
                           .catch((err) => {
                              if (err.response.status === 500) {
                                 console.log(err);
                              }
                              console.log(err);
                           }));
         
         Promise.allSettled(promises).then(() => {
            this.getCallsByType();
            this.worklistItemTypes = this.$store.state.worklistItemTypes.sort(
                (a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
            this.newWorklistItemsList = this.worklistItems.map(function (e) {
               return self.getItemTypeDescriptionForId(e.worklistItemTypeId);
            }).join(", ");
         });
      },
      computed: {
         items() {
            let primaryCareNurseItem = this.allowPrimaryCareNurseEdit() ? {
                   text: "Primary Nurse",
                   value: this.nurses
                } :
                {
                   text: "Primary Nurse",
                   value: this.primaryCareNurseInfo
                };
            return [
               {
                  text: "Patient Status",
                  value: this.patientStatus.patientStatusTypeDescription
               },
               {
                  text: "Pod Name",
                  value: this.patient.pod ? this.patient.pod.name : "Unassigned"
               },
               primaryCareNurseItem,
               {
                  text: "Snoozed Status",
                  value: this.patient.snoozedTill ? "Snoozed until " + new Intl.DateTimeFormat('en-US', {
                     month: '2-digit', day: '2-digit', year: 'numeric' }).format(new Date(this.patient.snoozedTill)) :
                         "Not Snoozed"
               },
               {
                  text: this.worklistItems.length > 1 ? "New Worklist Items" : "New Worklist Items",
                  value: this.newWorklistItemsList ? this.newWorklistItemsList : "None"
               },
               {
                  text: "Next Worklist Items",
                  value: this.nextWorklistItemsList ? this.nextWorklistItemsList : "None"
               },
               {
                  text: "Total interactions",
                  value: this.interactionsByType.totalCalls
               },
               {
                  text: "Initial Outreach",
                  value: this.interactionsByType.IO
               },
               {
                  text: "Training",
                  value: this.interactionsByType.training
               }
            ];
         },
         userRoleInput() {
            return new Input();
         },
         podAccess() {
            if (!this.podId || !this.podAccessServices) {
               return null;
            }
            return this.podAccessServices;
         }
      }
   };
</script>

<style scoped>

</style>