<template>
   <Summary :items="items" :headers="headers"></Summary>
</template>

<script>
   
   import GetMemberSummary from "./queries/member-summary.graphql";
   import Utils from "../../mixins/utils";
   import Summary from "../Shared/Summary";
   
   export default {
      name: "MemberSummary",
      props: {
         customerId: Number,
         memberId: Number
      },
      mixins: [Utils],
      components: {
         Summary
      },
      data: () => ({
         memberSummary: {},
         skipGetMemberSummary: true,
         initialLoad: true,
         headers: [
            {
               text: "",
               align: "start",
               value: "text"
            },
            {
               text: "",
               value: "value"
            }
         ]
      }),
      computed: {
         member() {
            return this.memberSummary.member;
         },
         items() {
            return [
               {
                  text: "Snoozed Status",
                  value: typeof this.member != "undefined" && this.member.snoozedTill != null
                         ? "Snoozed until " + new Intl.DateTimeFormat("en-US", {
                     month: "2-digit",
                     day: "2-digit",
                     year: "numeric"
                  }).format(new Date(this.member.snoozedTill))
                         : "Not Snoozed"
               },
               {
                  text: "Member Status",
                  value: typeof this.member != "undefined" && this.member.memberStatusType != null &&
                         this.member.memberStatusType.description
                         ? this.member.memberStatusType.description
                         : ""
               },
               {
                  text: "Rx Request",
                  value: ""
               },
               {
                  text: "Rx Received",
                  value: typeof this.member != "undefined" && this.member.isPrescriptionReceived ? "Yes" : "No"
               },
               {
                  text: "Date of Last Interaction",
                  value: ""
               },
               {
                  text: "Total number of Interactions",
                  value: ""
               }
            ];
         }
      },
      methods: {
         triggerQuery() {
            if (!this.initialLoad) {
               return;
            }
            
            this.$apollo.queries.memberSummary.skip = false;
            this.$apollo.queries.memberSummary.refetch();
         }
      },
      apollo: {
         memberSummary: {
            query: GetMemberSummary,
            variables() {
               return {
                  customerId: this.customerId,
                  memberId: this.memberId
               };
            },
            update(data) {
               this.initialLoad = false;
               return data.customer;
            },
            skip() {
               return this.skipGetMemberSummary;
            }
         }
      },
      mounted() {
         this.triggerQuery();
      }
   };
</script>

<style scoped lang="scss">

</style>