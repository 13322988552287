import * as BaseService from './BaseService';
import * as customer from '../types/customer';

export default class WorklistItemService extends BaseService.BaseService {
   private url = '/api/WorklistItemHistory';
      
   constructor() {
     super();     
   }
  
   async getWorklistItems(customerId: number, id: number): Promise<Array<customer.WorklistItemHistory>>{
    let url = this.url + `/${customerId}/${id}`;
    let response;               
    response = await this.api.get(url).catch(err => {
        throw err;          
      });
    return response.data;
  }
   
  }