<template>
   <v-container fluid class="px-0 pa-sm-3">
      <v-row class="px-0 pa-sm-3">
         <v-col sm="10" md="7">
            <v-card>
               <v-data-table
                   :headers="headers"
                   :items="items"
                   class="elevation-1"
                   :hide-default-footer="true"
                   :hide-default-header="true"
                   :footer-props="{ 'items-per-page-options': rowsPerPage }">
                  <template v-slot:item.text="{ item }">
                     <span class="font-weight-bold">{{item.text}}</span>
                  </template>
                  <template v-slot:item.value="{ item }">
                     <div v-if="item.text === 'Primary Nurse'">
                        
                        <div class="primary-care-nurse">
                           <v-select v-if="allowPrimaryCareNurseEdit && !isPrimaryCareNurseReadOnly"
                              style="height: 55px; margin-top: 5px;max-width: 200px;"
                              :items="item.value"  flat dense label=""  item-value="userId" item-text="displayName"
                              :value="defaultPrimaryCareNurseInfo.userId"
                              @change="$emit('primary-care-nurse-changed', $event)"
                              :readonly="isPrimaryCareNurseReadOnly" outlined height="50">
                           </v-select>
                           <span v-if="!allowPrimaryCareNurseEdit || isPrimaryCareNurseReadOnly">{{defaultPrimaryCareNurseInfo.displayName}}</span>
                           <v-btn v-if="allowPrimaryCareNurseEdit" icon @click="isPrimaryCareNurseReadOnly = !isPrimaryCareNurseReadOnly" class="edit-button float-right">
                              <v-icon>mdi-pencil</v-icon>
                           </v-btn>
                        </div>
                        
                     </div>   

                     <span v-if="item.text !== 'Primary Nurse'">{{item.value}}</span>
                  </template>

               </v-data-table>
            </v-card>
            <div class="edit-actions">
               <v-btn
                  :disabled="isPrimaryCareNurseReadOnly || !allowPrimaryCareNurseEdit"
                  class="mr-4"
                  color="secondary"
                  @click="() => {
                     isPrimaryCareNurseReadOnly = true
                     $emit('cancel')
                  }">
                  Cancel
               </v-btn>
               <v-btn
                  :disabled="isPrimaryCareNurseReadOnly || !allowPrimaryCareNurseEdit"
                  class="mr-4 btn-save"
                  color="secondary"
                  @click="() => {
                     isPrimaryCareNurseReadOnly = true
                     $emit('save')
                  }">
                  Save
               </v-btn>
            </div>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   export default {
      name: "Summary",
      props:{
         items:{},
         headers:{},
         allowPrimaryCareNurseEdit:Boolean,
         defaultPrimaryCareNurseInfo:{},
         isModified:Boolean
      },
      data: () => ({
         isPrimaryCareNurseReadOnly: true
      }),
      computed: {
         rowsPerPage() {
            return this.$rowsPerPage;
         }
      }
   };
</script>

<style scoped>
   .primary-care-nurse {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
   }

   .edit-actions {
      margin-top: 10px;
      display: flex;
      justify-content: right;
   }

   .btn-save {
      margin-right: 0px !important;
   }

   .edit-button {
      margin-left: 5px;
   }
</style>
<style>
   .primary-care-nurse .v-select__selection {
      font-size: 0.875rem;
   }
</style>