import moment from 'moment';
const commaSeparatedList = arr => {
    if (!arr) return "";
    return arr.filter(item => item !== null && item !== undefined && item !== "").join(", ");
};
const doLowerCase = arr => {
    if (!arr) return arr;
    return arr.toLowerCase();
};
const isNull = arr => {
    if (!arr || arr === null || typeof arr === 'undefined' || doLowerCase(arr) === 'null')
        return true;
    return false;
};
const nullSafe = (val, defaultVal) => isNull(val) ? defaultVal : val;
const valFormatter = val => nullSafe(val, 'N/A');

function checkForNA(que, ans, isDate) {
    if (!isNull(ans)) {
        return `- ${que} ${isDate ? valFormatter(moment(String(ans)).format('MM/DD/YYYY')) : valFormatter(ans)} \n`;
    }
    return '';
}

export function buildEscalationSummaryHeader(interactionDateTime, nextClinicVisit, nextClinicVisitNote, lastClinicVisit, lastClinicVisitNote) {
    let escalationSummaryHeader =
        checkForNA(`Date of the Interaction:`, interactionDateTime, true) +
        checkForNA(`Date of Last Clinic Visit:`, lastClinicVisit, true) +
        checkForNA(`Last Clinic Visit Note:`, lastClinicVisitNote, false) +
        checkForNA(`Date of Next Clinic Visit:`, nextClinicVisit, true) +
        checkForNA(`Next Clinic Visit Note:`, nextClinicVisitNote, false) +
        '\n';
    return escalationSummaryHeader;
}

export function buildEscalationSummary(sq) {
    if (!sq) return "";
    const wb = sq.subjectiveQuestionWoundBlister || {};
    let answers =
        checkForNA(`How would you describe the finding?`, (sq.subjectiveQuestionDescribeFindingOther || sq.subjectiveQuestionDescribeFinding), false) +
        checkForNA(`Where is the subjective finding located?`, commaSeparatedList(sq.subjectiveQuestionLocation), false) +
        checkForNA(`When did you notice it?`, (sq.subjectiveQuestionFirstNoticeFreeText || (isNull(sq.subjectiveQuestionFirstNotice) ? '' : moment(String(sq.subjectiveQuestionFirstNotice)).format('MM/DD/YYYY'))), false) +
        checkForNA(`How big is it (diameter)?`, sq.subjectiveQuestionHowBigOther || sq.subjectiveQuestionHowBig, false) +
        checkForNA(`What caused the finding?`, sq.subjectiveQuestionWhatCausedFindingOther || sq.subjectiveQuestionWhatCausedFinding, false) +
        checkForNA(`Is it open?`, wb.subjectiveQuestionWoundBlisterIsOpen, false) +
        checkForNA(`Is it draining?`, wb.subjectiveQuestionWoundBlisterIsDraining, false) +
        checkForNA(`If draining, what is the color of the drainage?`, commaSeparatedList(wb.subjectiveQuestionWoundBlisterColorDrainage), false) +
        checkForNA(`If draining, what thickness is the drainage?`, commaSeparatedList(wb.subjectiveQuestionWoundBlisterThicknessDrainage), false) +
        checkForNA(`If draining, is there an odor?`, wb.subjectiveQuestionWoundBlisterIsThereAnOdor, false) +
        checkForNA(`Any redness at the site or around the site?`, wb.subjectiveQuestionWoundBlisterIsThereRedness, false) +
        checkForNA(`Is the edge wet?`, wb.subjectiveQuestionWoundBlisterIsEdgeWet, false) +
        checkForNA(`Any flu-like symptoms - fevers, chills, nausea, vomiting?`, wb.subjectiveQuestionWoundBlisterAnyFlu, false) +
        checkForNA(`Is your provider aware?`, wb.subjectiveQuestionWoundBlisterProviderAware, false) +
        checkForNA(`What treatment did your provider prescribe?`, wb.subjectiveQuestionWoundBlisterTreatment, false) +
        checkForNA(`Is it getting better?`, wb.subjectiveQuestionWoundBlisterGettingBetter, false) +
        checkForNA(`When did you receive your last pair of prescribed shoes?`, wb.subjectiveQuestionWoundBlisterLastPrescribedShoes);
    return answers;
}