<template>
    <v-row class="pa-5" no-gutters>
      <v-col cols="2">
        <v-text-field
            v-model="number"
            label="NPI Number"
            placeholder="NPI Number"
        ></v-text-field>
      </v-col>
      <v-col class="pl-4" cols="3">
        <v-text-field
            v-model="firstName"
            label="First Name"
            placeholder="First Name"
        ></v-text-field>
      </v-col>
      <v-col class="pl-4" cols="3">
        <v-text-field
            v-model="lastName"
            label="Last Name"
            placeholder="Last Name"
        ></v-text-field>
      </v-col>
      <v-col class="pl-4" cols="2">
        <v-select v-model="state" :items="$store.state.states" item-text="name" item-value="name" label="State"></v-select>
      </v-col>
      <v-col class="text-right" cols="2">
        <v-btn :loading="isLoading" :disabled="isLoading" class="primary mt-2" @click="search()">Search</v-btn>
      </v-col>
      <v-col>
        <v-data-table
            :headers="headers"
            :items="entries"
            :footer-props="{ 'items-per-page-options': rowsPerPage }"
            class="elevation-1"
        >
          <template v-slot:item.addressLine1="{item}">
            <div>{{item.addressLine1}}</div>
            <div>{{item.city}}, {{item.state}} {{item.zip.replace(/(\d{5})(\d{4})/, "$1-$2")}}</div>
          </template>
          
          <template v-slot:item.action="{item}">
            <v-btn v-on:click="select(item.npi)">{{ item.action }}</v-btn>
          </template>


        </v-data-table>        
      </v-col>

  
    </v-row>
</template>

<script>
import NpiService from "@/services/NpiService";

export default {
  name: "ProviderSearch",
  props: {
    npi: Object,
    customerId: Number
  },
  data: () => ({
    isLoading: false,
    firstName: "",
    lastName: "",
    state: "",
    number: "",
    headers: [
      { text: '', value: 'action' },
      { text: 'Number', value: 'number' },
      { text: 'First Name', value: 'firstName' },
      { text: 'Last Name', value: 'lastName' },
      { text: 'Primary Practice Address', value: 'addressLine1' },
      { text: 'Phone', value: 'phone' },
      { text: 'Taxonomy', value: 'taxonomyDescription' },
    ],
    entries: []
  }),
  methods: {
    select(item) {
      this.$emit('selected', item);
    },
    
    search() {
      this.isLoading = true;
      this.entries.splice(0);
      const request = {
       firstName: this.firstName,
       lastName: this.lastName,
       number: this.number,
       state: this.state,
       customerId: this.customerId,
       registryLookupOnly: true
      };
      const self = this;
      new NpiService().search(request)
         .then(res => {
           if (res.length > 0) {
             res.forEach(r => {
               self.entries.push(
                   {
                     firstName: r.firstName,
                     lastName: r.lastName,
                     prefix: r.prefix,
                     credential: r.credential,
                     number: r.number,
                     taxonomyDescription: r.taxonomy ? r.taxonomy.description : "",
                     taxonomyCode: r.taxonomy ? r.taxonomy.code : "",
                     taxonomyState: r.taxonomy ? r.taxonomy.state : "",
                     addressLine1: r.addresses ? r.addresses[0].addressLine1 : "",
                     city: r.addresses ? r.addresses[0].city : "",
                     state: r.addresses ? r.addresses[0].state : "",
                     zip: r.addresses ? r.addresses[0].zip : "",
                     phone: r.addresses ? r.addresses[0].phoneNumber : "",
                     action: "Select",
                     npi: r
                   }
               );
             })
           }
         })
         .catch(err => {
           console.log(err)
         })
         .finally(() => (this.isLoading = false))
   }
  },
  computed: {
    rowsPerPage() {
      return this.$rowsPerPage;
    }
  },
  watch: {
    //
  }
}
</script>

<style scoped>

</style>