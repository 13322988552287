<template>
   <v-container>
      <v-row justify="center" align="center">
         <nav>
            <v-app-bar app color="primary" dark>
               <div class="d-flex align-center">
                  <v-img
                      alt="Podimetrics Logo"
                      class="shrink mr-2"
                      contain
                      src="../assets/Podi_logo-color-white.svg"
                      transition="scale-transition"
                      width="250"/>
               </div>
            </v-app-bar>
         </nav>
      </v-row>
   </v-container>
</template>

<script>
   export default {
      name: "EmptyBanner"
   };
</script>

<style scoped>

</style>