<template>
   <v-row>
      <v-col md="7">
         <interaction-stats :customerId="customerId" :memberId="memberId"></interaction-stats>
         
         <v-card :loading="isLoading" class="pr-10 card-default" elevation="2" outlined tile>
            <v-snackbar v-model="savedSnackbar" :timeout="1100" bottom>
               {{savedSnackbarText}}
               
               <template v-slot:action="{ attrs }">
                  <v-btn color="blue" text v-bind="attrs" @click="savedSnackbar = false">Close</v-btn>
               </template>
            </v-snackbar>
            
            <v-timeline v-show="isLoaded" dense>
               
               <!-- Start: Add Interaction Section -->
               <v-timeline-item class="white--text" fill-dot>
                  <template v-slot:icon>
                     <v-avatar color="primary" size="48">
                        <v-img :alt="imageAlt" :src="imageSrc" lazy-src="../../../assets/account.png"/>
                     </v-avatar>
                  </template>
                  <v-menu offset-y rounded="lg">
                     <template v-slot:activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" color="secondary">Add</v-btn>
                     </template>
                     
                     <!-- Start:Interaction forms selection-->
                     <!-- End: Interaction forms selection-->
                  </v-menu>
               </v-timeline-item>
               <!-- End: Add Interaction Section -->
               
               <!-- Start: Add interaction form -->
               <v-timeline-item v-if="addingForm" hide-dot small>
                  <add-member-interaction-form :key="newInteractionFormKey" v-if="selectedMemberInteractionType!==0"
                                               :customerId="customerId" :memberId="memberId" :isEdited="false"
                                               :selectedMemberInteractionType="selectedMemberInteractionType" :readOnly="false"
                                               :showMoreEnabled="showMoreEnabled"/>
               
               </v-timeline-item>
               <!-- End: Add interaction form -->
            </v-timeline>
         </v-card>
      </v-col>
   </v-row>
</template>

<script>
   import AddMemberInteractionForm from "./AddMemberInteractionForm";
   import InteractionStats from "./InteractionStats";
   import ReferenceDataService from "@/services/ReferenceDataService";
   // import Utils from "../../mixins/utils";
   export default {
      name: "MemberInteractionList",
      props: {
         customerId: Number,
         memberId: Number
      },
      components: {
         AddMemberInteractionForm,
         InteractionStats
      },
      //    mixins: [Utils],
      data: () => ({
         achillesUsers: [],
         savedSnackbar: false,
         savedSnackbarText: "Saved",
         isLoading: true,
         isLoaded: false,
         addingForm: true, //TODO: update to be dynamic value
         newInteractionFormKey: 0,
         memberInteractionForm: {},
         showMoreEnabled: true,
         model: {},
         schema: {},
         autoSaveEnabled: process.env.VUE_APP_AUTO_SAVE_INTERACTION_FORM === "enabled",
         //TODO: Separate ticket: update dynamically on selecting Type of form from list when user clicks  Add button. 
         selectedMemberInteractionType: 1
      }),
      computed:{
         imageAlt(){
            return this.$store.getters.getGUserProfile.sd 
                   ? this.$store.getters.getGUserProfile.sd 
                   : this.$store.state.achillesUser.firstName+' '+this.$store.state.achillesUser.lastName;
         },
         imageSrc(){
            return this.$store.getters.getGUserProfile.fI 
                   ? this.$store.getters.getGUserProfile.fI 
                   : this.$store.state.achillesUser.avatarUrl;
         }
      },
      methods: {
         cancelledNewInteractionForm() {
            this.addingForm = false;
            this.model = {};
            this.clearSavedForm();
         },
         clearSavedForm() {
            if (this.autoSaveEnabled) {
               console.log("TODO: clearSavedForm");
            }
         },
         async savedNewInteractionForm(val) {
            console.log("TODO: savedNewInteractionForm");
         },
         autoSave(form) {
            console.log("TODO: autoSave");
         },
         resolveUserName: function (memberInteraction) {
            let resolvedName = "";
            if (memberInteraction.updatedByUserId != null) {
               let user = this.achillesUsers.find((u) => u.userId === memberInteraction.updatedByUserId);
               if (user) {
                  if (user.firstName && user.lastName) {
                     resolvedName = user.firstName + " " + user.lastName;
                  } else {
                     resolvedName = user.email;
                  }
               }
            } else if (memberInteraction.updatedByUser) {
               resolvedName = memberInteraction.updatedByUser;
            }
            return resolvedName;
         },
         getStates() {
            if (this.$store.state.states.length === 0) {
               let promises = [];
               promises.push(new ReferenceDataService()
                                 .getStates()
                                 .then((value) => {
                                    this.$store.commit("setStates", value);
                                 })
                                 .catch((err) => {
                                    console.log(err);
                                 }));
            }
         }
      },
      created() {
         this.achillesUsers = this.$store.state.users;
         this.getStates();
         this.isLoading = false;
         this.isLoaded = true;
      }
   };
</script>

<style scoped lang="scss">
   .card-default {
      min-height: 90px;
   }
</style>