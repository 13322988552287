<template>
   <v-combobox
       v-bind="$attrs"
       item-text="description"
       item-value="description"
       :search-input.sync="query"
       :loading="loading"
       :items="results"
       v-on="$listeners"
       :placeholder="placeholder"
       @input="handleInput"
       :disabled="disabled"
       :id="id"
       :dense="dense">
      <template v-slot:item="{ item }">
         <v-list-item-content v-if="item">
            <v-list-item-title v-html="item.description" v-show="false"/>
            <v-list-item-title v-html="item.structured_formatting.main_text"/>
            <v-list-item-subtitle v-html="item.structured_formatting.secondary_text"/>
         </v-list-item-content>
      </template>
      <template v-slot:append-item>
         <v-list-item-subtitle class="text-right pr-3">address verification powered by
            <span class="blue--text">G</span>
            <span class="red--text">o</span>
            <span class="yellow--text text--darken-2">o</span>
            <span class="blue--text">g</span>
            <span class="green--text">l</span>
            <span class="red--text">e</span>
         </v-list-item-subtitle>
      </template>
   </v-combobox>
</template>

<script>
   const ADDRESS_COMPONENTS = {
      subpremise: "short_name",
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      administrative_area_level_2: "long_name",
      country: "long_name",
      postal_code: "short_name"
   };
   
   import {debounce, isEmpty, isObject} from "lodash";
   import {contactDetailsUtils} from "../../mixins/contactDetailsUtils";
   
   export default {
      props: {
         id: {
            type: String,
            default: null
         },
         disabled: {
            type: Boolean,
            default: false
         },
         dense: {
            type: Boolean,
            default: false
         },
         latitude: {
            type: Number,
            default: 52.08966
         },
         longitude: {
            type: Number,
            default: 5.11436
         },
         debounceMsec: {
            type: Number,
            default: 200
         },
         placesFields: {
            type: Array,
            default: () => ([
               "address_components",
               "formatted_address"
            ])
         },
         placesTypes: {
            type: Array,
            default: () => ([
               "address"
            ])
         },
         placesRadius: {
            type: Number,
            default: 100000
         },
         placeholder: null
      },
      mixins: [contactDetailsUtils],
      data() {
         return {
            platform: null,
            searchService: null,
            placesService: null,
            query: "",
            results: [],
            loading: false,
            googleSessionToken: ""
         };
      },
      watch: {
         query(val) {
            this.search(val);
         }
      },
      created() {
         this.search = debounce(this.search, this.debounceMsec);
      },
      updated() {
         this.initService();
      },
      methods: {
         initService() {
            if(typeof window.google =="undefined")return;
            this.searchService = new window.google.maps.places.AutocompleteService();
            this.placesService = new window.google.maps.places.PlacesService(
                new window.google.maps.Map(document.createElement("div"))
            );
            this.generateNewSessionToken();
         },
         async search(val) {
            if (isEmpty(val) || val.length < 3) {
               this.results = [];
               return;
            }
            this.loading = true;
            try {
               let r = await this.searchPlace(val);
               this.results = [];
               r.forEach(r => {
                  this.results.push(r);
               });
               //console.log(this.results);
            }
            catch (e) {
               // eslint-disable-next-line no-console
               console.warn("Could not retrieve location suggestions", e);
            }
            this.loading = false;
         },
         formatResult(place) {
            let returnData = {};
            for (let i = 0; i < place.address_components.length; i++) {
               let addressType = place.address_components[i].types[0];
               if (ADDRESS_COMPONENTS[addressType]) {
                  let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
                  returnData[addressType] = val;
               }
            }
            //returnData['latitude'] = place.geometry.location.lat();
            //returnData['longitude'] = place.geometry.location.lng();
            return returnData;
         },
         searchPlace(val) {
            return new Promise((resolve, reject) => {
               this.searchService.getPlacePredictions({
                                                         input: val,
                                                         location: new window.google.maps.LatLng(this.latitude,
                                                                                                 this.longitude),
                                                         radius: this.placesRadius,
                                                         types: this.placesTypes,
                                                         sessionToken: this.googleSessionToken,
                                                         componentRestrictions: {country: "us"}
                                                      },
                                                      (predictions, status) => {
                                                         if (status ===
                                                             window.google.maps.places.PlacesServiceStatus.OK) {
                                                            return resolve(predictions);
                                                         }
                                                         if (status ===
                                                             window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                                                            return resolve([]);
                                                         }
                                                         return reject(status);
                                                      });
            });
         },
         generateNewSessionToken() {
            this.googleSessionToken = new window.google.maps.places.AutocompleteSessionToken();
         },
         async handleInput(choice) {
            if (isObject(choice) && choice.structured_formatting) {
               this.$emit("input", choice.structured_formatting.main_text);
               this.loading = true;
               try {
                  // Attempt to get full address including postal code
                  const placeDetails = await this.getPlaceDetails(choice);
                  let details = this.formatResult(placeDetails);
                  //this.$emit('input', placeDetails.formatted_address)
                  this.$emit("selected", details);
               }
               catch (e) {
                  // eslint-disable-next-line no-console
                  console.error(e);
               }
               this.loading = false;
            } else {
               //this.$emit('input', choice);
            }
            
            this.generateNewSessionToken();
         },
         getPlaceDetails(choice) {
            return new Promise((resolve, reject) => {
               this.placesService.getDetails({
                                                placeId: choice.place_id,
                                                sessionToken: this.googleSessionToken,
                                                fields: this.placesFields
                                             },
                                             (result, status) => {
                                                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                                   return resolve(result);
                                                }
                                                return reject(result);
                                             });
            });
         }
      }
   };
</script>