import * as BaseService from './BaseService';
import * as customer from '../types/customer';

export default class ScheduledWorklistItemService extends BaseService.BaseService {
   private url = '/api/ScheduledWorklistItem';
      
   constructor() {
     super();     
   }

   async getScheduledWorklistItems(customerId: number, patientid: number): Promise<Array<customer.ScheduledWorklistItem>>{
    let url = this.url + `/${customerId}/${patientid}/true`;
    let response;               
    response = await this.api.get(url).catch(err => {
        throw err;          
      });
    return response.data;
  }
  
   async getScheduledWorklistItem(customerId: number, id: number): Promise<customer.ScheduledWorklistItem>{
    let url = this.url + `/${customerId}/${id}`;
    let response;               
    response = await this.api.get(url).catch(err => {
        throw err;          
      });
    return response.data;
  }

  async putScheduledWorklistItem(customerId: number,id: number, worklistItem: customer.ScheduledWorklistItem){
    let url = this.url + `/${customerId}/${id}`;     
    let response = await this.api.put(url,worklistItem).catch(err => {         
        throw err;          
      });
    return response;
  }

  async postScheduledWorklistItem(customerId: number, worklistItem: customer.ScheduledWorklistItem){
    let url = `/api/ScheduledWorklistItem/${customerId}`;            
    let response = await this.api.post(url,worklistItem).catch(err => {
      throw err;          
    });                          
    return response;
  }
   
  }