import * as master from '../types/master'
import * as baseService from './BaseService';


export default class CustomerWorklistItemsService extends baseService.BaseService {
   private url = '/api/CustomerWorklistItems';
      
   constructor() {
     super();     
   } 

    async post(pagedDataOptions: baseService.FlexDataOptions): Promise<baseService.PagedData<master.CustomerWorklistItem>>{          
      let response =  (await this.api.post(this.url,pagedDataOptions)).data as baseService.PagedData<master.CustomerWorklistItem>;         
      return response;
    }  
  }