<template>
   <v-main>
      <!-- <Worklist/> -->
      <p v-if="!stateLoaded">Loading....</p>
      <p v-if="worklistEnabled && stateLoaded" class="h4 ma-5">Welcome to Achilles, view your
         <router-link to="Worklist">Worklist</router-link>
         .
      </p>
      <p v-if="!worklistEnabled" class="h4 ma-5">Welcome to Achilles, search Patients to get started.</p>
      <p class="h4 ma-5">
         <SavedPatientInteractions />
      </p>
      <v-row v-if="isMobile" justify="center">
         <v-col cols="11">
            <v-container class="px-0 px-sm-3">
               <PatientSearch />
            </v-container>
         </v-col>
      </v-row>
      <p v-if="podFeatureEnabled && hasRole" class="h4 ma-5">
         <nurse-care-manager-dashboard />
      </p>
   </v-main>
</template>

<script>
import { PodAccessService } from '@/services/PodAccessService';
import PatientSearch from '../components/PatientSearch.vue';
import SavedPatientInteractions from "@/components/SavedPatientInteractions.vue";
import NurseCareManagerDashboard from "@/components/Dashboards/NurseCareManager/NurseCareManagerDashboard.vue";


export default {
   name: 'Home',
   components: {
      NurseCareManagerDashboard,
      SavedPatientInteractions,
      PatientSearch
   },

   data: () => ({
      worklistEnabled: true,
      podFeatureEnabled: false,
      podAccessServices: null
   }),
   computed: {
      isMobile() {
         return this.$vuetify.breakpoint.xsOnly;
      },
      stateLoaded() {
         return !!this.$store.state.achillesUser.firstName;
      },
      podAccess() {
         if (!this.podAccessServices) {
            return null;
         }
         return this.podAccessServices;
      },
      hasRole() {
         return this.podAccess && this.podAccess.displayDashboard();
      }
   },
   methods: {
      openDocument(id) {
         let url = process.env.VUE_APP_REPORT_BASE_URL + id;
         window.open(url, "_blank");
      },
      initPodAccessService() {
            let podId = null;
            let service = new PodAccessService(this.$store.state.achillesUser.userId, podId, this.$apollo);
            //get user data: pod roles and system roles
            service.getUser()
               .then(() => {
                  this.podAccessServices = service;
                  this.podAccessServices.setIsSuperAdmin();
               });
      }
   },
   mounted() {
      this.podFeatureEnabled = process.env.VUE_APP_PODS_FEATURE == "enabled";
      this.worklistEnabled = process.env.VUE_APP_WORKLIST_FEATURE == "enabled";
      this.initPodAccessService();
   },
};
</script>
