<template>
  <div class="d-flex flex-column flex-md-row">
    <v-col :md="thermogramsEnabled ? '6' : '9'" order="2" order-md="1" class="px-0 px-sm-3">
      <v-container px-0 px-sm-3>
        <v-chip class="ma-2" label>Total Interactions: {{interactionsByType.totalCalls}}</v-chip>
        <v-chip label class="ma-2">Initial Outreach: {{interactionsByType.IO}}</v-chip>
        <v-chip label class="ma-2">Training: {{interactionsByType.training}}</v-chip>
        <v-chip label class="ma-2" v-if="patient.currentTrainingCadence">Training Cadence: {{patient.currentTrainingCadence}}</v-chip>
        <v-chip label class="ma-2" v-if="patient.snoozedTill != null">Snoozed Till: {{snoozedUntilFormatted}}</v-chip>
        <v-btn small v-if="localWorklistItemId != null" color="secondary" class="ml-5" @click="showDialog">Edit Worklist Item</v-btn>
        <v-card :loading="isLoading" class="pr-sm-6 pr-md-8" elevation="2" outlined tile style="min-height: 90px;">
        <v-snackbar v-model="savedSnackbar" :timeout="1100" bottom>
          {{ savedSnackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="savedSnackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <v-timeline v-show="isLoaded" dense>
          <!-- Add Interaction Section -->
          <v-timeline-item class="white--text" fill-dot>
            <template v-slot:icon>
              <v-avatar color="primary" size="48">
                <v-img
                :alt="$store.getters.getGUserProfile.sd ? $store.getters.getGUserProfile.sd : $store.state.achillesUser.firstName+' '+$store.state.achillesUser.lastName"
                :src="$store.getters.getGUserProfile.fI ? $store.getters.getGUserProfile.fI : $store.state.achillesUser.avatarUrl"
                lazy-src="../assets/account.png"
                referrerpolicy="no-referrer"
                 />
              </v-avatar>
            </template>
            <v-menu
                offset-y
                rounded="lg"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn color="secondary" v-bind="attrs" v-on="on">Add</v-btn>
              </template>
              <v-list class="overflow-y-auto" style="max-height: 500px">
                <v-list-item
                    v-for="item in interactionTypesFiltered"
                    :key="item.interactionTypeId"
                    dense
                    link
                    @click="formSelected(item)"
                >
                  <v-list-item-title v-text="item.description"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-timeline-item>
          <!-- Add interaction form -->
          <v-timeline-item
              v-show="addingForm"
              hide-dot
              small
          >
            <!--<InteractionForm :title="patientInteraction.interactionTypeDescription" :created-by="resolveUserName(patientInteraction)" :schema="schema" :readOnly="false" :model="model" :patientInteraction="patientInteraction" :customerId="customerId"  @saved="savedNewInteractionForm" @cancelled="cancelledNewInteractionForm" :showMoreEnabled="patientInteraction.showMoreEnabled"/>-->
            <InteractionForm v-if="patientInteractionForm.patientInteraction" :key="newInteractionFormKey"
                             :created-by="resolveUserName(patientInteractionForm.patientInteraction)"
                             :customerId="customerId"
                             :model="model" :patientInteraction="patientInteractionForm"
                             :readOnly="false" :schema="schema" :showMoreEnabled="showMoreEnabled"
                             :title="patientInteractionForm.interactionTypeDescription"
                             :worklist-items="worklistItems"
                             @autoSave="autoSave" @cancelled="cancelledNewInteractionForm"
                             @saved="savedNewInteractionForm"/>
          </v-timeline-item>
          <!-- Existing History -->
          <div v-for="(form, index) in patientInteractionForms"
               :key="form.patientInteractionFormId">

            <v-timeline-item v-if="index < showItems"
                             small
            >
              <!--<InteractionForm :title="getTitle(form.patientInteraction.interactionTypeId)" :created-by="resolveUserName(form.patientInteraction)" :schema="form.formId === 1 ? legacySchema : getSchema(form.formId)" :readOnly="true" :model="form.parsedForm" :editEnabled="form.formId !== 1"    :showMoreEnabled="getForm(form.formId).showMoreEnabled" :isEdited="form.isEdited" :patientInteraction="form.patientInteraction" :customerId="customerId"  @saved="savedInteractionForm" @cancelled="cancelledInteractionForm"/>-->
              <InteractionForm v-if="form.patientInteraction" :created-by="resolveUserName(form.patientInteraction)"
                               :customerId="customerId"
                               :editEnabled="form.formId !== 1 && allowEdit(form)"
                               :isEdited="form.isEdited" :model="form.parsedForm"
                               :patientInteraction="form" :readOnly="true"
                               :schema="form.formId === 1 ? legacySchema : getSchema(form.formId)"
                               :showMoreEnabled="getForm(form.formId) && getForm(form.formId).showMoreEnabled"
                               :title="getTitle(form.patientInteraction.interactionTypeId)"
                               :worklist-items="worklistItems"
                               @cancelled="cancelledInteractionForm"
                               @saved="savedInteractionForm"/>
              <template v-slot:icon>
                <v-avatar v-if="form.patientInteraction.updatedByUserId" color="primary" size="48">
                  <v-img :alt="resolveUserName(form.patientInteraction)"
                         :src="resolveUserImageUrl(form.patientInteraction)"
                         height="48"
                         lazy-src="../assets/account.png"
                         width="48"
                  />
                </v-avatar>
                <v-avatar v-if="!form.patientInteraction.updatedByUserId"
                          color="primary"
                          size="48"
                >
                  <v-icon dark x-large>
                    mdi-account
                  </v-icon>
                </v-avatar>
              </template>
            </v-timeline-item>
          </div>
          <v-slide-x-transition
              group
          >
          </v-slide-x-transition>
          <v-timeline-item v-if="this.showItems < patientInteractionForms.length" class="text-center" hide-dot small>
            <v-btn color="primary" @click="showMore()">Show More...</v-btn>
          </v-timeline-item>
        </v-timeline>
      </v-card>
      </v-container>
    </v-col>
    <v-col :md="thermogramsEnabled ? '6' : 'auto'" order="1" order-md="2" class="px-0 px-sm-3">
      <v-container id="ThermogramContainer" v-if="thermogramsEnabled" px-0 px-sm-3></v-container>
    </v-col>
     <v-dialog v-model="dialog" max-width="800px" persistent v-if="dialog">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialogClosed">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Edit Worklist Item</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialogClosed"> Close </v-btn>
          </v-toolbar-items>
        </v-toolbar>
       <WorklistItemEdit  
          :customerId="customerId"          
          :worklistItemId="localWorklistItemId"
          :patientId="patient.patientId"
          :key="componentKey"
          @saved="refresh"
      />
    </v-dialog>

     <v-dialog v-model="confirmDialog" v-if="confirmDialog"
          persistent
          max-width="500"
          content-class="confirm-dialog">
          <v-card>
            <v-card-title class="headline primary">
               Disregard Draft?
            </v-card-title>
            <v-card-text>
              You have an unsaved Interaction Form. Would you like to disregard and open a new Interaction Form?
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div> 
</template>

<script>
import ReferenceDataService from "@/services/ReferenceDataService";
import InteractionForm from "@/components/InteractionForm2";
import PatientService from "@/services/PatientService";
import Vue from "vue";
import UserService from "@/services/UserService";
import WorklistItemEdit from "@/components/WorklistItemEdit";
import WorklistItemService from "../services/WorklistItemService";
import ls from 'localstorage-slim';
import encUTF8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';
import _ from 'lodash';
import WorklistItemHistoryService from "@/services/WorklistItemHistoryService";
import {InteractionTypeEnum, WorklistItemTypeEnum} from "@/types/enum";

export default {
  props: {
    patient: Object,
    customerId: Number,
    customerOnboardingModel: String,
    worklistItemId: {type: Number, default: null}
  },
  components: {
    InteractionForm,
    WorklistItemEdit
  },
  data: () => ({
    useTestSchema: false,
    testSchema: ``,
    achillesUsers: [],
    isLoading: true,
    isLoaded: false,
    interactionTypes: [],
    interactionTypesFiltered: [],
    interactionTypeForms: [],
    worklistItems: [],
    showItems: 5,
    newInteractionFormKey: 0,
    localWorklistItemId: 0,
    savedSnackbar: false,
    savedSnackbarText: 'Saved',
    thermogramsEnabled: process.env.VUE_APP_INTERACTIONS_WITH_THERMOGRAMS === "enabled",
    autoSaveEnabled: process.env.VUE_APP_AUTO_SAVE_INTERACTION_FORM === "enabled",
    autoSaveData: {},
    forms: [],
    addingForm: false,
    selectedForm: null,
    confirmDialog: false,
    showMoreEnabled: false,
    model: {},
    patientInteractionForm: {},
    patientInteractionForms: [],
    interactionsByType: {
      totalCalls: 0,
      IO: 0,
      training: 0,
    },
    legacySchema: {
      "id": "https://podimetrics.com",
      "$schema": "http://json-schema.org/draft-06/schema#",
      "description2": "Default",
      "type": "object",
      "properties": {
        "notes": {
          "type": "string",
          "title": "Notes",
          "x-summary": true,
          "x-display": "custom-html-component",
          "x-props": {
            "dense": true,
            "filled": true
          }
        }
      }
    },
    dialog: false,
    componentKey: 0,
    hasBeganIOTrainingProcess: false,
    hasCompleteOnboardingWLItems: false
  }),
  watch: {},
  computed: {
    snoozedUntilFormatted() {
      return (this.patient.snoozedTill != null && this.patient.snoozedTill != '') ? new Intl.DateTimeFormat('en-US', {
        month: '2-digit', day: '2-digit', year: 'numeric'
      }).format(new Date(this.patient.snoozedTill)) : ''
    },
     isCompleteOnboardingCust(){
       return this.customerOnboardingModel === 'CompleteOnboarding';
     },
     isIOTrainingCust() {
        return this.customerOnboardingModel === 'InitialOutreach';
     }
  },
  methods: {
    refresh(val) {
      if (val != null || val != '')
        this.patient.snoozedTill = val;
      else
        this.patient.snoozedTill = null;
    },
    showDialog() {
      this.dialog = true;
      this.forceRerender();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    dialogClosed() {
      this.dialog = !this.dialog;
    },
    allowEdit(form) {
      return (((new Date(form.patientInteraction.interactionDateTime) - new Date()) / (1000 * 3600 * 24)) * -1 <= 30
          && form.patientInteraction.updatedByUserId === this.$store.state.achillesUser.userId);
    },
    showMore() {
      this.showItems += 5;
    },
    getTitle(interactionTypeId) {
      let interactionType = this.interactionTypes.find(i => i.interactionTypeId === interactionTypeId);
      if (interactionType) {
        return interactionType.description;
      }
    },
    resolveUserImageUrl: function (patientInteraction) {
      let resolvedImageUrl = '';
      if (patientInteraction.updatedByUserId != null) {
        let user = this.achillesUsers.find((u) => u.userId === patientInteraction.updatedByUserId);
        if (user)
          resolvedImageUrl = user.avatarUrl
      }
      return resolvedImageUrl;
    },
    resolveUserName: function (patientInteraction) {
      let resolvedName = '';
      if (patientInteraction.updatedByUserId != null) {
        let user = this.achillesUsers.find((u) => u.userId === patientInteraction.updatedByUserId);
        if (user) {
          if (user.firstName && user.lastName)
            resolvedName = user.firstName + ' ' + user.lastName;
          else
            resolvedName = user.email;
        }
      } else if (patientInteraction.updatedByUser)
        resolvedName = patientInteraction.updatedByUser;
      return resolvedName;
    },
    getForm(formId) {
      return this.forms.find(f => f.formId === formId);
    },
    getSchema(formId) {
      if (this.forms.find(f => f.formId === formId)) {
        return this.forms.find(f => f.formId === formId).schema;
      } else {
        return this.legacySchema;
      }

    },
    autoSave(form) {
      try {
        if (this.autoSaveEnabled) {
          const formData = JSON.stringify(form);
          if (formData === "{}") return;
          if (!_.isEqual(this.autoSaveData, form)) {
            let selectedPatientInteractionForm = JSON.parse(JSON.stringify(this.patientInteractionForm));
            selectedPatientInteractionForm.formData = formData;
            selectedPatientInteractionForm.patientInfo = {};
            selectedPatientInteractionForm.version = 1.0;
            selectedPatientInteractionForm.patientInfo.customerId = this.customerId;
            selectedPatientInteractionForm.patientInfo.designation = this.patient.designation;
            selectedPatientInteractionForm.patientInfo.firstName = this.patient.firstName;
            selectedPatientInteractionForm.patientInfo.lastName = this.patient.lastName;
            selectedPatientInteractionForm.patientInfo.patientId = this.patient.patientId;

            this.autoSaveData = _.clone(form);
            const savedPatientInteractionForms = ls.get('savedPatientInteractionForms', {decrypt: true}) ? ls.get('savedPatientInteractionForms', {decrypt: true}) : [];
            if (savedPatientInteractionForms.length > 0) {
              let parsedSavedPatientInteractionForms = savedPatientInteractionForms;
              if (!Array.isArray(savedPatientInteractionForms)) {
                parsedSavedPatientInteractionForms = JSON.parse(savedPatientInteractionForms);
              }
              let savedFormIndex = parsedSavedPatientInteractionForms.findIndex(f => f.patientInfo.patientId === this.patient.patientId && f.patientInfo.customerId === this.customerId);
              if (savedFormIndex > -1) {
                parsedSavedPatientInteractionForms[savedFormIndex] = selectedPatientInteractionForm;
              } else {
                parsedSavedPatientInteractionForms.push(selectedPatientInteractionForm);
              }

              ls.set('savedPatientInteractionForms', JSON.stringify(parsedSavedPatientInteractionForms), {encrypt: true});
            } else {
              ls.set('savedPatientInteractionForms', JSON.stringify([selectedPatientInteractionForm]), {encrypt: true});
            }

            this.$store.commit("setLastUnsavedInteraction", new Date().getTime().toString());
          }
        }
      } catch (e) {
        this.$store.commit("setLastUnsavedInteraction", new Date().getTime().toString());
        ls.remove("savedPatientInteractionForms");
      }
    },
    async formSelected(item) {
      this.selectedForm = item;
      if (this.addingForm) {
        this.confirmDialog = true;
      } else {
        await this.confirmDialogCallback(true);
      }
    },
    async confirmDialogCallback(agreed) {

      this.confirmDialog = false;

      if (!agreed) return;

      this.clearSavedForm();
      this.addingForm = false;
      this.model = {};
      this.newInteractionFormKey = this.newInteractionFormKey + 1;

      Vue.nextTick(() => {
        let interactionTypeForm = this.interactionTypeForms.find(f => f.interactionTypeId === this.selectedForm.interactionTypeId);
        let form = this.forms.find(f => f.formId === interactionTypeForm.formId);

        this.patientInteractionForm.formId = form.formId;
        this.patientInteractionForm.formData = {};
        this.patientInteractionForm.isEdited = false;
        this.patientInteractionForm.patientInteraction = {};
        this.patientInteractionForm.patientInteraction.patientId = this.patient.patientId;
        this.patientInteractionForm.patientInteraction.interactionTypeId = this.selectedForm.interactionTypeId;
        this.patientInteractionForm.patientInteraction.worklistItemId = this.localWorklistItemId;
        this.patientInteractionForm.patientInteraction.worklistItem = this.worklistItem;
        this.patientInteractionForm.patientInteraction.updatedByUserId = this.$store.state.achillesUser.userId;
        this.patientInteractionForm.patientInteraction.createdByUserId = this.$store.state.achillesUser.userId;
        this.patientInteractionForm.patientInteraction.interactionTypeDescription = this.selectedForm.description;
        this.showMoreEnabled = form.showMoreEnabled;
        this.schema = form.schema;
        this.addingForm = true;
      });
    },
    async savedNewInteractionForm(val) {
      let self = this;
      this.addingForm = false;

      if (val.success) {
        if (val.worklistItemId > 0) {
          this.localWorklistItemId = val.worklistItemId;
          await this.loadWorklistItems();
        }
        new PatientService()
            .getPatientInteractionForms(this.customerId, this.patient.patientId)
            .then((resp) => {
              let forms = [];
              resp.forEach(function (form) {
                if (form.formId === 1) {
                  // Legacy form conversion
                  let parsedNotes = JSON.parse(form.formData).Fields[0].Value;
                  form.parsedForm = {notes: parsedNotes};
                  form.formId = 1;
                } else {
                  form.parsedForm = JSON.parse(form.formData);
                }

                forms.push(form);
              });

              self.patientInteractionForms = forms;
              this.showItems = 5;
            })
            .catch((err) => {
              console.log(err);
            })
        self.savedSnackbarText = "Saved successfully";
        self.savedSnackbar = true;
        this.getCallsByType();
        this.clearSavedForm();
      } else {
        console.log("Save failed");
        self.savedSnackbarText = 'Unable to Save';
        self.savedSnackbar = true;
      }

      this.$emit('saved');
    },
    async savedInteractionForm(success) {
      let self = this;
      if (success) {
        this.clearSavedForm();
        self.savedSnackbarText = "Saved successfully";
        self.savedSnackbar = true;
        await this.loadWorklistItems();
      } else {
        self.savedSnackbarText = 'Unable to Save';
        self.savedSnackbar = true;
      }

      this.$emit('saved');
    },
    cancelledNewInteractionForm() {
      this.addingForm = false;
      this.model = {};
      this.clearSavedForm();
    },
    clearSavedForm() {
      if (this.autoSaveEnabled) {
        const savedPatientInteractionForms = ls.get('savedPatientInteractionForms', {decrypt: true}) ? ls.get('savedPatientInteractionForms', {decrypt: true}) : [];
        if (savedPatientInteractionForms.length > 0) {
          let parsedSavedPatientInteractionForms = savedPatientInteractionForms;
          if (!Array.isArray(savedPatientInteractionForms)) {
            parsedSavedPatientInteractionForms = JSON.parse(savedPatientInteractionForms);
          }
          let savedFormIndex = parsedSavedPatientInteractionForms.findIndex(f => f.patientInfo.patientId === this.patient.patientId && f.patientInfo.customerId === this.customerId);
          if (savedFormIndex > -1) {
            ls.set("savedPatientInteractionForms", parsedSavedPatientInteractionForms.filter(item => item !== parsedSavedPatientInteractionForms[savedFormIndex]), {encrypt: true});
            if (parsedSavedPatientInteractionForms.length < 2) {
              ls.remove("savedPatientInteractionForms");
            }

            this.$store.commit("setLastUnsavedInteraction", new Date().getTime().toString());
          }
        }
      }
    },
    cancelledInteractionForm() {
      console.log("Cancelled Interaction Form");
      this.clearSavedForm();
    },
    getCallsByType() {
      this.interactionsByType.totalCalls = this.patientInteractionForms.length;
      this.interactionsByType.IO = this.patientInteractionForms.filter(i => i.patientInteraction.interactionTypeId == 1).length;
      this.interactionsByType.training = this.patientInteractionForms.filter(i => i.patientInteraction.interactionTypeId == 2).length;
    },
    loadAutoSavedForm() {
      try {
        if (this.autoSaveEnabled) {
          const savedPatientInteractionForms = ls.get('savedPatientInteractionForms', {decrypt: true}) ? ls.get('savedPatientInteractionForms', {decrypt: true}) : [];
          if (savedPatientInteractionForms.length > 0) {
            let parsedSavedPatientInteractionForms = savedPatientInteractionForms;
            if (!Array.isArray(savedPatientInteractionForms)) {
              parsedSavedPatientInteractionForms = JSON.parse(savedPatientInteractionForms);
            }
            let savedForm = parsedSavedPatientInteractionForms.find(f => f.patientInteraction.patientId === this.patient.patientId);
            if (savedForm) {
              delete savedForm.patientInfo;
              delete savedForm.version;
              this.patientInteractionForm = savedForm;
              this.patientInteractionForm.formData = JSON.parse(savedForm.formData);
              this.model = JSON.parse(JSON.stringify(this.patientInteractionForm.formData));
              let matchedForm = this.forms.find(f => f.formId === savedForm.formId);
              this.schema = matchedForm.schema;
              this.addingForm = true;
              this.autoSaveData = _.clone(this.form);
            }
          }
        }
      } catch (e) {
        ls.remove("savedPatientInteractionForms");
      }
    },
    async loadWorklistItems() {
      return new PatientService()
          .getPatientWorklistItems(this.customerId, this.patient.patientId)
          .then((resp) => {
            this.worklistItems = resp;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getExcludedForms() {
       return [
          InteractionTypeEnum.Onboarding,
          InteractionTypeEnum.ClinicFeedback,
          InteractionTypeEnum.Inbound,
          InteractionTypeEnum.Assessment,
          InteractionTypeEnum.Welcome,
          InteractionTypeEnum.Prequalification,
          InteractionTypeEnum.InboundTraining,
          InteractionTypeEnum.DeviceAssigned,
          InteractionTypeEnum.DeviceReplaced,
          InteractionTypeEnum.Escalation,
          InteractionTypeEnum.PatientWithdrew,
          InteractionTypeEnum.InitialOutreach,
          InteractionTypeEnum.Training,
          InteractionTypeEnum.Offloading,
          InteractionTypeEnum.Reengagement,
          InteractionTypeEnum.Resolution,
          InteractionTypeEnum.Wellness,
          InteractionTypeEnum.InflammationCheckin,
          InteractionTypeEnum.Renewal,
          InteractionTypeEnum.CallBackFollowUp,
          InteractionTypeEnum.CompleteOnboarding,
          InteractionTypeEnum.PostRenewal,
          InteractionTypeEnum.RenewalPreQualification
       ]
    },
     // Is CompOnb, hide Renewal
     hideRenewal(item){
       let interactions = [
          InteractionTypeEnum.StructuredInteractions_Renewal
       ]
       return interactions.includes(item.interactionTypeId) && this.isCompleteOnboardingCust
     },
     // Is not CompOnb, hide Post Renewal
     hidePostRenewal(item){
        let interactions = [
            InteractionTypeEnum.StructuredInteractions_PostRenewal
        ]
        return interactions.includes(item.interactionTypeId) &&  !this.isCompleteOnboardingCust
     },
     // hide Complete Onboarding when...
     // Is not Complete Onboarding model and has IO/Training items and has no CompleteOnboarding items
     hideOnboarding(item){
        let interactions = [
           InteractionTypeEnum.StructuredInteractions_CompleteOnboarding
        ]
        
        return interactions.includes(item.interactionTypeId) && !this.isCompleteOnboardingCust && this.hasBeganIOTrainingProcess && !this.hasCompleteOnboardingWLItems;
     },
     // hide Training when...
     // Is not Initial Outreach customer and has not began IO/Training process
     hideTraining(item){
        let interactions = [
           InteractionTypeEnum.StructuredInteractions_Training
        ];
        
        return interactions.includes(item.interactionTypeId) && !this.isIOTrainingCust && !this.hasBeganIOTrainingProcess
     },
     // hide Initial Outreach when...
     // Is not Initial Outreach customer and has not began IO/Training process
     hideInitialOutreach(item){
        let interactions = [
           InteractionTypeEnum.StructuredInteractions_InitialOutreach
        ];
        
        return interactions.includes(item.interactionTypeId) && !this.isIOTrainingCust && !this.hasBeganIOTrainingProcess;
     }
  },
  created() {
    ls.config.encrypt = true;
    ls.config.secret = this.$store.state.achillesUser.uniqueKey;
    ls.config.encrypter = (data, secret) => AES.encrypt(JSON.stringify(data), secret).toString();
    ls.config.decrypter = (data, secret) => JSON.parse(AES.decrypt(data, secret).toString(encUTF8));

    this.isLoading = true;
    let promises = [];

    if (this.$store.state.states.length === 0) {
      promises.push(new ReferenceDataService()
          .getStates()
          .then((value) => {
            this.$store.commit("setStates", value);
          })
          .catch((err) => {
            console.log(err);
          }));
    }

    if (this.$store.state.footConditions.length === 0) {
      promises.push(new ReferenceDataService()
          .getFootConditions()
          .then((value) => {
            this.$store.commit("setFootConditions", value);
          })
          .catch((err) => {
            console.log(err);
          }));
    }

    if (this.$store.state.footLocations.length === 0) {
      promises.push(new ReferenceDataService()
          .getFootLocations()
          .then((value) => {
            this.$store.commit("setFootLocations", value);
          })
          .catch((err) => {
            console.log(err);
          }));
    }

    if (this.$store.state.foots.length === 0) {
      promises.push(new ReferenceDataService()
          .getFoots()
          .then((value) => {
            this.$store.commit("setFoots", value);
          })
          .catch((err) => {
            console.log(err);
          }));
    }


    if (this.$store.state.interactionTypes.length === 0) {
      promises.push(new ReferenceDataService()
          .getInteractionTypes()
          .then((resp) => {
            this.$store.commit("setInteractionTypes", resp);
          })
          .catch((err) => {
            console.log(err);
          })
      );
    }

    if (this.$store.state.interactionTypeForms.length === 0) {
      promises.push(new ReferenceDataService()
          .getInteractionTypeForms()
          .then((resp) => {
            this.$store.commit("setInteractionTypeForms", resp);
          })
          .catch((err) => {
            console.log(err);
          })
      );
    }

    if (this.useTestSchema) {
      localStorage.debug = 'vjsf:expr';
    }

    if (this.$store.state.forms.length === 0 || this.useTestSchema) {
      const self = this;
      promises.push(new ReferenceDataService()
          .getForms()
          .then((resp) => {
            let forms = [];
            resp.forEach(function (form) {
              if (self.useTestSchema) {
                form.schema = JSON.parse(self.testSchema);
              } else {
                form.schema = JSON.parse(form.schema);
              }
              forms.push(form);
            });

            this.$store.commit("setForms", forms);
          })
          .catch((err) => {
            console.log(err);
          })
      );
    }

    if (this.$store.state.users.length === 0) {
      promises.push(new UserService()
          .getUsers()
          .then((resp) => {
            this.$store.commit("setUsers", resp);
          })
          .catch((err) => {
            console.log(err);
          }));
    }

    if (this.localWorklistItemId > 0) {
      promises.push(new WorklistItemService()
          .getWorklistItem(this.customerId, this.localWorklistItemId)
          .then((resp) => {
            this.worklistItem = resp;
          })
          .catch((err) => {
            console.log(err);
          }));
    } else if (this.worklistItemId > 0) {
      promises.push(new WorklistItemService()
          .getWorklistItem(this.customerId, this.worklistItemId)
          .then((resp) => {
            this.worklistItem = resp;
          })
          .catch((err) => {
            console.log(err);
          }));
    }


    promises.push(this.loadWorklistItems());

    promises.push(new PatientService()
        .getPatientInteractionForms(this.customerId, this.patient.patientId)
        .then((resp) => {
          let forms = [];
          resp.forEach(function (form) {
            if (form.formId === 1) {
              // Legacy form conversion
              let parsedNotes = JSON.parse(form.formData).Fields[0].Value;
              form.parsedForm = {notes: parsedNotes};
            } else {
              form.parsedForm = JSON.parse(form.formData);
            }

            forms.push(form);
          });

          this.patientInteractionForms = forms;
        })
        .catch((err) => {
          console.log(err);
        }));

    let existingWlItemTypes = [];
    if (!this.isIOTrainingCust) {
      promises.push(new WorklistItemHistoryService()
          .getWorklistItems(this.customerId, this.patient.patientId)
          .then((resp) => {
            resp.forEach(r => {
              if (!existingWlItemTypes.includes(r.worklistItemTypeId)) {
                existingWlItemTypes.push(r.worklistItemTypeId);
              }
            })

          })
          .catch((err) => {
            console.log(err);
          }));
      promises.push(new PatientService()
          .getPatientWorklistItems(this.customerId, this.patient.patientId)
          .then((resp) => {
            resp.forEach(r => {
              if (!existingWlItemTypes.includes(r.worklistItemTypeId)) {
                existingWlItemTypes.push(r.worklistItemTypeId);
              }
            })

          })
          .catch((err) => {
            console.log(err);
          }));
    }

    Promise.allSettled(promises).then(() => {
      this.localWorklistItemId = this.worklistItemId;
      this.forms = this.$store.state.forms;
      this.achillesUsers = this.$store.state.users;
      this.interactionTypeForms = this.$store.state.interactionTypeForms;
      this.interactionTypes = this.$store.state.interactionTypes.sort((a, b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
      if (existingWlItemTypes.some(e => e === WorklistItemTypeEnum.InitialOutreach || e === WorklistItemTypeEnum.Training)) {
        this.hasBeganIOTrainingProcess = true;
      }
      if (existingWlItemTypes.some(e => e === WorklistItemTypeEnum.CompleteOnboarding))
        this.hasCompleteOnboardingWLItems = true;
       
      //old forms -> exclude from drop down
      const excludedForms = this.getExcludedForms();
      this.interactionTypesFiltered = this.interactionTypes.filter(obj => {
        if (excludedForms.includes(obj.interactionTypeId)) return false;
        
        //business rules which forms to display in dropdown
         //  Is CompOnb, hide Renewal
        if (this.hideRenewal(obj)) return false;
         // Is not CompOnb, hide Post Renewal
        if (this.hidePostRenewal(obj)) return false; 
         // hide Complete Onboarding when
         // Is not Complete Onboarding model and has IO/Training items and has no CompleteOnboarding items
        if (this.hideOnboarding(obj)) return false;
         // hide Training when
         // Is not Initial Outreach customer
         // and has not began IO/Training process
        if (this.hideTraining(obj)) return false;
         // hide Initial Outreach when
         // Is not Initial Outreach customer
         // and has not began IO/Training process
        if (this.hideInitialOutreach(obj)) return false;
       
        return true;
      });


      this.getCallsByType();
      this.loadAutoSavedForm();
      this.isLoading = false;
      this.isLoaded = true;
    });
  },
  mounted() {
    // 
  }
};
</script>

<style scoped>


.confirm-dialog .v-card__title {
  color: #FFFFFF;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
}

.confirm-dialog .v-card__text {
  margin-top: 26px;
  font-size: 1rem !important;
}
</style>