<template>
   <v-card :loading="loading">
      <v-card-title>
         <div class="headline">Worklist Item Cadence</div>
      </v-card-title>
      <v-card-text>
         <v-container>
            <v-dialog v-model="confirmDialog" v-if="confirmDialog"
               persistent
               max-width="500"
               content-class="confirm-dialog">
               <v-card>
                  <v-card-title class="headline primary">
                     Save?
                  </v-card-title>
                  <v-card-text>
                     Are you sure you want to save these changes?
                  </v-card-text>
                  <v-card-actions>
                     <v-spacer></v-spacer>
                     <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
                     <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
                  </v-card-actions>
               </v-card>
            </v-dialog>
            <v-form ref="configForm">
               <div v-if="patient !== null">
                  <v-row>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-checkbox v-model="patient.hasRequestedDoNotCall"
                                  label="Has Requested Do Not Call"></v-checkbox>
                    </span>
                           </template>
                           <span>Will stop all Worklist Items for this Patient. Also places a "DO NOT CALL" message on the contact card.</span>
                        </v-tooltip>
                     
                     </v-col>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-checkbox v-model="patient.offloadingCallsOnly" label="Offloading Calls Only"></v-checkbox>
                     </v-col>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-checkbox v-model="patient.noOffloadingCheckin" label="No Inflammation Checkin"></v-checkbox>
                     </v-col>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                    <v-checkbox v-model="patient.offloadingCallDelay" label="Offloading Call Delay"></v-checkbox>  
                    </span>
                           
                           </template>
                           <span>Only create Offloading Worklist Item if 30 days since last called</span>
                        </v-tooltip>
                     
                     </v-col>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-checkbox v-model="patient.checkinCallDelay" label="Checkin Call Delay"></v-checkbox>
                    </span>
                           </template>
                           <span>Only create Checkin Worklist Item if 30 days since last called</span>
                        </v-tooltip>
                     
                     </v-col>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-bind="attrs"
                                  v-model.number="patient.temperatureThresholdOverride"
                                  v-on="on"
                                  :rules="tempThresholdRules"
                                  label="Temperature Threshold Override"
                                  prepend-icon="mdi-thermometer" type="number"
                                  @input="($value) => (patient.temperatureThresholdOverride = parseFloat($value) || null)">
                              </v-text-field>
                           </template>
                           <span>Only provide a value if the override is different than the current default of 2.22</span>
                        </v-tooltip>
                     
                     </v-col>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-bind="attrs"
                                            v-model.number="patient.reengagementDaysOverride"
                                            v-on="on"
                                            :rules="reengagementDaysOverrideRules"
                                            label="Reengagement Days Override"
                                            prepend-icon="mdi-counter" type="number"
                                            @input="($value) => (patient.reengagementDaysOverride = parseFloat($value) || null)"></v-text-field>
                           </template>
                           <span>Only provide a value if the override is different than the current default of 4</span>
                        </v-tooltip>
                     
                     </v-col>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-menu
                            ref="snoozedTillMenu"
                            v-model="snoozedTillMenu"
                            :close-on-content-click="false"
                            :return-value.sync="patientSnoozedTill"
                            min-width="auto"
                            offset-y
                            transition="scale-transition">
                           <template v-slot:activator="{ on, attrs }">
                              <div class="d-flex">
                                 <v-tooltip bottom>
                                    <template #activator="{ on: tooltip }">
                                       <v-text-field
                                           v-model="patientSnoozedTillFormatted" v-bind="attrs"
                                           v-on="{ ...on, ...tooltip }"
                                           clearable
                                           label="Snoozed Till"
                                           prepend-icon="mdi-calendar"
                                           readonly
                                       ></v-text-field>
                                    </template>
                                    <span>Will not create Worklist Items until Snoozed Till date Expires. Offloading Worklist Items will be created ignoring Snoozed Till.</span>
                                 </v-tooltip>
                              </div>
                           
                           </template>
                           <v-date-picker
                               v-model="patientSnoozedTill"
                               :min="new Date().toISOString().substring(0, 10)"
                               no-title
                               scrollable>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text @click="snoozedTillMenu = false">
                                 Cancel
                              </v-btn>
                              <v-btn color="primary" text @click="$refs.snoozedTillMenu.save(patientSnoozedTill)">
                                 OK
                              </v-btn>
                           </v-date-picker>
                        </v-menu>
                     </v-col>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-menu
                            ref="resetInitialOutreachProtocolFromDateMenu"
                            v-model="resetInitialOutreachProtocolFromDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="patientResetInitialOutreachProtocolFromDateFormatted"
                            min-width="auto"
                            offset-y
                            transition="scale-transition">
                           <template v-slot:activator="{ on, attrs }">
                              <div class="d-flex">
                                 <v-text-field
                                     v-model="patientResetInitialOutreachProtocolFromDateFormatted" v-bind="attrs"
                                     v-on="on"
                                     clearable
                                     label="Reset Initial Outreach Protocol From Date"
                                     prepend-icon="mdi-calendar"
                                     readonly
                                     @blur="patientResetInitialOutreachProtocolFromDate = parseDate(patientResetInitialOutreachProtocolFromDateFormatted)"></v-text-field>
                              </div>
                           
                           </template>
                           <v-date-picker v-model="patientResetInitialOutreachProtocolFromDate" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text @click="resetInitialOutreachProtocolFromDateMenu = false">
                                 Cancel
                              </v-btn>
                              <v-btn color="primary" text
                                     @click="$refs.resetInitialOutreachProtocolFromDateMenu.save(patientResetInitialOutreachProtocolFromDateFormatted)">
                                 OK
                              </v-btn>
                           </v-date-picker>
                        </v-menu>
                     </v-col>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-menu
                            ref="resetTrainingProtocolFromDateMenu"
                            v-model="resetTrainingProtocolFromDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="patientResetTrainingProtocolFromDateFormatted"
                            min-width="auto"
                            offset-y
                            transition="scale-transition">
                           <template v-slot:activator="{ on, attrs }">
                              <div class="d-flex">
                                 <v-text-field
                                     v-model="patientResetTrainingProtocolFromDateFormatted" v-bind="attrs"
                                     v-on="on"
                                     clearable
                                     label="Reset Training Protocol From Date"
                                     prepend-icon="mdi-calendar"
                                     readonly
                                     @blur="patientResetTrainingProtocolFromDate = parseDate(patientResetTrainingProtocolFromDateFormatted)">
                                 </v-text-field>
                              </div>
                           
                           </template>
                           <v-date-picker v-model="patientResetTrainingProtocolFromDate" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text @click="resetTrainingProtocolFromDateMenu = false">
                                 Cancel
                              </v-btn>
                              <v-btn color="primary" text
                                     @click="$refs.resetTrainingProtocolFromDateMenu.save(patientResetTrainingProtocolFromDateFormatted)">
                                 OK
                              </v-btn>
                           </v-date-picker>
                        </v-menu>
                     </v-col>
                  </v-row>
               </div>
            </v-form>
         </v-container>
      </v-card-text>
      <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn @click="onCancel" color="blue darken-1" text> Cancel</v-btn>
         <v-btn color="blue darken-1" text @click="onSave"> Save</v-btn>
      </v-card-actions>
   </v-card>

</template>

<script>
   
   import PatientService from "@/services/PatientService";
   import "vuetify-dialog/dist/vuetify-dialog.css";
   import Utils from "./../mixins/utils";
   import moment from 'moment';
   
   export default {
      name: "PatientConfig",
      props: {
         customerId: Number,
         patientId: Number
      },
      mixins:[Utils],
      components: {},
      data: () => ({
         loading: false,
         confirmDialog: false,
         patientResetTrainingProtocolFromDate: null,
         patientResetTrainingProtocolFromDateFormatted: null,
         resetTrainingProtocolFromDateMenu: false,
         snoozedTillMenu: false,
         patientSnoozedTill: null,
         resetInitialOutreachProtocolFromDateMenu: false,
         patientResetInitialOutreachProtocolFromDate: null,
         patientResetInitialOutreachProtocolFromDateFormatted: null,
         patient: null,
         initialPatient: null,
         customer: null,
         tempThresholdRules: [
            v => (!v || v <= 9.99) || "Temperature Threshold Override should be under 9.99",
            v => (!v || v >= 0) || "Temperature Threshold Override should be greater than 0",
            v => (!v || v !== 2.22) || "This is currently the default no override is needed"
         ],
         reengagementDaysOverrideRules: [
            v => (!v || v <= 90) || "Reengagement Days Override should be under 999",
            v => (!v || v >= 0) || "Reengagement Days Override should be greater than 0",
            v => (!v || v !== 4) || "This is currently the default no override is needed"
         ]
      }),
      async created() {
         await this.loadData();
      },
      computed: {
         patientSnoozedTillFormatted() {
            if (this.patientSnoozedTill == null) {
               return '';
            }
            let isoDateString = moment(this.patientSnoozedTill).toISOString();
            return new Intl.DateTimeFormat('en-US', {
                     month: '2-digit', day: '2-digit', year: 'numeric' }).format(new Date(isoDateString));
         }
      },
      watch: {
         patientResetTrainingProtocolFromDate() {
            this.patientResetTrainingProtocolFromDateFormatted =
                this.formatDate(this.patientResetTrainingProtocolFromDate);
            this.patient.resetTrainingProtocolFromDate = this.patientResetTrainingProtocolFromDate;
         },
         patientResetInitialOutreachProtocolFromDate() {
            this.patientResetInitialOutreachProtocolFromDateFormatted =
                this.formatDate(this.patientResetInitialOutreachProtocolFromDate);
            this.patient.resetInitialOutreachProtocolFromDate = this.patientResetInitialOutreachProtocolFromDate;
         }
      },
      methods: {
         onSiteChange() {
            this.resetState();
         },
         async loadData() {
            this.loading = true;
            let promises = [];
            promises.push(new PatientService()
                              .get(this.customerId, this.patientId)
                              .then((resp) => {
                                 this.patient = resp;

                                 if (this.patient.snoozedTill && this.patient.snoozedTill.length > 10) {
                                    this.patientSnoozedTill = this.patient.snoozedTill.substr(0, 10);
                                 }
                   
                                 if (this.patient.resetTrainingProtocolFromDate &&
                                     this.patient.resetTrainingProtocolFromDate.length > 10) {
                                    this.patientResetTrainingProtocolFromDate =
                                        this.patient.resetTrainingProtocolFromDate.substr(0, 10);
                                 }
                   
                                 if (this.patient.resetInitialOutreachProtocolFromDate &&
                                     this.patient.resetInitialOutreachProtocolFromDate.length > 10) {
                                    this.patientResetInitialOutreachProtocolFromDate =
                                        this.patient.resetInitialOutreachProtocolFromDate.substr(0, 10);
                                 }
                   
                                 this.initialPatient = Object.assign({}, resp);
                              })
                              .catch((err) => {
                                 console.log(err);
                              }));
            
            await Promise.allSettled(promises);
            this.loading = false;
         },
         async confirmDialogCallback (agreed) {
            this.confirmDialog = false;

            if (!agreed) return;
            try {
               this.loading = true;
               this.patient.snoozedTill = this.patientSnoozedTill;
               await new PatientService().put(this.customerId, this.patient);
               
               await this.loadData();
               this.loading = false;
               
               await this.$dialog.info({
                                          text: "Patient Configuration was saved successfully.",
                                          title: "Saved",
                                          actions: ["Okay"]
                                       });
               this.$emit("saved");
            }
            catch (e) {
               console.log(e);
               await this.$dialog.error({
                                           text: "Unable to save.",
                                           title: "Error",
                                           actions: ["Okay"]
                                        });
            }
            finally {
               this.loading = false;
            }
         },
         async onSave() {
            if (!this.$refs.configForm.validate()) {
               await this.$dialog.error({
                                           text: "Unable to save.",
                                           title: "Error",
                                           actions: ["Okay"]
                                        });
               return;
            }

            this.confirmDialog = true;
         },
         onCancel() {
            this.resetState();
            this.$emit("cancelled");
         },
         resetState() {
            this.patient = Object.assign({}, this.initialPatient);
         }
      }
   };
</script>

<style scoped>
   .confirm-dialog .v-card__title {
    color: #FFFFFF;
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
  }

  .confirm-dialog .v-card__text {
    margin-top: 26px;
    font-size: 1rem !important;
  }
</style>