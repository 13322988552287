import Vue from "vue";
import VueApollo from "vue-apollo";
import {createApolloClient} from "vue-cli-plugin-apollo/graphql-client";
import "regenerator-runtime/runtime.js";
import {ApolloLink} from "apollo-link";
import store from "./store";
import { v4 as uuidv4 } from 'uuid';
import { InMemoryCache } from 'apollo-cache-inmemory';

// Install the vue plugin
Vue.use(VueApollo);


// Http endpoint
const httpEndpoint = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_APOLLO_SERVICE_ROUTE;

const authMiddleware = new ApolloLink((operation, forward) => {
   // add the authorization to the headers
   operation.setContext(
      {
         headers:
            {
               "Authorization": `Bearer ${store.getters.getGUserProfile.token}`,
               "Request-Id": uuidv4()
            }
      });
   return forward(operation);
});

const inMemoryCache = new InMemoryCache(
   {
      addTypename: false
   });

// Config
const defaultOptions = {
   // You can use `https` for secure connection (recommended in production)
   httpEndpoint,

   // Enable Automatic Query persisting with Apollo Engine
   persisting: false,
   // Is being rendered on the server?
   ssr: false,
   
   // Override default apollo link
   // note: don't override httpLink here, specify httpLink options in the
   // httpLinkOptions property of defaultOptions.
   link: authMiddleware,
   cache: inMemoryCache
};

// Call this in the Vue app file
export function createProvider(options = {}) {
   // Create apollo client
   const {apolloClient} = createApolloClient({...defaultOptions, ...options});
   
   // Create vue apollo provider
   const apolloProvider = new VueApollo({
                                 defaultClient: apolloClient,
                                 defaultOptions: {
                                     $query: {
                                     // fetchPolicy: 'cache-and-network',
                                     }
                                 },
                                 errorHandler(error) {
                                    // eslint-disable-next-line no-console
                                    console.log("%cError",
                                                  "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
                                                  error.message);
                                    }
                                 });
   
   return apolloProvider;
}
