<template v-slot:top>
  <div>
      <v-card>
         <v-alert :value="alertRef.alert" :type="alertRef.alertType" dense transition="fade-transition">
            {{alertRef.alertMessage}}
         </v-alert>
         <v-card-title>
            <v-row>
               <v-col md="3" >
                 <span v-if="!title">{{personalListName}} Worklist</span>
                 <span v-if="title">{{title}}</span>
                  <v-icon @click="getWorklistItems">mdi-refresh</v-icon>
                  <v-switch dense 
                            v-model="isViewFlex"
                            @change="getFlex"
                            label="View Flex Worklist"
                            v-if="showFlex">
                  </v-switch>
               </v-col>
               <v-col md="3" v-if="!isFiltered">
                  <v-switch dense
                            v-model="isViewEscalations"
                            @change="getEscalation"
                            label="View Escalations Worklist"
                            v-if="showEscalations"
                            class="mt-12">
                  </v-switch>
               </v-col>
               <v-col :offset-md="!isFiltered ? 4 : 7" md="2">
                  <v-spacer></v-spacer>
                  <v-text-field v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                clearable
                                @click:clear="searchCleared">
                  </v-text-field>
               </v-col>
            </v-row>
         </v-card-title>
         <v-data-table :headers="_headers"
                       :items="worklistItems"
                       class="elevation-1"
                       :server-items-length="totalWorklistItems"
                       :options.sync="options"
                       :loading="loading"
                       :search="search"
                       :footer-props="{
                          showFirstLastPage: true,
                          'items-per-page-options': rowsPerPage}">
            <template #[`item.escalatedOn`]="{ item }">
               <span>{{item.escalatedOn ? new Date(item.escalatedOn + "Z").toLocaleString() : ""}}</span>
            </template>
            <template v-slot:top>
               <v-dialog v-model="dialog" max-width="800px" persistent v-if="dialog">
                  <v-toolbar dark color="primary">
                     <v-btn icon dark @click="closeWorklistItemDialog">
                        <v-icon>mdi-close</v-icon>
                     </v-btn>
                     <v-toolbar-title>Edit Worklist Item
                     </v-toolbar-title>
                     <v-spacer></v-spacer>
                     <v-toolbar-items>
                        <v-btn dark text @click="closeWorklistItemDialog"> Close</v-btn>
                     </v-toolbar-items>
                  </v-toolbar>
                  <WorklistItemEdit
                      :customerId="customerId"
                      :worklistItemId="worklistItemId"
                      :patientId="patientId"
                      :key="componentKey"/>
               </v-dialog>
            </template>
            <template #[`item.actions`]="{ item }">
               <v-icon medium class="mr-2" @click="editItem(item)" v-if="isAuthorized(item)">
                  mdi-pencil
               </v-icon>
               <v-btn small class="mr-2" @click="claimItem(item)" v-if="item.assignedToUserId === null">
                  Claim
               </v-btn>
               <v-tooltip bottom>
                  <template #[`activator`]="{ on }">
                     <v-icon medium class="mr-2" v-on="on">mdi-eye</v-icon>
                  </template>
                  <span>
                     Is Escalation: {{item.isEscalation ? "Yes" : "No"}} <br/>
                     Escalation Status: {{item.isEscalation ? item.escalationStatus : "NA"}} <br/>
                     Created by: {{item.createdByUserId == null ? "Achilles" : getUserNameForId(item.createdByUserId)}}<br/>
                     Last Updated by: {{item.updatedByUserId == null ? "None" : getUserNameForId(item.updatedByUserId)}}
                  </span>
               </v-tooltip>
          
            </template>
            <template #[`item.status`]="{ item }">
               {{item.status}}
               <v-icon medium class="mr-2" v-if="item.isEscalation">mdi-arrow-up-bold</v-icon>
            </template>
            <template #[`item.patientName`]="{ item }">
               {{item.patientName}}
              <v-icon medium class="mr-2" @click="viewPatient(item)">
                mdi-open-in-new
              </v-icon>
                   </template>
                   <template #[`item.escalationStatus`]="{ item }">
                      {{item.escalationStatus}}
                      <v-icon medium class="mr-2" @click="viewEscalationDialog(item)" v-if="item.isEscalation">
                         mdi-open-in-new
                      </v-icon>
                   </template>
                </v-data-table>
             </v-card>
             
             <span class="caption ma-2"><v-icon small>mdi-open-in-new</v-icon>Open</span>
             <span class="caption ma-2"><v-icon small>mdi-arrow-up-bold</v-icon>Escalated</span>
             <span class="caption ma-2"><v-icon small>mdi-pencil</v-icon>Edit</span>
             <span class="caption ma-2"><v-icon small>mdi-eye</v-icon>View</span>
             
             <!-- patient dialog -->
      <v-dialog v-model="dialogPatient" v-if="dialogPatient"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                persistent :retain-focus="false">
         <v-card>
            <div style="background: #123559" v-if="false">
               <v-btn icon dark @click="dialogPatient = false">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
               <v-btn class="float-right" dark text @click="dialogPatient = false"> Close</v-btn>
            </div>
            <v-toolbar dark color="primary" v-if="false">
               <v-btn icon dark @click="closePatientDialog">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
               <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                     <v-toolbar-title class="pl-8" @click="copyToClipboard(patientName)" v-on="on" v-bind="attrs">
                        {{patientName}}
                     </v-toolbar-title>
                  </template>
                  <span>Click to copy to clipboard</span>
               </v-tooltip>
               <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                     <v-toolbar-title class="pl-8 blue-grey--text text--lighten-4"
                                      @click="copyToClipboard(patientDesignation)"
                                      v-on="on"
                                      v-bind="attrs">
                        {{patientDesignation}}
                     </v-toolbar-title>
                  </template>
                  <span>Click to copy to clipboard</span>
               </v-tooltip>
               <v-toolbar-title class="pl-8 blue-grey--text text--lighten-4">
                  {{customerName}}
               </v-toolbar-title>
               <v-spacer></v-spacer>
               <v-toolbar-items>
                  <v-btn dark text @click="closePatientDialog"> Close</v-btn>
               </v-toolbar-items>
               <v-snackbar v-model="clipboardSnackbar" :timeout="1100" bottom>
                  Copied "{{clipboardText}}" to clipboard
                  
                  <template v-slot:action="{ attrs }">
                     <v-btn color="blue" text v-bind="attrs" @click="clipboardSnackbar = false">
                        Close
                     </v-btn>
                  </template>
               </v-snackbar>
            </v-toolbar>
            <PatientDetails :patientId="patient.patientId"
                            :customerId="customerId"
                            :customer-name="customerName"
                            :worklistItemId="worklistItemId"
                            :key="componentKey"
                            v-on:closed="closePatientDialog"/>
         </v-card>
      </v-dialog>
  
      
      <!--escalation dialog-->
      <v-dialog v-model="dialogEscalation" v-if="dialogEscalation"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                persistent
                :retain-focus="false">
         <v-card>
            <v-toolbar dark color="primary">
               <v-btn icon dark @click="closeEscalationDialog">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
               <v-toolbar-title>Escalation for {{patientName}} -
                  <span class="subtitle-2">{{customerName}}</span></v-toolbar-title>
               <v-spacer></v-spacer>
               <v-toolbar-items>
                  <v-btn dark text @click="closeEscalationDialog"> Close</v-btn>
               </v-toolbar-items>
            </v-toolbar>
            <EscalationDetails :customerId="customerId"
                               :escalationId="escalationId"
                               :worklistItemId="worklistItemId"
                               :key="componentKey"
                               @escalationUpdated="getWorklistItems"/>
         </v-card>
      </v-dialog>
  </div>
</template>


<script>
   import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import CustomerWorklistItemsService from "../services/CustomerWorklistItemsService";
import PatientService from "../services/PatientService";
import UserService from "../services/UserService";
import WorklistItemService from "../services/WorklistItemService";
import EscalationDetails from "./EscalationDetails";
import PatientDetails from "./PatientDetails";
import WorklistItemEdit from "./WorklistItemEdit";

 
   
   Vue.use(VueClipboard);
   
   export default {
      name: "Worklist",
      props: {
        podId: Number,
        assignedToUserPodIds: Array,
        type: String,
        title: String,
        userId: Number,
        podIds: Array,
        viewFlex: Boolean,
        viewEscalations: Boolean
      },
      components: {
         PatientDetails,
         EscalationDetails,
         WorklistItemEdit,
      },
      data: () => ({
         snoozeMenu: false,
         isViewFlex: false, 
         isViewEscalations: false,
         worklistItemId: 0,
         personalListName: "",
         patientName: "",
         patientDesignation: "",
         customerName: "",
         loading: true,
         totalWorklistItems: 0,
         search: "",
         dialog: false,
         dialogPatient: false,
         dialogEscalation: false,
         escalationId: 0,
         options: {},
         achillesUsers: [],
         headers: [
            {
               text: "Patient Name",
               align: "start",
               value: "patientName",
               forFlex: true,
               forEscalation: true
            },
            {
               text: "Designation",
               value: "patientDesignation",
               forFlex: true,
               forEscalation: true
            },
            {
               text: "Customer",
               value: "customerName",
               forFlex: true,
               forEscalation: true
            },
            {
               text: "Type",
               value: "type",
               forFlex: true,
               forEscalation: true
            },
            {
               text: "Timezone",
               value: "timeZone",
               forFlex: true,
               forEscalation: true
            },
            {
               text: "Status",
               value: "status",
               forFlex: true,
               forEscalation: false
            },
            {
               text: "Escalation Status",
               value: "escalationStatus",
               forEscalation: true,
               forFlex: false
            },
            {
               text: "Escalation Date",
               value: "escalatedOn",
               forEscalation: true,
               forFlex: false
            },
            {
               text: "Escalation Type",
               value: "escalationType",
               forEscalation: true,
               forFlex: false
            },
            {
               text: "Assigned User",
               value: "assignedToUser",
               forFlex: true,
               forEscalation: true
            },
           {
             text: "Pod",
             value: "podName",
             forFlex: true,
             forEscalation: true
           },
            {
               text: "Actions",
               value: "actions",
               sortable: false,
               forFlex: true,
               forEscalation: true
            }
         ],
         worklistItems: [],
         worklistItem: {},
         copyOfWorklistItem: {},
         clipboardSnackbar: false,
         clipboardText: "",
         editedIndex: -1,
         editedItem: {},
         editedPatient: {},
         defaultItem: {},
         patient: {},
         member: {},
         patientStatuses: [],
         customerId: 0,
         patientId: 0,
         componentKey: 0,
         alertRef: {
            alert: false,
            alertType: "success",
            alertMessage: ""
         },
         patientStatusType: {
            "Qualified": 1,
            "NotQualified": 2,
            "ConsentPending": 3,
            "PendingTraining": 4,
            "Trained": 5,
            "Scanning": 6,
            "Withdrawn": 7,
            "Renewal": 8,
            "Decline": 9,
            "ConsentReceived": 10
         },
         patientScanStatusType: {
            "Awaiting": 1,
            "FirstScan": 2,
            "Symmetrical": 3,
            "Asymmetrical": 4,
            "Disengaged": 5,
            "NA": 6
         },
         worklistItemStatusType: {
            "Open": 1,
            "Closed": 2,
            "Snoozed": 3
         },
         worklistItemType: {
            "InitialOutreach": 1,
            "Training": 2,
            "Onboarding": 3,
            "Offloading": 4,
            "Reengagement": 5,
            "Resolution": 6,
            "ClinicFeedback": 7,
            "Inbound": 8,
            "Wellness": 9,
            "DeviceAction": 10,
            "PatientCallRequested": 11,
            "Escalation": 12,
            "Replacement": 13,
            "Renewal": 14,
            "Prequalification": 15,
            "InflammationCheckin": 16
         },
         dialogMember: false,
         memberId:0
      }),
      
      computed: {
         rowsPerPage() {
            return this.$rowsPerPage;
         },
        isFiltered() {
          return !!this.podId || !!this.type || !!this.podIds;
        },
        showEscalations() {
          return !this.isFiltered && (this.isClinicalOperationsUser || this.isSalesSuperviser || this.hasEscalationsWorklistAccess);
        },
        showFlex() {
          return !this.isFiltered && (this.isCareManagerUser || this.isClinicalOperationsUser || this.isDeviceOperationsUser || this.hasFlexWorklistAccess);
        },
         _headers() {
            if (this.isViewEscalations || this.isViewFlex) {
               return this.headers.filter(x => x.forEscalation == this.isViewEscalations || x.forFlex == this.isViewFlex);
            } else {
               if (this.isCareManagerUser || this.isSuperviser) {
                  return this.headers.filter(x => x.forFlex);
               } else {
                  return this.headers.filter(x => x.forEscalation);
               }
            }
         },
         console: () => console,
         formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Workitem";
         },
         hasFlexWorklistAccess() {
            let access;
            if (this.$store.state.achillesUser.userRoles) {
               access = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                          r.role.roleAccesses ?
                                                                          r.role.roleAccesses.some(
                                                                              ra => ra.access.name ===
                                                                                    "FlexWorklistAccess") : false
               );
            }
            return !!access;
         },
         hasEscalationsWorklistAccess() {
            let access;
            if (this.$store.state.achillesUser.userRoles) {
               access = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                          r.role.roleAccesses ?
                                                                          r.role.roleAccesses.some(
                                                                              ra => ra.access.name ===
                                                                                    "EscalationsWorklistAccess") : false
               );
            }
            return !!access;
         },
         isSuperviser() {
            let role = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                         r.role.name.includes("CareManagerSuperviser")
            );
            return !!role;
         },
         isCareManagerUser() {
            let role = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                         r.role.name == "CareManager"
            );
            return !!role;
         },
         isSalesSuperviser() {
            let role = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                         r.role.name.includes("SalesSuperviser")
            );
            return !!role;
         },
         isClinicalOperationsUser() {
            let role = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                         r.role.name == "ClinicalOperations"
            );
            return !!role;
         },
         isDeviceOperationsUser() {
            let role = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                         r.role.name == "DeviceOperations"
            );
            return !!role;
         },
         achillesPodiUsers() {
            return this.achillesUsers.filter(u => u.email.includes("@podimetrics.com") && u.isServiceAccount == false && u.isActive===true);
         },
         dateFormatted() {
            return new Intl.DateTimeFormat('en-US', {
                     month: '2-digit', day: '2-digit', year: 'numeric' }).format(new Date(this.editedPatient.snoozedTill));
         },
         tomorrow() {
            let date = new Date();
            // Add a day
            date.setDate(date.getDate());
            return date.toISOString().substr(0, 10);
         }
      },
      
      watch: {
         options: {
            handler() {
               this.getWorklistItems();
            },
            deep: true
         },
         search: {
            handler(val) {
               if (!val) {
                  return;
               }
               this.options.page = 1;
               this.fetchEntriesDebounced();
            },
            deep: true
         }
      },
      
      mounted() {
         this.isViewFlex = this.viewFlex;
         this.isViewEscalations = this.viewEscalations
         this.getUsers();
      },
      methods: {
         closePatientDialog() {
            this.dialogPatient = false;
            this.getWorklistItems();
         },
         closeEscalationDialog() {
            this.dialogEscalation = false;
            this.getWorklistItems();
         },
         closeWorklistItemDialog() {
            this.dialog = false;
            this.getWorklistItems();
         },
         isAuthorized(item) {
            return item.assignedToUserId === this.$store.state.achillesUser.userId || this.isSuperviser ||
                   this.isDeviceOperationsUser || this.isClinicalOperationsUser || this.isCareManagerUser;
         },
         copyToClipboard(value) {
            let self = this;
            this.$copyText(value).then(
                function (e) {
                   self.clipboardText = value;
                   self.clipboardSnackbar = true;
                   console.log(e);
                },
                function (e) {
                   console.log(e);
                }
            );
         },
         snoozeChanged() {
            if (this.editedItem["snoozedItem"]) {
               this.snoozeMenu = true;
            }
         },
         searchCleared() {
            this.fetchEntriesDebounced();
         },
         formatDate(val) {
            if (val) {
               let date = new Date(val).toISOString().substr(0, 10);
               let year = date.substr(0, 4);
               let month = date.substr(5, 2);
               let day = date.substr(8, 2);
               return month + "/" + day + "/" + year;
            } else {
               return null;
            }
         },
         forceRerender() {
            this.componentKey += 1;
         },
         getFlex() {
            this.isViewEscalations = false;
            this.getList();
            
         },
         getEscalation() {
            this.isViewFlex = false;
            this.options.page = 1;
            this.getList();
         },
         getList() {
            if (this.isViewFlex) {
               this.personalListName = "Flex";
            } else if (this.isViewEscalations) {
               this.personalListName = "Escalation";
            } else {
               this.personalListName = this.$store.state.achillesUser.firstName + "'s";
            }
            this.search = "";
            this.getWorklistItems();
         },
         getUserNameForId(id) {
            if (id === null) {
               return "Unassigned";
            } else {
               let user = this.achillesUsers.find((u) => u.userId === id);
               if (user) {
                  return user.firstName + " " + user.lastName;
               } else {
                  return "Unknown";
               }
            }
         },
         fetchEntriesDebounced() {
            // cancel pending call
            clearTimeout(this._timerId);
            
            // delay new call 500ms
            this._timerId = setTimeout(() => {
               this.getWorklistItems();
            }, 500);
         },
         getWorklistItems() {
            this.loading = true;
            let pagedOptions = {
               Search: this.search,
               SortBy: this.options.sortBy.length > 0 ? this.options.sortBy[0] : null,
               SortDesc:
                   this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : null,
               ItemsPerPage: this.options.itemsPerPage,
               Page: this.options.page,
               ViewFlex: this.isViewFlex,
               ViewEscalation: this.isViewEscalations,
               ForUserId: this.userId,
               PodId: this.podId,
               AssignedToUserPodIds: this.assignedToUserPodIds,
               PodIds: this.podIds,
               Type: this.type
            };

            new CustomerWorklistItemsService().post(pagedOptions).then((value) => {
               this.worklistItems = value.items;
               this.totalWorklistItems = value.totalCount;
               this.loading = false;
            });
         },
         async getWorklistItem(customerId, worklistItemId) {
            this.loading = true;
            await new WorklistItemService().getWorklistItem(customerId, worklistItemId).then((resp) => {
               this.worklistItem = resp;
               this.loading = false;
            });
         },
         async editItem(item) {
            this.customerId = item.customerId;
            this.patientName = item.patientName;
            this.worklistItemId = item.worklistItemId;
            this.patientId = item.patientId;
            this.forceRerender();
            if (item.isEscalation && !this.isCareManagerUser && !this.isSuperviser) {
               this.viewEscalationDialog(item);
            } else {
               this.dialog = true;
            }
         },
         hasStatus(status) {
            return this.patientStatuses.some(s => s.patientStatusTypeId === status);
         },
         hasScanStatus(scanStatus) {
            return this.patientStatuses.some(s => s.patientScanStatusTypeId === scanStatus);
         },
         assignItem(item) {
            console.log(item);
         },
         claimItem(item) {
            this.loading = true;
            new WorklistItemService()
                .getWorklistItem(item.customerId, item.worklistItemId)
                .then((resp) => {
                   let activeWorklistItem = resp;
                   if (activeWorklistItem.assignedToUserId == null) {
                      activeWorklistItem.assignedToUserId = this.$store.state.achillesUser.userId;
                      activeWorklistItem.lastUpdated = new Date().toISOString();
                      this.saveWorklistItem(activeWorklistItem, item.customerId);
                   } else {
                      this.getWorklistItems();
                   }
                   this.loading = true;
                });
         },
         viewPatient(item) {
            this.customerId = item.customerId;
            this.patientName = item.patientName;
            this.customerName = item.customerName;
            this.worklistItemId = item.worklistItemId;
            this.patientDesignation = item.patientDesignation;
            this.lookupPatient(item, true);
         },
         viewEscalationDialog(item) {
            this.customerId = item.customerId;
            this.escalationId = item.escalationId;
            this.worklistItemId = item.worklistItemId;
            this.customerName = item.customerName;
            this.patientName = item.patientName;
            this.dialogEscalation = true;
         },
         async saveWorklistItem(worklistItem, customerId) {
            worklistItem.updatedByUserId = this.$store.state.achillesUser.userId;
            await new WorklistItemService()
                .putWorklistItem(customerId, worklistItem.worklistItemId, worklistItem)
                .then(() => {
                   this.getWorklistItems();
                });
         },
         getUsers: async function () {
            if (this.$store.state.users.length == 0) {
               this.loading = true;
               new UserService()
                   .getUsers()
                   .then((resp) => {
                      this.achillesUsers = resp;
                      this.$store.commit("setUsers", this.achillesUsers);
                   })
                   .catch((err) => {
                      console.log(err);
                   })
                   .finally(() => (this.loading = false));
            } else {
               this.achillesUsers = this.$store.state.users;
            }
         },
         lookupPatient: async function (item, view) {
            this.loading = true;
            await new PatientService()
                .get(item.customerId, item.patientId)
                .then((value) => {
                   this.patient = value;
                   if (view) {
                      this.forceRerender();
                      this.dialogPatient = true;
                   }
                })
                .finally(() => (this.loading = false));
         },
      
       
      }
   };
</script>

<style scoped>
   .v-snack:not(.v-snack--absolute) {
      z-index: 9999;
   }
</style>