<template>
  <div v-if="loadingState" class="text-center">
    <v-progress-circular indeterminate :color="color" />
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    id: String,
    loadingState: {
      type: Boolean,
      default: true
    },
    color: {
      type: [String, Number],
      default: 'rgba(0,40,89,1)'
    }
  }
}
</script>
