<template>
  <v-textarea v-model="escalationSummary" :rows="7" :label="label" prepend-icon="mdi-note"></v-textarea>
</template>

<script>
import {buildEscalationSummary, buildEscalationSummaryHeader} from './escalationSummaryBuilder';

export default {
  name: "EscalationSummary",
  props: {
    subjectiveQuestionsFindingsAssessment: null,
    interactionDateTime: null,
    nextClinicVisit: null,
    nextClinicVisitNote: null,
    lastClinicVisit: null,
    lastClinicVisitNote: null,
    label: {
      type: String,
      default: 'Escalation Context'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      escalationSummary: this.value
    }
  },
  created() {
    const sQFA = this.subjectiveQuestionsFindingsAssessment;
    if (sQFA !== null && sQFA.length > 0) {
      let escalationSummaryParagrah = buildEscalationSummaryHeader(this.interactionDateTime, this.nextClinicVisit, this.nextClinicVisitNote, this.lastClinicVisit, this.lastClinicVisitNote);
      for (let i = 0; i < sQFA.length; i++) {
        escalationSummaryParagrah += `${sQFA.length > 1 ? `Finding ${i + 1}:\n` : ''}${buildEscalationSummary(sQFA[i])}\n`;
      }
      this.escalationSummary = `${escalationSummaryParagrah}`;
    }
  },
  methods: {},
  watch: {
    escalationSummary(currentValue) {
      this.$emit('input', currentValue);
    }
  },
};
</script>

