import * as BaseService from './BaseService';
import * as customer from '../types/customer';
import * as device from '../types/device';

export default class PatientService extends BaseService.BaseService {
   private url = '/api/Patient';
      
   constructor() {
     super();     
   }
  
    async get(customerId: number, patientId: number): Promise<customer.Patient>{
      let url = this.url + `/${customerId}/${patientId}`;            
      let response = (await this.api.get(url)).data as customer.Patient;          
      return response;
    }
    
    async put(customerId: number,patient: customer.Patient)
    {
      let url = this.url + `/${customerId}/${patient.patientId}`;            
      let response = await this.api.put(url,patient).catch(err => {
        throw err;          
      });                
      return response;
    }

    async postPatientClinicalNoteByInteractionId(customerId: number,patientInteractionId: number, patientClinicalNote: customer.PatientClinicalNote){
        let url = `/api/PatientClinicalNote/${customerId}/InteractionId/${patientInteractionId}`;
        let response = await this.api.post(url, patientClinicalNote).catch(err => {
            throw err;
        });
        return response;
    }

    async putPatientClinicalNote(customerId: number,patientClinicalNoteId: number, patientClinicalNote: customer.PatientClinicalNote){
        let url = `/api/PatientClinicalNote/${customerId}/${patientClinicalNoteId}`;
        let response = await this.api.put(url, patientClinicalNote).catch(err => {
            throw err;
        });
        return response;
    }

    async postPatientClinicalNote(customerId: number, patientClinicalNote: customer.PatientClinicalNote){
        let url = `/api/PatientClinicalNote/${customerId}`;
        return await this.api.post(url, patientClinicalNote).catch(err => {
            throw err;
        });
    }

    async getPatientClinicalNotes(customerId: number, patientId: number): Promise<Array<customer.PatientClinicalNote>>{
        let url = `/api/PatientClinicalNote/${customerId}/${patientId}`;
        return (await this.api.get(url)).data as Array<customer.PatientClinicalNote>;
    }


    async putPatientDocument(customerId: number, patientDocumentId: number, patientDocument: customer.PatientDocument){
      let url = `/api/PatientDocument/${customerId}/${patientDocumentId}`;
      return await this.api.put(url, patientDocument).catch(err => {
          throw err;
      });
    }

    async postPatientDocument(customerId: number, patientDocument: customer.PatientDocument){
      let url = `/api/PatientDocument/${customerId}`;            
      let response = await this.api.post(url,patientDocument).catch(err => {
        throw err;          
      });                          
      return response;
    }

    async getPatientDocuments(customerId: number, patientId: number): Promise<Array<customer.PatientDocument>>{
      let url = `/api/PatientDocument/${customerId}/${patientId}`;            
      let response = (await this.api.get(url)).data as Array<customer.PatientDocument>;          
      return response;
    }

    async getPatientWorklistItems(customerId: number, patientId: number): Promise<Array<customer.WorklistItem>>{
      let url = `/api/PatientWorklistItem/${customerId}/${patientId}`;            
      let response = (await this.api.get(url)).data as Array<customer.WorklistItem>;          
      return response;
    }    

    async getDeviceUsage(customerId: number, patientId: number): Promise<device.DeviceUsage>{
      let url = `/api/DeviceUsage/${customerId}/${patientId}`;            
      let response =  (await this.api.get(url)).data as device.DeviceUsage;          
      return response;
    }

    async getPatientDevice(customerId: number, patientId: number): Promise<customer.PatientDevice>{
        let url = `/api/PatientDevice/${customerId}/${patientId}`;
        let response =  (await this.api.get(url)).data as customer.PatientDevice;
        return response;
    }

    async getPatientStatuses(customerId: number, patientId: number): Promise<Array<customer.PatientStatus>>{
      let url = `/api/PatientStatus/${customerId}/${patientId}`;            
      let response = (await this.api.get(url)).data as Array<customer.PatientStatus>;          
      return response;
    }

    async getPatientInteractionForms(customerId: number, patientId: number): Promise<Array<customer.PatientInteractionForm>>{
      let url = `/api/PatientInteractionForm/${customerId}/${patientId}`;            
      let response = (await this.api.get(url)).data as Array<customer.PatientInteractionForm>;          
      return response;
    }

    async getPatientPrescription(customerId: number, patientId: number): Promise<Array<customer.Prescription>>{
      let url = `/api/Prescription/${customerId}/${patientId}`;            
      let response = (await this.api.get(url)).data as Array<customer.Prescription>;          
      return response;
    }

    async postPatientInteractionForm(customerId: number, patientInteractionForm: customer.PatientInteractionForm) {
      let url = `/api/PatientInteractionForm/${customerId}`;            
      let response = await this.api.post(url,patientInteractionForm).catch(err => {
        throw err;          
      });
      return response;
    }

    async postPatientStatus(customerId: number, patientStatus: customer.PatientStatus) {
      let url = `/api/PatientStatus/${customerId}`;            
      let response = await this.api.post(url,patientStatus).catch(err => {
        throw err;          
      });
      return response;
    }

  }