<template>
  <v-container fluid>
    <v-row align="center" no-gutters>
      <div class="d-flex align-center flex-column">
        <v-card elevation="2" outlined tile>
          <v-card-text v-if="isLoading">
            <v-col>Contacting PADS...
              <v-progress-linear
                  color="indigo darken-4"
                  indeterminate
                  rounded
                  height="6"
              ></v-progress-linear>
            </v-col>
          </v-card-text>
          <v-card-text v-if="!isLoading">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon v-bind="on" v-if="loadedDeviceUsage && loadedDeviceUsage.serial_Number">mdi-access-point
                </v-icon>
              </template>
              <span>Connected to PADS</span>
            </v-tooltip>

            <v-chip v-show="false"
                    class="ma-2"
                    color="orange darken-4"
                    text-color="white"
                    v-if="currentDeviceUsage.current_Adherence != null && currentDeviceUsage.current_Adherence != undefined"
            >
              <v-avatar
                  left
                  class="orange darken-3"
              >
                {{ currentDeviceUsage.current_Adherence }}
              </v-avatar>
              Adherence
            </v-chip>
            <v-chip
                v-show="false"
                class="ma-2"
                color="orange darken-3"
                text-color="white"
                v-if="daysSinceLastUsed != null && daysSinceLastUsed != undefined"
            >
              <v-avatar
                  left
                  class="orange darken-2"
              >
                {{ daysSinceLastUsed }}
              </v-avatar>
              Days Since Last Use
            </v-chip>
            <v-chip
                class="ma-2"
                label
                v-if="currentSerialNumber"
            >
              Serial Number: {{ currentSerialNumber }}
            </v-chip>
            <v-chip
                class="ma-2"
                label
                v-if="dateTrained != ''"
            >
              Date Trained: {{ dateTrained }}
            </v-chip>

            <v-chip
                class="ma-2"
                label
                v-if="noDeviceUsage"
            >
              <span class="text-h8">No Device Usage</span>
            </v-chip>
            <v-chip
                class="ma-2"
                label
                v-if="patient.legacyUniqueId != null"
            >
              <v-icon>mdi-open-in-new</v-icon>
              <a class="btn pl-2" style="text-decoration: none;" v-bind:href="padsURL + patient.legacyUniqueId"
                 target="_blank">View in PADS</a>
            </v-chip>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
    <v-row>
      <v-col md="12">
        <div class="text-h6">Assignment History</div>
        <AssignmentHistory :patientId="patient.patientId" :customerId="customerId"/>
      </v-col>
    </v-row>
  </v-container>

</template>
<script>
import PatientService from "../services/PatientService";
import AssignmentHistory from "../components/Assignment/AssignmentHistory.vue";

export default {
  name: "DeviceUsage",
  components: {
    AssignmentHistory
  },
  props: {patient: Object, customerId: Number, serialNumber: String, deviceUsage: Object},
  data: () => ({
    dateTrained: '',
    loadedDeviceUsage: {},
    isLoading: false,
    padsURL: process.env.VUE_APP_PODS_URL
  }),
  watch: {
    patient(val) {
      // Items have already been requested
      if (this.isLoading) return;
      if (val === "" || val === null || val === undefined) return;
      // cancel pending call
      clearTimeout(this._timerId);
      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.getDeviceUsage();
        this.getPatientStatus();
      }, 500);
    },
  },
  computed: {
    currentSerialNumber() {
      return this.currentDeviceUsage && this.currentDeviceUsage.serial_Number ? this.currentDeviceUsage.serial_Number : this.serialNumber
    },
    noDeviceUsage() {
      return !this.currentDeviceUsage.serial_Number;
    },
    currentDeviceUsage() {
      return this.deviceUsage ? this.deviceUsage : this.loadedDeviceUsage;
    },
    daysSinceLastUsed: function () {
      if (this.currentDeviceUsage.days_Since_Last_Used != null)
        return this.currentDeviceUsage.days_Since_Last_Used;
      else {
        const diffTime = new Date() - new Date(this.currentDeviceUsage.last_Used);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays >= 871 ? null : diffDays;
      }
    }
  },
  methods: {
    getDeviceUsage: function () {
      this.isLoading = true;
      new PatientService().getDeviceUsage(this.customerId, this.patient.patientId)
          .then((resp) => {
            this.loadedDeviceUsage = resp
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            (this.isLoading = false)
          });
    },
    getPatientStatus: function () {
      this.isLoading = true;
      new PatientService().getPatientStatuses(this.customerId, this.patient.patientId)
          .then((resp) => {
            let found = resp.find(ps => ps.patientStatusTypeName == "Trained");
            if (found != undefined)
              this.dateTrained = new Date(found["updated"]).toLocaleDateString("en-US");
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
  },
  created() {
    this.dateTrained = '';
    if (!this.deviceUsage) {
      this.getDeviceUsage();
    }
    this.getPatientStatus();
  }
};
</script>
