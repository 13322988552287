<template>
   <v-dialog v-model="isAddMode" width="800" persistent v-if="isAddMode">
      <v-card>
         <v-card-title class="headline">Add Contact</v-card-title>
         <v-card-text>
            <v-container>
               <v-form v-model="addFormValid" lazy-validation>
                  <v-row v-for="(phone, index) in newPhones" :key="index">
                     <v-col md="5">
                        <v-text-field v-model="phone.phoneNumber"
                                      label="Phone Number" required prepend-icon="mdi-phone"
                                      v-mask="'###-###-####'" :rules="$data.phoneNumberValidRules">
                        </v-text-field>
                     </v-col>
                     <v-col md="5">
                        <v-text-field v-model="phone.note" label="Phone Label"
                                      required :rules="!!phone.phoneNumber? $data.phoneLabelRules:[]">
                        </v-text-field>
                     </v-col>
                     <v-col md="2">
                        <v-btn v-if="index > 0" fab dark x-small color="red"
                               class="mt-4" @click="removeNewPhone(index)">
                           <v-icon dark>mdi-minus</v-icon>
                        </v-btn>
                        <v-btn v-if="index === 0" fab dark x-small color="green"
                               class="mt-4" @click="addNewPhone()">
                           <v-icon dark> mdi-plus</v-icon>
                        </v-btn>
                     </v-col>
                  </v-row>
                  <v-row v-for="(email, index) in newEmails" :key="'email_'+index">
                     <v-col md="6" v-if="emailCount===0">
                        <v-text-field v-model="email.emailAddress" label="Email"
                                      prepend-icon="mdi-email" :rules="$data.emailValidRules">
                        </v-text-field>
                     </v-col>
                  </v-row>
                  <v-row v-for="(address, index) in newAddresses" :key="'address_'+index">
                     <v-col md="12" v-if="addressCount===0">
                        <v-row>
                           <v-col md="12">
                              <v-text-field prepend-icon="mdi-map-marker" ref="autocomplete"
                                 placeholder=""
                                 label="Search New Address" type="search">
                              </v-text-field>
                           </v-col>
                        </v-row>
                        <v-row v-if="addressCount===0">
                           <v-col md="6">
                              <v-text-field v-model="address.addressLine1" label="Address Line 1"
                                            required :rules="$data.addressLine1Rules" prepend-icon="mdi-map-marker">
                              </v-text-field>
                           </v-col>
                           <v-col md="6">
                              <v-text-field v-model="address.addressLine2" label="Address Line 2" required
                                            prepend-icon="mdi-map-marker">
                              </v-text-field>
                           </v-col>
                        </v-row>
                        <v-row v-if="addressCount===0">
                           <v-col md="6">
                              <v-text-field v-model="address.city" label="City"
                                            :rules="$data.cityRules" required prepend-icon="mdi-map-marker">
                              </v-text-field>
                           </v-col>
                           <v-col md="6">
                              <v-select :items="states" :rules="$data.stateRules"
                                        item-text="description" item-value="stateId"
                                        v-model="address.stateId" label="State"
                                        required prepend-icon="mdi-map-marker">
                              </v-select>
                           </v-col>
                           <v-col md="6">
                              <v-text-field v-model="address.zipCode" label="Zip Code"
                                            required prepend-icon="mdi-map-marker" v-mask="'#####'"
                                            :rules="$data.zipCodeRules">
                              </v-text-field>
                           </v-col>
                        </v-row>
                     </v-col>
                  </v-row>
               </v-form>
            </v-container>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancel"> Cancel</v-btn>
            <v-btn text @click="save"> Save</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
   import {contactDetailsUtils} from "@/mixins/contactDetailsUtils";
   import {AddEntityPhones, AddEntityAddresses, AddEntityEmails} from "./mutations/update-contact.graphql";
   
   export default {
      name: "AddContact",
      props: {
         customerId: Number,
         entityId: Number,
         emailCount: Number,
         addressCount: Number,
         isAddMode: Boolean,
         entityTypeId: Number
      },
      mixins: [contactDetailsUtils],
      data: () => ({
         addFormValid: false,
         newPhones: [
            {
               phoneNumber: "",
               note: ""
            }
         ],
         newEmails: [
            {
               emailAddress: "",
            }
         ],
         newAddresses: [
            {
               addressLine1: "",
               addressLine2: "",
               city: "",
               stateId: "",
               zipCode: ""
            }
         ]
      }),
      mounted() {
         this.initAutocomplete();
      },
      computed:{
         hasNewPhone(){
            return this.newPhones.length > 0 && this.newPhones[0].phoneNumber.length>0;
         },
         hasNewAddress(){
            return this.newAddresses.length > 0 && this.newAddresses[0].addressLine1.length>0;
         },
         hasNewEmail(){
            return this.newEmails.length > 0 && this.newEmails[0].emailAddress.length>0;
         }
      },
      methods: {
         async cancel() {
            this.$emit("canceled");
         },
         async save() {
            if (this.hasNewPhone) {
               await this.savePhones();
            }
            if (this.hasNewAddress) {
               await this.saveAddresses();
            }
            if (this.hasNewEmail) {
               await this.saveEmails();
            }
            this.$emit("saved");
         },
         removeNewPhone: function (idx) {
            this.newPhones.splice(idx, 1);
         },
         addNewPhone: function () {
            let phone = {
               phoneNumber: "",
               note: ""
            };
            
            this.newPhones.push(phone);
         },
         async savePhones() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: AddEntityPhones,
                             variables: {
                                input: {
                                   phones: this.newPhones,
                                   entityTypeId: this.entityTypeId,
                                   entityId: this.entityId,
                                   customerId: this.customerId,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
                         if (!this.hasNewAddress && !this.hasNewEmail) {
                            return await this.success();
                         }
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         
         async saveAddresses() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: AddEntityAddresses,
                             variables: {
                                input: {
                                   addresses: this.newAddresses,
                                   entityTypeId: this.entityTypeId,
                                   entityId: this.entityId,
                                   customerId: this.customerId,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
                         if (!this.hasNewEmail) {
                            return await this.success();
                         }
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         
         async saveEmails() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: AddEntityEmails,
                             variables: {
                                input: {
                                   emails: this.newEmails,
                                   entityTypeId: this.entityTypeId,
                                   entityId: this.entityId,
                                   customerId: this.customerId,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         
         async failure(data) {
            let errorTest = "Unable to add contact information";
            
            if (typeof data.errorCode !== undefined) {
               errorTest += `: ${data.errorCode}`;
            }
            //exception from API
            else {
               console.log(data);
            }
            
            let options = {
               text: errorTest,
               title: "Unable to save",
               actions: ["Okay"]
            };
            await this.$dialog.error(options);
         },
         async success() {
            
            let options = {
               text: "New contact information was added successfully",
               title: "Saved",
               actions: ["Okay"]
            };
            await this.$dialog.info(options);
         },
         fillInAddress(place) {
            // Get the place details from the autocomplete object.      
            if (typeof place === "undefined") {
               return;
            }
            let components = {
               street_number: {
                  result: "",
                  type: "short_name"
               },
               route: {
                  result: "",
                  type: "long_name"
               },
               locality: {
                  result: "",
                  type: "long_name"
               },
               administrative_area_level_1: {
                  result: "",
                  type: "short_name"
               },
               postal_code: {
                  result: "",
                  type: "short_name"
               }
            };
            
            for (let i = 0; i < place.address_components.length; i++) {
               let addressType = place.address_components[i].types[0];
               if (typeof components[addressType] !== "undefined") {
                  components[addressType].result = place.address_components[i][components[addressType].type];
               }
            }
            
            let address = {};
            address.addressLine1 =
                (components["street_number"].result + " " + components["route"].result).trim();
            address.city = components["locality"].result;
            address.stateId =
                this.stateIdFromShortName(components["administrative_area_level_1"].result);
            address.zipCode = components["postal_code"].result;
            address.addressLine2 = "";
            
            this.newAddresses[0] = address;
            this.$forceUpdate();
         }
      }
   };
</script>

<style scoped>

</style>