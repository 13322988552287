<template>
  <div>
    <v-btn color="secondary" @click="isUploadMode=true">Add</v-btn>
    <uploader :entityId="member.memberId" :entityType="entityType()" :customerId="customerId" @uploadcomplete="uploadComplete" v-if="isUploadMode"></uploader>
   <v-row>
       <v-col md="3" v-for="(doc,index) in documents" :key="doc.memberDocumentId">
           <v-card elevation="2" outlined shaped tile class="my-2" max-width="300" :loading="isLoading && index==activeIndex">
             <v-list-item dense>
             <v-list-item-icon>
                <v-icon color="indigo" v-if="doc.documentType=='Image'" large> mdi-image</v-icon>
                <v-icon color="indigo" v-if="doc.documentType.includes('Report')" large> mdi-file-chart</v-icon>
             </v-list-item-icon>
             <v-list-item-content> 
              <v-list-item-title class="font-weight-bold">
                 {{ doc.documentType }}
              </v-list-item-title>                
              <v-list-item-subtitle>
               {{doc.note}}
              </v-list-item-subtitle>
               <v-list-item-subtitle>
               Added: {{new Date(doc.created).toLocaleDateString("en-US")}}
              </v-list-item-subtitle>                      
            </v-list-item-content>
                   
            </v-list-item>
              <v-card-actions>
                <v-btn small text rounded @click="viewDocument(doc,index)">
                  View
                </v-btn>
                <v-btn small text rounded @click="deleteDocument(doc,index)" v-if="doc.documentType=='Image'">
                  Delete
                </v-btn>               
            </v-card-actions>
          </v-card>
       </v-col>
   </v-row>  
    <div v-viewer="{movable: false}">
        <img max-height="150" max-width="250" :src="imageSrc" class="image"/>
    </div>
    <v-dialog v-model="deleteConfirmDialog" v-if="deleteConfirmDialog"
        persistent
        max-width="500"
        content-class="confirm-dialog">
      <v-card>
       <v-card-title class="headline primary">
            Confirm Delete
       </v-card-title>
        <v-card-text>Are you sure you want to delete Image? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No </v-btn>
          <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Uploader from '@/components/Shared/Uploader';
import MemberDocumentService from '@/services/MemberDocumentService';
import ImageService from '@/services/ImageService';
import {EntityTypeEnum} from '@/types/enum';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer'
import Vue from 'vue'
Vue.use(Viewer);

export default {
  name: 'MemberDocuments',
  props: { member: Object, customerId: Number },
  components: {
    Uploader      
  },
  data: () => ({
    documents: [],
    activeIndex:-1,
    isLoading: false,
    imageSrc:'',
    deleteConfirmDialog:false,
    deletedItem:{},
    isUploadMode:false,
  }),
  watch: {
    member(val) {
      // Items have already been requested
      if (this.isLoading) return;
      if (val === "" || val === null || val === undefined) return;
      // cancel pending call
      clearTimeout(this._timerId);
      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.getDocuments();
      }, 500);
    },
  }, 
  methods: {
    confirmDialogCallback:function(agreed){      
      if(agreed)
      {
          let document = this.deletedItem;          
          let index = this.documents.findIndex(d => d.memberDocumentId == document.memberDocumentId);
          this.documents.splice(index,1);
          this.deleteMemberDocument(document);
          this.imageSrc ='';
      }      
      this.deleteConfirmDialog=false;
      this.deletedItem={};
    },
    deleteMemberDocument(document){
        this.isLoading = true;
        document.updatedByUserId = this.$store.state.achillesUser.userId;
        document.isDeleted = true;
        new MemberDocumentService()
        .putDocument(this.customerId, this.member.memberId, document)
        .then(() => {          
            this.getDocuments();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },    
    viewDocument:function(doc,index)
    {
      this.activeIndex = index;
         if(doc.documentType.includes("Report"))
         {
           let url = process.env.VUE_APP_REPORT_BASE_URL + doc.fileStoreObjectName;
           window.open(url, "_blank");
         }
         else if (doc.documentType == "Image")
         {
            this.getImage(doc.fileStoreObjectName);            
         }
    },
    deleteDocument: async function(doc,index)
    {
        this.activeIndex = index;
        this.deletedItem = doc;

        this.deleteConfirmDialog = true;
    },
    async getImage(objectName){
      this.isLoading = true;
      new ImageService()
        .getImage(objectName)
        .then((resp) => {               
         this.imageSrc = resp;         
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getDocuments: function () {
      this.isLoading = true;     
      new MemberDocumentService()
        .getDocuments(this.customerId, this.member.memberId)
        .then((resp) => {
          this.documents = resp;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    uploadComplete() {
      this.isUploadMode = false;
      this.getDocuments();
    },
    entityType() {
      return EntityTypeEnum.Member;
    }
  },
  mounted() {
    this.documents = [];
    this.getDocuments();
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  .image {
    width: calc(20% - 10px);
    cursor: pointer;
    margin: 5px;
    display: inline-block;
  }
  .confirm-dialog .v-card__title {
    color: #FFFFFF;
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
  }

  .confirm-dialog .v-card__text {
    margin-top: 26px;
    font-size: 1rem !important;
  }
</style>