import * as BaseService from './BaseService';
import * as customer from '../types/customer';
import * as device from '../types/device';

export default class PatientService extends BaseService.BaseService {
   private url = '/api/PatientExport';
      
   constructor() {
     super();     
   }
  
    async get(customerId: number, patientId: number, timezoneOffset: number): Promise<string>{
      let url = this.url + `/${customerId}/${patientId}/${timezoneOffset}`;            
      let response = (await this.api.get(url)).data as string;          
      return response;
    }
    
  }