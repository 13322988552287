<template>
    <v-container>
        <v-tabs>
            <v-tab default key="prescriptionDetails">
                Details
            </v-tab>
            <v-tab-item default key="prescriptionDetails">
                <v-container>
                    <form>
                        <v-row>
                            <v-col cols="4">
                                <div class="text-h6"><strong>Patient:</strong> {{ patientFirstName }} {{ patientLastName }}</div>
                            </v-col>
                            <v-col cols="4">
                                <div><strong>Renewal:</strong> {{ isRenewalPatient==true?'Yes':'No' }} </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <div><strong>Health Plan Member Id:</strong> {{ healthPlanMemberId }} </div>
                            </v-col>
                            <v-col cols="4">
                                <div><strong>Prescribing MD:</strong> {{ prescribingPhysicianName }}</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <div><strong>Rx Date:</strong> {{ prescriptionDate }} </div>
                            </v-col>
                            <v-col cols="4">
                                <div><strong>Received Date:</strong> {{ received }} </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <div><strong>Processed:</strong> {{ processed }} </div>
                            </v-col>
                            <v-col cols="4">
                                <div><strong>Delivered:</strong> {{ delivered }} </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <div><strong>Assigned:</strong> {{ assigned }} </div>
                            </v-col>
                            <v-col cols="4">
                                <div><strong>Trained:</strong> {{ trained }} </div>
                            </v-col>
                        </v-row>
                        <v-row v-show="voidDate != null">
                            <v-col cols="4">
                                <div><strong>Voided:</strong> {{ voidDate }} </div>
                            </v-col>
                            <v-col cols="4">
                                <div><strong>Voided Reason:</strong> {{ voidedReasonId | voidReason }} </div>
                            </v-col>
                        </v-row>
                        <v-row v-show="voidDate != null">
                            <v-col cols="12">
                                <div><strong>Void Note:</strong> {{ voidNote }} </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div><strong>Note:</strong> {{ note }} </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div><strong>Assigned Note:</strong> {{ assignedNote }} </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div><strong>Trained Note:</strong> {{ trainedNote }} </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <div><strong>Shipment Requested:</strong> {{ isShipmentRequested==true?'Yes':'No' }} </div>
                            </v-col>
                            <v-col cols="4">
                                <div><strong>On Fulfillment Hold:</strong> {{ onFulfillmentHold==true?'Yes':'No' }} </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <div><strong>Initial Delivery Method:</strong> {{ initialDeliveryMethod }} </div>
                            </v-col>
                            <v-col cols="4">
                                <div><strong>Training Type:</strong> {{ trainingTypeDescription }} </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <div><strong>Updated By:</strong> {{ updatedByUserName }} </div>
                            </v-col>
                            <v-col cols="4">
                                <div><strong>Is Deleted:</strong> {{ isDeleted==true?'Yes':'No' }} </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <div><strong>Line Of Business:</strong> {{ lineOfBusinessName }} </div>
                            </v-col>
                        </v-row>
                    </form>
                </v-container>
            </v-tab-item>
            <v-tab key="prescriptionImage" v-if="showViewOption">
                View
            </v-tab>
            <v-tab-item key="prescriptionImage" v-if="showViewOption">
                <v-btn color="secondary" @click="editDocument" v-if="showDocument" class="ma-5 pa-2">Change</v-btn>
                <v-btn color="secondary" @click="addDocument" v-else class="ma-5 pa-2">Add</v-btn>
                <v-row v-if="showDocument">
                    <v-col md="3">
                        <v-card elevation="2" outlined shaped tile class="my-2" max-width="300">
                            <v-list-item dense>
                                <v-list-item-icon>
                                    <v-icon color="indigo" large> mdi-file-chart</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                            <v-card-actions>
                                <v-btn small text rounded @click="viewDocument()">
                                    View Prescription Doc
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>
<script>
    import Vue from "vue";

    Vue.filter('voidReason', function (value) {
        switch (value) {
            case 1:
                return 'Deceased';
            case 2:
                return 'Declined';
            case 3:
                return 'Duplicate';
            case 4:
                return 'Fulfillment Issue';
            case 5:
                return 'Ineligible - Coverage';
            case 6:
                return 'Ineligible - Health Criteria';
            case 7:
                return 'Invalid Rx';
            case 8:
                return 'Provider Revoked';
            case 9:
                return 'Unreachable';
            default:
                return 'Unknown';
        }
    });

    export default Vue.extend({
        props: {
            legacyId: String,
            isRenewalPatient: Boolean,
            patientFirstName: String,
            patientLastName: String,
            siteId: Number,
            prescribingPhysicianName: String,
            prescriptionId: Number,
            prescriptionDate: String,
            created: String,
            delivered: String,
            assigned: String,
            voidDate: String,
            voidedReasonId: Number,
            voidNote: String,
            trained: String,
            updatedByUserId: Number,
            received: String,
            legacyNumber: String,
            patientId: Number,
            fileStoreObjectName: String,
            healthPlanMemberId: String,
            providerId: Number,
            processed: String,
            note: String,
            assignedNote: String,
            trainedNote: String,
            isShipmentRequested: Boolean,
            onFulfillmentHold: Boolean,
            initialDeliveryMethod: String,
            trainingTypeDescription: String,
            updatedByUserName: String,
            isDeleted: Boolean,
            lineOfBusinessName: String,
            showViewOption: {
                type: Boolean,
                default: true
            }
        },
        name: "prescriptionDetails",
        data: () => ({
        }),

        computed: {
            showDocument() {
                if (this.$props.fileStoreObjectName != "" && this.$props.fileStoreObjectName != "sharing") {
                    return true;
                }

                return false;
            }
        },

        methods: {
            viewDocument: function () {
                let url;
                if (this.$props.fileStoreObjectName.includes('https://')) {
                    url = this.$props.fileStoreObjectName;
                } else {
                    const baseUrl = process.env.VUE_APP_REPORT_BASE_URL;
                    url = baseUrl + this.$props.fileStoreObjectName;
                }

                window.open(url, "_blank");
            },

            addDocument() {
                console.log("Add");
            },

            editDocument() {
                console.log("Edit");
            },
        },
    });
</script>
