import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import IdleVue from 'idle-vue'
import { VueMaskDirective } from 'v-mask'
import { createProvider } from './vue-apollo'

Vue.config.productionTip = false
Vue.directive('mask', VueMaskDirective);

Vue.prototype.$rowsPerPage = [10,25,50,100,150];

const eventsHub = new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: process.env.VUE_APP_IDLE_TIMEOUT,
  startAtIdle: false,
});