import * as BaseService from './BaseService';
import * as customer from '../types/customer';

export default class WorklistItemService extends BaseService.BaseService {
   private url = '/api/NextWorklistItem';
      
   constructor() {
     super();     
   }
  
   async getNextWorklistItems(customerId: number, id: number): Promise<customer.WorklistItem>{
    let url = this.url + `/${customerId}/${id}`;
    let response;               
    response = await this.api.get(url).catch(err => {
        throw err;          
      });
    return response.data;
  }
   
  }