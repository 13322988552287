var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.items,"itemKey":_vm.itemKey,"loading":_vm.isLoading,"search-input":_vm.search,"item-text":"number","item-value":"number","label":"NPI","placeholder":"Start typing to Search","prepend-icon":"mdi-card-account-details","return-object":"","clearable":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"select":_vm.selected},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4"},[_vm._v("Please enter a 10 digit NPI")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.search && _vm.search.length === 10),expression:"search && search.length === 10"}],staticClass:"px-4"},[_vm._v("NPI not found")])]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-card-account-details ")]),_c('div',{domProps:{"textContent":_vm._s(item.number + ' ' + (item.prefix ? item.prefix + '' : '') + item.firstName + ' ' + item.lastName)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.firstName.charAt(0) + item.lastName.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.number)}}),_c('v-list-item-title',{domProps:{"textContent":_vm._s((item.prefix ? item.prefix + '' : '') + item.firstName + ' ' + item.lastName)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.addresses.length > 0 ? item.addresses[0].phoneNumber + " " + item.addresses[0].addressLine1 + " " + item.addresses[0].city + " " + item.addresses[0].state + " " + item.addresses[0].zip.replace(/(\d{5})(\d{4})/, "$1-$2") : "")}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-card-account-details")])],1)]}}]),model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}})}
var staticRenderFns = []

export { render, staticRenderFns }