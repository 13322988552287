<template>
   <v-dialog id="config-dialog" v-model="isOpen" max-width="800px" persistent v-if="isOpen">
      <v-toolbar dark color="primary">
         <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
         </v-btn>
         <v-toolbar-title>Edit {{text}} Configuration</v-toolbar-title>
         <v-spacer></v-spacer>
         <v-toolbar-items>
            <v-btn dark text @click="closeDialog()"> Close</v-btn>
         </v-toolbar-items>
      </v-toolbar>
      <PatientConfig 
          v-if="isPatient" 
          @saved="saveAction"
          @cancelled="cancelAction"
          :patientId="itemId" 
          :customerId="customerId">
      </PatientConfig>
      <MemberConfig
          v-if="!isPatient"
          @saved="saveAction"
          @cancelled="cancelAction"
          :memberId="itemId"
          :customerId="customerId">
      </MemberConfig>
   </v-dialog>
</template>

<script>
   import PatientConfig from "./../PatientConfig.vue";
   import MemberConfig from "../Member/MemberConfig.vue";
   
   export default {
      name: "WorklistItemCadence",
      props: {
         customerId: Number,
         itemId: Number,
         saveAction: Function,
         cancelAction: Function,
         isPatient: Boolean,
         configDialog: Boolean
      },
      components: {
         PatientConfig,
         MemberConfig
      },
      computed: {
         text() {
            return this.isPatient ? "Patient" : "Member";
         },
         isOpen() {
            return this.configDialog;
         },
      },
      methods: {
         closeDialog() {
            this.$parent.closeConfigDialog(false);
         }
      },
   };
</script>

<style scoped>

</style>