<template>
   <div>
      <!-- address card -->
      <v-col>
         <v-card max-width="375" class="mx-auto" :loading="isLoading" :key="renderKey">
            <v-alert :value="alertRef.alert" :type="alertRef.alertType" dense transition="fade-transition">
               {{alertRef.alertMessage}}
            </v-alert>
            <v-row>
               <v-col offset-md="10">
                  <v-menu bottom right v-if="editMode===''">
                     <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                           <v-icon class="mt-1">mdi-dots-vertical</v-icon>
                        </v-btn>
                     </template>
                     <v-list>
                        <v-list-item v-for="(action, i) in actions" :key="i"
                                     link @click="actionSelected(action)">
                           <v-list-item-title>{{action}}</v-list-item-title>
                        </v-list-item>
                     </v-list>
                  </v-menu>
                  <v-icon color="secondary" large class="mr-2" v-if="editMode==='Delete'" @click="clearDeleteMode">
                     mdi-close-circle
                  </v-icon>
               </v-col>
            </v-row>
            <v-row class="mt-n7">
               <v-col>
                  <v-list>
                     <v-list-item>
                        <v-list-item-icon>
                           <v-icon color="indigo"> mdi-account</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                           <v-list-item-title class="font-weight-bold">
                              {{member.firstName}} {{member.lastName}}
                           </v-list-item-title>
                        </v-list-item-content>
                     </v-list-item>
                  </v-list>
               </v-col>
            </v-row>
            <v-row class="mt-n7">
               <v-col>
                  <v-alert dense outlined type="error" v-if="member.hasRequestedDoNotCall">
                     Member requested <strong>DO NOT CALL</strong>
                  </v-alert>
                  <v-list two-line>
                     <v-list-item v-for="item in phones" :key="item.entityPhoneId">
                        <v-list-item-icon>
                           <v-icon color="indigo"> mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                           <v-list-item-title v-on:click="copyToClipboard(formatPhone(item.phoneNumber))">
                              {{formatPhone(item.phoneNumber)}}
                           </v-list-item-title>
                           <v-list-item-subtitle>{{item.phoneType}}</v-list-item-subtitle>
                           <v-list-item-subtitle>{{item.note}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon v-if="editMode==='Delete'">
                           <v-icon color="red" @click="removePhone(item)">mdi-minus-circle</v-icon>
                        </v-list-item-icon>
                     </v-list-item>
                     
                     <v-divider inset></v-divider>
                     
                     <v-list-item v-for="item in emails" :key="item.entityEmailId">
                        <v-list-item-icon>
                           <v-icon color="indigo"> mdi-email</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                           <v-list-item-title>{{item.emailAddress}}</v-list-item-title>
                           <v-list-item-subtitle>{{item.note}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon v-if="editMode==='Delete'">
                           <v-icon color="red" @click="removeEmail(item)">mdi-minus-circle</v-icon>
                        </v-list-item-icon>
                     </v-list-item>
                     <v-divider inset></v-divider>
                     <v-list-item v-for="item in addresses" :key="item.entityAddressId">
                        <v-list-item-icon>
                           <v-icon color="indigo"> mdi-map-marker</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                           <v-list-item-title>
                              {{item.addressLine1}} {{item.addressLine2 ? " " + item.addressLine2 : item.addressLine2}}
                           </v-list-item-title>
                           <v-list-item-subtitle>
                              {{item.city}}, {{stateName(item.stateId)}}{{item.zipCode}}
                           </v-list-item-subtitle>
                        </v-list-item-content>
                     </v-list-item>
                  </v-list>
               </v-col>
            </v-row>
         </v-card>
      </v-col>
      <!-- END: address card -->
      
      <!-- Clipboard -->
      <v-snackbar v-model="clipboardSnackbar" :timeout="1100" bottom>
         Copied "{{clipboardText}}" to clipboard
         <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="clipboardSnackbar = false">
               Close
            </v-btn>
         </template>
      </v-snackbar>
      <!-- END: Clipboard -->
      
      <!-- add dialog  -->
      <add-contact v-if="displayAddContactInfo" :isAddMode="displayAddContactInfo" :customerId="customerId"
                   :entityId="entityId" :entityTypeId="entityTypeId"
                   :emailCount="emails.length" :addressCount="addresses.length"
                   @canceled="cancel" @saved="onUpdateContactSaved">
      </add-contact>
      <!-- END: add dialog  -->
      
      <!-- edit dialog  -->
      <edit-contact v-if="displayEditContactInfo" :isEditMode="displayEditContactInfo" :customerId="customerId"
                    :entityId="entityId" :entityTypeId="entityTypeId"
                    :phones="phones" :emails="emails" :addresses="addresses"
                    @canceled="cancel" @saved="onUpdateContactSaved">
      </edit-contact>
      <!-- END: edit dialog  -->
      
      <!-- delete dialog  -->
      <v-dialog v-model="deleteConfirmDialog" persistent max-width="500" content-class="confirm-dialog" v-if="deleteConfirmDialog">
         <v-card>
            <v-card-title class="headline primary">
               Confirm Delete
            </v-card-title>
            <v-card-text>Are you sure you want to delete Member {{deletedItemInfo.type}}
                         {{deletedItemInfo.type == "Email" ? deletedItemInfo.item.emailAddress :
                           deletedItemInfo.item.phoneNumber}}?
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
      
      <!-- END: delete dialog  -->
   </div>
</template>

<script>
   import {contactDetailsUtils} from "@/mixins/contactDetailsUtils";
   import EntityLockService from "@/services/EntityLockService";
   import {GetMemberContactDetails} from "./queries/member-contact-details.graphql";
   import AddContact from "./AddContact";
   import EditContact from "./EditContact";
   import {DeleteEntityPhones, DeleteEntityEmails} from "./mutations/update-contact.graphql";
   
   export default {
      name: "ContactDetails",
      props: {
         customerId: Number,
         entityId: Number,
         entityTypeId: Number
      },
      components: {
         AddContact,
         EditContact
      },
      mixins: [contactDetailsUtils],
      data: () => ({
         member: {},
         actions: ["Add", "Edit", "Delete"],
         isLoading: false,
         isEditMode: false,
         editMode: "",
         alertRef: {
            alert: false,
            alertType: "success",
            alertMessage: ""
         },
         clipboardSnackbar: false,
         clipboardText: "",
         deleteConfirmDialog: false,
         deletedItemInfo: {
            type: "",
            item: {}
         },
         phonesToDelete: [],
         emailsToDelete: [],
         renderKey: 0
      }),
      computed: {
         addresses() {
            return typeof this.member.addresses !== "undefined" ? this.member.addresses.data : [];
         },
         emails() {
            return typeof this.member.emails !== "undefined" ? this.member.emails.data : [];
         },
         phones() {
            return typeof this.member.phones !== "undefined" ? this.member.phones.data : [];
         },
         displayEditContactInfo() {
            return this.isEditMode && this.editMode === "Edit";
         },
         displayAddContactInfo() {
            return this.isEditMode && this.editMode === "Add";
         }
      },
      methods: {
         async actionSelected(action) {
            const res = await new EntityLockService().post(this.customerId, this.$store.state.achillesUser.userId,
                                                           this.member.memberId, 1, "MemberContactDetail");
            if (res.lockedByAnotherUser) {
               await this.$dialog.info({
                                          text: "This Member has being updated by another user. Please wait or contact " +
                                                res.lockedByUserFullName + " to access the record.",
                                          title: "Locked for edit by another user",
                                          actions: ["Okay"]
                                       });
               
               return;
            }
            this.isEditMode = action !== "Delete";
            this.editMode = action;
         },
         
         async clearDeleteMode() {
            this.editMode = "";
         },
         
         copyToClipboard(value) {
            let self = this;
            this.$copyText(value).then(
                function (e) {
                   self.clipboardText = value;
                   self.clipboardSnackbar = true;
                   console.log(e);
                }
            );
         },
         removePhone(phone) {
            this.phonesToDelete = [phone.entityPhoneId];
            this.deletedItemInfo.type = "Phone";
            this.deletedItemInfo.item.phoneNumber = phone.phoneNumber;
            this.deleteConfirmDialog = true;
         },
         async deletePhones() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: DeleteEntityPhones,
                             variables: {
                                input: {
                                   phones: this.phonesToDelete,
                                   customerId: this.customerId,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         this.phonesToDelete = [];
                         this.deletedItemInfo.type = "";
                         this.deletedItemInfo.item = {};
               
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
               
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         async failure(data) {
            let errorTest = "Unable to update contact information item";
            
            if (typeof data.errorCode !== undefined) {
               errorTest += `: ${data.errorCode}`;
            }
            //exception from API
            else {
               console.log(data);
            }
            
            let options = {
               text: errorTest,
               title: "Unable to save",
               actions: ["Okay"]
            };
            await this.$dialog.error(options);
         },
         async success() {
            this.clearApolloCache();
            
            let options = {
               text: "Contact information was updated",
               title: "Saved",
               actions: ["Okay"]
            };
            await this.$dialog.info(options);
            
            this.$apollo.queries.member.skip = false;
            this.$apollo.queries.member.refetch();
            this.renderKey++;
         },
         clearApolloCache() {
            let clients = Object.values(this.$apollo.provider.clients);
            clients.forEach(client => client.cache.reset());
         },
         removeEmail(email) {
            this.emailsToDelete = [email.entityEmailId];
            this.deletedItemInfo.type = "Email";
            this.deletedItemInfo.item.emailAddress = email.emailAddress;
            this.deleteConfirmDialog = true;
         },
         async deleteEmails() {
            await this.$apollo
                      .mutate(
                          {
                             mutation: DeleteEntityEmails,
                             variables: {
                                input: {
                                   emails: this.emailsToDelete,
                                   customerId: this.customerId,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         this.emailsToDelete = [];
                         this.deletedItemInfo.type = "";
                         this.deletedItemInfo.item = {};
               
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
               
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         async cancel() {
            if (this.editMode === "Edit") {
               this.isEditMode = false;
               this.editMode = "";
               
               return;
            }
            if (this.editMode === "Add") {
               this.isEditMode = false;
               this.editMode = "";
            }
         },
         
         confirmDialogCallback: function (agreed) {
            this.deleteConfirmDialog = false;
            if (!agreed) {
               this.phonesToDelete = [];
               this.emailsToDelete = [];
               this.deletedItemInfo.type = "";
               this.deletedItemInfo.item = {};
            }
            
            if (this.phonesToDelete.length > 0) {
               this.deletePhones();
               return;
            }
            
            if (this.emailsToDelete.length > 0) {
               this.deleteEmails();
            }
         },
         onUpdateContactSaved() {
            this.isEditMode = false;
            this.editMode = "";
            
            this.$apollo.queries.member.skip = false;
            this.$apollo.queries.member.refetch();
            this.renderKey++;
         }
      },
      apollo: {
         member: {
            query: GetMemberContactDetails,
            variables() {
               return {
                  customerId: this.customerId,
                  memberId: this.entityId
               };
            },
            update: data => data.customer.member
         }
      }
   };
</script>

<style scoped>

</style>