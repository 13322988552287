/* eslint-disable no-useless-escape */
<template>
   <v-card ref="formCard" :elevation="this.options.disableAll ? 2 : 8" class="interactions-card">
      <v-col>
      <span class="caption grey--text">
        {{(selectedPatientInteraction.patientInteraction &&
           selectedPatientInteraction.patientInteraction.interactionDateTime ?
           formatDateTime(selectedPatientInteraction.patientInteraction.interactionDateTime) : "")}}
      </span>
         <span class="caption grey--text text-right">
        {{
               selectedPatientInteraction.isEdited ? (selectedPatientInteraction.patientInteraction.updatedDateTime ?
                                                      "(edited on " + formatDateTime(
                                                                        selectedPatientInteraction.patientInteraction.updatedDateTime) +
                                                      ")" :
                                                      "(edited)") : ""
        }}
      </span>
         
         <h3 class="my-1 pb-4">
            {{
               selectedPatientInteraction.patientInteraction &&
               selectedPatientInteraction.patientInteraction.interactionTypeDescription
            }}
            <span class="caption">by {{createdBy}}</span>
            <span class="caption grey--text text-right float-right" v-show="draftSaved">
          Draft Saved
        </span>
         </h3>
         
         <v-form ref="form" v-model="valid" class="pm-form">
            <v-btn v-show="options.disableAll && editEnabled" absolute class="text--primary" icon right top @click="edit">
               <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-jsf v-model="form" :options="options" :schema="schema" class="pb-10" v-if="render">
               <template slot="custom-address-component" slot-scope="context">
                  <address-component v-if="shouldShow(context)"
                                     v-show="evaluateExpression(context, 'x-show-expression') && evaluateExpression(context, 'x-eval-expression')"
                                     v-bind="context" @selected="addressSelected"/>
               </template>
               <template slot="custom-html-component" slot-scope="context">
                  <html-editor v-if="shouldShow(context)"
                               v-show="evaluateExpression(context, 'x-show-expression') && evaluateExpression(context, 'x-eval-expression')"
                               v-bind="context" class="html-editor" @selected="addressSelected"/>
               </template>
               
               <template v-show="false" slot="custom-component" slot-scope="context" v-if="isDisplayedForWithdrawalForm(context)">
                  <v-jsf
                      v-if="shouldShow(context)"
                      v-show="evaluateExpression(context, 'x-show-expression') && evaluateExpression(context, 'x-eval-expression')"
                      :disabled="context.disabled"
                      :model-key="context.modelKey"
                      :options="evaluateOptions(context)"
                      :required="context.required"
                      :rules="context.rules"
                      :schema="JSON.parse(JSON.stringify(context.schema).replaceAll('x--tag', 'x-tag'))"
                      :value="context.value"
                      @change="context.on.change"
                      @input="context.on.input">
                  </v-jsf>
               </template>
            </v-jsf>
            <v-alert :type="alertRef.alertType" :value="alertRef.alert" dense transition="fade-transition">
               {{alertRef.alertMessage}}
            </v-alert>
            <v-col v-show="!options.disableAll" class="text-right">
               <v-btn class="mr-4" @click="cancel">Cancel</v-btn>
               <v-btn :disabled="loading" :loading="loading" class="mr-4" color="primary" @click="saveForm">Save</v-btn>
            </v-col>
            <div v-show="options.disableAll &&  showMoreEnabled">
               <v-btn v-show="options.summary" absolute bottom elevation="2" right small v-on:click="showMore(true)">
                  <v-icon>mdi-chevron-down</v-icon>
                  Show More
               </v-btn>
               <v-btn v-show="!options.summary" absolute bottom elevation="2" right small v-on:click="showMore(false)">
                  <v-icon>mdi-chevron-up</v-icon>
                  Show Less
               </v-btn>
            </div>
         </v-form>
      </v-col>
      <v-dialog v-model="confirmUpdateMedicalInfoDialog" v-if="confirmUpdateMedicalInfoDialog" persistent max-width="500" content-class="confirm-dialog">
         <v-card>
            <v-card-title class="headline primary">
               Update Clinical Information
            </v-card-title>
            <v-card-text>
               Would you like to update the Patient\'s Clinical Information with the Applicable Medical History?
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="blue darken-1" text @click="confirmUpdateMedicalInfoDialogCallback(false)"> No</v-btn>
               <v-btn color="blue darken-1" text @click="confirmUpdateMedicalInfoDialogCallback(true)"> Yes</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
      <v-dialog v-model="confirmOverwritePatientAddressDialog" persistent max-width="500" content-class="confirm-dialog" v-if="confirmOverwritePatientAddressDialog">
         <v-card>
            <v-card-title class="headline primary">
               Overwrite Address
            </v-card-title>
            <v-card-text>
               Would you like to overwrite the current patient\'s address?
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="blue darken-1" text @click="confirmOverwritePatientAddressDialogCallback(false)"> No
               </v-btn>
               <v-btn color="blue darken-1" text @click="confirmOverwritePatientAddressDialogCallback(true)"> Yes
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-card>
</template>

<script>
   import VJsf from "@koumoul/vjsf/lib/VJsf.js";
   import "@koumoul/vjsf/lib/VJsf.css";
   import "@koumoul/vjsf/lib/deps/third-party.js";
   import Ajv from "ajv";
   import Vue from "vue";
   import AddressComponent from "./Shared/AddressComponent";
   import HtmlEditor from "./HtmlEditor";
   import VuetifyDialog from "vuetify-dialog";
   import "vuetify-dialog/dist/vuetify-dialog.css";
   import vuetify from "@/plugins/vuetify";
   import PatientService from "@/services/PatientService";
   import EntityContactService from "@/services/EntityContactService";
   import WorklistItemService from "../services/WorklistItemService";
   import _ from "lodash";
   import {WorklistItemTypeEnum, FormEnum} from "@/types/enum";
   
   const ajv = new Ajv(); // options can be passed, e.g. {allErrors: true}
   Vue.use(VuetifyDialog, {
      context: {
         vuetify
      }
   });
   
   /*eslint no-useless-escape: "error"*/
   export default {
      props: {
         patientInteraction: Object,
         title: String,
         createdBy: String,
         schema: Object,
         customerId: Number,
         readOnly: Boolean,
         isEdited: Boolean,
         customerTypeId: Number,
         model: Object,
         showMoreEnabled: Boolean,
         editEnabled: {
            type: Boolean,
            default: true
         },
         worklistItems: Array
      },
      name: "App",
      components: {
         HtmlEditor,
         AddressComponent,
         VJsf
      },
      data: () => ({
         confirmUpdateMedicalInfoDialog: false,
         confirmOverwritePatientAddressDialog: false,
         confirmOverwritePatientAddressResolver: null,
         patientClinicalNoteDTO: null,
         selectedPatientInteraction: {},
         valid: false,
         form: {},
         oldForm: {},
         draftSaved: false,
         patientAddresses: [],
         worklistItemId: 0,
         patientDevice: null,
         autoSaveTimer: null,
         autoSaveEnabled: process.env.VUE_APP_AUTO_SAVE_INTERACTION_FORM === "enabled",
         render: true,
         autoSaveData: {},
         alertRef: {
            alert: false,
            alertType: "success",
            alertMessage: ""
         },
         patientStatusType: {
            "Qualified": 1,
            "NotQualified": 2,
            "ConsentPending": 3,
            "PendingTraining": 4,
            "Trained": 5,
            "Scanning": 6,
            "Withdrawn": 7,
            "Renewal": 8,
            "Decline": 9,
            "ConsentReceived": 10
         },
         patientScanStatusType: {
            "Awaiting": 1,
            "FirstScan": 2,
            "Symmetrical": 3,
            "Asymmetrical": 4,
            "Disengaged": 5,
            "NA": 6
         },
         options: {
            editMode: "inline",
            evalMethod: "newFunction",
            context: {
               interactionDate: null,
               user: null,
               states: [],
               footLocations: [],
               footConditions: []
            },
            sectionsTitlesClasses: ["title", "subtitle-1", "subtitle-2"],
            summary: false,
            disableAll: false,
            markdownit: {
               html: true
            }
         },
         loading: true,
         showWithdrawalReasonFeature: process.env.VUE_APP_PATIENT_WITHDRAWAL_REASON === "enabled"
      }),
      computed: {},
      watch: {
         readOnly: function () {
            this.options.disableAll = true;
         }
      },
      methods: {
         isDisplayedForWithdrawalForm(context) {
            if (context.fullKey === "reasonForWithdrawal" && !this.showWithdrawalReasonFeature) {
               return false;
            }
            return true;
         },
         addressFilledOut() {
            return this.form.addressLine1
                   && this.form.city
                   && this.form.state
                   && this.form.zip;
         },
         patientAddressMatchesForm() {
            return (this.form.addressLine1 || "") === (this.patientAddresses[0].addressLine1 || "")
                   && (this.form.addressLine2 || "") === (this.patientAddresses[0].addressLine2 || "")
                   && (this.form.city || "") === (this.patientAddresses[0].city || "")
                   && (this.form.state || "") ===
                   (this.$store.state.states.find(f => f.stateId === this.patientAddresses[0].stateId).name || "");
         },
         patientHasAddress() {
            return this.patientAddresses
                   && this.patientAddresses[0]
                   && this.patientAddresses[0].stateId
                   && this.patientAddresses[0].addressLine1
                   && this.patientAddresses[0].zipCode;
         },
         addressChanged() {
            return (this.form.addressLine1 || "") !== (this.oldForm.addressLine1 || "") ||
                   (this.form.addressLine2 || "") !== (this.oldForm.addressLine2 || "") ||
                   (this.form.city || "") !== (this.oldForm.city || "") ||
                   (this.form.state || "") !== (this.oldForm.state || "") ||
                   (this.form.zip || "") !== (this.oldForm.zip || "");
         },
         async constructAndSavePatientStatus(formData) {
            if (!this.form.patientStatusChange) {
               return true;
            }
            
            // Get latest statuses
            await new PatientService().getPatientStatuses(this.customerId,
                                                          this.patientInteraction.patientInteraction.patientId)
                                      .then((resp) => {
                                         this.patientStatuses = resp;
                                      })
                                      .catch((err) => {
                                         console.log(err);
                                      });
            
            let patientStatus = {
               updated: "",
               patientId: 0,
               updatedByUserId: 0,
               patientStatusTypeId: 0,
               patientScanStatusTypeId: 0,
               notes: ""
            };
            
            patientStatus.updated = new Date().toISOString();
            patientStatus.patientId = this.patientInteraction.patientInteraction.patientId;
            patientStatus.updatedByUserId = this.$store.state.achillesUser.userId;

            /* BEGIN - When a Complete Onboarding Interaction is saved with a call disposition of "Engaged - Completed" 
             and the user did not check "Trained" in this case set patient status to "Trained"*/
            let onboardingForm = [
               FormEnum.StructuredInteractions_CompleteOnboarding
            ];

            if(onboardingForm.includes(this.patientInteraction.formId) && this.form.callDisposition == "Engaged - Completed")
            {
               if (this.form.patientStatusChange){
                  patientStatus.patientStatusTypeId = this.patientStatusType.Trained;
                  patientStatus.patientScanStatusTypeId =
                   (this.form.patientStatusChange && this.form.patientStatusChange.includes("Scan Confirmed")) ?
                   patientStatus.patientScanStatusTypeId = this.patientScanStatusType.FirstScan :
                   this.patientScanStatusType.Awaiting;
                  await this.savePatientStatus(this.customerId, patientStatus);    
               }                
            }
            /* END */
            
            if (this.form.patientStatusChange && this.form.patientStatusChange.includes("Decline")) {
               patientStatus.patientStatusTypeId = this.patientStatusType.Decline;
               patientStatus.patientScanStatusTypeId = this.patientScanStatusType.NA;
               await this.savePatientStatus(this.customerId, patientStatus);
            }
            
            if (this.form.patientStatusChange && this.form.patientStatusChange.includes("Consent Received")) {
               patientStatus.patientStatusTypeId = this.patientStatusType.ConsentReceived;
               patientStatus.patientScanStatusTypeId = this.patientScanStatusType.NA;
               patientStatus.notes = formData;
               await this.savePatientStatus(this.customerId, patientStatus);
            }
            
            if (this.form.patientStatusChange && this.form.patientStatusChange.includes("Trained")) {
               patientStatus.patientStatusTypeId = this.patientStatusType.Trained;
               patientStatus.patientScanStatusTypeId =
                   (this.form.patientStatusChange && this.form.patientStatusChange.includes("Scan Confirmed")) ?
                   patientStatus.patientScanStatusTypeId = this.patientScanStatusType.FirstScan :
                   this.patientScanStatusType.Awaiting;
               await this.savePatientStatus(this.customerId, patientStatus);
            }
            
            if (this.form.patientStatusChange && this.form.patientStatusChange.includes("Scan Confirmed")) {
               patientStatus.patientStatusTypeId = this.patientStatusType.Scanning;
               patientStatus.patientScanStatusTypeId = this.patientScanStatusType.FirstScan;
               await this.savePatientStatus(this.customerId, patientStatus);
            }
            
            return true;
         },
         async savePatientStatus(customerId, patientStatus) {
            await new PatientService().postPatientStatus(customerId, patientStatus);
         },
         hasStatus(status) {
            return this.patientStatuses.some(s => s.patientStatusTypeId === status);
         },
         hasScanStatus(scanStatus) {
            return this.patientStatuses.some(s => s.patientScanStatusTypeId === scanStatus);
         },
         formatDateTime(val) {
            if (val.includes("Z")) {
               return new Date(val).toLocaleString("en-US");
            } else {
               return new Date(val + "Z").toLocaleString("en-US");
            }
         },
         edit() {
            this.oldForm = _.cloneDeep(this.form);
            this.options.disableAll = false;
            this.options.summary = false;
            this.edited = true;
         },
         removeAutoComplete() {
            let self = this;
            setInterval(function () {
               if (document.getElementById(self.options.idPrefix + "addressLine1")) {
                  document.getElementById(self.options.idPrefix + "addressLine1")
                          .setAttribute("autocomplete", Math.random().toString());
               }
               if (document.getElementById(self.options.idPrefix + "addressLine2")) {
                  document.getElementById(self.options.idPrefix + "addressLine2")
                          .setAttribute("autocomplete", Math.random().toString());
               }
               if (document.getElementById(self.options.idPrefix + "state")) {
                  document.getElementById(self.options.idPrefix + "state")
                          .setAttribute("autocomplete", Math.random().toString());
               }
               if (document.getElementById(self.options.idPrefix + "zip")) {
                  document.getElementById(self.options.idPrefix + "zip")
                          .setAttribute("autocomplete", Math.random().toString());
               }
               if (document.getElementById(self.options.idPrefix + "city")) {
                  document.getElementById(self.options.idPrefix + "city")
                          .setAttribute("autocomplete", Math.random().toString());
               }
            }, 1500);
         },
         escalationAsNeeded: async function () {
            if (this.hasMetInitialOutreachEscalationConditions()) {
               this.patientInteraction.patientInteraction.worklistItem.isEscalation = true;
               this.patientInteraction.patientInteraction.worklistItem.note =
                   `Interaction call Disposition of ${this.form.callDisposition}
                    was selected on Initial Outreach`;
               try {
                  await new WorklistItemService().putWorklistItem(
                      this.customerId,
                      this.patientInteraction.patientInteraction.worklistItemId,
                      this.patientInteraction.patientInteraction.worklistItem);
               }
               catch (e) {
                  console.log(e);
                  return false;
               }
               
               return true;
            }
            
            if (this.hasMetUpdatedDeviceSerialNumberEscalationConditions()) {
               if (this.patientInteraction.patientInteraction.worklistItem) {
                  this.patientInteraction.patientInteraction.worklistItem.isEscalation = true;
                  this.patientInteraction.patientInteraction.worklistItem.note =
                      this.getNoteUpdatedDeviceSerialNumber();
                  
                  try {
                     await new WorklistItemService().putWorklistItem(
                         this.customerId,
                         this.patientInteraction.patientInteraction.worklistItemId,
                         this.patientInteraction.patientInteraction.worklistItem);
                  }
                  catch (e) {
                     console.log(e);
                     return false;
                  }
                  return true;
               }
               
               let newWorklistItem = {};
               newWorklistItem.worklistId = 2;
               newWorklistItem.worklistItemTypeId = WorklistItemTypeEnum.Escalation;
               newWorklistItem.worklistItemStatusTypeId = 2;
               newWorklistItem.patientId = this.patientInteraction.patientInteraction.patientId;
               newWorklistItem.created = new Date().toISOString();
               newWorklistItem.createdByUserId = this.$store.state.achillesUser.userId;
               newWorklistItem.isEscalation = true;
               newWorklistItem.note = this.getNoteUpdatedDeviceSerialNumber();
               
               try {
                  await new WorklistItemService()
                      .postWorklistItem(this.customerId, newWorklistItem);
               }
               catch (e) {
                  console.log(e);
                  return false;
               }
               
            }
            
            return true;
         },
         hasMetInitialOutreachEscalationConditions() {
            let callDispositionOptions = [
               "Engaged - Patient Declined",
               "Engaged - Patient Requested Do Not Call",
               "Unable to Engage - Wrong Number"
            ];
            let formsWithEscalation = [
               FormEnum.StructuredInteractions_InitialOutreach
            ];
            return formsWithEscalation.includes(this.patientInteraction.formId)
                   && callDispositionOptions.includes(this.form.callDisposition)
                   && this.patientInteraction.patientInteraction.worklistItem
                   && !this.patientInteraction.patientInteraction.worklistItem.isEscalation;
         },
         hasMetUpdatedDeviceSerialNumberEscalationConditions() {
            let formsWithDeviceUpdates = [
               FormEnum.StructuredInteractions_CompleteOnboarding,
               FormEnum.StructuredInteractions_PostRenewal
            ];
            
            return (formsWithDeviceUpdates.includes(this.patientInteraction.formId))
                   && this.form.updateDeviceSerialNumber
                   && this.form.deviceSerialNumber
                   && this.form.deviceSerialNumber !== this.oldForm.deviceSerialNumber;
         },
         getNoteUpdatedDeviceSerialNumber() {
            let onboardingForms = [
               FormEnum.StructuredInteractions_CompleteOnboarding
            ];
            let formName = onboardingForms.includes(this.patientInteraction.formId)
                           ? "Complete Onboarding"
                           : "Post Renewal";
            
            return `Device Serial Number was updated on the ${formName} Interaction`;
         },
         async confirmOverwritePatientAddressDialogCallback(agreed) {
            this.confirmOverwritePatientAddressDialog = false;
            
            this.confirmOverwritePatientAddressResolver(agreed);
         },
         async confirmUpdateMedicalInfoDialogCallback(agreed) {
            this.confirmUpdateMedicalInfoDialog = false;
            
            if (agreed) {
               await new PatientService().postPatientClinicalNoteByInteractionId(this.customerId,
                                                                                 this.patientClinicalNoteDTO.patientInteractionId,
                                                                                 this.patientClinicalNoteDTO);
            }
         },
         async saveForm() {
            if (!this.$refs.form.validate()) {
               const res = await this.$dialog.error({
                                                       text: "Please fix the highlighted issues before savings.",
                                                       title: "Unable to save.",
                                                       actions: ["Okay"]
                                                    });
               
               return;
            }
            
            try {
               this.loading = true;
               if (this.patientInteraction.formId === FormEnum.Legacy) {
                  let legacyModel = {};
                  legacyModel.Fields = [
                     {
                        Name: "note",
                        Value: this.form.notes
                     }
                  ];
                  this.selectedPatientInteraction.formData = JSON.stringify(legacyModel);
               } else {
                  this.selectedPatientInteraction.formData = JSON.stringify(this.form);
               }
               
               if (!this.selectedPatientInteraction.patientInteraction.interactionDateTime) {
                  this.selectedPatientInteraction.patientInteraction.interactionDateTime = new Date().toISOString();
                  this.selectedPatientInteraction.patientInteraction.updatedDateTime = new Date().toISOString();
                  this.selectedPatientInteraction.isEdited = false;
               } else {
                  this.selectedPatientInteraction.patientInteraction.updatedDateTime = new Date().toISOString();
                  this.selectedPatientInteraction.patientInteraction.updatedByUserId =
                      this.$store.state.achillesUser.userId;
                  this.selectedPatientInteraction.isEdited = true;
               }
               
               // Custom Form Logic
               
               if (this.hasSoftTrainingCondition()) {
                  const res = await this.$dialog.warning({
                                                            text: "Soft Training was NOT completed",
                                                            title: "Soft Training",
                                                            actions: ["Okay"]
                                                         });
               }
               
               if (this.hasNotShipCondition()) {
                  const res = await this.$dialog.warning({
                                                            text: "Mat will NOT ship as Consent Received was not selected",
                                                            title: "Mat will NOT ship",
                                                            actions: ["Okay"]
                                                         });
               }
               
               if (this.hasTrainingCompleteCondition()) {
                  const res = await this.$dialog.error({
                                                          text: "Since Patient Status is Trained, you must select the Training Complete checkbox",
                                                          title: "Training Complete Selection Required",
                                                          actions: ["Okay"]
                                                       });
                  
                  this.loading = false;
                  return;
               }
               
               if (this.hasInvalidNextClinicVisitDate()) {
                  const res = await this.$dialog.error({
                                                          text: "The date selected for Next Clinic Visit must be in the future",
                                                          title: "Incorrect Next Clinic Visit",
                                                          actions: ["Okay"]
                                                       });
                  
                  this.loading = false;
                  return;
               }
               
               if (this.hasInvalidLastClinicVisitDate()) {
                  const res = await this.$dialog.error({
                                                          text: "The date selected for Last Clinic Visit must be in the past",
                                                          title: "Incorrect Last Clinic Visit",
                                                          actions: ["Okay"]
                                                       });
                  
                  this.loading = false;
                  return;
               }
               
               if (this.hasInvalidDeviceSerialNumber()) {
                  const res = await this.$dialog.error({
                                                          text: "You must either enter a Device Serial Number or uncheck the \"Update Device Serial Number?\" checkbox",
                                                          title: "Device Serial Number Required",
                                                          actions: ["Okay"]
                                                       });
                  
                  this.loading = false;
                  return;
               }
               
               if (this.hasInvalidAddress()) {
                  const res = await this.$dialog.error({
                                                          text: "Address is required when consent is received",
                                                          title: "Address Required",
                                                          actions: ["Okay"]
                                                       });
                  
                  this.loading = false;
                  return;
               }
               
               if (this.hasFormWithUpdatedAddress()) {
                  if (this.patientAddresses.length > 0) {
                     
                     this.confirmOverwritePatientAddressDialog = true;
                     
                     let res = await new Promise((resolver) => {
                        this.confirmOverwritePatientAddressResolver = resolver;
                     });
                     
                     this.selectedPatientInteraction.updateAddress = res;
                  } else {
                     this.selectedPatientInteraction.updateAddress = true;
                  }
               } else {
                  this.selectedPatientInteraction.updateAddress = false;
               }

               
               if (await this.proceedToSave()) {
                  try {
                     if (this.form.thermometryAssessment) {
                        this.selectedPatientInteraction.updateThermometryAssessment = true;
                     }
                     
                     let response = await new PatientService().postPatientInteractionForm(this.customerId,
                                                                                          this.selectedPatientInteraction);
                     
                     if (this.hasUpdatedMedicalHistory()) {
                        this.patientClinicalNoteDTO = {
                           patientId: this.patientInteraction.patientInteraction.patientId,
                           note: this.form.applicableMedicalHistory,
                           created: new Date().toISOString(),
                           updatedByUserId: this.$store.state.achillesUser.userId,
                           isHtml: true,
                           patientInteractionId: response.data.patientInteractionId
                        };
                        
                        if (this.selectedPatientInteraction.isEdited) {
                           this.confirmUpdateMedicalInfoDialog = true;
                        } else {
                           await new PatientService().postPatientClinicalNote(this.customerId,
                                                                              this.patientClinicalNoteDTO);
                        }
                     }
                     
                     this.$emit("saved", {
                        success: true,
                        worklistItemId: this.worklistItemId
                     });
                     this.options.summary = true;
                     this.options.disableAll = true;
                     this.oldForm = _.cloneDeep(this.form);
                     this.clearAutoSaveTimer();
                     
                  }
                  catch (e) {
                     this.options.summary = false;
                     this.options.disableAll = false;
                     this.loading = false;
                     this.$emit("saved", {
                        success: false,
                        worklistItemId: 0
                     });
                  }
                  
               }
            }
            catch (e) {
               const res = await this.$dialog.error({
                                                       text: "Unable to save: " + e,
                                                       title: "Unable to save",
                                                       actions: ["Okay"]
                                                    });
               
               this.options.summary = false;
               this.options.disableAll = false;
               this.loading = false;
               this.$emit("saved", {
                  success: false,
                  worklistItemId: 0
               });
            }
            
            this.loading = false;
            
         },
         hasSoftTrainingCondition() {
            let forms = [
               FormEnum.StructuredInteractions_InitialOutreach
            ];
            return forms.includes(this.patientInteraction.formId)
                   && this.form.patientStatusChange
                   && this.form.patientStatusChange.includes("Consent Received")
                   && !this.form.softTraining;
            
         },
         hasNotShipCondition() {
            let forms = [
               FormEnum.StructuredInteractions_InitialOutreach
            ];
            return forms.includes(this.patientInteraction.formId)
                   && this.form.patientStatusChange
                   && !this.form.patientStatusChange.includes("Consent Received");
         },
         hasTrainingCompleteCondition() {
            let forms = [
               FormEnum.StructuredInteractions_Training
            ];
            return forms.includes(this.patientInteraction.formId)
                   && this.form.patientStatusChange
                   && this.form.patientStatusChange.includes("Trained")
                   && !this.form.trainingComplete;
         },
         hasInvalidNextClinicVisitDate() {
            return this.form.nextClinicVisit && this.parseDate(this.form.nextClinicVisit) <
                   new Date(new Date().toDateString());
         },
         hasInvalidLastClinicVisitDate() {
            return this.form.lastClinicVisit && this.parseDate(this.form.lastClinicVisit) >
                   new Date(new Date().toDateString());
         },
         hasInvalidDeviceSerialNumber() {
            let forms = [
               FormEnum.StructuredInteractions_CompleteOnboarding,
               FormEnum.StructuredInteractions_PostRenewal
            ];
            return forms.includes(this.patientInteraction.formId) &&
                   this.form.updateDeviceSerialNumber && !this.form.deviceSerialNumber;
         },
         hasInvalidAddress() {
            let forms = [
               FormEnum.StructuredInteractions_InitialOutreach,
               FormEnum.StructuredInteractions_Renewal
            ];
            return forms.includes(this.patientInteraction.formId)
                   && this.form.patientStatusChange
                   && this.form.patientStatusChange.includes("Consent Received")
                   && !this.form.addressLine1;
         },
         hasFormWithUpdatedAddress() {
            let forms = [
               FormEnum.StructuredInteractions_InitialOutreach,
               FormEnum.StructuredInteractions_Renewal,
               FormEnum.StructuredInteractions_CompleteOnboarding,
               FormEnum.StructuredInteractions_PostRenewal
            ];
            return forms.includes(this.patientInteraction.formId)
                   && this.addressFilledOut()
                   && ((this.patientHasAddress() && !this.patientAddressMatchesForm()) || !this.patientHasAddress())
                   && this.addressChanged();
         },
         async proceedToSave() {
            let isOffloading = await this.hasOffloadingWorkflow();
            let hasChangesToSave = await this.hasChanges();
            
            return isOffloading || hasChangesToSave;
         },
         
         async hasChanges() {
            const escalationSuccessful = await this.escalationAsNeeded();
            const statusChangeSuccessful = await this.constructAndSavePatientStatus(
                this.selectedPatientInteraction.formData);
            
            return statusChangeSuccessful && escalationSuccessful;
         },
         async hasOffloadingWorkflow() {
            const createWorklistItemsSuccessful = await this.createWorklistItems();
            let forms = [
               FormEnum.StructuredInteractions_Offloading
            ];
            
            return forms.includes(this.patientInteraction.formId) && createWorklistItemsSuccessful;
         },
         hasUpdatedMedicalHistory() {
            let forms = [
               FormEnum.StructuredInteractions_InitialOutreach,
               FormEnum.StructuredInteractions_CompleteOnboarding
            ];
            
            return forms.includes(this.patientInteraction.formId)
                   && typeof this.oldForm.applicableMedicalHistory !== "undefined"
                   && typeof this.oldForm.applicableMedicalHistory !== "undefined"
                   && this.oldForm.applicableMedicalHistory.replace(/\s/g, "").replace(/&nbsp;/g, "") !==
                   this.form.applicableMedicalHistory.replace(/\s/g, "").replace(/&nbsp;/g, "")
                   && this.form.callDisposition.startsWith("Engaged");
         },
         clearAutoSaveTimer() {
            if (this.autoSaveEnabled) {
               clearInterval(this.autoSaveTimer);
            }
         },
         cancel() {
            this.$emit("cancelled");
            this.options.disableAll = true;
            this.options.summary = true;
            this.clearAutoSaveTimer();
         },
         parseDate: function (input) {
            let parts = input.split("-");
            
            return new Date(parts[0], parts[1] - 1, parts[2]); // Note: months are 0-based
         },
         log: function (val) {
            console.log("Model" + val);
            console.log(JSON.stringify(val));
         },
         replaceTokens: function (val) {
            return val
                .replaceAll("{initials}", this.$store.state.achillesUser.firstName.charAt(0) +
                                          this.$store.state.achillesUser.lastName.charAt(0))
                .replaceAll("{timestamp}", new Date().toISOString());
         },
         evaluateValueExpression: function (e, context, property, value) {
            if (context.schema[property]) {
               return eval(this.replaceTokens(context.schema[property]));
            } else {
               return value;
            }
         },
         evaluateExpression: function (context, property) {
            if (context.schema[property]) {
               return eval(context.schema[property]);
            } else {
               return true;
            }
         },
         addressSelected: function (val) {
            if (typeof val !== "object" || val === null) {
               return;
            }
            if (this.schema.properties.zip) {
               this.model.zip = val.postal_code;
            }
            if (this.schema.properties.addressLine2) {
               this.model.addressLine2 = "";
            }
            if (this.schema.properties.city) {
               this.form.city = val.locality;
            }
            if (this.schema.properties.zip) {
               this.form.zip = val.postal_code;
            }
            if (this.schema.properties.state) {
               this.form.state = val.administrative_area_level_1;
            }
            this.render = false;
            
            this.$nextTick(() => {
               this.render = true;
            });
         },
         shouldShow(context) {
            return (!this.options.summary || (this.options.summary && context.schema["x-summary"]));
         },
         showMore(val) {
            this.options.summary = !val;
         },
         setDefaults() {
            if (this.readOnly) {
               this.options.disableAll = this.readOnly;
               this.options.summary = true;
            }
         },
         hasOffloadingWorklistItem() {
            if (!this.worklistItems) {
               return false;
            }
            return this.worklistItems.find(w => w.worklistItemTypeId === WorklistItemTypeEnum.Offloading);
         },
         autoSave() {
            if (!_.isEqual(this.form, this.autoSaveData)) {
               this.$emit("autoSave", this.form);
               this.autoSaveData = _.cloneDeep(this.form);
               if (!this.draftSaved) {
                  this.draftSaved = true;
                  const self = this;
                  setTimeout(() => {
                     self.draftSaved = false;
                  }, 3000);
               }
            }
         },
         enableAutoSave() {
            if (this.autoSaveEnabled && !this.readOnly) {
               this.$nextTick(() => {
                  this.oldForm = _.cloneDeep(this.form);
                  this.autoSaveData = _.cloneDeep(this.form);
                  const self = this;
                  self.autoSaveTimer = setInterval(
                      function () {
                         self.autoSave();
                      }, 3000);
               });
            }
         },
         loadData() {
            this.loading = true;
            let promises = [];
            
            if (!this.readOnly) {
               promises.push(new EntityContactService()
                                 .getPatientAddresses(this.customerId,
                                                      this.patientInteraction.patientInteraction.patientId)
                                 .then((resp) => {
                                    this.patientAddresses = resp;
                                 })
                                 .catch((err) => {
                                    console.log(err);
                                 }));
            }
            
            if (!this.readOnly) {
               promises.push(new PatientService()
                                 .getPatientDevice(this.customerId,
                                                   this.patientInteraction.patientInteraction.patientId)
                                 .then((value) => {
                                    this.patientDevice = value;
                                 })
                                 .catch((err) => {
                                    console.log(err);
                                 }));
            }
            
            Promise.allSettled(promises).then(() => {
               this.options.context.states = this.$store.state.states.map(s => s.name.trim());
               this.options.context.footLocations = this.$store.state.footLocations;
               this.options.context.footConditions = this.$store.state.footConditions;
               this.options.context.foots = this.$store.state.foots;
               this.form = this.model;
               
               if (this.patientAddresses && this.patientAddresses.length > 0 && !this.isEdited && this.form &&
                   !this.form.addressLine1) {
                  if (this.schema.properties.addressLine1 && !this.form.addressLine1) {
                     this.form.addressLine1 =
                         this.patientAddresses[0].addressLine1;
                  }
                  if (this.schema.properties.addressLine2 && !this.form.addressLine2) {
                     this.form.addressLine2 =
                         this.patientAddresses[0].addressLine2;
                  }
                  if (this.schema.properties.city && !this.form.city) {
                     this.form.city = this.patientAddresses[0].city;
                  }
                  if (this.schema.properties.state && !this.form.state) {
                     this.form.state =
                         this.$store.state.states.find(f => f.stateId === this.patientAddresses[0].stateId).name;
                  }
                  if (this.schema.properties.zip && !this.form.zip) {
                     this.form.zip = this.patientAddresses[0].zipCode;
                  }
               }
               
               if (this.patientDevice && !this.isEdited && this.form && this.schema.properties.deviceSerialNumber) {
                  this.form.deviceSerialNumber = this.patientDevice.serialNumber;
               }
               
               if (this.schema.properties.thermometryAssessment
                   && this.patientInteraction.patientInteraction.worklistItemId > 0
                   && !this.isEdited
                   && !this.form.thermometryAssessment
               ) {
                  this.form.thermometryAssessment =
                      this.patientInteraction.patientInteraction.worklistItem.thermometryAssessment;
               }
               
               this.enableAutoSave();
               this.loading = false;
            });
         },
         evaluateOptions(context) {
            context.options.disableAll =
                context.options.disableAll || !this.evaluateExpression(context, "x-disabled-expression");
            return context.options;
         },
         async createWorklistItems() {
            if (!this.hasOffloadingCondition()) {
               return false;
            }
            
            if (this.hasOffloadingWorklistItem()
                && this.form.triggerOffloading === this.oldForm.triggerOffloading) {
               const res = await this.$dialog.error({
                                                       text: "You have requested Inflammation Check-In cadence to be initiated for this patient however the patient is already in the cadence. Please uncheck to continue.",
                                                       title: "Already In Cadence",
                                                       actions: ["Okay"]
                                                    });
               return false;
            }
            
            const res = await this.$dialog.info({
                                                   text: "You have requested Inflammation Check-In cadence to be initiated for this patient. A worklist item has been created.",
                                                   title: "Inflammation Check-In cadence initiated",
                                                   actions: ["Okay"]
                                                });
            
            let newWorklistItem = {};
            newWorklistItem.worklistId = 1;
            newWorklistItem.worklistItemTypeId = WorklistItemTypeEnum.Offloading;
            newWorklistItem.worklistItemStatusTypeId = 1;
            newWorklistItem.patientId = this.patientInteraction.patientInteraction.patientId;
            newWorklistItem.created = new Date().toISOString();
            newWorklistItem.createdByUserId = this.$store.state.achillesUser.userId;
            newWorklistItem.assignedToUserId = this.$store.state.achillesUser.userId;
            newWorklistItem.isEscalation = false;
            newWorklistItem.note = "User selected Start Inflammation Check-In Cadence";
            
            try {
               const worklistItem = await new WorklistItemService()
                   .postWorklistItem(this.customerId, newWorklistItem);
               this.worklistItemId = worklistItem.data.worklistItemId;
            }
            catch (e) {
               console.log(e);
               return false;
            }
            
            return true;
         },
         hasOffloadingCondition() {
            return this.patientInteraction.formId === FormEnum.StructuredInteractions_Offloading
                   && this.form.triggerOffloading;
         }
      },
      mounted() {
         this.removeAutoComplete();
         this.selectedPatientInteraction = this.patientInteraction;
      },
      created() {
         this.options.idPrefix = this._uid.toString();
         this.loadData();
         this.setDefaults();
         
      }
   };
</script>

<style>
   
   .v-application .title {
      font-size: 16px !important;
   }
   
   /* bug fix for date fields */
   .interactions-card .pm-form .vjsf-property .v-input__slot {
      display: flex !important;
   }
   
   .interactions-card .pm-form .vjsf-property .html-editor .v-input__slot {
      display: initial !important;
   }
   
   [class$="vjsf-property-"],
   [class*="vjsf-property-"] {
      max-width: 100% !important;
   }
   
   [class$="--disabled"],
   [class*="--disabled "] * {
      color: rgba(0, 0, 0, 0.80) !important;
   }
   
   [class$="--is-disabled"],
   [class*="--is-disabled "] * {
      color: rgba(0, 0, 0, 0.80) !important;
   }
   
   .interactions-card .v-label--is-disabled {
      color: rgba(0, 0, 0, 0.60) !important;
   }
   
   .interactions-card .v-text-field--filled {
      background: #ffffff !important;
   }
   
   .interactions-card .v-input--is-disabled .v-input__slot {
      background: #ffffff !important;
   }
   
   .interactions-card .v-input--is-disabled:not(.v-input--is-readonly) {
      pointer-events: all !important;
   }
   
   .interactions-card .theme--light.v-sheet--outlined {
      border: none;
   }
   
   .confirm-dialog .v-card__title {
      color: #ffffff;
      font-size: 1.25rem !important;
      line-height: 1.5 !important;
   }
   
   .confirm-dialog .v-card__text {
      margin-top: 26px;
      font-size: 1rem !important;
   }
</style>