<template>
    <div class="mb-2 mb-sm-1 data-field fill-half">
      <div class="mb-1 grey--text data-title text-caption">{{ fieldTitle }}</div>
      <h3 class="data-content" @click="copyData(fieldData)">
        <span class="data">{{ fieldData }}</span>
      </h3>
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: "DataField",
  props: [
    "fieldTitle",
    "fieldData",
  ],
  methods: {
    copyData(value) {
      this.$emit("copy-data", value)
    }
  },
}
</script>

<style lang="scss" scoped>
   .fill-half {
      height: 50%;
   }

   .data-field {
    &:nth-of-type(2) {
      margin-top: 8px;
    }

    .data-title {
      line-height: 1rem;
    }

    .data-content {
      font-size: 1rem;
      line-height: 1.1rem;
      >.data {
        cursor: pointer;
      }
      
    }
   }
</style>