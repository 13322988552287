import * as BaseService from './BaseService';
import * as customer from '../types/customer';

export default class ReferenceDataService extends BaseService.BaseService {
    private url = '/api/ReferenceData';
         
   constructor() {
     super();     
   }
  
    async getStates(): Promise<Array<customer.State>>{      
      let url = this.url + '/state';            
      let response = await (await this.api.get(url)).data as Array<customer.State>;          
      return response;
    }
    
    async getInteractionTypes(): Promise<Array<customer.InteractionType>>{      
      let url = this.url + '/InteractionType';            
      let response = await (await this.api.get(url)).data as Array<customer.InteractionType>;          
      return response;
    }

    async getEscalationTypes(): Promise<Array<customer.EscalationType>>{      
      let url = this.url + '/EscalationType';            
      let response = await (await this.api.get(url)).data as Array<customer.EscalationType>;          
      let sortedResponse = response.sort((a,b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));  
      return sortedResponse;
    }

    async getEscalationStatusTypes(): Promise<Array<customer.EscalationStatusType>>{      
      let url = this.url + '/EscalationStatusType';            
      let response = await (await this.api.get(url)).data as Array<customer.EscalationStatusType>;          
      return response;
    }

    async getWorklistItemTypes(): Promise<Array<customer.WorklistItemType>>{      
      let url = this.url + '/WorklistItemType';            
      let response = await (await this.api.get(url)).data as Array<customer.WorklistItemType>;          
      return response;
    }

    async getTimeZones(): Promise<Array<customer.TimeZone>>{      
      let url = this.url + '/TimeZone';            
      let response = await (await this.api.get(url)).data as Array<customer.TimeZone>;          
      return response;
    }

    async getInteractionTypeForms(): Promise<Array<customer.InteractionTypeForm>> {
        let url = this.url + '/InteractionTypeForm';
        let response = await (await this.api.get(url)).data as Array<customer.InteractionTypeForm>;
        return response;
    }

    async getForms(): Promise<Array<customer.Form>> {
        let url = this.url + '/Form';
        let response = await (await this.api.get(url)).data as Array<customer.Form>;
        return response;
    }

    async getFootLocations(): Promise<Array<customer.FootLocation>> {
        let url = this.url + '/FootLocation';
        return await (await this.api.get(url)).data as Array<customer.FootLocation>;
    }

    async getFoots(): Promise<Array<customer.Foot>> {
        let url = this.url + '/Foot';
        return await (await this.api.get(url)).data as Array<customer.Foot>;
    }

    async getFootConditions(): Promise<Array<customer.FootCondition>> {
        let url = this.url + '/FootCondition';
        return await (await this.api.get(url)).data as Array<customer.FootCondition>;
    }

    async getGenders(): Promise<Array<customer.Gender>>{      
      let url = this.url + '/Gender';            
      let response = await (await this.api.get(url)).data as Array<customer.Gender>;          
      return response;
    }

    async getProviderTypes(): Promise<Array<customer.ProviderType>>{      
      let url = this.url + '/ProviderType';            
      let response = await (await this.api.get(url)).data as Array<customer.ProviderType>;          
      return response;
    }

    async getPreferredModeOfContact(): Promise<Array<customer.PreferredModeOfContact>>{      
      let url = this.url + '/PreferredModeOfContact';            
      let response = await (await this.api.get(url)).data as Array<customer.PreferredModeOfContact>;          
      return response;
    }

    async getLanguagePreference(): Promise<Array<customer.LanguagePreference>>{      
      let url = this.url + '/LanguagePreference';            
      let response = await (await this.api.get(url)).data as Array<customer.LanguagePreference>;          
      return response;
    }

    async getEntityType(): Promise<Array<customer.EntityType>>{      
      let url = this.url + '/EntityType';            
      let response = await (await this.api.get(url)).data as Array<customer.EntityType>;          
      return response;
    }

    async getLineOfBusiness(customerId: number): Promise<Array<customer.LineOfBusiness>>{
      let url = this.url + `/LineOfBusiness/${customerId}`;
      let response =  (await this.api.get(url)).data as Array<customer.LineOfBusiness>;
      return response;
    }

  }