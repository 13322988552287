<template>
   <div>
     <div v-if="!validPatient && !loading">Patient Not Found</div>
      <DetailsBanner
         v-if="customer" 
         :display-data="PatientData"
         :key="componentKey + detailsBannerKey"
         @copy-data="copyToClipboard"
         @close="close"
         />
     
      <v-col v-if="validPatient">

        <v-container fluid pt-0>
          <ExportSnackbar />
          <ClipboardSnackbar />

          <!-- Crisis call dialog -->
          <CrisisCallDialog
              v-if="customer.patient" :isPatient="true"
              :dialogCrisis="dialogCrisis"
              :customerCrisisCallContent="customer.crisisCallContent"
          />

          <!-- Crisis call button -->
          <CrisisCallButton
              :isPatient="true"
              :isFixed="true"
              :info="customer.patient.designation"
              @set-crisis-dialog-val="setCrisisDialogVal"
          />
         
         <v-tabs id="PatientTabs" @change="clearApolloCache" :class="setDynamicStyleClass" >
            <v-tab key="patientSummary"> Patient Summary</v-tab>
            <v-tab-item key="patientSummary">
               <patient-summary v-if="customer.patient.patientId" :patient="customer.patient" :customer-id="customerId" @patient-primary-nurse-user-saved="primaryCareNurseUserSaved($event)"></patient-summary>
            </v-tab-item>
            <v-tab key="demo"> Demographics</v-tab>
            <v-tab-item key="demo">
               <PatientDemographics v-if="renderDemo" :patientId="patientId" :customerId="customerId" :key="componentKey" @changed="demoChanged"/>
            </v-tab-item>
            <v-tab key="usage"> Device Usage</v-tab>
            <v-tab-item key="usage">
               <DeviceUsage v-if="renderDeviceUsage" :serialNumber="serialNumber" :deviceUsage="deviceUsage" :patient="customer.patient" :customerId="customerId" :key="componentKey"/>
            </v-tab-item>
            <v-tab key="history"> History</v-tab>
            <v-tab-item key="history">
               <patient-history v-if="renderClinical" :patientId="customer.patient.patientId" :customerId="customerId" :key="componentKey"/>
            </v-tab-item>
            <v-tab key="workitems" v-if="worklistEnabled"> Worklist Items</v-tab>
            <v-tab-item key="workitems" v-if="worklistEnabled">
               <PatientWorklistItems v-if="renderWorklist" :patient="customer.patient" :customerId="customerId" :key="componentKey"/>
            </v-tab-item>
            <v-tab key="interactions"> Interactions</v-tab>
            <v-tab-item key="interactions">
               <Interactions v-if="customer" ref="interactionsComp" @saved="interactionChanged" :patient="customer.patient" :customerId="customerId" :customerOnboardingModel="customer.onboardingModel" :worklistItemId="resolvedWorklistItemId" :key="componentKey"/>
            </v-tab-item>
            <v-tab key="providers"> Providers</v-tab>
            <v-tab-item key="providers">
               <provider-list v-if="renderProviders" :customer-id="customerId" :entityId="customer.patient.patientId" :entityTypeId="1"></provider-list>
            </v-tab-item>
            <v-tab key="documents"> Documents</v-tab>
            <v-tab-item key="documents">
               <PatientDocuments :patient="customer.patient" :customerId="customerId" :provider="provider" :key="componentKey"/>
            </v-tab-item>
            <v-tab key="related-entities" v-if="prequalificationEnabled">Related Entities</v-tab>
            <v-tab-item key="related-entities" v-if="prequalificationEnabled">
               <RelatedEntityList :patient="customer.patient" :customerId="customerId"/>
            </v-tab-item>
         </v-tabs>
         </v-container>
      </v-col>
   </div>

</template>

<script>
   import {GetPatient} from "./Patient/queries/patient.graphql";
   import {Input} from "@/types/queryInput";
   import {PodRoleEnum, CustomerEnum} from "@/types/enum";
   import ClipboardSnackbar from "./Shared/ClipboardSnackbar";
   import CrisisCallButton from "./Shared/CrisisCallButton";
   import CrisisCallDialog from "./Shared/CrisisCallDialog";
   import DetailsBanner from "./Shared/DetailsBanner.vue"
   import DeviceUsage from "./DeviceUsage.vue";
   import ExportSnackbar from "./Shared/ExportSnackbar";
   import Interactions from "./Interactions2.vue";
   import PatientDemographics from "./PatientDemographics.vue";
   import PatientDocuments from "./PatientDocuments";
   import PatientHistory from "@/components/Patient/History/PatientHistory";
   import PatientPurchaseOrderService from "@/services/PatientPurchaseOrderService";
   import PatientService from "@/services/PatientService";
   import PatientSummary from "@/components/PatientSummary";
   import PatientWorklistItems from "./PatientWorklistItems";
   import ProviderList from "@/components/Provider/ProviderList";
   import ProviderService from "@/services/ProviderService";
   import RelatedEntityList from "@/components/Patient/RelatedEntities/RelatedEntityList";
   
   export default {
      props: {
         patientId: Number,
         customerId: Number,
         worklistItemId: {
            type: Number,
            default: null
         }
      },
      name: "PatientDetails",
      components: {
         ClipboardSnackbar,
         CrisisCallButton,
         CrisisCallDialog,
         DetailsBanner,
         DeviceUsage,
         ExportSnackbar,
         Interactions,
         PatientDemographics,
         PatientDocuments,
         PatientHistory,
         PatientSummary,
         PatientWorklistItems,
         ProviderList,
         RelatedEntityList,
      },
      data: () => ({
         loading: false,
         padsOffline: true,
         renderDemo: true,
         renderClinical: true,
         patientDevice: null,
         renderWorklist: true,
         renderProviders: true,
         renderDeviceUsage: false,
         deviceUsage: {},
         detailsBannerKey: 0,
         dialogCrisis: false,
         openWorklistItemId: null,
         customer: {
            patient: {
               pod: {
                  name: "------"
               },
               primaryNurseUser: {
                  displayName: "Unassigned"
               },
               site: {
                  name: null
               }
            }
         },
         provider: null,
         providerTypeName: null,
         prescribingProvider: null,
         serialNumber: null,
         worklistEnabled: process.env.VUE_APP_WORKLIST_FEATURE === "enabled",
         prequalificationEnabled: process.env.VUE_APP_PREQUALIFICATION_FEATURE === "enabled"
   }),
   apollo: {
         customer: {
            query: GetPatient,
            variables() {
               return {
                  customerId: this.customerId,
                  patientId: this.patientId,
                  assignedUsersByPodRoleInput: this.assignedUsersByPodRoleInput,
                  podRoleIds: [PodRoleEnum.PodNurse, PodRoleEnum.PodAdministrator, PodRoleEnum.PodSupervisor, PodRoleEnum.PodTeamLeader],
                  patientIdentifierInput: this.patientIdentifierInput
               };
            },
            update(data) {
               return data.customer;
            }
         }
      },
   computed: {
      resolvedWorklistItemId() {
         if (this.worklistItemId != null)
         {
            return this.worklistItemId;
         } else if (this.openWorklistItemId != null) {
            return this.openWorklistItemId;
         } else {
            return null;
         }
      },
      validPatient() {
        return !this.loading && (      
            this.customerId > 0 &&
            this.patientId > 0 &&
            this.customer && 
            this.customer.patient &&
            this.customerId === this.customer.customerId &&
            this.patientId === this.customer.patient.patientId);
      },
      customerName() {
         return this.customer !== null ? this.customer.name : ''
      },
      patientAge() {
         return this.customer.patient !== null ? this.customer.patient.age : ''
      },
      vaSiteName() {
         return this.customer.patient.site !== null ? this.customer.patient.site.name : ''
      },
      podName() {   
         return  this.customer.patient.pod !== null ? this.customer.patient.pod.name : ''
      },
      primaryNurse() {
         return  this.customer.patient.primaryNurseUser !== null ? this.customer.patient.primaryNurseUser.displayName : ''
      },
      componentKey() {
         return this.customerId + ":" + this.customer.patient.patientId;
      },
      genderText() {
         const gen = this.$store.state.genders.find(g => g.genderId === this.customer.patient.genderId);
         return gen ? gen.description : "";
      },
      assignedUsersByPodRoleInput() {
         return new Input();
      },
      patientIdentifierInput() {
         return new Input();
      },
      patientIdentifier() {
         if (!this.customer.patient || !this.customer.patient.patientIdentifiers || this.customer.patient.patientIdentifiers.count ===
               0) {
            return null;
         }
         return this.customer.patient.patientIdentifiers.data[0];
      },
      patientNickname() {
         return this.customer.patient !== null ? this.customer.patient.nickName : ''
      },
      PatientData() {
         return {
            customer: this.customer,
            patientAge: this.patientAge,
            genderText: this.genderText,
            customerName: this.customerName,
            vaSiteName: this.vaSiteName,
            provider: this.provider,
            providerTypeName: this.providerTypeName,
            serialNumber: this.serialNumber,
            primaryNurse: this.primaryNurse,
            podName: this.podName,
            patientIdentifier: this.patientIdentifier,
            patientNickname: this.patientNickname
        }
      },
      setDynamicStyleClass() {
         let dynamicClass = ''
         switch (this.$vuetify.breakpoint.name) {
            case 'xs': dynamicClass = 'xs-screen'; break;
            case 'sm': dynamicClass = 'sm-screen'; break;
            case 'md': dynamicClass = 'md-screen'; break;
            case 'lg': dynamicClass = 'lg-screen'; break;
            case 'xl': dynamicClass = 'xl-screen'
         }
         return dynamicClass
      }
         
      },
      mounted() {
         this.loadData();
      },
      methods: {
         primaryCareNurseUserSaved(primaryNurseUser) {
            if (primaryNurseUser.userId == -1) {
               this.customer.patient.primaryNurseUser = null;
            } else {
               this.customer.patient.primaryNurseUser = primaryNurseUser;
            }
         },
         async loadData() {
            this.loading = true;
            let promises = [];
            
            await this.$apollo.queries.customer.refetch();
            
            promises.push(
                new ProviderService()
                    .getProviderByPatientId(this.customerId, this.patientId)
                    .then((value) => {
                       if (value) {
                          this.provider = value.name;
                          this.providerTypeName = value.providerTypeName;
                          this.prescribingProvider = value;
                       }
                    })
                    .catch((err) => {
                       console.log(err);
                    })
            );
            
            promises.push(
                new PatientService()
                    .getDeviceUsage(this.customerId, this.patientId)
                    .then((resp) => {
                       this.deviceUsage = resp;
                    })
                    .catch(err => {
                       console.log(err);
                    }));
            
            promises.push(
                new PatientService()
                    .getPatientDevice(this.customerId, this.patientId)
                    .then((value) => {
                       this.patientDevice = value;
                    })
                    .catch((err) => {
                       console.log(err);
                    }));
            
            if (this.customerId === CustomerEnum.VA) {
               promises.push(
                  new PatientPurchaseOrderService()
                     .get(this.customerId, this.patientId)
                     .then((resp) => {
                        this.patientPurchaseOrder = resp[0];
                     })
                     .catch((err) => {
                        if (!(err.response.status === 404)) {
                           console.log(err);
                        }
                     }));
            }

            if (this.worklistItemId === null) {
               promises.push(new PatientService()
                  .getPatientWorklistItems(this.customerId, this.patientId)
                  .then((resp) => {
                     this.worklistItems = resp;

                     let openWorklistItems = this.worklistItems.filter(x => x.closed == null);

                     if (this.worklistItemId == null && openWorklistItems.length > 0) {
                        this.openWorklistItemId = openWorklistItems[0].worklistItemId;
                     }
                  })
                  .catch((err) => {
                     console.log(err);
                  }));
            }
            
            Promise.allSettled(promises).then(() => {
               if (this.patientPurchaseOrder) {
                  this.isDLC = this.patientPurchaseOrder.isDLC;
               }
               
               this.serialNumber = (this.deviceUsage && this.deviceUsage.serial_Number) ? this.deviceUsage.serial_Number :
                                   (this.patientDevice ? this.patientDevice.serialNumber : "");
               
               this.padsOffline = !this.deviceUsage || !this.deviceUsage.serial_Number;
               this.detailsBannerKey++;
               this.renderDeviceUsage = true;
               
               this.loading = false;
            });
         },
         async close(value) {
            this.clearApolloCache();
            this.$emit("closed", value);
         },
         clearApolloCache() {
            let clients = Object.values(this.$apollo.provider.clients);
            clients.forEach(client => client.cache.reset());
         },
         copyToClipboard(value) {
            let self = this;
            this.$copyText(value).then(
                function (e) {
                   self.$root.$emit("updateClipboardText", value);
                   
                   self.$root.$emit("updateClipboardSnackbar", true);
                   console.log(e);
                }
            );
         },
         async demoChanged() {
            // await this.loadData();
            this.$emit("changed", this.customer.patient);
         },
         async interactionChanged() {
            // await this.loadData();
            this.$emit("changed", this.customer.patient);
            this.renderDemo = false;
            this.renderClinical = false;
            this.renderWorklist = false;
            this.$nextTick(() => {
               this.renderDemo = true;
               this.renderClinical = true;
               this.renderWorklist = true;
            });
         },
         setCrisisDialogVal(val) {
            return this.dialogCrisis = val;
         }
      }
   };
</script>

<style lang="scss">
.v-tabs {
   > .v-tabs-bar {
      position: sticky;
      z-index: 100;
      padding-top: 12px;
      box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
   }
   &.xs-screen > .v-tabs-bar {
      top: 315px;
   }
   &.sm-screen > .v-tabs-bar  {
      top: 260px;
   }
   &.md-screen > .v-tabs-bar  {
      top: 165px;
   }
   &.lg-screen > .v-tabs-bar  {
      top: 135px;
   }
   &.xl-screen > .v-tabs-bar  {
      top: 135px;
   }
}



</style>