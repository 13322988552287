<template>
   <v-card :loading="loading">
      <v-card-title>
         <div class="headline">Worklist Item Cadence</div>
      </v-card-title>
      <v-card-text>
         <v-container>
            <v-form ref="configForm">
               <div v-if="member !== null">
                  <v-row>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                 <v-checkbox v-model="member.hasRequestedDoNotCall" label="Has Requested Do Not Call"></v-checkbox>
                              </span>
                           </template>
                           <span>Will stop all Worklist Items for this Member. Also places a "DO NOT CALL" message on the contact card.</span>
                        </v-tooltip>
                     </v-col>
                     <v-col class="pt-0 pb-0" cols="12">
                        <v-menu
                            ref="snoozedTillMenu"
                            v-model="snoozedTillMenu"
                            :close-on-content-click="false"
                            :return-value.sync="memberSnoozedTillFormatted"
                            min-width="auto"
                            offset-y
                            transition="scale-transition">
                           <template v-slot:activator="{ on, attrs }">
                              <div class="d-flex">
                                 <v-tooltip bottom>
                                    <template #activator="{ on: tooltip }">
                                       <v-text-field
                                           v-model="memberSnoozedTillFormatted" v-bind="attrs"
                                           v-on="{ ...on, ...tooltip }"
                                           clearable
                                           label="Snoozed Till"
                                           prepend-icon="mdi-calendar"
                                           readonly
                                           @blur="memberSnoozedTill = parseDate(memberSnoozedTillFormatted)">
                                       </v-text-field>
                                    </template>
                                    <span>Will not create Worklist Items until Snoozed Till date Expires.</span>
                                 </v-tooltip>
                              </div>
                           
                           </template>
                           <v-date-picker
                               v-model="memberSnoozedTill"
                               :min="new Date().toISOString().substring(0, 10)"
                               no-title
                               scrollable>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text @click="snoozedTillMenu = false">
                                 Cancel
                              </v-btn>
                              <v-btn color="primary" text @click="$refs.snoozedTillMenu.save(memberSnoozedTillFormatted)">
                                 OK
                              </v-btn>
                           </v-date-picker>
                        </v-menu>
                     </v-col>
                  </v-row>
               </div>
            </v-form>
         </v-container>
      </v-card-text>
      <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn @click="onCancel" color="blue darken-1" text>Cancel</v-btn>
         <v-btn color="blue darken-1" text @click="onSave">Save</v-btn>
      </v-card-actions>
   </v-card>
</template>

<script>
   import {GetMemberConfiguration} from "./queries/member-demographics.graphql";
   import Utils from "../../mixins/utils";
   import {UpdateMember} from "./mutations/member-demographics.graphql";
   import {ComponentName} from "@/types/enum";
   
   export default {
      name: "MemberConfig",
      props: {
         customerId: Number,
         memberId: Number
      },
      mixins: [Utils],
      data: () => ({
         loading: false,
         member: {},
         defaultMember: {},
         snoozedTillMenu: false,
         memberSnoozedTill: null,
         memberSnoozedTillFormatted: null
      }),
      watch: {
         memberSnoozedTill() {
            this.memberSnoozedTillFormatted = new Intl.DateTimeFormat("en-US", {
               month: "2-digit",
               day: "2-digit",
               year: "numeric"
            }).format(new Date(this.memberSnoozedTill));
            this.member.snoozedTill = this.memberSnoozedTill;
         }
      },
      methods: {
         async onSave() {
            this.loading = true;
            await this.$apollo
                      .mutate(
                          {
                             mutation: UpdateMember,
                             variables: {
                                input: {
                                   memberId: this.memberId,
                                   customerId: this.customerId,
                                   snoozedTill: this.member.snoozedTill,
                                   hasRequestedDoNotCall: this.member.hasRequestedDoNotCall,
                                   component: ComponentName.MemberConfig
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.data.payload.errorCode);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
            
            this.$emit("saved");
         },
         onCancel() {
            this.resetState();
            this.$emit("cancelled");
         },
         resetState() {
            this.member = this.defaultMember;
         },
         async success() {
            
            this.loading = false;
            this.disabledAdd = false;
            
            let options = {
               text: "Member Config was saved successfully",
               title: "Saved",
               actions: ["Okay"]
            };
            await this.$dialog.info(options);
            
            this.$emit("saved");
         },
         async failure(message) {
            this.loading = false;
            let options = {
               text: message,
               title: "Unable to save",
               actions: ["Okay"]
            };
            await this.$dialog.error(options);
         }
      },
      apollo: {
         member: {
            query: GetMemberConfiguration,
            variables() {
               return {
                  customerId: this.customerId,
                  memberId: this.memberId
               };
            },
            update(data) {
               this.defaultMember = data.customer.member;
               this.memberSnoozedTill = data.customer.member.snoozedTill;
               
               return data.customer.member;
            }
         }
      }
   };
</script>

<style scoped>

</style>