<template>
   <v-container>
      <v-card>
         <v-row>
            <v-col md="12">
               <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                     <v-col class="md-6">
                        <v-textarea v-model="note" :rules="noteRules"
                                    auto-grow class="ma-4" label="Note here. Initials and timestamp will be auto added."
                                    prepend-icon="mdi-note" required scrollable></v-textarea>
                     </v-col>
                  </v-row>
                  <v-row>
                     <v-col class="md-6">
                        <v-btn class="ma-3" color="secondary" @click="cancel">Cancel</v-btn>
                        <v-btn class="ma-3" color="secondary" @click="save">Save</v-btn>
                     </v-col>
                  </v-row>
               </v-form>
            </v-col>
         </v-row>
      </v-card>
   </v-container>
</template>

<script>
   
   import AddPatientClinicalNote from "./mutations/add-notes.graphql";
   
   export default {
      name: "ClinicalNoteAdd",
      props: {
         patientId: Number,
         customerId: Number
      },
      data: () => ({
         note: "",
         valid: false,
         noteRules: [
            v => !!v || "Note is required"
         ]
      }),
      methods: {
         cancel() {
            this.note = "";
            this.$emit("canceled");
         },
         async save() {
            if (!this.$refs.form.validate()) {
               await this.$dialog.error(
                   {
                      text: "Please fix the highlighted issues before savings",
                      title: "Unable to save",
                      actions: ["Okay"]
                   });
               return;
            }
            await this.$apollo
                      .mutate(
                          {
                             mutation: AddPatientClinicalNote,
                             variables: {
                                input: {
                                   patientId: this.patientId,
                                   customerId: this.customerId,
                                   note: this.note,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.data.payload.errorCode);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
         async success() {
            
            let options = {
               text: "Note was saved successfully",
               title: "Saved",
               actions: ["Okay"]
            };
            await this.$dialog.info(options);
            
            this.$emit("saved");
         },
         async failure(message) {
            let options = {
               text: message,
               title: "Unable to save",
               actions: ["Okay"]
            };
            await this.$dialog.error(options);
         }
      }
   };
</script>

<style scoped>

</style>