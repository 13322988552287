<template>
   <div>
      <v-avatar v-if="userId" color="primary" size="48">
         <v-img :alt="alt" :src="src" height="48" lazy-src="../../../assets/account.png" width="48"/>
      </v-avatar>
      <v-avatar v-if="!userId" color="primary" size="48">
         <v-icon dark x-large>mdi-account</v-icon>
      </v-avatar>
   </div>
</template>

<script>
   export default {
      name: "TimelineAvatar",
      props: {
         userId: Number,
         alt: String,
         src: String
      }
   };
</script>

<style scoped>

</style>