<template>
   <div>
      <v-tooltip top>
         <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="info" @click="onClipboardClick" :loading="loadingClipboard"
               :disabled="loadingClipboard">
               <v-icon dark>mdi-clipboard-text</v-icon>
            </v-btn>
         </template>
         <span>Copy Patient Information to Clipboard</span>
      </v-tooltip>
   </div>
</template>

<script>
   //TODO: update logic for Member
   import ExportService from "@/services/PatientExportService";
   
   export default {
      name: "ClipboardButton",
      props: {
         customerId: Number,
         itemId: Number,
         firstName: String,
         lastName: String,
         isPatient: Boolean
      },
      data: () => ({
         loadingClipboard: false,
         clipboardText: ""
      }),
      methods: {
         async onClipboardClick() {
            this.loadingClipboard = true;
            
            if (this.isPatient) {
               await this.exportPatientData();
            } else {
               this.exportMember();
            }
            this.loadingClipboard = false;
            this.updateClipboardText();
            this.updateClipboardSnackbar();
         },
         async exportPatientData() {
            let text = await new ExportService().get(this.customerId, this.itemId,
                                                     new Date().getTimezoneOffset() / 60);
            this.copyFormatted(text);
         },
         exportMember() {
            console.log("get member data from graphGL")
         },
         updateClipboardSnackbar() {
            this.$root.$emit("updateClipboardSnackbar", true);
         },
         updateClipboardText() {
            this.$root.$emit("updateClipboardText", "Export");
         },
         copyFormatted(html) {
            let i;
            let container = document.createElement("div");
            container.innerHTML = html;
            container.style.position = "fixed";
            container.style.pointerEvents = "none";
            container.style.opacity = 0;
            
            let activeSheets = Array.prototype.slice.call(document.styleSheets)
                                    .filter(function (sheet) {
                                       return !sheet.disabled;
                                    });
            
            document.body.appendChild(container);
            window.getSelection().removeAllRanges();
            let range = document.createRange();
            range.selectNode(container);
            window.getSelection().addRange(range);
            document.execCommand("copy");
            for (i = 0; i < activeSheets.length; i++) {
               activeSheets[i].disabled = true;
            }
            document.execCommand("copy");
            for (i = 0; i < activeSheets.length; i++) {
               activeSheets[i].disabled = false;
            }
            document.body.removeChild(container);
         }
      }
   };
</script>

<style scoped>
.v-btn {
   height: 30px !important;
   min-width: 58px !important;
   padding: 0 12px !important;
}
</style>