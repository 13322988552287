<template>
  <v-dialog v-model="show" fullscreen v-if="show">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Escalation Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="show = false"> Close </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <worklist :pod-id="podId" :assignedToUserPodIds="assignedToUserPodIds" :user-id="userId" :viewEscalations="true" :type="type" :title="title" :pod-ids="podIds" class="mt-5"></worklist>
      </v-card-text>
      <v-card-actions v-if="false">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

import Worklist from "@/components/Worklist";

export default {
  name: "EscalationsTypeDialog",
  props: {
    podId: Number,
    assignedToUserPodIds: Array,
    userId: Number,
    type: String,
    title: String,
    visible: Boolean,
    podIds: Array
  },
  data: () => ({
  }),
  components: {
    Worklist
    //
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },
  methods: {
    onClick(e) {
      this.$emit("clicked", e);
    },
    closeDialog() {
      this.show = false;
    }
  },
  created () {
    //
  },
}
</script>

<style scoped>

</style>