export enum EntityTypeEnum {
    Patient = 1,
    Provider = 2,
    Caregiver = 3,
    PurchaseOrder = 4,
    Prescription = 5,
    Member = 6,
    Practice = 7,
    PrequalificationProvider = 8
}

export enum ReferenceDataEnum {
    BestDayToCall = "BestDayToCall",
    BestTimeToCall = "BestTimeToCall",
    CustomField = "CustomField",
    DeviceStatus = "DeviceStatus",
    EmploymentStatus = "EmploymentStatus",
    EntityType = "EntityType",
    FootCondition = "FootCondition",
    FootLocation = "FootLocation",
    FormType = "FormType",
    Gender = "Gender",
    IdentifierType = "IdentifierType",
    InteractionType = "InteractionType",
    LanguagePreference = "LanguagePreference",
    PatientScanStatusType = "PatientScanStatusType",
    PatientStatusType = "PatientStatusType",
    PreferredModeOfContact = "PreferredModeOfContact",
    ProviderType = "ProviderType",
    Relationship = "Relationship",
    State = "State",
    TimeZone = "TimeZone",
    WorklistItemStatusType = "WorklistItemStatusType",
    WorklistItemType = "WorklistItemType",
    WorklistType = "WorklistType"
}

export enum SystemRoleEnum {
    Administrator = 1,
    CareManager = 2,
    DeviceOperations = 3,
    ClinicalOperations = 4,
    VASales = 5,
    CommercialSales = 6,
    SofwareDeveloper = 7,
    CareManagerSuperviser = 8,
    VASalesSuperviser = 9,
    CommercialSalesSuperviser = 10,
    EscalationsWorklistUser = 11,
    CohortAdministrator = 12,
}

export enum PodRoleEnum {
    PodAdministrator = 1,
    PodTeamLeader = 2,
    PodSupervisor = 3,
    PodNurse = 4,
    PodRep = 5
}

export enum ProviderTypeEnum {
    Podiatrist = 1,
    PCP = 2,
    Endocrinologist = 3,
    Vascular = 4,
    Other = 5,
}

export enum CustomerEnum {
    "VA" = 1,
    "Aetna" = 2, 
    "Intermountain Healthcare" = 3,
    "Independence Blue Cross" = 4,
    "Demo" = 5,
    "KP Mid Atlantic" = 6,
    "Superior" = 7,
    "Fallon" = 8,
    "HSeval" = 9,
}

export enum IdentifierTypeEnum {
    MRN = 1,
    ICN = 2,
    EnterpriseProviderId = 3,
    DUZ = 4,
    EnterpriseId = 5,
    StationNumber = 6,
    DFN = 7,
}

export enum ComponentName {
    MemberConfig = "MemberConfig",
    MemberDemographics = "MemberDemographics"
}

export enum InteractionTypeEnum {
    InitialOutreach = 1,
    Training = 2,
    Onboarding = 3,
    Offloading = 4,
    Reengagement = 5,
    Resolution = 6,
    ClinicFeedback = 7,
    Inbound = 8,
    Wellness = 9,
    Assessment = 10,
    Welcome = 11,
    InflammationCheckin = 12,
    Other = 13,
    Replacement = 14,
    Renewal = 15,
    Prequalification = 16,
    InboundTraining = 17,
    ScanIssue = 18,
    CallBackFollowUp = 19,
    DeviceAssigned = 20,
    DeviceReplaced = 21,
    Escalation = 22,
    PatientWithdrew = 23,
    RenewalPreQualification = 24,
    CompleteOnboarding = 25,
    PostRenewal = 26,
    Withdrawal = 27,
    TechnicalHelp = 28,
    PatientUnWithdrew = 29,
    StructuredInteractions_InitialOutreach = 101,
    StructuredInteractions_Training = 102,
    StructuredInteractions_Offloading = 104,
    StructuredInteractions_Reengagement = 105,
    StructuredInteractions_Resolution = 106,
    StructuredInteractions_Wellness = 109,
    StructuredInteractions_InflammationCheckin = 112,
    StructuredInteractions_Renewal = 115,
    StructuredInteractions_CallBackFollowUp = 119,
    StructuredInteractions_CompleteOnboarding = 125,
    StructuredInteractions_PostRenewal = 126
}


export enum HistoryTabNames {
    ClinicalHistory = "CLINICAL_HISTORY",
    SubjectiveQuestions = "SUBJECTIVE_QUESTIONS",
    
    OtherQuestion = "OTHER_QUESTION",

    SincePreviousCall = "SINCE_PREVIOUS_CALL",
    MedicalHistory = "MEDICAL_HISTORY_QUESTIONS",
    SocialHistory = "SOCIAL_HISTORY",
    SurgicaHistory = "SURGICAL_HISTORY"
}

export enum WorklistItemTypeEnum {
    InitialOutreach = 1,
    Training = 2,
    Onboarding = 3,
    Offloading = 4,
    Reengagement = 5,
    Resolution = 6,
    ClinicFeedback = 7,
    Inbound = 8,
    Wellness = 9,
    DeviceAction = 10,
    PatientCallRequested = 11,
    Escalation = 12,
    Replacement = 13,
    Renewal = 14,
    Prequalification = 15,
    InflammationCheckin = 16,
    CompleteOnboarding = 17,
    PostRenewal = 18,
    Withdrawal = 19
}

export enum FormEnum {
    Legacy = 1,
    InitialOutreach = 2,
    Renewal = 3,
    Training = 4,
    Wellness = 5,
    ScanIssue = 6,
    Replacement = 7,
    Offloading = 8,
    InflammationCheckin = 9,
    Resolution = 10,
    Reengagement = 11,
    Default12,
    CompleteOnboarding = 13,
    PostRenewal = 14,
    Withdrawal = 15,
    TechnicalHelp = 16,
    MemberPrequalificationOutreach = 17,
    StructuredInteractions_CallBackFollowUp = 18,
    StructuredInteractions_CompleteOnboarding = 19,
    StructuredInteractions_InflammationCheckin = 20,
    StructuredInteractions_InitialOutreach = 21,
    StructuredInteractions_Offloading = 22,
    StructuredInteractions_PostRenewal = 23,
    StructuredInteractions_Reengagement = 24,
    StructuredInteractions_Renewal = 25,
    StructuredInteractions_Resolution = 26,
    StructuredInteractions_Training = 27,
    StructuredInteractions_Wellness = 28
}

export enum EscalationType{
    Clinical = 1,
    ClinicalAdvisory = 2,
    NonClinicalPatientUpdate = 3,
    NonClinicalDevice = 4,
    NonClinicalInternalRecords = 5,
    OnboardingUnfulfilled = 6,
    OnboardingUntrained = 7,
    Withdrawal = 8,
    InternalClinical = 9
}

export enum PodsSummaryTypeEnum
{
    SelectedPodAndPrimaryPatients  = 0,
    NoSelectedPodAndPrimaryPatients  = 1,
    SelectedPodAndNoPrimaryPatients  = 2,
    NoSelectedPodAndNoPrimaryPatients  = 3, 
    SelectedAllAssignedPods = 4
}