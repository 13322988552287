<template>
   <v-container fluid>
      
      <worklist-item-cadence v-if="configDialog"
                           :configDialog="configDialog"
                           :customerId="customerId"
                           :itemId="memberId"
                           :isPatient="false"
                           :saveAction="onMemberConfigSaved"
                           :cancelAction="() => configDialog = false"/>
      
      <v-row>
         <v-col md="7" sm="12" xs="12">
            <v-card :loading="isLoading" class="pa-5" elevation="2" outlined tile>
               <v-alert :type="alertRef.alertType" :value="alertRef.alert" dense transition="fade-transition">
                  {{alertRef.alertMessage}}
               </v-alert>
               <v-row>
                  <v-col md="5">
                     <v-card-title class="headline">Member
                        <v-icon v-if="isSnoozed" class="ma-1">mdi-sleep</v-icon>
                     </v-card-title>
                  </v-col>
                  <v-col md="2" offset-md="5">
                     <v-btn icon @click="edit" class="float-right">
                        <v-icon>mdi-pencil</v-icon>
                     </v-btn>
                     <v-btn class="float-right" v-if="hasAccess('EditPatientWorklistItemCadence')" icon @click="configDialog = true">
                        <v-icon>mdi-cog-outline</v-icon>
                     </v-btn>
                  </v-col>
               </v-row>
               
               <v-form ref="form" v-model="valid" lazy-validation :key="cancelKey" @submit.prevent="save">
                  <v-row v-if="member">
                     <v-col cols="12" md="6" sm="12">
                        <v-text-field
                           v-model="member.firstName"
                           :readonly="!isEditMode"
                           :rules="nameRules"
                           label="First Name"
                           prepend-icon="mdi-account"
                           required/>
                        <v-text-field
                           v-model="member.lastName"
                           :readonly="!isEditMode"
                           :rules="nameRules"
                           label="Last Name"
                           prepend-icon="mdi-account"
                           required/>
                        <v-select
                           v-model="member.timeZoneId"
                           :items="$store.state.timeZones"
                           :readonly="!isEditMode"
                           item-text="description"
                           item-value="timeZoneId"
                           label="Time Zone"
                           prepend-icon="mdi-clock"/>
                        <v-text-field
                           v-model="member.lastFourSSN"
                           v-mask="'####'"
                           :readonly="!isEditMode"
                           label="SSN Last Four"
                           prepend-icon="mdi-card-bulleted"/>
                        <v-select
                           v-model="member.genderId"
                           :items="$store.state.genders"
                           item-text="description"
                           item-value="genderId"
                           label="Gender"
                           :readonly="!isEditMode"
                           prepend-icon="mdi-human-non-binary"/>
                     </v-col>
                     <v-col cols="12" md="6" sm="12">
                        <v-text-field
                           v-model="member.middleName"
                           :readonly="!isEditMode"
                           label="Middle Name"
                           prepend-icon="mdi-account"
                           required/>
                        <v-text-field
                           v-model="member.nickName"
                           :readonly="!isEditMode"
                           label="Nick Name"
                           prepend-icon="mdi-account"
                           required/>
                        
                        <v-text-field
                           v-model="member.designation"
                           label="Member Designation"
                           :readonly="(!isClinicalOperationsUser && !isDeviceOperationsUser && validDesignation) || !isEditMode"
                           prepend-icon="mdi-card-account-details"/>
                        <v-textarea
                           v-model="member.note"
                           :readonly="!isEditMode"
                           auto-grow
                           cols="5"
                           label="Notes"
                           prepend-icon="mdi-note"/>
                     </v-col>
                     <v-col cols="12" class="text-right">
                        <v-btn :disabled="!isEditMode" class="mr-4" color="secondary" @click="cancel">
                           Cancel
                        </v-btn>
                        <v-btn :disabled="!isEditMode" type="submit" class="mr-4" color="secondary">
                           Save
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-form>
            </v-card>
         </v-col>
         <v-col cols="12" md="5" sm="12">
            <contact-details :customerId="customerId" 
                            :entityId="memberId" 
                            :entityTypeId="entityTypeId" 
                            :key="contactDetailKey"
                            :updated="onContactDetailUpdated">
            </contact-details>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   import _isEqual from "lodash/isEqual";
   import * as Shared from "../../shared";
   import WorklistItemCadence from "./../Shared/WorklistItemCadence";
   import Security from "../../mixins/security";
   import EntityLockService from "../../services/EntityLockService";
   import { GetMemberRowVersion, GetMemberDemographics } from "./queries/member-demographics.graphql";
   import { UpdateMember } from './mutations/member-demographics.graphql'
   import ContactDetails from "./ContactInformation/ContactDetails";
   import {ComponentName, EntityTypeEnum} from "@/types/enum";

   
   export default {
      name: "MemberDemographics",
      mixins: [Security],
      components: {
         WorklistItemCadence,
         ContactDetails
      },
      props: {
         customerId: Number,
         memberId: Number
      },
      data: () => ({
         key: 0,
         contactDetailKey: 0,
         initialMember: {},
         isEditMode: false,
         configDialog: false,
         isLoading: false,
         alertRef: {
            alert: false,
            alertType: "success",
            alertMessage: ""
         },
         nameRules: [
            v => !!v || "Name is required"
         ],
         valid: false,
         lastRowRowVersion: null
      }),
      watch: {
         member() {
            Object.assign(this.initialMember, this.member);
         }
      },
      computed: {
         cancelKey: {
            get() {
               return this.key;
            },
            set(newValue) {
               this.key = newValue;
            }
         },
         updatedConfigKey:{
            get() {
               return this.contactDetailKey;
            },
            set(val) {
               this.contactDetailKey = val;
            }
         },
         isClinicalOperationsUser() {
            let role = this.$store.state.achillesUser.userRoles.find(
                (r) => r.role.name === "ClinicalOperations"
            );
            return !!role;
         },
         isDeviceOperationsUser() {
            let role = this.$store.state.achillesUser.userRoles.find((r) =>
                                                                         r.role.name === "DeviceOperations"
            );
            return !!role;
         },
         validDesignation() {
            return !(this.initialMember.designation == null
                     || this.initialMember.designation == ""
                     || this.initialMember.designation.includes("XXXX")
                     || this.initialMember.designation.includes("VA "));
         },
         
         isSnoozed() {
            if (typeof this.member != "undefined") {
               let snoozedDate = new Date(this.member.snoozedTill);
               return (new Date()) < snoozedDate;
            }
            return false;
         },
         entityTypeId(){
            return EntityTypeEnum.Member;
         }
      },
      methods: {
         closeConfigDialog(val) {
            this.configDialog = val;
         },
         async save(submitEvent) {
            if (this.$refs.form.validate()) {
               try {
                  if (!_isEqual(this.member, this.initialMember)) {
                     this.loading = true;
                     this.member.updatedByUserId = this.$store.state.achillesUser.userId;
                     
                     let input = Object.assign(this.member, { 'customerId': this.customerId });

                     input.component = ComponentName.MemberDemographics;

                     delete input.snoozedTill;
                     delete input.lastRowRowVersion;
                     delete input["__typename"];

                     await this.$apollo.mutate(
                     {
                        mutation: UpdateMember,
                        variables: {
                           input: input
                        }
                     })      
                     .then(async (data) => {
                        this.clearApolloCache();
                        this.key++;
                        this.contactDetailKey++;
                        Object.assign(this.initialMember, this.member);
                        
                        this.$emit("changed");
                        Shared.showSaveSuccessAlert(this.alertRef);
                     });
                  }
               }
               catch (ex) {
                  Shared.showSaveFailedAlert(this.alertRef);
                  await this.cancel();
                  console.log(ex);
               }
               finally {
                  await new EntityLockService().remove(this.customerId, this.$store.state.achillesUser.userId,
                                                       this.member.memberId, 1, "MemberDemographics");
                  this.isEditMode = false;
                  this.loading = false;
               }
            }                 
         },
         async cancel() {
            this.$refs.form.resetValidation();
            Object.assign(this.member, this.initialMember);
            this.cancelKey++;
            await new EntityLockService().remove(this.customerId, this.$store.state.achillesUser.userId,
                                                 this.memberId, 1, "MemberDemographics");
            this.isEditMode = false;
         },
         async edit() {
            const res = await new EntityLockService().post(this.customerId, this.$store.state.achillesUser.userId,
                                                           this.memberId, 1, "MemberDemographics");

            if (res.lockedByAnotherUser) {
               await this.$dialog.info(
                   {
                      text: "This Member is being edited by another user. Please wait or contact " +
                            res.lockedByUserFullName + " to access the record.",
                      title: "Locked for edit by another user",
                      actions: ['Okay']
                   });
               
            } else {
               this.isEditMode = true;
            }
         },
         onMemberConfigSaved() {
            this.clearApolloCache();
            this.contactDetailKey++;
            this.configDialog = false;
         },
         onContactDetailUpdated(){
            this.clearApolloCache();
            this.contactDetailKey ++;
         },
         clearApolloCache() {
            let clients = Object.values(this.$apollo.provider.clients);
            clients.forEach(client => client.cache.reset());
         }
      },
      apollo: {
         lastRowRowVersion: {
            query: GetMemberRowVersion,
            variables() {
               return {
                  customerId: this.customerId,
                  memberId: this.memberId
               };
            },
            update: data => data.lastRowRowVersion
         },
         member: {
            query: GetMemberDemographics,
            variables() {
               this.isLoading = true;
               return {
                  customerId: this.customerId,
                  memberId: this.memberId
               };
            },
            update: data => data.customer.member,
            result(result) {
               this.member = result.data.customer.member;
               Object.assign(this.initialMember, this.member);
               this.isLoading = false;
            }
         }
      }
   };
</script>

<style scoped>

</style>