import * as searchResult from '../types/searchResult';
import * as baseService from './BaseService';
import * as customer from '../types/customer';
import * as master from '../types/master';

export default class GlobalSearchService extends baseService.BaseService {
   private url = '/api/GlobalSearch';
      
   constructor() {
     super();     
   } 

    async searchMembersAndPatients(pagedDataOptions: baseService.DataOptions): Promise<baseService.PagedData<searchResult.EntityResult>>{
      let urlSearch = this.url + '/SearchMembersAndPatients';          
      let response =  (await this.api.get(urlSearch,{params:{Page:pagedDataOptions.Page,ItemsPerPage:pagedDataOptions.ItemsPerPage,Search:pagedDataOptions.Search,SortBy:pagedDataOptions.SortBy,SortDesc:pagedDataOptions.SortDesc}})).data as baseService.PagedData<searchResult.EntityResult>;
      return response;
    }

    async searchProviders(customerId: number, pagedDataOptions: baseService.DataOptions): Promise<baseService.PagedData<customer.Provider>>{          
        let url = this.url + `/SearchProviders/${customerId}`;
        let response = (await this.api.get(url, {
            params:
                { Page: pagedDataOptions.Page, ItemsPerPage: pagedDataOptions.ItemsPerPage, Search: pagedDataOptions.Search, SortBy: pagedDataOptions.SortBy, SortDesc: pagedDataOptions.SortDesc }
        })).data as baseService.PagedData<customer.Provider>;   
      return response;
    } 

     async searchSites(customerId: number, pagedDataOptions: baseService.DataOptions): Promise<baseService.PagedData<master.CustomerSite>>{          
        let url = this.url + `/SearchSites/${customerId}`;
        let response = (await this.api.get(url, {
            params:
                { Page: pagedDataOptions.Page, ItemsPerPage: pagedDataOptions.ItemsPerPage, Search: pagedDataOptions.Search, SortBy: pagedDataOptions.SortBy, SortDesc: pagedDataOptions.SortDesc }
        })).data as baseService.PagedData<master.CustomerSite>;   
      return response;
    } 

    async searchProvidersByPatientSiteId(customerId: number, patientSiteId: number, pagedDataOptions: baseService.DataOptions): Promise<baseService.PagedData<customer.ProviderSiteSearch>>{
        let url = this.url + `/SearchProvidersByPatientSiteId/${customerId}/${patientSiteId}`;
        let response = (await this.api.get(url, {
            params:
                { Page: pagedDataOptions.Page, ItemsPerPage: pagedDataOptions.ItemsPerPage, Search: pagedDataOptions.Search, SortBy: pagedDataOptions.SortBy, SortDesc: pagedDataOptions.SortDesc }
            })).data as baseService.PagedData<customer.ProviderSiteSearch>;
        return response;
    }

    async SearchVAProvidersByPatientSiteId(customerId: number, patientSiteId: number, pagedDataOptions: baseService.DataOptions): Promise<baseService.PagedData<customer.ProviderSiteSearch>>{
      let url = this.url + `/SearchVAProvidersByPatientSiteId/${customerId}/${patientSiteId}`;
      let response = (await this.api.get(url, {
          params:
              { Page: pagedDataOptions.Page, ItemsPerPage: pagedDataOptions.ItemsPerPage, Search: pagedDataOptions.Search, SortBy: pagedDataOptions.SortBy, SortDesc: pagedDataOptions.SortDesc }
          })).data as baseService.PagedData<customer.ProviderSiteSearch>;
      return response;
  }

    async warmUpSearchCache(): Promise<string>{
        let urlSearch = this.url + '/WarmUpSearchCache';
        return (await this.api.get(urlSearch)).data as string;
    }
}
