var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-alert',{attrs:{"value":_vm.alertRef.alert,"type":_vm.alertRef.alertType,"dense":"","transition":"fade-transition"}},[_vm._v(" "+_vm._s(_vm.alertRef.alertMessage)+" ")]),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[(!_vm.title)?_c('span',[_vm._v(_vm._s(_vm.personalListName)+" Worklist")]):_vm._e(),(_vm.title)?_c('span',[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('v-icon',{on:{"click":_vm.getWorklistItems}},[_vm._v("mdi-refresh")]),(_vm.showFlex)?_c('v-switch',{attrs:{"dense":"","label":"View Flex Worklist"},on:{"change":_vm.getFlex},model:{value:(_vm.isViewFlex),callback:function ($$v) {_vm.isViewFlex=$$v},expression:"isViewFlex"}}):_vm._e()],1),(!_vm.isFiltered)?_c('v-col',{attrs:{"md":"3"}},[(_vm.showEscalations)?_c('v-switch',{staticClass:"mt-12",attrs:{"dense":"","label":"View Escalations Worklist"},on:{"change":_vm.getEscalation},model:{value:(_vm.isViewEscalations),callback:function ($$v) {_vm.isViewEscalations=$$v},expression:"isViewEscalations"}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"offset-md":!_vm.isFiltered ? 4 : 7,"md":"2"}},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},on:{"click:clear":_vm.searchCleared},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm._headers,"items":_vm.worklistItems,"server-items-length":_vm.totalWorklistItems,"options":_vm.options,"loading":_vm.loading,"search":_vm.search,"footer-props":{
                        showFirstLastPage: true,
                        'items-per-page-options': _vm.rowsPerPage}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.escalatedOn",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.escalatedOn ? new Date(item.escalatedOn + "Z").toLocaleString() : ""))])]}},{key:"top",fn:function(){return [(_vm.dialog)?_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeWorklistItemDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Edit Worklist Item ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.closeWorklistItemDialog}},[_vm._v(" Close")])],1)],1),_c('WorklistItemEdit',{key:_vm.componentKey,attrs:{"customerId":_vm.customerId,"worklistItemId":_vm.worklistItemId,"patientId":_vm.patientId}})],1):_vm._e()]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(_vm.isAuthorized(item))?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(item.assignedToUserId === null)?_c('v-btn',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.claimItem(item)}}},[_vm._v(" Claim ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"medium":""}},on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v(" Is Escalation: "+_vm._s(item.isEscalation ? "Yes" : "No")+" "),_c('br'),_vm._v(" Escalation Status: "+_vm._s(item.isEscalation ? item.escalationStatus : "NA")+" "),_c('br'),_vm._v(" Created by: "+_vm._s(item.createdByUserId == null ? "Achilles" : _vm.getUserNameForId(item.createdByUserId))),_c('br'),_vm._v(" Last Updated by: "+_vm._s(item.updatedByUserId == null ? "None" : _vm.getUserNameForId(item.updatedByUserId))+" ")])])]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" "),(item.isEscalation)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""}},[_vm._v("mdi-arrow-up-bold")]):_vm._e()]}},{key:"item.patientName",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.patientName)+" "),_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.viewPatient(item)}}},[_vm._v(" mdi-open-in-new ")])]}},{key:"item.escalationStatus",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.escalationStatus)+" "),(item.isEscalation)?_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.viewEscalationDialog(item)}}},[_vm._v(" mdi-open-in-new ")]):_vm._e()]}}],null,true)})],1),_c('span',{staticClass:"caption ma-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v("Open")],1),_c('span',{staticClass:"caption ma-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-up-bold")]),_vm._v("Escalated")],1),_c('span',{staticClass:"caption ma-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")]),_vm._v("Edit")],1),_c('span',{staticClass:"caption ma-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v("View")],1),(_vm.dialogPatient)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","persistent":"","retain-focus":false},model:{value:(_vm.dialogPatient),callback:function ($$v) {_vm.dialogPatient=$$v},expression:"dialogPatient"}},[_c('v-card',[(false)?_c('div',{staticStyle:{"background":"#123559"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialogPatient = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{staticClass:"float-right",attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialogPatient = false}}},[_vm._v(" Close")])],1):_vm._e(),(false)?_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closePatientDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-toolbar-title',_vm._g(_vm._b({staticClass:"pl-8",on:{"click":function($event){return _vm.copyToClipboard(_vm.patientName)}}},'v-toolbar-title',attrs,false),on),[_vm._v(" "+_vm._s(_vm.patientName)+" ")])]}}],null,false,17155206)},[_c('span',[_vm._v("Click to copy to clipboard")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-toolbar-title',_vm._g(_vm._b({staticClass:"pl-8 blue-grey--text text--lighten-4",on:{"click":function($event){return _vm.copyToClipboard(_vm.patientDesignation)}}},'v-toolbar-title',attrs,false),on),[_vm._v(" "+_vm._s(_vm.patientDesignation)+" ")])]}}],null,false,318676528)},[_c('span',[_vm._v("Click to copy to clipboard")])]),_c('v-toolbar-title',{staticClass:"pl-8 blue-grey--text text--lighten-4"},[_vm._v(" "+_vm._s(_vm.customerName)+" ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.closePatientDialog}},[_vm._v(" Close")])],1),_c('v-snackbar',{attrs:{"timeout":1100,"bottom":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.clipboardSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}],null,false,1250343584),model:{value:(_vm.clipboardSnackbar),callback:function ($$v) {_vm.clipboardSnackbar=$$v},expression:"clipboardSnackbar"}},[_vm._v(" Copied \""+_vm._s(_vm.clipboardText)+"\" to clipboard ")])],1):_vm._e(),_c('PatientDetails',{key:_vm.componentKey,attrs:{"patientId":_vm.patient.patientId,"customerId":_vm.customerId,"customer-name":_vm.customerName,"worklistItemId":_vm.worklistItemId},on:{"closed":_vm.closePatientDialog}})],1)],1):_vm._e(),(_vm.dialogEscalation)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","persistent":"","retain-focus":false},model:{value:(_vm.dialogEscalation),callback:function ($$v) {_vm.dialogEscalation=$$v},expression:"dialogEscalation"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeEscalationDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Escalation for "+_vm._s(_vm.patientName)+" - "),_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.customerName))])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.closeEscalationDialog}},[_vm._v(" Close")])],1)],1),_c('EscalationDetails',{key:_vm.componentKey,attrs:{"customerId":_vm.customerId,"escalationId":_vm.escalationId,"worklistItemId":_vm.worklistItemId},on:{"escalationUpdated":_vm.getWorklistItems}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }