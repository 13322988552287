import * as BaseService from './BaseService';

export default class ImageService extends BaseService.BaseService {
   private url = '/api/StorageImage';
      
   constructor() {
     super();     
   }
  
    async getImage(objectname: string): Promise<string>
    {
      let url = this.url + `/${objectname}`;
      let retURL;            
      await this.api.get(url,{responseType: 'blob'}).then((response)=>{
        retURL = window.URL.createObjectURL(new Blob([response.data]));
      });     
      return retURL;
    }
    
    async postImage(formFile: File): Promise<string>
    {  
      let formData = new FormData();
      formData.append('file', formFile);                 
      let config={headers: {'Content-Type': 'multipart/form-data'}};
      let response = await this.api.post(this.url,formData,config).catch(err => {
        throw err;          
      });                          
      return response.data;
    }
   
  }