<template>
   <v-container fluid>
     <v-alert :value="formAlertRef.alert" :type="formAlertRef.alertType" dense transition="fade-transition">{{formAlertRef.alertMessage}}</v-alert>
    <v-row>
      <v-col md="4">
        <v-card elevation="2" outlined tile class="pa-5" :loading="loading">
          <v-alert :value="alertRef.alert" :type="alertRef.alertType" dense transition="fade-transition">{{alertRef.alertMessage}}</v-alert>
          <v-dialog v-model="showVaEscalationDialog" v-if="showVaEscalationDialog" content-class="confirm-dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="closeShowEscalationDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                Preview VA Escalation Details
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="closeShowEscalationDialog">Close</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <va-escalation 
                @cancel="closeShowEscalationDialog" 
                @sent="showVaEscalationDialog = false" 
                :patient-id="this.worklistItem.patientId"
                :patient-site-id="this.purchaseOrder.siteId"
                :provider-site-id="this.purchaseOrder.providerSiteId"
                :provider-id-prop="this.providerId"
                :customer-id="this.customerId"
                :escalation-id="this.escalation.escalationId"
                :current-adherence="this.escalation.currentAdherence"
                :last-used="this.escalation.lastUsed"
                :inflammation-duration="this.escalation.inflammationDuration"
                :inflammation-location="this.escalation.inflammationLocation"
                :has-sent-to-clinic-on ="this.hasSentToClinicOn()"
            >
            </va-escalation>
          </v-dialog>
          <v-dialog v-model="showHoldDialog" v-if="showHoldDialog"
          persistent
          max-width="500"
          content-class="confirm-dialog">
            <v-card>
                <v-card-title class="headline primary">
                  Place on Fulfillment Hold?
                </v-card-title>
                <v-card-text>
                  {{this.isPurchaseOrder ? "Purchase Order" : "Prescription" + " will be put on Fulfillment Hold on Save."}}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
                  <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>

          
          <v-form ref="form" v-model="valid" lazy-validation :disabled="readonly">
            <v-row>
                <v-col class="md-6">

                    <div>
                        <v-autocomplete v-model="worklistItem.assignedToUserId"
                                        :items="achillesPodiUsers"
                                        dense
                                        filled
                                        label="Assigned to"
                                        item-text="email"
                                        item-value="userId">
                            <template v-slot:selection="data">
                                <v-chip v-bind="data.attrs"
                                        :input-value="data.selected">
                                    <v-avatar left>
                                        <v-img :src="data.item.avatarUrl" lazy-src="../assets/account.png"></v-img>
                                    </v-avatar>
                                    {{ data.item.email }}
                                </v-chip>
                            </template>
                            <template v-slot:item="data">
                                <v-list-item-avatar v-if="data.item.avatarUrl != null">
                                    <v-img :src="data.item.avatarUrl" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.email"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.lastName +' '+ data.item.firstName"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </div>
                    <v-select v-model="escalation.escalationTypeId"
                              :items="escalationTypes"
                              label="Escalation Type"
                              prepend-icon="mdi-folder-multiple"
                              item-text="description"
                              item-value="escalationTypeId"
                              :readonly="!isClinicalOperationsUser && !isSalesUser"
                              @change="holdRequired"></v-select>
                    <v-select v-model="escalation.escalationStatusTypeId"
                              :items="escalationStatusTypes"
                              label="Escalation Status"
                              prepend-icon="mdi-state-machine"
                              item-text="description"
                              item-value="escalationStatusTypeId"></v-select>
                    <v-textarea v-model="escalation.escalationContext"
                                label="Escalation Context"
                                readonly
                                prepend-icon="mdi-note"
                                rows="1"
                                auto-grow></v-textarea>
                    <v-text-field v-model="this.priorClinicalEscalationsTotal"
                                  v-if="escalation.escalationTypeId === clinicalEscalationId"
                                  label="Total Number of Prior Clinical Escalations"
                                  prepend-icon="mdi-note"
                                  readonly 
                                  class="ml-1"></v-text-field>
                   <v-text-field v-model="this.escalation.currentAdherence"
                                 v-if="displayAdherence"
                                 label="Adherence"
                                 prepend-icon="mdi-note"
                                 readonly
                                 class="ml-1"></v-text-field>
                   <v-text-field v-model="lastUsed"
                                 v-if="displayAdherence"
                                 label="Last Time Used"
                                 readonly
                                 prepend-icon="mdi-calendar-range"></v-text-field>
                   <v-text-field v-model="this.escalation.inflammationDuration"
                                 v-if="displayAdherence"
                                 label="Inflammation Duration"
                                 prepend-icon="mdi-note"
                                 readonly
                                 class="ml-1"></v-text-field>
                   <v-text-field v-model="this.escalation.inflammationLocation"
                                 v-if="displayAdherence"
                                 label="Location"
                                 readonly
                                 prepend-icon="mdi-note"></v-text-field>            
                    <v-text-field v-model="escalatedOn"
                                  label="Escalated On"
                                  readonly
                                  prepend-icon="mdi-calendar-range"></v-text-field>
                    <v-textarea v-model="escalation.operationsNotes"
                                label="Operations Notes"
                                prepend-icon="mdi-note"
                                rows="1"
                                auto-grow
                                :readonly="!isClinicalOperationsUser"></v-textarea>
                    <v-textarea v-model="escalation.actionNotes"
                                label="Action Notes"
                                prepend-icon="mdi-note"
                                rows="1"
                                auto-grow></v-textarea>
                    <div>
                        <v-menu ref="datemenu"
                                v-model="datemenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="sentToClinicOn" label="Sent To Clinic On" persistent-hint readonly prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="escalation.sentToClinicOn"
                                           no-title
                                           @input="datemenu = false"></v-date-picker>
                        </v-menu>
                    </div>
                    <v-textarea v-model="escalation.clinicResponse"
                                label="Clinic Response"
                                prepend-icon="mdi-note"
                                rows="1"
                                auto-grow></v-textarea>
                    <v-textarea v-model="escalation.closingNote"
                                label="Closing Notes"
                                prepend-icon="mdi-note"
                                rows="1"
                                auto-grow
                                :readonly="!isClinicalOperationsUser"></v-textarea>
                    <v-row v-if="!readonly">
                        <v-col>
                            <v-btn color="secondary"
                                   class="mr-4"
                                   @click="cancel">
                                Cancel
                            </v-btn>
                            <v-btn :disabled="!saveEnabled"
                                   color="secondary"
                                   class="mr-4"
                                   @click="save">
                                Save
                            </v-btn>
                          
                            <v-btn v-if="showVaEscalationButton && this.patient.patientId" @click="vaEscalationButtonOnClick" color="secondary" class="mr-4">
                              Provider Preview 
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
       <v-col md="4">
        <v-card elevation="2" outlined tile :loading="loading">        
          <v-row>
            <v-col md="6">
              <v-card-title class="headline">Patient            
               </v-card-title> 
            </v-col>            
          </v-row>
           <v-row>
            <v-col md="3">              
              <v-text-field v-model="patient.designation" label="Designation" readonly class="ml-3"></v-text-field> 
              <!-- <v-text-field :value="formatDate(patient.dateOfBirth)" label="DOB" readonly class="ml-3"></v-text-field>  -->
            </v-col>   
            <v-col md="3">                            
              <v-text-field v-model="patient.firstName" label="First Name" readonly class="ml-1"></v-text-field>             
            </v-col>
            <v-col md="3">                            
                <v-text-field v-model="patient.lastName" label="Last Name" readonly class="ml-1"></v-text-field>
            </v-col>              
            <v-col md="3">
                 <v-text-field v-model="patient.lastFourSsn" label="Last Four SSN" readonly class="ml-1"></v-text-field>
            </v-col>
           </v-row>       
         </v-card>
          <v-card elevation="2" outlined tile :loading="loading" class="mt-4" v-if="customer.customerTypeName == 'VA'">        
          <v-row>
            <v-col md="6">
              <v-card-title class="headline">Purchase Order            
               </v-card-title> 
            </v-col>            
          </v-row>
           <v-row>
            <v-col md="4">              
              <v-text-field v-model="purchaseOrder.legacyId" label="ID" readonly class="ml-3"></v-text-field>                            
              <v-text-field :value="formatDate(purchaseOrder.received)" label="Received" readonly class="ml-2"></v-text-field>
               <v-text-field :value="formatBool(purchaseOrder.isShipmentRequested)" label="Shipment Requested" readonly class="ml-4"></v-text-field>
              <v-text-field v-model="delivered" label="Delivered" readonly class="ml-4"></v-text-field>
            </v-col>   
            <v-col md="4">                            
              <v-text-field v-model="purchaseOrder.legacyNumber" label="Number" readonly class="ml-3"></v-text-field>   
              <v-text-field :value="formatDate(purchaseOrder.processed)" label="Processed" readonly class="ml-2"></v-text-field>  
               <v-text-field :value="formatBool(purchaseOrder.onFulfillmentHold)" label="Fulfillment Hold" readonly class="ml-4"></v-text-field>
              <v-text-field v-model="trained" label="Trained" readonly class="ml-4"></v-text-field>                     
            </v-col>
            <v-col md="3">                            
                <v-text-field :value="formatDate(purchaseOrder.poDate)" label="Date" readonly class="ml-3"></v-text-field>
                <v-text-field :value="formatBool(purchaseOrder.isRenewalPatient)"  label="Renewal" readonly class="ml-2"></v-text-field> 
                <v-text-field v-model="assigned" label="Assigned" readonly class="ml-4"></v-text-field>
            </v-col>              
           </v-row>       
         </v-card>
          <v-card elevation="2" outlined tile :loading="loading" class="mt-4" v-if="customer.customerTypeName !== 'VA'">        
          <v-row>
            <v-col md="6">
              <v-card-title class="headline">Prescription           
               </v-card-title> 
            </v-col>            
          </v-row>
           <v-row>
            <v-col md="4">              
              <v-text-field v-model="prescription.legacyId" label="ID" readonly class="ml-4"></v-text-field>                            
              <v-text-field :value="formatDate(prescription.received)" label="Received" readonly class="ml-4"></v-text-field>
              <v-text-field :value="formatBool(prescription.isShipmentRequested)" label="Shipment Requested" readonly class="ml-4"></v-text-field>
              <v-text-field v-model="delivered" label="Delivered" readonly class="ml-4"></v-text-field>
            </v-col>   
            <v-col md="4">                            
              <v-text-field v-model="prescription.legacyNumber" label="Number" readonly class="ml-3"></v-text-field>
              <v-text-field :value="formatDate(prescription.processed)" label="Processed" readonly class="ml-3"></v-text-field>             
              <v-text-field :value="formatBool(prescription.onFulfillmentHold)" label="Fulfillment Hold" readonly class="ml-4"></v-text-field>
              <v-text-field v-model="trained" label="Trained" readonly class="ml-4"></v-text-field>
            </v-col>
            <v-col md="3">                            
                <v-text-field :value="formatDate(prescription.prescriptionDate)" label="Date" readonly class="ml-3"></v-text-field>
                <v-text-field :value="formatBool(prescription.isRenewalPatient)" label="Renewal" readonly class="ml-3"></v-text-field>   
                 <v-text-field v-model="assigned" label="Assigned" readonly class="ml-4"></v-text-field>          
            </v-col>              
           </v-row>       
         </v-card> 
         <v-card elevation="2" outlined tile :loading="loading" class="mt-4">        
          <v-row>
            <v-col md="6">
              <v-card-title class="headline">Provider           
               </v-card-title> 
            </v-col>            
          </v-row>
           <v-row>
            <v-col md="3">              
              <v-text-field v-model="provider.npi" label="NPI" readonly class="ml-4"></v-text-field>                                          
            </v-col>   
            <v-col md="3">                            
              <v-text-field v-model="provider.name" label="Name" readonly class="ml-3"></v-text-field>              
            </v-col>
            <v-col md="3">                            
                <v-text-field v-model="provider.providerTypeName" label="Provider Type" readonly class="ml-3"></v-text-field>                
            </v-col>              
           </v-row>       
         </v-card>   
       </v-col>             
    </v-row>
  </v-container>
</template>
<script>
import EscalationService from "../services/EscalationService";
import * as Shared from '../shared'
import PatientService from '../services/PatientService';
import CustomerMasterService from '../services/CustomerMasterService';
import ReferenceDataService from "../services/ReferenceDataService";
import UserService from "../services/UserService";
import WorklistItemService from "../services/WorklistItemService";
import PatientPurchaseOrderService from '../services/PatientPurchaseOrderService';
import PatientPrescriptionService from '../services/PatientPrescriptionService';
import ProviderService from '../services/ProviderService';
import PurchaseOrderService from '../services/PurchaseOrderService';
import PrescriptionService from '../services/PrescriptionService';
import VaEscalation from "@/components/Escalation/VaEscalation.vue";
import { EscalationType, SystemRoleEnum } from "@/types/enum";

const CLINICAL_ESCALATION_TYPE_ID = 1;
const VA_CUSTOMER_TYPE_NAME = "VA";
export default {
  components: {VaEscalation},
props: { escalationId: Number,customerId: Number,worklistItemId: Number, readonly: Boolean},
name: "EscalationDetails",
data: () => ({
    datemenu: false,
    showHoldDialog: false,
    achillesUsers: [], 
    initialEscalation:{},
    initialWorklistItem:{},
    priorClinicalEscalationsTotal: '',
    loading: false,
    escalation:{},
    worklistItem:{},
    escalationTypes:[],
    escalationStatusTypes:[],
    valid: false,
    patient:{},
    purchaseOrder:{},
    prescription:{},
    customer:{}, 
    provider:{},       
    alertRef:{
        alert:false,
        alertType:"success",
        alertMessage:'',
    },
    formAlertRef:{
      alert: false,
      alertType: "info",
      alertMessage: '',
    },
    escalationStatusType:{"Open":1,"InProgress":2,"Resolved":3,"Closed":4},
    fulfillmentHoldRequested:false,
    saveEnabled:false,
    showVaEscalationDialog: false,
    clinicalEscalationId: 1,
    providerId: null
}),
computed:{   
   displayAdherence(){
    return (this.escalation.escalationTypeId === EscalationType.Clinical
             || this.escalation.escalationTypeId === EscalationType.ClinicalAdvisory)
             && this.escalation.currentAdherence !== null && this.escalation.currentAdherence !== "";
   },
   isClinicalOperationsUser()
    {
      let role = this.$store.state.achillesUser.userRoles.find((r) =>
        r.role.name == "ClinicalOperations"
      );
      if (role) return true;
      else return false;
    },
    isSalesUser() {
       let role = this.$store.state.achillesUser.userRoles.find((r) =>
            r.role.name.includes("Sales")
      );
      if (role) return true;
      else return false;            
    }, 
    escalatedOn()
    {
       return this.formatDate(this.escalation.escalatedOn);
    },
   lastUsed(){
      return this.formatDate(this.escalation.lastUsed);
   },
    sentToClinicOn()
    {
       return this.formatDate(this.escalation.sentToClinicOn);
    },
    tomorrow(){      
      let date = new Date();
      // Add a day
      date.setDate(date.getDate());
      return date.toISOString().substr(0, 10);
    },
     achillesPodiUsers(){
      return this.achillesUsers.filter(u=> u.email.includes('@podimetrics.com') && u.isServiceAccount==false && u.isActive===true);
    },
    assigned(){
        if(this.isPurchaseOrder)        
            return this.purchaseOrder.assigned != null?this.formatDate(this.purchaseOrder.assigned):this.purchaseOrder.assignedNote;        
        else
            return this.prescription.assigned != null?this.formatDate(this.prescription.assigned):this.prescription.assignedNote;
    },
    delivered(){
        if(this.isPurchaseOrder)        
            return this.purchaseOrder.delivered != null?this.formatDate(this.purchaseOrder.delivered):this.purchaseOrder.deliveredNote;        
        else
            return this.prescription.delivered != null?this.formatDate(this.prescription.delivered):this.prescription.deliveredNote;
    },
    trained(){
        if(this.isPurchaseOrder)        
            return this.purchaseOrder.trained != null ?this.formatDate(this.purchaseOrder.trained):this.purchaseOrder.trainedNote;        
        else
            return this.prescription.trained != null ?this.formatDate(this.prescription.trained):this.prescription.trainedNote;
    },
    isPurchaseOrder(){
      return this.customer.customerTypeName=="VA";
    },
    showVaEscalationButton() {
     const userRoles = this.$store.state.achillesUser.userRoles;
     return process.env.VUE_APP_EXTERNAL_VA_ESCALATION === 'enabled' && this.customer
         && this.customer.customerTypeName === VA_CUSTOMER_TYPE_NAME 
         && this.escalation 
         && this.escalation.escalationTypeId === EscalationType.Clinical
         && (userRoles.some(userRole => userRole.roleId === SystemRoleEnum.VASales 
             || userRole.roleId === SystemRoleEnum.VASalesSuperviser));
    }
},
watch:{    
    worklistItemId()
    {        
        this.saveEnabled = false;
        this.patient = {};
        this.escalation = {}; 
        this.worklistItem = {};
        this.purchaseOrder = {};
        this.prescription = {};
        this.provider = {};                 
        this.getEscalation();        
        this.getWorklistItem();
    }
},
mounted()
 {
  this.getAll();
  this.getOnce();                    
 },
methods:{
    hasSentToClinicOn: function()
    {
      return !!this.escalation && !!this.escalation.sentToClinicOn;
    },
    getAll: async function () {
        this.patient = {};
        this.escalation = {};
        this.worklistItem = {};
        this.customer = {};
        this.purchaseOrder = {};
        this.prescription = {};
        this.provider = {};
        this.getCustomer();
        this.getEscalation();
        this.getWorklistItem(); 
    },
    getOnce: async function () {
        this.getEscalationTypes();
        this.getEscalationStatusTypes();
        this.getUsers();
    },
     async getWorklistItem(){
      this.loading = true;      
      this.getCustomer();
      this.fulfillmentHoldRequested = false;  
      await new WorklistItemService().getWorklistItem(this.customerId,this.worklistItemId).then((resp)=> {
        this.worklistItem = resp;
        Object.assign(this.initialWorklistItem,this.worklistItem);
        this.getPatient();
        if(this.isPurchaseOrder)
            this.getPatientPurchaseOrder();        
        else
            this.getPatientPrescription();    
        this.loading = false;
        this.saveEnabled = true;
      });      
    },
    getUsers: async function () {
      if (this.$store.state.users.length == 0) {
        this.loading = true;
        await new UserService()
          .getUsers()
          .then((resp) => {
            this.achillesUsers = resp;
            this.$store.commit("setUsers", this.achillesUsers);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => (this.loading = false));
      } else {
        this.achillesUsers = this.$store.state.users;
      }
    },
    holdRequired(sel)
    {
      this.fulfillmentHoldRequested = false;

      if(sel == 6 || sel == 7)
      {
        if(this.isPurchaseOrder && !this.purchaseOrder.onFulfillmentHold)
          this.showHoldDialog = true;

        if(!this.isPurchaseOrder && !this.prescription.onFulfillmentHold)
          this.showHoldDialog = true;
      }
    }, 
    confirmDialogCallback(agreed)
    {
      if(agreed) {
        this.fulfillmentHoldRequested = true;
      }

      this.showHoldDialog = false;
    }, 
    formatDate(val)
    {
      if(val)
      {
        let date =  new Date(val).toISOString().substr(0, 10);
        let year = date.substr(0, 4);
        let month = date.substr(5, 2);
        let day = date.substr(8, 2);
        return month + '/' + day + '/' + year;
      }
      else
       return null;
    },
    formatBool(val)
    {
        return val == true?'Yes':'No';
    },
    getPatient: async function () {     
      this.isLoading = true;
      await new PatientService()
        .get(this.customerId, this.worklistItem.patientId)
        .then((resp) => {          
          this.patient = resp;          
        })
        .catch((err) => {          
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getPatientPurchaseOrder: async function(){
         this.isLoading = true;
      await new PatientPurchaseOrderService()
        .get(this.customerId, this.worklistItem.patientId)
        .then((resp) => {          
          this.purchaseOrder = resp[0];
          if(this.purchaseOrder.providerId != null)
            this.providerId = this.purchaseOrder.providerId;
            this.getProvider(this.purchaseOrder.providerId);                      
        })
        .catch((err) => {          
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    putPurchaseOrder: async function(){
        this.isLoading = true;
      await new PurchaseOrderService()
        .put(this.customerId, this.purchaseOrder)
        .then(() => {          
            this.getPatientPurchaseOrder();
        })        
        .catch((err) => {          
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    putPrescription: async function(){
      this.isLoading = true;
      await new PrescriptionService()
        .put(this.customerId, this.prescription)      
        .then(() => {          
            this.getPatientPrescription();
        })        
        .catch((err) => {          
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getPatientPrescription: async function(){
         this.isLoading = true;
      await new PatientPrescriptionService()
        .get(this.customerId, this.worklistItem.patientId)
        .then((resp) => {
          this.prescription = resp[0];
          if(this.prescription.providerId != null)
          this.getProvider(this.prescription.providerId);                      
        })
        .catch((err) => {          
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getEscalation: async function () {     
      this.isLoading = true;
      await new EscalationService()
        .getEscalation(this.customerId, this.escalationId)
        .then((resp) => {
          this.escalation = resp;
          this.priorClinicalEscalationsTotal = Math.max(0, (this.escalation.numberOfClinicalEscalations -1)).toString();
          if (resp.escalationsCount>1) {
            this.formAlertRef.alertMessage = `Found ${resp.escalationsCount} escalation(s) for this patient!`;
            this.formAlertRef.alert = true;
            this.formAlertRef.alertType = "info";
          } else {
            this.formAlertRef.alert = false;
          }

          Object.assign(this.initialEscalation,this.escalation);
        })
        .catch((err) => {          
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getCustomer: async function () {     
      this.isLoading = true;    
      await new CustomerMasterService()
        .get(this.customerId)
        .then((resp) => {          
          this.customer = resp;                   
        })
        .catch((err) => {          
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
       });      
    },
    getProvider: async function (providerId) {     
      this.isLoading = true;
      await new ProviderService()
        .get(this.customerId,providerId)
        .then((resp) => {          
          this.provider = resp;          
        })
        .catch((err) => {          
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getEscalationTypes: async function(){
        await new ReferenceDataService()
        .getEscalationTypes()
        .then((resp) => {      
          this.escalationTypes = resp;           
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getEscalationStatusTypes: async function(){
        await new ReferenceDataService()
        .getEscalationStatusTypes()
        .then((resp) => {      
          this.escalationStatusTypes = resp;           
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async postPatientInteractionForm(form){
        this.isLoading = true;           
        try
        {
            await new PatientService().postPatientInteractionForm(this.customerId,form);                                 
        }
        catch(ex)
        {   
            console.log(ex);
        }
        finally
        {
            this.isLoading = false;
        }
     },
     constructEscalationInteraction()
     {
        console.log(this.formatDate(this.escalation.sentToClinicOn));
        let noteVal = `<b>Escalation</b>: ${this.formatDate(this.escalation.escalatedOn)} <br>
        ${this.escalation.escalationContext} <br>
        <b>Sent to Clinic On</b>:${this.escalation.sentToClinicOn != null?this.formatDate(this.escalation.sentToClinicOn):'NA'} <br>
        <b>Closing Note</b>: ${this.escalation.closingNote != null ? this.escalation.closingNote : ''} `;
        
        let formData = {Fields:[{Name:'note',Value:noteVal}]};
        let patientInteractionForm={};
        let formDataAsString = JSON.stringify(formData);
        let patientInteraction = {};
        patientInteraction.patientId = this.patient.patientId;
        patientInteraction.interactionTypeId = 22; //escalation
        patientInteraction.interactionDateTime = new Date().toISOString();
        patientInteraction.worklistItemId = this.worklistItem.worklistItemId;
        patientInteraction.updatedByUserId = this.$store.state.achillesUser.userId;
        patientInteractionForm.formData = formDataAsString;
        patientInteractionForm.formId = 1; //Default until templates is developed
        patientInteractionForm.patientInteraction = patientInteraction;
        return patientInteractionForm;
     },
     
     async save() {
      if(this.$refs.form.validate())
      {        
        try
        {
          this.loading = true;
          if(JSON.stringify(this.escalation) != JSON.stringify(this.initialEscalation))
          {
            //Auto move to inprogress if activity detected
            if((this.escalation.actionNotes != null && this.initialEscalation.actionNotes == null) 
            || (this.escalation.sentToClinicOn != null && this.initialEscalation.sentToClinicOn == null))
            {
                if(this.escalation.escalationStatusTypeId == this.escalationStatusType.Open)
                  this.escalation.escalationStatusTypeId = this.escalationStatusType.InProgress;
            }             
            
            this.escalation.updatedByUserId=this.$store.state.achillesUser.userId;
            this.escalation.lastUpdated= new Date().toISOString();
            await new EscalationService().putEscalation(this.customerId,this.escalation.escalationId,this.escalation);
             
            if(this.escalation.escalationStatusTypeId == this.escalationStatusType.Closed) // Only escalations that have a status of closed should create an Escalation Interaction.  
            {
              this.postPatientInteractionForm(this.constructEscalationInteraction());    
            }                      
          }
          if(JSON.stringify(this.worklistItem) != JSON.stringify(this.initialWorklistItem))
          {
            this.worklistItem.updatedByUserId=this.$store.state.achillesUser.userId;
            this.worklistItem.lastUpdated= new Date().toISOString();
            await new WorklistItemService().putWorklistItem(this.customerId,this.worklistItem.worklistItemId,this.worklistItem);            
          }
          //Put Purchase Order on fulfillment hold if requested
          if(this.isPurchaseOrder && !this.purchaseOrder.OnFulfillmentHold && this.fulfillmentHoldRequested)
          {
              this.purchaseOrder.onFulfillmentHold = true;
              this.putPurchaseOrder();
          }
          //Put Prescription on fulfillment hold if requested
          if(!this.isPurchaseOrder && !this.prescription.OnFulfillmentHold && this.fulfillmentHoldRequested)
          {
              this.prescription.onFulfillmentHold = true; 
              this.putPrescription();
          }

          Shared.showSaveSuccessAlert(this.alertRef);       
        }
        catch(ex)
        {
          Shared.showSaveFailedAlert(this.alertRef);
          this.cancel();
          console.log(ex);
        }
        finally{     
          this.getAll();
          this.loading = false;
          this.$emit('escalationUpdated')          
        }
      }
    },
    cancel(){
       this.$refs.form.resetValidation();
       Object.assign(this.escalation, this.initialEscalation) 
       Object.assign(this.worklistItem, this.initialWorklistItem) 
    },
    vaEscalationButtonOnClick() {
      this.showVaEscalationDialog = true;
    },
  closeShowEscalationDialog() {
    this.showVaEscalationDialog = false;
  },
}    
};
</script>

<style scoped>
  .confirm-dialog .v-card__title {
    color: #FFFFFF;
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
  }

  .confirm-dialog .v-card__text {
    margin-top: 26px;
    font-size: 1rem !important;
  }
</style>