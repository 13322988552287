import * as BaseService from './BaseService';
import * as customer from '../types/customer';
import gql from 'graphql-tag';

export default class MemberProviderService extends BaseService.BaseService {
    private apolloConn;
       
    constructor(apollo) {
      super();
      this.apolloConn = apollo;
    }

    getMemberProvider(customerId: number, memberId: number){
        const q = gql`query {
            customer(customerId: ${customerId}){
                name
                member(memberId: ${memberId}){
                    memberProviders(input: { take:100, skip:0 }){
                        data{
                            memberProviderId,
                            prequalificationProviderId
                            memberId
                            memberProviderType {
                              name
                            }
                            providerEffectiveDate
                            providerExpirationDate
                            isDeleted
                            isActive,
                            isPrescribing
                            created
                            prequalificationProvider{
                                prequalificationProviderId
                                name
                                npi
                                specialityCode
                                providerTypeId
                                providerType{
                                    name
                                }
                                addresses(input: {take: 1, skip: 0}) {
                                    data {
                                        addressLine1
                                        addressLine2
                                        city
                                        stateId
                                        zipCode
                                    }
                                },
                                phones(input: {take: 1, skip: 0}) {
                                    data {
                                        phoneNumber
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }`;

        return this.apolloConn.query({ query: q});
    }

    parseMemberProviders(memProvidersData): Array<customer.MemberProvider>{
        const resp = memProvidersData.map(d => {
            let holdVal: customer.MemberProvider = {
                memberProviderId: d.memberProviderId,
                prequalificationProviderId: d.prequalificationProviderId,
                memberId: d.memberId,
                isPrescribing: d.isPrescribing,
                memberProviderType: d.memberProviderType.name,
                providerEffectiveDate: d.providerEffectiveDate,
                providerExpirationDate: d.providerExpirationDate,
                isDeleted: d.isDeleted,
                isActive: d.isActive,
                provider: d.prequalificationProvider,
                created: d.created
            };

            return holdVal;
        })

        return resp;
    }
}
