export interface QueryInput {
    skip: number;
    take: number;
}

export class Input implements QueryInput {
    skip: number;
    take: number;

    constructor() {
        this.skip = 0;
        this.take = 100;
    }

    set Skip(skip: number) {
        if (skip > 0) {
            this.skip = skip;
        }
    }

    set Take(take: number) {
        if (take > 0 && take <= 100) {
            this.take = take;
        }
    }
}
