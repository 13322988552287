import * as BaseService from './BaseService';
import * as customer from '../types/customer';
import * as master from '../types/master';

export default class PrescriptionService extends BaseService.BaseService {
   private url = '/api/Prescription';
      
   constructor() {
     super();     
   }

    async getAllPrescriptions(pagedDataOptions: BaseService.DataOptions): Promise<BaseService.PagedData<master.CustomerPrescription>>{          
        let response = (await this.api.get(this.url, {
            params:
                { Page: pagedDataOptions.Page, ItemsPerPage: pagedDataOptions.ItemsPerPage, Search: pagedDataOptions.Search, SortBy: pagedDataOptions.SortBy, SortDesc: pagedDataOptions.SortDesc }
        })).data as BaseService.PagedData<master.CustomerPrescription>;   
      return response;
    } 
  
    async get(customerId: number, prescriptionId: number): Promise<customer.Prescription>{
      let url = this.url + `/${customerId}/${prescriptionId}`;            
      let response = (await this.api.get(url)).data as customer.Prescription;          
      return response;
    }

    async put(customerId: number,prescription: customer.Prescription)
    {
      let url = this.url + `/${customerId}/${prescription.prescriptionId}`;            
      let response = await this.api.put(url,prescription).catch(err => {
        throw err;          
      });                
      return response;
    }

  }