import * as BaseService from './BaseService';
import * as customer from '../types/customer';

export default class PatientProviderService extends BaseService.BaseService {
    private url = '/api/PatientProvider';
       
    constructor() {
      super();     
    }
   
     async get(customerId: number, patientId: number): Promise<Array<customer.PatientProvider>>{
       let url = this.url + `/${customerId}/${patientId}`;            
       let response = (await this.api.get(url)).data as Array<customer.PatientProvider>;          
       return response;
     }

     async getOne(customerId: number, patientId: number, providerId: number): Promise<customer.PatientProvider>{
        let url = this.url + `/${customerId}/${patientId}/${providerId}`;            
        let response = (await this.api.get(url)).data as customer.PatientProvider;          
        return response;
      }

    async delete(customerId: number, patientproviderid: number){
        let url = this.url + `/${customerId}/${patientproviderid}`;
        let response = await this.api.delete(url).catch(err => {
            throw err;
        });
        return response;
    }


    async putPatientProvider(customerId: number,patientproviderid: number, patientProvider: customer.PatientProvider){
        let url = this.url + `/${customerId}/${patientproviderid}`;     
        let response = await this.api.put(url,patientProvider).catch(err => {         
            throw err;          
          });
        return response;
      }
    
      async postPatientProvider(customerId: number, patientProvider: customer.PatientProvider){
        let url = `/api/PatientProvider/${customerId}`;            
        let response = await this.api.post(url,patientProvider).catch(err => {
          throw err;          
        });                          
        return response;
      }     
 
   }