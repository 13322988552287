<template>
   <v-container>
      <h4 v-if="loaded && !hasData" class="ml-5">No records available.</h4>
      <v-timeline v-if="loaded && hasData" dense>
         <div v-for="(item, index) in patient.patientHistory.surgicalHistory" :key="index">
            <v-timeline-item>
               <v-card>
                  <v-col>
                     <interaction-info :description="item.interactionType.description"
                                       :date="item.interactionDateTime" :name="item.updatedByUser.displayName"/>
   
                     <v-text-field disabled v-if="displayItem(item, 'surgicalHistoryPrevious')" v-model="item.surgicalHistoryPrevious" label="Any previous foot surgeries other than amputation?"/>
                     <v-text-field disabled v-if="displayItem(item, 'surgicalHistoryLingeringEffects')" v-model="item.surgicalHistoryLingeringEffects" label="Any lingering effects/deformity?"/>
                     <v-text-field disabled v-if="displayItem(item, 'surgicalHistoryAmputationHistory')" v-model="item.surgicalHistoryAmputationHistory" label="Amputation History?"/>
                     
                     <div class="py-2 title" v-if="displayItem(item, 'surgicalHistoryAmputationHistorySelection')">Most recent 3 amputations</div>
                     <div v-for="(amputation, amputationIndex) in item.surgicalHistoryAmputationHistorySelection" :key="amputationIndex">
                        <v-card tile class="pl-2 pr-2 mb-4">
                           <v-text-field disabled v-if="displayItem(amputation, 'surgicalHistoryAmputationHistoryDiabetesRelated')" v-model="amputation.surgicalHistoryAmputationHistoryDiabetesRelated" label="Diabetes related?"/>
                           <v-text-field disabled v-if="displayItem(amputation, 'surgicalHistoryAmputationHistoryLocationLeftOrRight')" v-model="amputation.surgicalHistoryAmputationHistoryLocationLeftOrRight" label="Left or Right"/>
                           <v-text-field disabled v-if="displayItem(amputation, 'surgicalHistoryAmputationHistoryLocation')" v-model="amputation.surgicalHistoryAmputationHistoryLocation" label="Location"/>
                           <v-text-field disabled v-if="displayItem(amputation, 'surgicalHistoryAmputationHistoryWhen')" v-model="amputation.surgicalHistoryAmputationHistoryWhen" label="When did amputation occur?"/>
                      
                        </v-card>
                     </div>
                     <v-text-field disabled v-if="displayItem(item, 'surgicalHistoryVascularSurgeries')" v-model="item.surgicalHistoryVascularSurgeries" label="Vascular surgeries?"/>
                     <v-text-field disabled v-if="displayItem(item, 'surgicalHistoryOtherMajor')" v-model="item.surgicalHistoryOtherMajor" label="Other major surgeries"/>
                  </v-col>
               </v-card>
               <template v-slot:icon>
                  <timeline-avatar :userId="item.updatedByUser.updatedByUserId" :alt="item.updatedByUser.displayName" :src="item.updatedByUser.avatarURL"/>
               </template>
            </v-timeline-item>
         </div>
      </v-timeline>
   </v-container>
</template>

<script>
   import {GetSurgicalHistory} from "./queries/patient-history.graphql";
   import {HistoryTabNames} from "@/types/enum";
   import InteractionInfo from "./InteractionInfo";
   import TimelineAvatar from "./TimelineAvatar";
   
   export default {
      name: "Surgical",
      props: {
         patientId: Number,
         customerId: Number
      },
      components: {
         InteractionInfo,
         TimelineAvatar
      },
      data: () => ({
         patient: null,
         loaded: false,
         hasData:false
      }),
      methods: {
         displayItem(item, propertyName) {
            return typeof item[propertyName] !== "undefined" && item[propertyName] !== null && item[propertyName].length > 0;
         }
      },
      apollo: {
         patient: {
            query: GetSurgicalHistory,
            variables() {
               return {
                  customerId: this.customerId,
                  patientId: this.patientId,
                  patientHistoryTab: HistoryTabNames.SurgicaHistory
               };
            },
            update(data) {
               this.loaded = true;
               this.hasData = data.customer.patient.patientHistory!==null
                              && data.customer.patient.patientHistory.surgicalHistory.length > 0;
               return data.customer.patient;
            }
         }
      }
   };
</script>

<style scoped>

</style>