<template>
  <v-autocomplete
      v-model="inputVal"
      :items="items"
      :itemKey="itemKey"
      :loading="isLoading"
      :search-input.sync="search"
      item-text="number"
      item-value="number"
      label="NPI"
      placeholder="Start typing to Search"
      prepend-icon="mdi-card-account-details"
      return-object
      clearable
      @select="selected"
  >
    <template v-slot:no-data>
      <div class="px-4">Please enter a 10 digit NPI</div>
      <div v-show="search && search.length === 10" class="px-4">NPI not found</div>
    </template>
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
          v-bind="attr"
          :input-value="selected"
          color="blue-grey"
          class="white--text"
          v-on="on"
      >
        <v-icon left>
          mdi-card-account-details
        </v-icon>
        <div v-text="item.number + ' ' + (item.prefix ? item.prefix + '' : '') + item.firstName + ' ' + item.lastName"></div>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
          color="indigo"
          class="text-h5 font-weight-light white--text"
      >
        {{ item.firstName.charAt(0) + item.lastName.charAt(0) }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.number"></v-list-item-title>
        <v-list-item-title v-text="(item.prefix ? item.prefix + '' : '') + item.firstName + ' ' + item.lastName"></v-list-item-title>
        <v-list-item-subtitle v-text='item.addresses.length > 0 ? item.addresses[0].phoneNumber + " " + item.addresses[0].addressLine1 + " " + item.addresses[0].city + " " + item.addresses[0].state + " " + item.addresses[0].zip.replace(/(\d{5})(\d{4})/, "$1-$2") : ""'></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>mdi-card-account-details</v-icon>
      </v-list-item-action>
    </template>

  </v-autocomplete>
</template>

<script>
import NpiService from "@/services/NpiService";

export default {
  name: "ProviderInput",
  props: {
    npi: Object,
    value: Object,
    itemKey: Number,
    customerId: Number
  },
  data: () => ({
    isLoading: false,
    descriptionLimit: 60,
    entries: [],
    model: null,
    search: null,
  }),
  created() {
    if (this.value && this.value.number) {
      if (!this.entries.find(e => e.number === this.value.number)) {
        this.entries.push(this.value);
      }
    }
  },
  methods: {

    selected() {
      this.$emit('selected', this.model);
    }
  },
  watch: {
    npi(val) {
      //
    },
    value(val) {
      if (val) {
        if (!this.entries.find(e => e.number === val.number)) {
          this.entries.push(val);
        }
      }
    },
    search() {
      if (this.search && this.search.length < 10) {
        this.entries.splice(0);
        return;
      }
      
      if (this.search) {
        if (this.isLoading) return
        this.isLoading = true
        const request = {
          number: this.search,
          customerId: this.customerId
        };
        const self = this;
        new NpiService().search(request)
            .then(res => {
              if (res.length > 0) {
                res.forEach(r => {
                  self.entries.push(r);
                })
              }
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoading = false))
      }
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    fields() {
      if (!this.model) return []
      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a',
        }
      })
    },
    items() {
      return this.entries;
    }
  }
}
</script>

<style scoped>

</style>