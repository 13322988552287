import * as master from '../types/master';
import * as baseService from './BaseService';


export default class CustomerAssignmentsService extends baseService.BaseService {
    private url = '/api/CustomerAssignments';

    constructor() {
        super();
    }

    async getPatientAssignmentHistory(customerId: number, patientId: number): Promise<master.Assignment> {
        let url = this.url + `/${customerId}/${patientId}`;
        let response;
        response = await this.api.get(url).catch(err => {
            throw err;
        });
        return response.data;
    }

    async saveNewDeviceEvent(command: master.Assignment) {
        let url = this.url + '/SaveNewDeviceEvent';
        let response = await this.api.post(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async updateDeviceSerialNumber(command: master.Assignment) {
        let url = this.url + '/UpdateDeviceSerialNumber';
        let response = await this.api.post(url, command).catch(err => {
            throw err;
        });
        return response;
    }

    async saveEditDeviceEvent(command: master.Assignment) {
        let url = this.url + '/SaveEditDeviceEvent';
        let response = await this.api.post(url, command).catch(err => {
            throw err;
        });
        return response;
    }
}   