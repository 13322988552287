<template>
   <v-container>
      <h4 v-if="loaded && !hasData" class="ml-5">No records available.</h4>
      <v-timeline v-if="loaded && hasData" dense>
         <div v-for="(item, index) in patient.patientHistory.socialHistory" :key="index">
            <v-timeline-item>
               <v-card>
                  <v-col>
                     <interaction-info :description="item.interactionType.description"
                                       :date="item.interactionDateTime" :name="item.updatedByUser.displayName"/>
   
                     <div class="py-2 title" v-if="item.history.hasFormData">Social History</div>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryAmbulatoryStatus')" 
                                   v-model="item.history.socialHistoryAmbulatoryStatus" label="Ambulatory Status"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryPrescribedTherapeuticShoes')" 
                                   v-model="item.history.socialHistoryPrescribedTherapeuticShoes" label="Have you been prescribed therapeutic shoes or inserts/insoles?"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryWearTherapeuticShoes')" 
                                   v-model="item.history.socialHistoryWearTherapeuticShoes" label="Do you wear them a majority of the time when not sleeping?"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryCaregiver')" 
                                   v-model="item.history.socialHistoryCaregiver" label="Do you have anyone who helps care for you?"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryLivingAlone')" 
                                   v-model="item.history.socialHistoryLivingAlone" label="Do you live alone?"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryDriving')"
                                   v-model="item.history.socialHistoryDriving" label="Do you drive?"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryVisionIssues')" 
                                   v-model="item.history.socialHistoryVisionIssues" label="Do you have vision issues that affect your ability to see your feet?"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryAudioImpairment')" 
                                   v-model="item.history.socialHistoryAudioImpairment" label="Audio impairment"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistorySmoking')" 
                                   v-model="item.history.socialHistorySmoking" label="Are you a current smoker?"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryWorking')" 
                                   v-model="item.history.socialHistoryWorking" label="Are you currently working?"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryJob')" 
                                   v-model="item.history.socialHistoryJob" label="What do you do?"/>
                     <v-text-field disabled v-if="displayItem(item.history, 'socialHistoryHoursOnFeet')" 
                                   v-model="item.history.socialHistoryHoursOnFeet" label="How many hours are you on your feet a day?"/>
   
                     <div class="py-2 title" v-if="item.socialHistoryFallRiskAssessment.hasFormData">Fall Risk Assessment</div>
                     <v-text-field disabled v-if="displayItem(item.socialHistoryFallRiskAssessment, 'socialHistoryFallAssessmentFalling')" 
                                   v-model="item.socialHistoryFallRiskAssessment.socialHistoryFallAssessmentFalling" label="Have you fallen in the past year?"/>
                     <v-text-field disabled v-if="displayItem(item.socialHistoryFallRiskAssessment, 'socialHistoryFallAssessmentInjured')" 
                                   v-model="item.socialHistoryFallRiskAssessment.socialHistoryFallAssessmentInjured" label="Were you injured?"/>
                     <v-text-field disabled v-if="displayItem(item.socialHistoryFallRiskAssessment, 'socialHistoryFallAssessmentUnsteady')" 
                                   v-model="item.socialHistoryFallRiskAssessment.socialHistoryFallAssessmentUnsteady" label="Do you feel unsteady when standing or walking?"/>
                     <v-text-field disabled v-if="displayItem(item.socialHistoryFallRiskAssessment, 'socialHistoryFallAssessmentWorried')" 
                                   v-model="item.socialHistoryFallRiskAssessment.socialHistoryFallAssessmentWorried" label="Are you worried about falling?"/>
                     
                  </v-col>
               </v-card>
               <template v-slot:icon>
                  <timeline-avatar :userId="item.updatedByUser.updatedByUserId" :alt="item.updatedByUser.displayName" :src="item.updatedByUser.avatarURL"/>
               </template>
            </v-timeline-item>
         </div>
      </v-timeline>
   </v-container>
</template>

<script>
   import {GetSocialHistory} from "./queries/patient-history.graphql";
   import {HistoryTabNames} from "@/types/enum";
   import InteractionInfo from "./InteractionInfo";
   import TimelineAvatar from "./TimelineAvatar";
   
   export default {
      name: "Social",
      props: {
         patientId: Number,
         customerId: Number
      },
      components: {
         InteractionInfo,
         TimelineAvatar
      },
      data: () => ({
         patient: null,
         loaded: false,
         hasData:false
      }),
      methods: {
         displayItem(item, propertyName) {
            return typeof item[propertyName] !== "undefined" && item[propertyName] !== null && item[propertyName].length > 0;
         }
      },
      apollo: {
         patient: {
            query: GetSocialHistory,
            variables() {
               return {
                  customerId: this.customerId,
                  patientId: this.patientId,
                  patientHistoryTab: HistoryTabNames.SocialHistory
               };
            },
            update(data) {
               this.loaded = true;
               this.hasData = data.customer.patient.patientHistory !== null
                              && data.customer.patient.patientHistory.socialHistory.length > 0;
               return data.customer.patient;
            }
         }
      }
   };
</script>

<style scoped>

</style>