<template>
   <v-container>
      <h4 v-if="loaded && !hasData" class="ml-5">No records available.</h4>
      <v-timeline v-if="loaded && hasData" dense>
         <div v-for="(item, index) in patient.patientHistory.medicalHistoryQuestions" :key="index">
            <v-timeline-item>
               <v-card>
                  <v-col>
                     <interaction-info :description="item.interactionType.description"
                                       :date="item.interactionDateTime" :name="item.updatedByUser.displayName"/>
                     
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsDiabetes')"
                                   v-model="item.medicalHistoryQuestionsDiabetes"
                                   label="Diabetes"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsNeuropathy')"
                                   v-model="item.medicalHistoryQuestionsNeuropathy"
                                   label="Neuropathy (burning, tingling pins & needles)"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsNeuropathyLocation')"
                                   v-model="item.medicalHistoryQuestionsNeuropathyLocation"
                                   label="Neuropathy location"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsPainLowerExtremities')"
                                   v-model="item.medicalHistoryQuestionsPainLowerExtremities"
                                   label="Have you had pain in your lower extremities over the past two weeks?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsPainLowerExtremitiesLocation')"
                                   v-model="item.medicalHistoryQuestionsPainLowerExtremitiesLocation"
                                   label="Where is the extremity pain located?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsPainScale')"
                                   v-model="item.medicalHistoryQuestionsPainScale"
                                   label="On a scale from 1-10, 10 being the worst, how would you rate your pain?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsHowLongPainLast')"
                                   v-model="item.medicalHistoryQuestionsHowLongPainLast"
                                   label="How long does the pain last?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsDescribePain')"
                                   v-model="item.medicalHistoryQuestionsDescribePain"
                                   label="How would you describe the pain?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsVascularComplications')"
                                   v-model="item.medicalHistoryQuestionsVascularComplications"
                                   label="Vascular Complications?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsVascularComplicationsSelection')"
                                   v-model="item.medicalHistoryQuestionsVascularComplicationsSelection"
                                   label="How would you describe the pain?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsAutoimmuneConditions')"
                                   v-model="item.medicalHistoryQuestionsAutoimmuneConditions"
                                   label="Vascular Complications?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsDialysis')"
                                   v-model="item.medicalHistoryQuestionsDialysis"
                                   label="Dialysis?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsFootHistory')"
                                   v-model="item.medicalHistoryQuestionsFootHistory"
                                   label="Foot History (non-amputation/ulcer)?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsFootHistoryLocation')"
                                   v-model="item.medicalHistoryQuestionsFootHistoryLocation"
                                   label="Non-amputation/ulcer Description"/>
                     <label v-if="displayItem(item, 'medicalHistoryQuestionsOther')" 
                            class="text-caption" for="other-medical-info">Other Medical History</label>
                     <div v-if="displayItem(item, 'medicalHistoryQuestionsOther')"
                                 v-html="item.medicalHistoryQuestionsOther"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsWoundNow')"
                                   v-model="item.medicalHistoryQuestionsWoundNow"
                                   label="Have you had a wound?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsWoundLast')"
                                   v-model="item.medicalHistoryQuestionsWoundLast"
                                   label="If yes, approximately when was the last wound?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsFootUlcer')"
                                   v-model="item.medicalHistoryQuestionsFootUlcer"
                                   label="Have you had a foot ulcer?"/>
                     <v-text-field disabled v-if="displayItem(item, 'medicalHistoryQuestionsFootUlcerLastDate')"
                                   v-model="item.medicalHistoryQuestionsFootUlcerLastDate"
                                   label="If yes, approximately when was the last foot ulcer?"/>
                     
                     <div v-for="(finding, findingIndex) in item.medicalHistorySubjectiveFinding" :key="findingIndex">
                        <v-card tile class="pl-2 pr-2 mb-4">
                           <div class="py-2 title">Medical history subjective finding</div>
                           <v-text-field disabled v-if="displayItem(finding, 'medicalHistorySubjectiveFindingLocation')"
                                         v-model="finding.medicalHistorySubjectiveFindingLocation"
                                         label="Where is the extremity pain located?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'medicalHistorySubjectiveFindingDidItHeal')"
                                         v-model="finding.medicalHistorySubjectiveFindingDidItHeal"
                                         label="Did it heal?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'medicalHistorySubjectiveOutcome')"
                                         v-model="finding.medicalHistorySubjectiveOutcome"
                                         label="If No, what was the outcome?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'medicalHistorySubjectiveFindingWhenDidItHeal')"
                                         v-model="finding.medicalHistorySubjectiveFindingWhenDidItHeal"
                                         label="If Yes, when did it heal?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'medicalHistorySubjectiveFindingWhenDidItHealOther')"
                                         v-model="finding.medicalHistorySubjectiveFindingWhenDidItHealOther"
                                         label="If other selected, when did it heal"/>
                           <v-text-field disabled v-if="displayItem(finding, 'medicalHistorySubjectiveHealedHowLong')"
                                         v-model="finding.medicalHistorySubjectiveHealedHowLong"
                                         label="If healed, how long did it take to heal?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'medicalHistorySubjectiveHealedWhatResulted')"
                                         v-model="finding.medicalHistorySubjectiveHealedWhatResulted"
                                         label="Where is the subjective finding located?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'medicalHistorySubjectiveHealedWhatResultedOther')"
                                         v-model="finding.medicalHistorySubjectiveHealedWhatResultedOther"
                                         label="If other, enter details:"/>
                        </v-card>
                     </div>
                  
                  </v-col>
               </v-card>
               <template v-slot:icon>
                  <timeline-avatar :userId="item.updatedByUser.updatedByUserId" :alt="item.updatedByUser.displayName" :src="item.updatedByUser.avatarURL"/>
               </template>
            </v-timeline-item>
         </div>
      </v-timeline>
   </v-container>
</template>

<script>
   import {GetMedicalHistory} from "./queries/patient-history.graphql";
   import {HistoryTabNames} from "@/types/enum";
   import InteractionInfo from "./InteractionInfo";
   import TimelineAvatar from "./TimelineAvatar";
   
   export default {
      name: "Medical",
      props: {
         patientId: Number,
         customerId: Number
      },
      components: {
         InteractionInfo,
         TimelineAvatar
      },
      data: () => ({
         patient: null,
         loaded: false,
         hasData: false
      }),
      methods: {
         displayItem(item, propertyName) {
            return typeof item[propertyName] !== "undefined" && item[propertyName] !== null &&
                   item[propertyName].length > 0;
         }
      },
      apollo: {
         patient: {
            query: GetMedicalHistory,
            variables() {
               return {
                  customerId: this.customerId,
                  patientId: this.patientId,
                  patientHistoryTab: HistoryTabNames.MedicalHistory
               };
            },
            update(data) {
               this.loaded = true;
               this.hasData = data.customer.patient.patientHistory !== null
                              && data.customer.patient.patientHistory.medicalHistoryQuestions.length > 0;
               return data.customer.patient;
            }
         }
      }
   };
</script>

<style scoped>

</style>