/* eslint-disable no-useless-escape */
<template>
   <v-card v-if="data" ref="formCard" :elevation="this.options.disableAll ? 2 : 8" class="interactions-card">
      <v-col>
      <span class="caption grey--text">
         {{
            (interaction &&
             interaction.interactionDateTime
             ? formatDateTime(interaction.interactionDateTime)
             : "")
         }}
      </span>
         
         <span class="caption grey--text text-right">
            {{
               isEdited
               ? (interaction && interaction.updatedDateTime
                  ? "(edited on " + formatDateTime(interaction.updatedDateTime) + ")"
                  : "(edited)")
               : ""
            }}
      </span>
         
         <h3 class="my-1 pb-4">
            <span v-if="data">{{formDescription}} </span>
            <span class="caption">by {{createdBy}}</span>
            <span class="caption grey--text text-right float-right" v-show="draftSaved">Draft Saved</span>
         </h3>
         
         <v-form ref="form" class="pm-form">
            <v-btn v-show="options.disableAll" absolute class="text--primary" icon right top @click="edit">
               <v-icon>mdi-pencil</v-icon>
            </v-btn>
            
            <v-jsf v-model="form" :options="options" :schema="schema" class="pb-10" v-if="render">
               <template slot="custom-address-component" slot-scope="context">
                  <address-component v-if="shouldShow(context)"
                                     :id="`${context.fullKey}`"
                                     v-show="evaluateExpression(context, 'x-show-expression') && evaluateExpression(context, 'x-eval-expression')"
                                     v-bind="context" @selected="addressSelected(context, $event)"/>
               </template>
               <template slot="custom-html-component" slot-scope="context">
                  <html-editor v-if="shouldShow(context)"
                               v-show="evaluateExpression(context, 'x-show-expression') && evaluateExpression(context, 'x-eval-expression')"
                               v-bind="context" class="html-editor"/>
               </template>
               <template v-show="false" slot="custom-component" slot-scope="context">
                  <v-jsf
                      v-if="shouldShow(context)"
                      v-show="evaluateExpression(context, 'x-show-expression') && evaluateExpression(context, 'x-eval-expression')"
                      :disabled="context.disabled"
                      :model-key="context.modelKey"
                      :ref="context.modelKey"
                      :options="evaluateOptions(context)"
                      :required="context.required"
                      :rules="context.rules"
                      :schema="JSON.parse(JSON.stringify(context.schema))"
                      :value="context.value"
                      @input="context.on.input"
                      @change="resetMemberStatusValidation(context)">
                  </v-jsf>
               </template>
            </v-jsf>
            
            <v-alert :type="alertRef.alertType" :value="alertRef.alert" dense transition="fade-transition">
               {{alertRef.alertMessage}}
            </v-alert>
            
            <v-col v-show="!options.disableAll" class="text-right">
               <v-btn class="mr-4" @click="cancel">Cancel</v-btn>
               <v-btn :disabled="loading" :loading="loading" class="mr-4" color="primary" @click="saveForm">Save</v-btn>
            </v-col>
            <div v-show="options.disableAll &&  showMoreEnabled">
               <v-btn v-show="options.summary" absolute bottom elevation="2" right small v-on:click="showMore(true)">
                  <v-icon>mdi-chevron-down</v-icon>
                  Show More
               </v-btn>
               <v-btn v-show="!options.summary" absolute bottom elevation="2" right small v-on:click="showMore(false)">
                  <v-icon>mdi-chevron-up</v-icon>
                  Show Less
               </v-btn>
            </div>
         </v-form>
      </v-col>
   </v-card>
</template>

<script>
   import {GetMemberPrequalificationOutreachForm} from "./../queries/member-interactions.graphql";
   import {UpdateMemberPrequalificationOutreachForm} from "./../mutations/member-interactions.graphql";
   import {DeactivateMemberProviders} from "../../Provider/mutations/member-provider.graphql";
   import {AddEntityAddresses, UpdateEntityAddresses} from "../ContactInformation/mutations/update-contact.graphql";
   import VJsf from "@koumoul/vjsf/lib/VJsf.js";
   import "@koumoul/vjsf/lib/VJsf.css";
   import "@koumoul/vjsf/lib/deps/third-party.js";
   import Vue from "vue";
   import AddressComponent from "@/components/Shared/AddressComponent";
   import HtmlEditor from "@/components/HtmlEditor";
   import VuetifyDialog from "vuetify-dialog";
   import "vuetify-dialog/dist/vuetify-dialog.css";
   import vuetify from "@/plugins/vuetify";
   import Utils from "@/mixins/utils";
   import {EntityTypeEnum} from "@/types/enum";
   import {contactDetailsUtils} from "@/mixins/contactDetailsUtils";
   
   Vue.use(VuetifyDialog, {
      context: {
         vuetify
      }
   });
   
   export default {
      name: "AddMemberInteractionForm",
      props: {
         customerId: Number,
         memberId: Number,
         selectedMemberInteractionType: Number,
         showMoreEnabled: Boolean,
         readOnly: Boolean,
         isEdited: Boolean
      },
      components: {
         HtmlEditor,
         VJsf,
         AddressComponent
      },
      mixins: [Utils, contactDetailsUtils],
      data: () => ({
         //specific data recieved from prequalification outreach
         prequalificationOutreach: null,
         //generic var used for all forms
         data: null,
         schema: {},
         model: null,
         form: {},
         interaction: null,
         memberAddress: null,
         providersUpdateDb: null,
         providers: null,
         selectedForm: null,
         // Disable query on component load.
         // We need to execute query when component is created and props are passed 
         skipPrequalificationOutreachQuery: true,
         autoSaveTimer: null,
         autoSaveEnabled: process.env.VUE_APP_AUTO_SAVE_INTERACTION_FORM === "enabled",
         render: false,
         autoSaveData: {},
         valid: false,
         draftSaved: false,
         loading: true,
         initialLoad: true,
         alertRef: {
            alert: false,
            alertType: "success",
            alertMessage: ""
         },
         options: {
            context: {
               states: [],
               memberStatusChangeActive: "Engaged - Completed"
            },
            summary: false,
            disableAll: false,
            markdownit: {
               html: true
            },
            editMode: "inline",
            arrayOperations: ["update"],
            sectionsTitlesClasses: ["title", "subtitle-1", "subtitle-2"],
            initialValidation: "none",
            rules: {
               validateStatusChange: true
            }
         }
      }),
      computed: {
         createdBy() {
            if (this.interaction == null) {
               return `${this.$store.state.achillesUser.firstName} ${this.$store.state.achillesUser.lastName}`;
            }
            return this.resolveUserName(this.$store.state.users, this.interaction.updatedByUserId,
                                        this.interaction.updatedByUser);
         },
         formDescription() {
            if (this.data && this.data.formByMemberInteractionType
                && this.data.formByMemberInteractionType.memberInteractionType) {
               return this.data.formByMemberInteractionType.memberInteractionType.description;
            }
            return null;
         }
      },
      methods: {
         async activateMemberStatusValidation() {
            if (typeof this.form.callDisposition != "undefined"
                && this.form.callDisposition === this.options.context.memberStatusChangeActive) {
               this.options.rules = {
                  validateStatusChange: v => !!v || "Member Status is required"
               };
               await this.$nextTick();
            } else if (typeof this.form.callDisposition != "undefined"
                       && this.form.callDisposition !== this.options.context.memberStatusChangeActive) {
               this.options.rules = {
                  validateStatusChange: true
               };
            }
         },
         resetMemberStatusValidation(context) {
            if (context.modelKey === "callDisposition"
                && context.value !== this.options.context.memberStatusChangeActive) {
               this.$refs.memberStatusChange.resetValidation();
            }
         },
         //call different queries based on interaction type
         triggerQuery() {
            if (!this.initialLoad) {
               return;
            }
            
            switch (this.selectedMemberInteractionType) {
               case 1: {
                  this.$apollo.queries.prequalificationOutreach.skip = false;
                  this.options.summary = true;
                  this.$apollo.queries.prequalificationOutreach.refetch();
                  
                  break;
               }
            }
            this.initialLoad = false;
         },
         autoSave() {
            console.log("TODO: autoSave");
         },
         shouldShow(context) {
            let val = (!this.options.summary
                       || (this.options.summary
                           && Object.hasOwn(context.schema, "x-summary")
                           && context.schema["x-summary"]));
            return val;
         },
         evaluateExpression: function (context, property) {
            if (context.schema[property]) {
               return eval(context.schema[property]);
            }
            return true;
         },
         evaluateOptions(context) {
            context.options.disableAll =
                context.options.disableAll || !this.evaluateExpression(context, "x-disabled-expression");
            return context.options;
         },
         addressSelected: function (context, val) {
            if (typeof val !== "object" || val === null) {
               return;
            }
            
            let address = {};
            address.addressLine1 = (val["street_number"] + " " + val["route"]).trim();
            address.city = val["locality"];
            address.stateId = this.stateIdFromShortName(val["administrative_area_level_1"]);
            address.zipCode = val["postal_code"];
            address.addressLine2 = "";
            
            //update existing address
            if(this.memberAddress!=null && this.memberAddress.entityAddressId){
               address.entityAddressId = this.memberAddress.entityAddressId;
            }
            this.memberAddress = address;
   
            this.form.zipCode = this.memberAddress.zipCode;
            this.form.addressLine1 = this.memberAddress.addressLine1;
            this.form.addressLine2 = this.memberAddress.addressLine2;
            this.form.city = this.memberAddress.city;
            this.form.state = val.administrative_area_level_1;
          
             this.render = false;
             
             this.$nextTick(() => {
               this.render = true;
             });
         },
         cancel() {
            console.log("TODO: cancel");
         },
         saveForm: async function () {
            await this.activateMemberStatusValidation();
            
            if (!await this.validateForm()) {
               return;
            }
            
            this.loading = true;
            this.setMemberInteractionFormValues();
            await this.saveAddress();
            this.updateMemberPrequalificationMutation();
            this.deactivateProviders();
         },
         validateForm: async function () {
            if (!this.$refs.form.validate()) {
               const res = await this.$dialog.error(
                   {
                      text: "Please fix the highlighted issues before savings",
                      title: "Unable to save",
                      actions: ["Okay"]
                   });
               return false;
            }
            return true;
         },
         setMemberInteractionFormValues() {
            this.setProvidersDbValues();
            this.setMemberAddressesDbVales();
            
            let formData = {
               callDesignation: this.form.callDesignation,
               callDisposition: this.form.callDisposition,
               memberStatusChange: this.form.memberStatusChange,
               notes: this.form.notes,
               addresses: this.memberAddress,
               providers: this.providersUpdateDb
            };
            this.setInteractionDbValues(formData);
         },
         setProvidersDbValues() {
            let providers = [];
            let that = this;
            that.form.providers.forEach((item) => {
               let provider = that.providers.find(element => element.npi === item.npi);
               let providerDb = {
                  memberProviderId: provider.memberProviderId,
                  prequalificationProviderId: provider.prequalificationProviderId,
                  isActive: !item.isInactive,
                  memberId: that.memberId,
                  memberProviderTypeId: provider.memberProviderTypeId
               };
               providers.push(providerDb);
            });
            that.providersUpdateDb = providers;
         },
         setMemberAddressesDbVales() {
            let addressDb = this.data.member.addresses.data.length > 0
                            ? this.data.member.addresses.data[0]
                            : null;
            //same address with minor change in address
            if (addressDb != null) {
               this.memberAddress.entityAddressId = addressDb.entityAddressId;
               this.setMemberState();
               
               return;
            }
            
            //new address
            this.memberAddress.addressLine1 = this.form.addressLine1;
            this.memberAddress.addressLine2 = this.form.addressLine2;
            this.memberAddress.city = this.form.city;
            this.memberAddress.zipCode = this.form.zipCode;
            this.setMemberState();
         },
         setMemberState() {
            let state = this.$store.state.states.find(f => f.name === this.form.state);
            this.memberAddress.stateId = state.stateId;
            this.memberAddress.state = state;
         },
         setInteractionDbValues(formData) {
            let emptyInteraction =
                {
                   createdByUserId: this.$store.state.achillesUser.userId,
                   memberInteractionForm: {
                      formData: "",
                      formId: this.selectedForm.formId
                   },
                   memberInteractionTypeId: this.selectedMemberInteractionType
                };
            let interaction = this.interaction == null || this.interaction.memberInteractionForm == null
                              ? emptyInteraction : this.interaction;
            
            interaction.interactionDateTime = new Date().toISOString();
            interaction.updatedDateTime = new Date().toISOString();
            interaction.memberInteractionForm.formData = JSON.stringify(formData);
            interaction.memberId = this.memberId;
            
            //updating existing interaction
            if (this.interaction != null) {
               interaction.updatedByUserId = this.$store.state.achillesUser.userId;
               interaction.memberInteractionForm.memberInteractionId = this.interaction.memberInteractionId;
               //TODO: update when adding functionality to edit/delete form
               //interaction.memberInteractionForm.isEdited;
               //interaction.memberInteractionForm.isDeleted;
            }
            this.interaction = interaction;
         },
         updateMemberPrequalificationMutation() {
            let interaction = this.interaction;
            
            delete this.interaction["__typename"];
            delete this.interaction.memberInteractionForm["__typename"];
            
            let input = {
               customerId: this.customerId,
               memberId: this.memberId,
               memberInteraction: interaction
            };
            
            this.$apollo.mutate(
                    {
                       mutation: UpdateMemberPrequalificationOutreachForm,
                       variables: {
                          input: input
                       }
                    })
                .then(result => {
                   this.clearApolloCache();
                });
         },
         async saveAddress() {
            delete this.memberAddress.state;
            
            let addresses = [this.memberAddress];
            
            //new address: member doesnt have address yet
            if(typeof this.memberAddress.entityAddressId === "undefined"){
               await this.$apollo
                         .mutate(
                             {
                                mutation: AddEntityAddresses,
                                variables: {
                                   input: {
                                      addresses: addresses,
                                      entityId: this.memberId,
                                      entityTypeId: EntityTypeEnum.Member,
                                      customerId: this.customerId,
                                      updatedByUserId: this.$store.state.achillesUser.userId
                                   }
                                }
                             })
                         .then(async (data) => {
                            if (data.data.payload.error) {
                               return await this.failure(data.payload);
                            }
                            return await this.success();
                         })
                         .catch(async (error) => {
                            await this.failure(error);
                         });
               return;
            }
            
            //update existing address
            await this.$apollo
                      .mutate(
                          {
                             mutation: UpdateEntityAddresses,
                             variables: {
                                input: {
                                   addresses: addresses,
                                   customerId: this.customerId,
                                   updatedByUserId: this.$store.state.achillesUser.userId
                                }
                             }
                          })
                      .then(async (data) => {
                         if (data.data.payload.error) {
                            return await this.failure(data.payload);
                         }
                         return await this.success();
                      })
                      .catch(async (error) => {
                         await this.failure(error);
                      });
         },
   
         async failure(data) {
            let errorTest = "Unable to update contact information";
      
            if (typeof data.errorCode !== undefined) {
               errorTest += `: ${data.errorCode}`;
            }
            //exception from API
            else {
               console.log(data);
            }
      
            let options = {
               text: errorTest,
               title: "Unable to update contact information",
               actions: ["Okay"]
            };
            await this.$dialog.error(options);
         },
         async success() {
      
            let options = {
               text: "Contact information was updated successfully",
               title: "Saved",
               actions: ["Okay"]
            };
            await this.$dialog.info(options);
         },
         deactivateProviders() {
            let ids = [];
            this.providersUpdateDb.forEach((item) => {
               ids.push(item.memberProviderId);
            });
            
            if (ids.length === 0) {
               return;
            }
            
            this.$apollo.mutate(
                    {
                       mutation: DeactivateMemberProviders,
                       variables: {
                          input: {
                             customerId: this.customerId,
                             memberProvidersIds: ids
                          }
                       }
                    })
                .then(result => {
                   //TODO: enable when Add button functionality is implemented
                   //this.loading = false;
                   this.clearApolloCache();
                });
         },
         clearApolloCache() {
            let clients = Object.values(this.$apollo.provider.clients);
            clients.forEach(client => client.cache.reset());
         },
         showMore(val) {
            this.options.summary = !val;
         },
         edit() {
            console.log("TODO: edit");
         },
         isEngagedComplete() {
            console.log("TODO: isEngagedComplete");
         },
         setFormDefaultValues() {
            this.setData();
            this.setSelectedForm();
            this.setSchema();
            this.setInteraction();
            this.setMemberAddress();
            
            switch (this.selectedMemberInteractionType) {
               case 1: {
                  this.setProviders();
               }
            }
            
            this.setFormData();
            this.render = true;
         },
         setData() {
            switch (this.selectedMemberInteractionType) {
               case 1: {
                  this.data = this.prequalificationOutreach;
                  break;
               }
            }
         },
         setInteraction() {
            if (this.data != null && this.data.member != null
                && this.data.member.memberInteractions != null
                && this.data.member.memberInteractions.data.length > 0) {
               
               this.interaction =
                   this.data.member.memberInteractions.data.find((element) => element.memberInteractionTypeId
                                                                              === this.selectedMemberInteractionType);
            }
         },
         setMemberAddress() {
            if (this.data != null && this.data.member != null
                && this.data.member.addresses.data.length > 0) {
               this.memberAddress = this.data.member.addresses.data[0];
            }
         },
         setProviders() {
            if (this.data != null && this.data.member != null
                && this.data.member.memberProviders.data.length > 0) {
               let providers = [];
               this.data.member.memberProviders.data.forEach((item, index) => {
                  let provider = item.prequalificationProvider;
                  provider.prequalificationProviderId = item.prequalificationProviderId;
                  provider.memberProviderId = item.memberProviderId;
                  provider.isInactive = !item.isActive;
                  provider.memberId = item.memberId;
                  provider.memberProviderTypeId = item.memberProviderTypeId;
                  
                  let address = [];
                  if (item.prequalificationProvider.addresses != null
                      && typeof item.prequalificationProvider.addresses.data != "undefined"
                      && item.prequalificationProvider.addresses.data.length > 0) {
                     address = item.prequalificationProvider.addresses.data[0];
                  }
                  provider.address = address;
                  
                  let phones = [];
                  if (item.prequalificationProvider.phones != null
                      && typeof item.prequalificationProvider.phones.data != "undefined"
                      && item.prequalificationProvider.phones.data.length > 0) {
                     phones = item.prequalificationProvider.phones.data;
                  }
                  provider.phone = phones;
                  
                  providers.push(provider);
                  
               });
               this.providers = providers;
            }
         },
         setFormData() {
            //existing form
            if (this.interaction != null) {
               console.log("TODO: setFormData existing form");
               return;
            }
            
            //new form
            if (this.data != null && this.memberAddress != null) {
               if (this.schema.properties.addressLine1 && !this.form.addressLine1) {
                  this.form.addressLine1 = this.memberAddress.addressLine1;
               }
               if (this.schema.properties.addressLine2 && !this.form.addressLine2) {
                  this.form.addressLine2 = this.memberAddress.addressLine2;
               }
               if (this.schema.properties.city && !this.form.city) {
                  this.form.city = this.memberAddress.city;
               }
               if (this.schema.properties.state && !this.form.state) {
                  this.form.state =
                      this.$store.state.states.find(f => f.stateId === this.memberAddress.stateId).name;
               }
               if (this.schema.properties.zipCode && !this.form.zipCode) {
                  this.form.zipCode = this.memberAddress.zipCode;
               }
               if (this.schema.properties.providers && !this.form.providers) {
                  this.form.providers = this.providers;
               }
            }
         },
         setSchema() {
            if (this.data.formByMemberInteractionType.form != null) {
               this.schema = JSON.parse(this.data.formByMemberInteractionType.form.schema);
            }
         },
         setSelectedForm() {
            if (this.data.formByMemberInteractionType.form != null) {
               this.selectedForm = this.data.formByMemberInteractionType.form;
            }
         }
      },
      
      apollo: {
         prequalificationOutreach: {
            query: GetMemberPrequalificationOutreachForm,
            variables() {
               return {
                  customerId: this.customerId,
                  memberId: this.memberId,
                  byInteractionType: true,
                  interactionTypeIds: [this.selectedMemberInteractionType]
               };
            },
            update: data => data.customer,
            // Disable query on component load.
            // We need to execute query when component is created and props are passed 
            skip() {
               return this.skipPrequalificationOutreachQuery;
            },
            result(data) {
               if (typeof data !== "undefined") {
                  this.setFormDefaultValues();
                  this.loading = false;
               }
            }
         }
      },
      created() {
         this.options.idPrefix = this._uid.toString();
         
         this.options.context.states = this.$store.state.states.map(s => s.name.trim());
         this.triggerQuery();
      }
   };
</script>

<style scoped lang="scss">
   .interactions-card {
      .pm-form {
         .vjsf-property {
            .v-input__slot {
               display: flex !important;
            }
            
            .html-editor {
               .v-input__slot {
                  display: initial !important;
               }
            }
            
            .v-label--is-disabled {
               color: rgba(0, 0, 0, 0.60) !important;
            }
            
            .v-text-field--filled {
               background: #ffffff !important;
            }
            
            .v-input--is-disabled {
               .v-input__slot {
                  background: #ffffff !important;
               }
               
               &:not(.v-input--is-readonly) {
                  pointer-events: all !important;
               }
            }
         }
      }
      
      .theme--light {
         &.v-sheet--outlined {
            border: none;
         }
      }
   }
   
   [class$="vjsf-property-"],
   [class*="vjsf-property-"] {
      max-width: 100% !important;
   }
   
   [class$="--disabled"],
   [class*="--disabled "] * {
      color: rgba(0, 0, 0, 0.80) !important;
   }
   
   [class$="--is-disabled"],
   [class*="--is-disabled "] * {
      color: rgba(0, 0, 0, 0.80) !important;
   }
   
   .phones-data {
      & > .row {
         .col:first-child, .col:last-child {
            flex: 0 0 25% !important;
         }
      }
   }

</style>