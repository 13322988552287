<template>
    <div>
        <v-app>
            <v-container fill-height>
                <v-row align="center" justify="center">
                    <v-col md="4">
                        <v-card class="mx-auto pa-10" max-width="400">
                            <h3>Request Access</h3>
                            <p>Unable to validate.   Please contact your supervisor regarding access to Achilles</p>
                            <v-btn color="primary" class="d-none d-sm-flex" @click="logout">
                                Logout <v-icon>mdi-logout-variant</v-icon>
                            </v-btn>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-app>
    </div>
</template>

<script>
export default {
    name: "RedirectComponent",
    data: () => ({
    }),
    methods: {
        logout() {{
            this.$store.commit('setSignedIn', false);
            this.$store.commit("setRestrictedAccess", false);
            this.$store.commit('setUsers', [])
            localStorage.setItem('getSession', '');
            sessionStorage.clear();
            this.$router.push('/')
        }}
    }
}
</script>