<template>
  <div>
    <slot>

    </slot>
  </div>
</template>

<script>
import { pageVisabilityDetect } from '../utils/pageFocusUtils'

const { hidden, visibilityChange } = pageVisabilityDetect();

export default {
  name: 'FocusManager',
  data() {
    return {
      inFocus: false,
    }
  },
  watch: {
    inFocus(newFlag, oldFlag) {
      return oldFlag !== newFlag ? this.$emit('focus-change', newFlag) : null
    }
  },
  mounted() {
    document.addEventListener(visibilityChange, this.handleFocusChange, false);

    document.addEventListener("focus", this.forceFocusTrue, false);
    document.addEventListener("blur", this.forceFocusFalse, false);

    window.addEventListener("focus", this.forceFocusTrue, false);
    window.addEventListener("blur", this.forceFocusFalse, false);
  },
  unmounted() {
    document.removeEventListener(visibilityChange, this.handleFocusChange, false);

    document.removeEventListener("focus", this.forceFocusTrue, false);
    document.removeEventListener("blur", this.forceFocusFalse, false);

    window.removeEventListener("focus", this.forceFocusTrue, false);
    window.removeEventListener("blur", this.forceFocusFalse, false);
  },
  methods: {
    handleFocusChange(forcedFlag) {
      // Handle focus and blue events
      if (typeof forcedFlag === "boolean") {
        if (forcedFlag) {
          return this.setFocus(true);
        }
        return this.setFocus(false);
      }

      // handle page visibility 
      if (document[hidden]) {
        return this.setFocus(false);
      }
      return this.setFocus(true);
    },

    forceFocusTrue() {
      return this.handleFocusChange(true);
    },

    forceFocusFalse() {
      return this.handleFocusChange(false);
    },

    setFocus(flag) {
      return this.inFocus !== flag ? this.inFocus = flag : null
    }
  }

}
</script>