<template>
   <div>
      <v-chip class="ma-2" label>Total Interactions: {{stats.totalInteractionCount}}</v-chip>
   </div>
</template>

<script>
   import {GetMemberInteractionStats} from "./../queries/member-interaction-stats.graphql";
   
   export default {
      name: "InteractionStats",
      props: {
         customerId: Number,
         memberId: Number
      },
      data: () => ({
         stats: {}
      }),
      apollo: {
         stats: {
            query: GetMemberInteractionStats,
            variables() {
               return {
                  customerId: this.customerId,
                  memberId: this.memberId
               };
            },
            update: data => data.customer.member.stats
         }
      }
   };
</script>

<style scoped>

</style>