import moment from "moment";

export default {
   methods: {
      parseDate(date) {
         if (!date) {
            return null;
         }
         
         const [month, day, year] = date.split("/");
         return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      
      resolveUserName(achillesUsers, updatedByUserId, updatedByUser) {
         let resolvedName = "";
         if (updatedByUserId != null) {
            let user = achillesUsers.find((u) => u.userId === updatedByUserId);
            if (user) {
               if (user.firstName && user.lastName) {
                  resolvedName = user.firstName + " " + user.lastName;
               } else {
                  resolvedName = user.email;
               }
            }
         } else if (updatedByUser != null) {
            resolvedName = updatedByUser;
         }
         return resolvedName;
      },
      
      formatDateTime(val) {
         if (val.includes("Z")) {
            return new Date(val).toLocaleString("en-US");
         } else {
            return new Date(val + "Z").toLocaleString("en-US");
         }
      },
      formatDate(val) {
         if (typeof val === undefined || val === null) {
            return "";
         }
      
         return moment(val).format("MM/DD/YYYY");
      }
   }
}