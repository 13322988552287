import * as BaseService from './BaseService';
import * as customer from '../types/customer';
import {Email} from "@/types/email";

export default class UserService extends BaseService.BaseService {
   private url = '/api/Email';
      
   constructor() {
     super();     
   }
   

    async post(email: Email)
    {
        let url = this.url;
        let response = await this.api.post(url,email).catch(err => {
            throw err;
        });
        return response;
    }
  }