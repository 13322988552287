<template>
  <v-row class="pa-5">
    <v-col class="pl-4" cols="4" v-if="!this.patientSiteId">
      <v-text-field
          v-model="name"
          label="Name"
          placeholder="Name"
      ></v-text-field>
    </v-col>
    <v-col cols="4" v-if="!this.patientSiteId">
      <v-text-field
          v-model="number"
          label="DUZ"
          placeholder="DUZ"
      ></v-text-field>
    </v-col>
    <v-col cols="2" v-if="!this.patientSiteId">
      <v-switch v-if="this.showAddressControl" v-model="showAddress"
                class="mt-5"
                dense
                label="Show Address"
                @change="search()">
      </v-switch>
    </v-col>
    <v-col class="text-right" cols="2" v-if="!this.patientSiteId">
      <v-btn :disabled="isLoading" :loading="isLoading" class="primary mt-2" @click="search()">Search</v-btn>
    </v-col>

    <v-col>
      <v-data-table
          :footer-props="{ 'items-per-page-options': rowsPerPage }"
          :headers="headers"
          :items="entries"
          :loading="isLoading"
          :options.sync="!!patientSiteId ? siteOptions : options"
          :server-items-length="totalRecords"
          :hide-default-footer="!!patientSiteId"
          class="elevation-2"
      >
        <template v-slot:item.action="{item}">
          <v-btn v-on:click="select(item)">Select</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import GetProvider from "@/components/Provider/queries/provider.graphql";
import GlobalSearchService from "@/services/GlobalSearchService";

export default {
  name: "ProviderSearchGql",
  props: {
    npi: Object,
    customerId: Number,
    patientSiteId: Number || null,
  },
  data: () => ({
    showAddress: false,
    showAddressControl: true,
    showLookupBySite: false,
    isLoading: false,
    name: "",
    firstName: "",
    lastName: "",
    number: "",
    baseHeaders: [
        { text: '', value: 'action', show: true, sortable: false },
        { text: 'Name', value: 'name', show: true, sortable: false },
        { text: 'DUZ', value: 'number' },
        { text: 'ProviderDUZ', value: 'providerDUZ', sortable: false },
        { text: 'LocationIEN', value: 'locationIEN', sortable: false },
    ],
    entries: [],
    totalRecords: null,
    options: {
      itemsPerPage: 10,
      page: 1,
      sortBy: ["Name"],
      sortDesc: [false]
    },
    siteOptions: {
        itemsPerPage: 150,
        page: 1,
        sortBy: ["Name"],
        sortDesc: [false]
    },
  }),
  mounted() {
      this.refreshSearch();
  },
  methods: {
    select(item) {
      this.$emit('selected', item);
    },
    search() {
      this.options.page = 1;

      if (this.showAddress && this.showAddressControl) {
        this.headers.push(
            {text: 'Address', value: 'addresses[0].addressLine1'},
            {text: 'City', value: 'addresses[0].city'},
            {text: 'Zip', value: 'addresses[0].zip'}
        );
        this.showAddressControl = false;
      }
        this.refreshSearch();
    },
    async getProvidersBySite() {
        if (this.patientSiteId) {
            let pagedOptions = {
                Search: null,
                SortBy: null,
                SortDesc: null,
                ItemsPerPage: 150,
                Page: 1
            };
            await new GlobalSearchService().searchProvidersByPatientSiteId(this.customerId, this.patientSiteId, pagedOptions)
                .then((value) => {
                    this.isLoading = true;
                    this.entries = value.items;
                    this.totalRecords = value.TotalCount;
                    this.isLoading = false;
                });
        }
    },
    refreshSearch() {
        if (this.patientSiteId) {
            this.getProvidersBySite();
        } else {
            this.$apollo.queries.provider.refetch();
        }
    }
  },
  apollo: {
    provider: {
      skip: true,
      query: GetProvider,
      variables() {
        return {
          customerId: this.customerId,
          name: this.name,
          npi: this.number,
          skip: this.options.page - 1,
          take: this.options.itemsPerPage,
          sortBy: this.options.sortBy[0],
          sortDesc: this.options.sortDesc[0],
          showAddress: this.showAddress
        };
      },
      update(data) {
        this.isLoading = true;
        this.entries = data.customer.providers.data;
        this.totalRecords = data.customer.providers.count;
        this.isLoading = false;
        return data.provider;
      }
    }
  },
  computed: {
    rowsPerPage() {
      return this.$rowsPerPage;
    },
    headers() {
        if (this.patientSiteId) {
            return this.baseHeaders.filter(header => header.text !== 'DUZ');
        } else {
            return this.baseHeaders.filter(header =>
                header.text !== 'ProviderDUZ' && header.text !== 'LocationIEN'
            );
        }
    }
  },
  watch: {
    options: {
      handler() {
        this.refreshSearch();
      },
      deep: true,
    },
    patientSiteId: {
        immediate: true,
        handler() {
            this.refreshSearch();
        }
    },
  },

}
</script>
