<template>
  <div>
      <v-dialog v-model="dialog" :max-width="dialogWidth" v-if="dialog">
           <v-card>
             <v-card-title>
                  <v-icon medium class="pr-2">
                     mdi-account-clock
                 </v-icon>
                 <span> {{ headerText }}</span>            
             </v-card-title>
             <v-card-text v-if="bodyText1">
               {{ bodyText1 }}
             </v-card-text>
             <v-card-text v-if="enableTimer" class="text-center text-h5" >
               {{ timerFormatted }}
             </v-card-text> 
             <v-card-text v-if="bodyText2">
               {{ bodyText2 }}
             </v-card-text>  
             <v-card-actions class="pa-5">
          <v-btn class="w-50" color="primary" block @click="close">OK</v-btn>
        </v-card-actions>    
           </v-card>
         </v-dialog>
    </div>
</template>

<script>
import { TaskTimer } from 'tasktimer';
export default {
  name: "DialogPrompt",
  props: {
    headerText: {
      type: String,
    },
    bodyText1: {
      type: String
    },
    bodyText2: {
      type: String
    },
    enableTimer: {
      type: Boolean
    },
    dialogWidth: {
      type: Number,
      default: 490
    }
  },
  data: () => ({
    timeLeft: 600000,
    dialog: true,
    timer: {}
  }),
  created() {
    this.timer = new TaskTimer(1000);
    this.timer.on('tick', () => this.elapsed());
    this.timer.start();
  },
  unmounted() {
    this.timer.stop();
  },
  computed: {
    timerFormatted() {
      let timeSeconds = this.enableTimer ? Math.floor((this.timeLeft / 1000) % 60) : null
      let timeMinutes = this.enableTimer ? Math.floor((this.timeLeft / 1000 / 60) % 60) : null
      let formattedMinutes = timeMinutes < 10 ? '0' + timeMinutes : timeMinutes
      let formattedSeconds = timeSeconds < 10 ? '0' + timeSeconds : timeSeconds
      return formattedMinutes + ':' + formattedSeconds
    },
  },  
  methods: {
    close() {
      this.timer.stop()
      this.dialog = false
    },
    elapsed() {
      this.timeLeft -= 1000;
      if (this.timeLeft < 1) {
        this.timer.stop();
      }
    }
  }
}
</script>