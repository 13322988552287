<template>
  <v-dialog v-model="show" fullscreen v-if="show">
    <v-card :loading="loading || !this.initialLoad">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Patients By Last Called</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="show = false"> Close </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-data-table v-if="show"
                      :headers="headers"
                      :items="notCalledSinceBucketByPrimaryNurseUserIdDetailsItems"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      class="elevation-1 mt-5"
                      :loading="loading || this.$apollo.loading"
                      :options.sync="options"
                      @update:page="currentPage = $event"
                      @update:items-per-page="itemsPerPage = $event"
                      :server-items-length="notCalledSinceBucketByPrimaryNurseUserIdDetails.count"
                      :footer-props="{ showFirstLastPage: true, 'items-per-page-options': [10, 25, 50, 100, 150] }">
           <template #[`item.patientDisplayName`]="{ item }">
              {{item.patientDisplayName}}
              <v-icon medium class="mr-2" @click="onClick(item)">
                 mdi-open-in-new
              </v-icon>
           </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions v-if="false">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = false">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import GetNotCalledSinceBucketByPrimaryNurseUserIdDetails
  from "@/components/Dashboards/NurseCareManager/queries/notCalledSinceBucketByPrimaryNurseUserIdDetails.graphql";

export default {
  name: "PatientsByWorklistDialog",
  props: {
    data: Array,
    visible: Boolean,
    primaryNurseUserId: Number,
    notCalledSinceBucket: String,
    podId: Number,
    podIds: Array,
    assignedToUserPodIds: Array 
  },
  data: () => ({
    currentPage: 1,
    sortBy: "patientFirstName",
    sortDesc: false,
    sortMap: {
      'patientFirstName': 'PatientFirstName',
      'patientDesignation': 'PatientDesignation',
      'customerName': 'PatientFirstName',
      'lastCalledDaysAgo': 'LastCalledDaysAgo',
      'podName': 'PodName'
    },
    headers: [
      {
        text: "Patient Name",
        align: "start",
        value: "patientDisplayName",
      },
      { text: "Patient Designation", value: "patientDesignation" },
      { text: "Customer", value: "customerName" },
      { text: "Last Called Days Ago", value: "lastCalledDaysAgo" },
      { text: "Pod", value: "podName" },
      { text: "", value: "actions", sortable: false },
    ],
    itemsPerPage: 10,
    loading: false,
    notCalledSinceBucketByPrimaryNurseUserIdDetails: [],
    skipNotCalledSinceBucketByPrimaryNurseUserIdDetailsQuery: true,
    initialLoad: true,
    options: {},
  }),
  components: {
    //
  },
  computed: {
    sortInput() {
      return {
        column: this.sortMap[this.sortBy],
        order:  this.sortDesc ? "desc" : "asc",
      };
    },
    rowsPerPage() {
      return this.$rowsPerPage;
    },
    notCalledSinceBucketByPrimaryNurseUserIdDetailsItems() {
      return this.notCalledSinceBucketByPrimaryNurseUserIdDetails ? this.notCalledSinceBucketByPrimaryNurseUserIdDetails.data : [];
    },
    input() {
      const skip = this.itemsPerPage * (this.currentPage - 1);
      return {
        take: this.itemsPerPage,
        skip: skip ? skip : 0,
        notCalledSinceBucket: this.notCalledSinceBucket,
        primaryNurseUserId: this.primaryNurseUserId,
        podId: this.podId,
        assignedToUserPodIds: this.assignedToUserPodIds,
        sort: this.sortInput
      };
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },
  methods: {
    async triggerQuery() {
      if (!this.initialLoad) {
        return;
      }

      this.$apollo.queries.notCalledSinceBucketByPrimaryNurseUserIdDetails.skip = false;
      await this.$apollo.queries.notCalledSinceBucketByPrimaryNurseUserIdDetails.refetch();
      this.initialLoad = false;

    },
    onClick(e) {
      this.$emit("clicked", e);
    },
    closeDialog() {
      this.show = false;
    }
  },
  created () {
    this.triggerQuery();
  },
  apollo: {
    notCalledSinceBucketByPrimaryNurseUserIdDetails: {
      query: GetNotCalledSinceBucketByPrimaryNurseUserIdDetails,
      variables() {
        return {
          input: this.input,
        };
      },
      update: data => data.notCalledSinceBucketByPrimaryNurseUserIdDetails,
      skip() {
        return this.skipNotCalledSinceBucketByPrimaryNurseUserIdDetailsQuery;
      }
    }
  },
}
</script>

<style scoped>

</style>