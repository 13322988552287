import * as BaseService from './BaseService';
import * as customer from '../types/customer';

export default class ProviderService extends BaseService.BaseService {
    private url = '/api/Provider';

    constructor() {
        super();
    }

    async get(customerId: number, providerId: number): Promise<customer.Provider>{
        let url = this.url + `/${customerId}/${providerId}`;
        let response = (await this.api.get(url)).data as customer.Provider;
        return response;
    }

    async getProviderTypes(customerId: number): Promise<customer.ProviderType>{
        let url = this.url + `/Type/${customerId}`;
        let response = (await this.api.get(url)).data as customer.ProviderType;
        return response;
    }

    async getProviderDuz(customerId: number, providerId: number): Promise<string>{
        let url = this.url + `/Duz/${customerId}/${providerId}`;
        let response = (await this.api.get(url)).data;
        return response;
    }

    async getProviderStation(customerId: number, providerId: number): Promise<string>{
        let url = this.url + `/Station/${customerId}/${providerId}`;
        let response = (await this.api.get(url)).data;
        return response;
    }

    async getProviderByPatientId(customerId: number, patientId: number): Promise<customer.Provider>{
        let url = this.url + `/${customerId}/Patient/${patientId}`;
        let response = (await this.api.get(url)).data as customer.Provider;
        return response;
    }

    async putProvider(customerid: number,id: number, provider: customer.Provider){
        let url = this.url + `/${customerid}/${id}`;
        let response = await this.api.put(url,provider).catch(err => {
            throw err;
        });
        return response;
    }

    async postProvider(customerId: number, patientProvider: customer.Provider){
        let url = `/api/Provider/${customerId}`;
        let response = await this.api.post(url,patientProvider).catch(err => {
            console.log("Post Provider Error: " + err)
            throw err;
        });
        return response;
    }

     async postProviderIdentifier(customerId: number, providerIdentifier: customer.ProviderIdentifier){
        let url = this.url + `/Identifier/${customerId}`;
        let response = await this.api.post(url, providerIdentifier).catch(err => {
            console.log("Post Provider Identifier Error: " + err)
            throw err;
        });
        return response;
    }

    async postProviderSite(customerId: number, providerSite: customer.ProviderSite){
        let url = this.url + `/Site/${customerId}`;
        let response = await this.api.post(url, providerSite).catch(err => {
            console.log("Post Provider Site Error: " + err)
            throw err;
        });
        return response;
    }
}