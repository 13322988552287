<template>
   <v-container>
      <h4 v-if="loaded && !hasData" class="ml-5">No records available.</h4>
      <v-timeline v-if="loaded && hasData" dense>
         <div v-for="(item, index) in patient.patientHistory.subjectiveQuestion" :key="index">
            <v-timeline-item>
               <v-card>
                  <v-col>
                     <interaction-info :description="item.interactionType.description"
                                       :date="item.interactionDateTime" :name="item.updatedByUser.displayName"/>
                     
                     <v-text-field disabled v-if="displayItem(item, 'subjectiveQuestionsTrigger')"  v-model="item.subjectiveQuestionsTrigger" 
                                   label="Have you noticed any color changes, redness, swelling, calluses, blisters, or wounds?"/>
                     
                     <div class="py-2 title" v-if="item.subjectiveQuestionsFindingsAssessment.length > 0">An assessment for each finding</div>
                     <div v-for="(finding, findingIndex) in item.subjectiveQuestionsFindingsAssessment" :key="findingIndex">
                        <v-card tile class="pa-2 mb-4">
                           <v-text-field disabled v-if="displayItem(finding, 'subjectiveQuestionDescribeFinding')" v-model="finding.subjectiveQuestionDescribeFinding" 
                                         label="How would you describe the finding?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'subjectiveQuestionDescribeFindingOther')" v-model="finding.subjectiveQuestionDescribeFindingOther" 
                                         label="If other selected, describe the finding"/>
                           <v-text-field disabled v-if="displayItem(finding, 'subjectiveQuestionLocation')" v-model="finding.subjectiveQuestionLocation" 
                                         label="Where is the subjective finding located?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'subjectiveQuestionFirstNotice')" v-model="finding.subjectiveQuestionFirstNotice" 
                                         label="When did you notice it"/>
                           <v-text-field disabled v-if="displayItem(finding, 'subjectiveQuestionFirstNoticeFreeText')" v-model="finding.subjectiveQuestionFirstNoticeFreeText" 
                                         label="If date unknown, enter notes here:"/>
                           <v-text-field disabled v-if="displayItem(finding, 'subjectiveQuestionHowBig')" v-model="finding.subjectiveQuestionHowBig" 
                                         label="How big is it (diameter)?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'subjectiveQuestionHowBigOther')" v-model="finding.subjectiveQuestionHowBigOther" 
                                         label="If other selected, how big is it (diameter)?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'subjectiveQuestionWhatCausedFinding')" v-model="finding.subjectiveQuestionWhatCausedFinding" 
                                         label="What caused the finding?"/>
                           <v-text-field disabled v-if="displayItem(finding, 'subjectiveQuestionWhatCausedFindingOther')" v-model="finding.subjectiveQuestionWhatCausedFindingOther" 
                                         label="If other selected, enter cause here:"/>
                        
                           <div  v-if="displayItemObject(finding, 'subjectiveQuestionWoundBlister')" >
                              <div class="py-2 title">If wound or blister is selected</div>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterIsOpen')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterIsOpen" 
                                            label="Is it open?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterIsDraining')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterIsDraining" 
                                            label="Is it draining?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterColorDrainage')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterColorDrainage" 
                                            label="If draining, what is the color of the drainage?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterColorDrainageOther')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterColorDrainageOther" 
                                            label="If draining, and other selected enter details here"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterThicknessDrainage')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterThicknessDrainage" 
                                            label="If draining, what thickness is drainage?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterIsThereAnOdor')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterIsThereAnOdor" 
                                            label="If draining, is there an odor?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterIsThereRedness')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterIsThereRedness" 
                                            label="Any redness at the site or around the site?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterIsEdgeWet')"
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterIsEdgeWet" 
                                            label="Is edge wet?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterAnyFlu')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterAnyFlu" 
                                            label="Any flu-like symptoms - fevers, chills, nausea, vomiting?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterBloodSugar')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterBloodSugar" 
                                            label="Have your blood sugar readings been higher than usual?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterProviderAware')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterProviderAware" 
                                            label="Is your provider aware?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterTreatment')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterTreatment" 
                                            label="What treatment did your provider prescribe?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterGettingBetter')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterGettingBetter" 
                                            label="Progress of finding"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterLastPrescribedShoes')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterLastPrescribedShoes" 
                                            label="When did you receive your last pair of prescribed shoes?"/>
                              <v-text-field disabled v-if="displayItem(finding.subjectiveQuestionWoundBlister, 'subjectiveQuestionWoundBlisterWearingPrescribedShoes')" 
                                            v-model="finding.subjectiveQuestionWoundBlister.subjectiveQuestionWoundBlisterWearingPrescribedShoes" 
                                            label="Are you wearing your prescribed shoes?  Including while in your home."/>

                           </div>
                          
                        </v-card>
                     </div>
                  </v-col>
               </v-card>
               <template v-slot:icon>
                  <timeline-avatar :userId="item.updatedByUser.updatedByUserId" :alt="item.updatedByUser.displayName" :src="item.updatedByUser.avatarURL"/>
               </template>
            </v-timeline-item>
         </div>
      </v-timeline>
   </v-container>
</template>

<script>
   import {GetSubjectiveQuestion} from "./queries/patient-history.graphql";
   import {HistoryTabNames} from "@/types/enum";
   import InteractionInfo from "./InteractionInfo";
   import TimelineAvatar from "./TimelineAvatar";
   
   export default {
      name: "Subjective",
      props: {
         patientId: Number,
         customerId: Number
      },
      components: {
         InteractionInfo,
         TimelineAvatar
      },
      data: () => ({
         patient: null,
         loaded: false,
         hasData:false
      }),
      methods: {
         hasInstructionsAcknowledgement(item) {
            return item.subjectiveQuestionPatientInstructions ? "Yes" : "No";
         },
         displayItem(item, propertyName) {
            return typeof item[propertyName] !== "undefined" && item[propertyName] !== null && item[propertyName].length > 0;
         },
         displayItemObject(item, propertyName){
            return typeof item[propertyName] !== "undefined" && item[propertyName] !== null;
         }
      },
      apollo: {
         patient: {
            query: GetSubjectiveQuestion,
            variables() {
               return {
                  customerId: this.customerId,
                  patientId: this.patientId,
                  patientHistoryTab: HistoryTabNames.SubjectiveQuestions
               };
            },
            update(data) {
               this.loaded = true;
               this.hasData = data.customer.patient.patientHistory!==null
                              && data.customer.patient.patientHistory.subjectiveQuestion.length > 0;
               return data.customer.patient;
            }
         }
      }
   };
</script>

<style scoped>

</style>