<template>
   <location-auto-complete :dense="schema['x-props'] && schema['x-props'].dense" 
                           :id="options.idPrefix + fullKey" :disabled="disabled" :label="label" 
                           @input="input" @selected="selected" v-bind:value.sync="value">
   
   </location-auto-complete>
</template>

<script>
   import LocationAutoComplete from "./LocationAutoComplete";
   import {isObject} from "lodash";


   export default {
      components: {LocationAutoComplete},
      // available props are the contextual elements passed by v-jsf to its slots
      props: {
         value: {
            type: String,
            default: ""
         },
         options: {
            type: Object,
            required: true
         },
         schema: {
            type: Object,
            required: true
         },
         fullSchema: {
            type: Object,
            required: true
         },
         fullKey: {
            type: String,
            required: true
         },
         label: {
            type: String,
            default: ""
         },
         htmlDescription: {
            type: String,
            default: ""
         },
         disabled: {
            type: Boolean,
            default: false
         },
         required: {
            type: Boolean,
            default: false
         },
         rules: {
            type: Array,
            required: true
         },
         on: {
            type: Object,
            required: true
         }
      },
      computed: {},
      methods: {
         input: function (val) {
            if (isObject(val)) {
               return;
            }
            this.on.input(val);
         },
         selected: function (val) {
            this.$emit("selected", val);
         }
      }
   };
</script>

<style scoped lang="css">
   .v-input__slot {
      display: block;
   }
</style>