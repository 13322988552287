<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col md="3">
          <v-card elevation="2" outlined>
            <div id="chartOne" class="chart"></div>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card elevation="2" outlined>
            <div id="chartTwo" class="chart"></div>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card elevation="2" outlined>
            <div id="chartThree" class="chart"></div>
          </v-card>
        </v-col>
        <v-col md="3">
          <v-card elevation="2" outlined>
            <div id="chartFour" class="chart"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
// include ECharts main module
import echarts from "echarts/lib/echarts";
import bar from "echarts/lib/chart/bar";
import gauge from "echarts/lib/chart/gauge";
import pie from "echarts/lib/chart/pie";
import line from "echarts/lib/chart/line";
import tooltip from "echarts/lib/component/tooltip";
import title from "echarts/lib/component/title";
//import dark from "echarts/lib/theme/dark";

export default {
  data: () => ({}),
  mounted() {
    // initialize echarts instance with prepared DOM
    let barChart = echarts.init(document.getElementById("chartOne"));
    let gaugeChart = echarts.init(document.getElementById("chartTwo"));
    let pieChart = echarts.init(document.getElementById("chartThree"));
    let lineChart = echarts.init(document.getElementById("chartFour"));
    // draw chart
    barChart.setOption({
      title: {
        text: "Assignments"              
      },
      tooltip: {},
      xAxis: {
        data: ["January", "February", "March", "April", "May", "June"],
      },
      yAxis: {},
      series: [
        {
          name: "Assigned",
          type: "bar",
          data: [5, 20, 36, 10, 10, 20],
        },
      ],
    });

    gaugeChart.setOption({
      title: {
        text: "Trained this Month",
      },
      tooltip: {
        formatter: "{a} <br/>{b} : {c}%",
      },
      series: [
        {
          name: "Patients",
          type: "gauge",
          detail: {
            valueAnimation: true,
            formatter: "{value}",
          },
          data: [
            {
              value: 50,
              name: "Patients",
            },
          ],
        },
      ],
    });

    pieChart.setOption({
      title: {
        text: "Population",
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        top: "5%",
        left: "center",
      },
      series: [
        {
          name: "Patient Population",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "20",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: 735, name: "Trained" },
            { value: 484, name: "Adherent" },           
            { value: 580, name: "Scanned Once" },
            { value: 1048, name: "Disengaged" },
            { value: 300, name: "Non-Renewed" },
          ],
        },
      ],
    });

    lineChart.setOption({
      title: {
        text: "POs/Prescriptions",
      },

      xAxis: {
        type: "category",
        data: ["Jan", "Feb", "Mar", "Apr", "May", "June"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: [820, 932, 901, 934, 1290, 1330],
          type: "line",
          smooth: true,
        },
      ],
    });
  },
};
</script>

<style scoped>
.chart {
  width: 400px;
  height: 400px;
}
</style>