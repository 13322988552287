<template>
   <v-dialog v-if="hasCustomerCrisisCallContent && isOpen"  v-model="isOpen" persistent max-width="600" content-class="confirm-dialog">
      <v-card>
         <v-card-title class="headline primary">
            Crisis Call
         </v-card-title>
         <template v-if="crisisWorkflow.step === 0">
            <v-card-text>Does the {{patientOrMemberText}} have a plan to hurt themselves or someone else?</v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn @click="setWorkflow(2)"> No</v-btn>
               <v-btn @click="setWorkflow(1)"> Yes</v-btn>
            </v-card-actions>
         </template>
         
         <template v-if="crisisWorkflow.step === 1">
            <v-card-text>Does the {{patientOrMemberText}} have a means to carry out the plan (e.g., weapon, medication
                         for overdose, vehicle, etc.)?
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn @click="setWorkflow(4)"> No</v-btn>
               <v-btn @click="setWorkflow(3)">Yes</v-btn>
            </v-card-actions>
         </template>
         
         <template v-if="crisisWorkflow.step === 3">
            <v-card-text>Is anyone with {{patientOrMemberText}} right now that can keep them safely away from lethal
                         means like firearms and drugs?
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn @click="setWorkflow(5)"> No</v-btn>
               <v-btn @click="setWorkflow(5)">Yes</v-btn>
            </v-card-actions>
         </template>
         
         <template v-if="crisisWorkflow.step === 4">
            <v-card-text>
               <ul>
                  <li>Take the {{patientOrMemberText}} seriously.</li>
                  <li>Tell {{patientOrMemberText}} you care about them.</li>
                  <li>Listen to the {{patientOrMemberText}}.</li>
                  <li>Avoid debating the value of life, minimizing the {{patientOrMemberText}}’s problems, or giving
                      advice.
                  </li>
               </ul>
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn @click="setWorkflow(5)"> Next</v-btn>
            </v-card-actions>
         </template>
         
         <template v-if="crisisWorkflow.step === 5">
            <v-card-text v-html="customerCrisisCallContent"></v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn @click="setWorkflow(6)">Next</v-btn>
            </v-card-actions>
         </template>
         
         <template v-if="crisisWorkflow.step === 6">
            <v-card-text>
               Complete documentation and follow up per the
               <a target="_blank" href="https://docs.google.com/document/d/1Wwj2k6rX7cchRDV8BkHcMXgOTIPeilT7/edit">Crisis
                                                                                                                   Call
                                                                                                                   Policy</a>.
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn @click="closeCrisisDialog(0)">Close</v-btn>
            </v-card-actions>
         </template>
         
         <template v-if="crisisWorkflow.step === 2">
            <v-card-text>
               <ul>
                  <li>Encourage them to seek treatment or contact their doctor or therapist.</li>
                  <li>Listen to the {{patientOrMemberText}}.</li>
                  <li>Avoid debating the value of life, minimizing the {{patientOrMemberText}}’s problems, or giving
                      advice.
                  </li>
                  <li>Provide education and resources for future reference by {{patientOrMemberText}}.
                     <ul>
                        <li>National Suicide Prevention Lifeline
                           <ul>
                              <li>988</li>
                              <li>https://988lifeline.org/</li>
                              <li>https://www.veteranscrisisline.net</li>
                           </ul>
                        </li>
                        <li>Crisis Text Line: a global not-for-profit organization providing free mental health texting
                            service through confidential crisis intervention via SMS message
                           <ul>
                              <li>Text HOME to 741741</li>
                           </ul>
                        </li>
                     </ul>
                  </li>
               
               </ul>
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn @click="setWorkflow(6);">Next</v-btn>
            </v-card-actions>
         </template>
      </v-card>
   </v-dialog>

</template>

<script>
   export default {
      name: "CrisisCallDialog",
      props: {
         dialogCrisis: Boolean,
         customerCrisisCallContent: String,
         isPatient: Boolean
      },
      data: () => ({
         crisisWorkflow: {
            step: 0
         }
      }),
      computed: {
         isOpen() {
            return this.dialogCrisis;
         },
         hasCustomerCrisisCallContent() {
            return this.customerCrisisCallContent != null
                   && this.customerCrisisCallContent.length > 0;
         },
         patientOrMemberText() {
            return this.isPatient
                   ? "patient"
                   : "member";
         }
      },
      methods: {
         closeCrisisDialog(workflow) {
            this.setWorkflow(workflow);
            this.$parent.setCrisisDialogVal(false);
         },
         setWorkflow(val) {
            this.crisisWorkflow.step = val;
         }
      }
   };
</script>

<style scoped>

</style>